import {
  useWorkersInfoQuery,
  WorkersInfoQuery,
  useGetJobTypeQuery,
  useGetTeamleadQuery,
  useDepartmentsQuery,
} from "../../generated/graphql";
import { useEffect, useState } from "react";
import styled from "styled-components";
import { useHistory, useParams } from "react-router-dom";
import Table, { Td } from "../styled/Table";
import React from "react";
import WorkerCard from "./WorkerCard";
import ViewModuleRoundedIcon from "@mui/icons-material/ViewModuleRounded";
import ViewHeadlineRoundedIcon from "@mui/icons-material/ViewHeadlineRounded";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import WorkerTable from "./WorkerTable";
import { StyledToggleButton } from "components/styled";
import RTable from "./ReactTable/WorkerReactTable";

const Grid = styled.div`
  display: grid;
  grid-template-columns: 250px 250px 250px;
  grid-gap: 2vw;
`;

const ViewButton = styled.button`
  background-color: white;
  border: none;
  color: grey;
  padding: 12px 16px;
  cursor: pointer;
  &: hover {
    color: black;
  } ;
`;

const ResetButton = styled.button`
  border: 0px solid;
  background-color: #c0c0c0;
  border-color: #d3d3d3;
  margin: 5px 10px 5px 0px;
  padding: 3px 7px;
  border-radius: 3px;
  font-size: small;
  font-weight: normal;
  line-height: normal;
  font-family: Inter;
  color: #404040;
  border-radius: 3px;
  &: hover {
    background-color: #fb888a;
  }
  cursor: pointer;
`;

const Search = styled.input`
  margin: 5px 10px 5px 0px;
  padding: 1px 7px;
  border: 1px solid #d3d3d3;
  border-radius: 3px;
`;

type Department = {
  name?: string;
  workers?: WorkersInfoQuery["hr_cdm_workers"];
};

const Team = (props: any) => {
  const { path, block, part } = useParams<{
    path?: string;
    block?: string;
    part?: string;
  }>();

  const history = useHistory();

  const { loading, data: dataWorkes } = useWorkersInfoQuery();
  const { data: datajobtype } = useGetJobTypeQuery();
  const { data: datalead } = useGetTeamleadQuery();
  const { data: dataDep } = useDepartmentsQuery();

  const [filteredWorkers, setFilteredWorkers] = useState<
    WorkersInfoQuery["hr_cdm_workers"]
  >();
  const wn = dataWorkes?.hr_cdm_workers as WorkersInfoQuery["hr_cdm_workers"];

  var JobTypes: Array<string> = [];
  datajobtype?.hr_cdm_jobtypes.forEach((type) => JobTypes.push(type.cdm_name!));

  var Teamleads: Array<string> = [];
  datalead?.hr_arm_teamleadassignment.forEach((lead) =>
    Teamleads.push(lead._cdm_wokerid.toLowerCase())
  );
  const [search, setSearch] = useState("");
  const [searchdep, setSearchDep] = useState("");
  const [searchtype, setSearchType] = React.useState<Array<string>>([]);

  const [tl, setTL] = useState(false);
  const [empl, setEmpl] = useState(false);
  const [contr, setContr] = useState(false);
  const [departments, setDepartments] = useState<Department[] | undefined>([]);
  const [filteredDeps, setFilteredDeps] = useState<Department[] | undefined>(
    []
  );
  const [isTable, setIstable] = React.useState(true);

  const handleFormat = (
    event: React.MouseEvent<HTMLElement>,
    newType: string[]
  ) => {
    setSearchType(newType);
  };

  useEffect(() => {
    let departments: Department[] = [];
    if (dataDep) {
      for (let i = 0; i < dataDep?.hr_cdm_departments?.length; i++) {
        const department = { ...dataDep?.hr_cdm_departments[i] };
        const workers = dataWorkes?.hr_cdm_workers.filter(
          (w) =>
            w.cdm_positionworkerassignmentmaps[0].cdm_jobposition
              ?.cdm_department?.cdm_name === department.cdm_name
        );
        departments.push({
          name: department.cdm_name?.toString(),
          workers: workers,
        });
      }
      setDepartments(departments);
    }
  }, [dataDep, dataWorkes]);

  useEffect(() => {
    if (departments) {
      const dep = departments.map((d) => {
        const workers = d.workers?.filter(
          (worker) =>
            (worker?.cdm_fullname
              ?.toLowerCase()
              .includes(search.toLowerCase()) ||
              worker?.cdm_lastnameukr_custom
                ?.toLowerCase()
                .concat(" ", worker?.cdm_firstnameukr_custom!.toLowerCase())
                .includes(search.toLowerCase())) &&
            worker?.cdm_positionworkerassignmentmaps[0].cdm_jobposition?.cdm_department?.cdm_name
              ?.toLocaleLowerCase()
              .includes(searchdep.toLocaleLowerCase()) &&
            (searchtype.length !== 0
              ? searchtype.includes(
                  worker?.cdm_positionworkerassignmentmaps[0].cdm_jobposition
                    .cdm_job?.cdm_jobtype?.cdm_name!
                )
              : JobTypes.includes(
                  worker?.cdm_positionworkerassignmentmaps[0].cdm_jobposition
                    .cdm_job?.cdm_jobtype?.cdm_name!
                )) &&
            (tl === true ? Teamleads.includes(worker.cdm_workerid) : {}) &&
            (empl === true
              ? worker.cdm_type?.toString().includes("754400000")
              : {}) &&
            (contr === true
              ? worker.cdm_type?.toString().includes("754400001")
              : {})
        );

        return { name: d.name, workers };
      });
      setFilteredDeps(dep);
    }
  }, [search, searchdep, searchtype, tl, wn, empl, contr, departments]);

  // useEffect(() => {
  //   if (wn) {
  //     const workname = wn?.filter(
  //       (worker) =>
  //         (worker?.cdm_fullname?.toLowerCase().includes(search.toLowerCase()) ||
  //           worker?.cdm_lastnameukr_custom
  //             ?.toLowerCase()
  //             .concat(" ", worker?.cdm_firstnameukr_custom!.toLowerCase())
  //             .includes(search.toLowerCase())) &&
  //         worker?.cdm_positionworkerassignmentmaps[0].cdm_jobposition?.cdm_department?.cdm_name
  //           ?.toLocaleLowerCase()
  //           .includes(searchdep.toLocaleLowerCase()) &&
  //         (searchtype.length !== 0
  //           ? searchtype.includes(
  //               worker?.cdm_positionworkerassignmentmaps[0].cdm_jobposition
  //                 .cdm_job?.cdm_jobtype?.cdm_name!
  //             )
  //           : JobTypes.includes(
  //               worker?.cdm_positionworkerassignmentmaps[0].cdm_jobposition
  //                 .cdm_job?.cdm_jobtype?.cdm_name!
  //             )) &&
  //         (tl === true ? Teamleads.includes(worker.cdm_workerid) : {}) &&
  //         (empl === true
  //           ? worker.cdm_type?.toString().includes("754400000")
  //           : {}) &&
  //         (contr === true
  //           ? worker.cdm_type?.toString().includes("754400001")
  //           : {})
  //     );

  //     setFilteredWorkers(workname);
  //   }
  // }, [search, searchdep, searchtype, tl, wn, empl, contr]);

  const setReset = () => {
    setSearch("");
    setSearchDep("");
    setTL(false);
    setSearchType([]);
    setEmpl(false);
    setContr(false);
  };

  if (loading) return <></>;
  return (
    <div>
      <Search
        type="text"
        placeholder="Filter by Name"
        value={search}
        onChange={(e) => setSearch(e.target.value)}
      />

      <Search
        type="text"
        placeholder="Filter by Dep"
        value={searchdep}
        onChange={(e) => setSearchDep(e.target.value)}
      />
      <br />

      {JobTypes.map((type) => (
        <ToggleButtonGroup value={searchtype} onChange={handleFormat}>
          <StyledToggleButton id="JC" value={type}>{type}</StyledToggleButton>
        </ToggleButtonGroup>
      ))}
      <br />

      <StyledToggleButton id="TL" onChange={() => setTL(!tl)} selected={tl}>
        Teamlead
      </StyledToggleButton>
      <br />

      <StyledToggleButton
        id="EM"
        onChange={() => setEmpl(!empl)}
        selected={empl}
      >
        Employee
      </StyledToggleButton>
      <StyledToggleButton
        id="CT"
        onChange={() => setContr(!contr)}
        selected={contr}
      >
        Contractor
      </StyledToggleButton>
      <br />

      <ResetButton onClick={setReset}>Reset filters</ResetButton>

      <div style={{ alignItems: "left" }}>
        <ViewButton onClick={() => setIstable(true)}>
          <ViewModuleRoundedIcon />
        </ViewButton>
        <ViewButton onClick={() => setIstable(false)}>
          <ViewHeadlineRoundedIcon />
        </ViewButton>
      </div>

      {isTable === true && (
        <div>
          {filteredDeps?.map((dep) => (
            <div>
              {dep.workers?.length !== 0 && (
                <>
                  <h2> {dep.name} </h2>
                  <Grid>
                    {dep?.workers?.map((worker) => (
                      <>
                        <WorkerCard worker={worker} Teamleads={Teamleads} />
                      </>
                    ))}
                  </Grid>
                </>
              )}
            </div>
          ))}
        </div>
      )}
      {isTable === false && (
        <div>
          <Table id="myTable">
            {filteredDeps?.map((dep) => (
              <div>
                {dep.workers?.length !== 0 && (
                  <>
                    <h2>{dep.name} </h2>
                    <thead>
                      <tr>
                        <td>group</td>
                        <td>name</td>
                        <td>position</td>
                        <td>tags</td>
                        <td>phone</td>
                        <td>email</td>
                      </tr>
                    </thead>

                    {dep?.workers?.map((worker) => (
                      <>
                        <WorkerTable worker={worker} Teamleads={Teamleads} />
                      </>
                    ))}
                  </>
                )}
              </div>
            ))}
          </Table>
        </div>
      )}
    </div>
  );
};

export default Team;
