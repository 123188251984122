import {
  useWorkerPositionsQuery,
} from "../../generated/graphql";
import styled from "styled-components";
import { useParams } from "react-router-dom";
import { H4, Paper, Text } from "components/styled";
import {
  Timeline,
  TimelineItem,
  TimelineSeparator,
  TimelineConnector,
  TimelineContent,
  TimelineDot,
  TimelineOppositeContent,
} from "@mui/lab";
import WorkRoundedIcon from "@mui/icons-material/WorkRounded";
import { FiBriefcase } from "react-icons/fi";

const Grid = styled.div`
  display: grid;
  grid-template-columns: 150px 250px;
`;

const WorkerPositions = () => {
  const { block } = useParams<{
    path?: string;
    block?: string;
    part?: string;
  }>();

  const { loading, data } = useWorkerPositionsQuery({
    variables: { id: block },
    context: {
      headers: {
        "x-hasura-role": "user",
      },
    },
  });

  const options: any = { dateStyle: "short" };

  const pos = data?.hr_cdm_workers[0].cdm_positionworkerassignmentmaps;
  const empl = data?.hr_cdm_workers[0].cdm_employments[0];
  let startdate;
  if (empl?.cdm_employmentstartdate)
  {startdate = "Works from " + new Date(empl?.cdm_employmentstartdate).toLocaleString(
    "ru",
    options
  );}
  else{
    startdate = "Not employed"
  }

  if (loading) return <></>;
  return (
    <div style={{ paddingBottom: "20px" }}>
      <Paper style={{ padding: 20 }}>
        <div style={{ position: "relative", height: "80px" }}>
          <div
            style={{
              position: "absolute",
              right: 0,
              width: "300px",
              textAlign: "right",
            }}
          >
            <H4>position assignment history</H4>
          </div>
          <div
            style={{
              position: "absolute",
              left: 0,
              width: "300px",
              textAlign: "left",
            }}
          >
            <H4> {startdate}</H4>
          </div>
        </div>
        <>
          <Timeline >
            {pos?.map((history) => (
              <>
                <TimelineItem>
                  <TimelineOppositeContent
                    style={{ flex: 0.2, marginRight: "20px" }}
                  >
                    <p style={{margin: "0px"}}>
                      {new Date(history.cdm_validfrom).toLocaleString("ru", options)}
                    </p>
                  </TimelineOppositeContent>
                  <TimelineSeparator>
                    {new Date(history.cdm_validto).valueOf() > Date.now() ? (
                      <TimelineDot style={{ backgroundColor: "#649c4c" }}>
                        <FiBriefcase />
                      </TimelineDot>
                    ) : (
                      <TimelineDot>
                        <FiBriefcase />
                      </TimelineDot>
                    )}
                    <TimelineConnector />
                  </TimelineSeparator>

                  <TimelineContent
                    style={{ marginBottom: "30px", marginLeft: "20px" }}
                  >
                    <Paper style={{ padding: "20px" }}>
                      <H4 style={{ marginBottom: "10px", marginTop: "10px" }}>
                        {history.cdm_jobposition?.cdm_description}
                      </H4>
                      <Grid>
                        <Text> group:</Text>
                        <Text>
                          {history.cdm_jobposition?.cdm_department?.cdm_name}
                        </Text>
                        <Text>end date:</Text>
                        {new Date(history.cdm_validto).getFullYear() > 2144 ? (
                          <Text>∞</Text>
                        ) : (
                          <Text>
                            {new Date(history.cdm_validto).toLocaleString(
                              "ru",
                              options
                            )}
                          </Text>
                        )}
                      </Grid>
                    </Paper>
                  </TimelineContent>
                </TimelineItem>
              </>
            ))}
          </Timeline>
        </>
      </Paper>
    </div>
  );
};

export default WorkerPositions;
