import React from "react";
import { useHistory } from "react-router-dom";
import { useAuth } from "@nhost/react-auth";

const AuthGate = (props: any) => {
  const { signedIn } = useAuth();
  const history = useHistory();

  if (signedIn === null) {
    return <div>Loading...</div>;
  }

  if (!signedIn) {
    history.push("/login");
  }

  // user is logged in
  return props.children;
};

export default AuthGate;
