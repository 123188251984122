import React from "react";
import styled from "styled-components";

const StyledText = styled.p`
  margin:8px 0;
`;

export const Text = (props: any) => {
  return <StyledText {...props} />;
};
