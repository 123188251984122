import {
  Dialog,
  Select,
  MenuItem,
  Button,
  Checkbox,
  TextField,
  Autocomplete,
} from "@mui/material";
import { useState } from "react";
import styled from "styled-components";
import { useWorkersInfoQuery } from "../../../generated/graphql";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";

const StyledPage = styled.div`
  font-family: Inter;
  font-size: 14px;
  padding: 20px;
`;

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

function PartnersModal(props: any) {
  const { data: dataWorkes } = useWorkersInfoQuery();
  //@ts-ignore
  const names: any[] = dataWorkes?.hr_cdm_workers?.map((t: any) => {
    return t?.cdm_fullname;
  });

  const [click, setClick] = useState(false);
  const [personName, setPersonName] = useState([]);

  const [personNameApp, setPersonNameApp] = useState([]);

  const CloseAll = () => {
    setPersonName([]);
    setPersonNameApp([]);
    setClick(false);
  };

  // console.log(personName)
  // console.log(personNameApp)\

  const keys = ['id'],
  filtered = props.arr.filter(
     (s => (o:any) => 
         (k => !s.has(k) && s.add(k))
         (keys.map(k => o[k]).join('|'))
     )
     (new Set)
 );

  return (
    <>
      <button onClick={() => setClick(true)}>Generate</button>
      <Dialog open={click}>
        <StyledPage>
          <div style={{ margin: "30px" }}>
            <h1>Approve partners list</h1>

            <h3>Receivers</h3>
            <Autocomplete
              multiple
              options={names}
              onChange={(event, value: any) => setPersonName(value)}
              disableCloseOnSelect
              getOptionLabel={(option) => option}
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {option}
                </li>
              )}
              style={{ width: 500 }}
              renderInput={(params) => <TextField {...params} />}
            />

            <h3>Approvers</h3>
            <Autocomplete
              multiple
              options={names}
              onChange={(event, value: any) => setPersonNameApp(value)}
              disableCloseOnSelect
              getOptionLabel={(option) => option}
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 8 }}
                    checked={selected}
                  />
                  {option}
                </li>
              )}
              style={{ width: 500 }}
              renderInput={(params) => <TextField {...params} />}
            />
             <h3>Changes</h3>
            {filtered.map((a:any)=>(
              <p>{"Assign "}{a?.new?.toLowerCase()}{" role to "}{a?.name}</p>
            ))}
            <br />
            <Button variant="contained" onClick={CloseAll}> Approve </Button>
          </div>
        </StyledPage>
      </Dialog>
    </>
  );
}

export default PartnersModal;
