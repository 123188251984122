import {
    useTable,
    useGroupBy,
    useExpanded,
    useSortBy,
    useFilters,
    useGlobalFilter,
    usePagination,
  } from "react-table";
  import GlobalFilter from "../../hr/ReactTable/Components/GlobalFilter";
  import Department from "../../hr/ReactTable/DecomposeTable/TableDepartment";
  import Pagination from "../../hr/ReactTable/Components/Pagination";
  import { FiArrowDown, FiArrowUp } from "react-icons/fi";
  import {useMemo} from 'react';
  import DefaultColumnFilter from "./DefaultFilter";


  function MainRTableDiv({ columns, data }:any) {

   
    const defaultColumn = useMemo(
      () => ({
        // Let's set up our default Filter UI
        Filter: DefaultColumnFilter,
      }),
      []
    )
   
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        state,
        preGlobalFilteredRows,
        prepareRow,
        setGlobalFilter,
        state: { groupBy, expanded },
      } = useTable(
        {
          columns,
          data: data,
          initialState: { groupBy: ["Партнер", "Макро"] },
          defaultColumn, // Be sure to pass the defaultColumn option
      
        },
        
        
        useFilters, // useFilters!
        useGroupBy,
        useGlobalFilter, // useGlobalFilter!
        useSortBy,
        useExpanded,
      );
    
   
    
      // Render the UI for your table
      return (
        <table {...getTableProps()}>
         <thead>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <th {...column.getHeaderProps()}>
                    <span {...column.getSortByToggleProps()}>
                      {column.render('Header')}{' '}
                      {/* Add a sort direction indicator */}
                      {column.isSorted
                        ? column.isSortedDesc
                          ? <FiArrowDown  size={17} />
                          : <FiArrowUp  size={17} />
                        : ''}
                    </span>
                  {column.canGroupBy ? (
                    // If the column can be grouped, let's add a toggle
                    <span {...column.getGroupByToggleProps()}>
                      {column.isGrouped ? ' 🛑 ' : ' 👊 '}
                    </span>
                  ) : null}
                  <div>{column.canFilter ? column.render('Filter') : null}</div>
                </th>
              ))}
            </tr>
          ))}
        </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row, i) => {
              prepareRow(row)
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map(cell => {
                    return (
                      <td
                        // For educational purposes, let's color the
                        // cell depending on what type it is given
                        // from the useGroupBy hook
                        {...cell.getCellProps()}
                        style={{
                          background: cell.isGrouped
                            ? '#ebebea'
                            : cell.isAggregated && cell?.value?.charAt(0) != "-"
                            ? '#fbfbf9'
                            : cell.isAggregated && cell?.value?.charAt(0) == "-"
                            ? '#ef9a9a'
                            : cell.isPlaceholder
                            ? '#F7F6F3'
                            :  parseFloat(cell.value.split(",").join("")) < 0 ? '#ef9a9a' : 'white',
                        }}
                      >
                        {cell.isGrouped ? (
                          // If it's a grouped cell, add an expander and row count
                          <div style={{display:'inline-flex', whiteSpace: "nowrap"}}>
                            <span {...row.getToggleRowExpandedProps()}>
                              {row.isExpanded ? '▾' : '▸'}
                            </span>{' '}
                            {cell.render('Cell')} ({row.subRows.length})
                          </div>
                        ) : cell.isAggregated ? (
                          // If the cell is aggregated, use the Aggregated
                          // renderer for cell
                          cell.render('Aggregated')
                        ) : cell.isPlaceholder ? null : ( // For cells with repeated values, render null
                          // Otherwise, just render the regular cell
                          cell.render('Cell')
                        )}
                      </td>
                    )
                  })}
                </tr>
              )
            })}
          </tbody>
        </table>
      )

  }

  export default MainRTableDiv;