import React from "react";
import styled from "styled-components";

const StyledH4 = styled.h4`
  font-size: 1rem;
  font-weight: 500;
`;

export const H4 = (props: any) => {
  return <StyledH4 {...props} />;
};
