import styled from "styled-components";

const StyledPaper = styled.div`
/* box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2); */
/* border: 1px solid #d8d7d1; */
transition: 0.3s;
border-radius: 5px;
transition: 20ms ease-in 0s;
box-shadow: rgb(15 15 15 / 10%) 0px 0px 0px 1px, rgb(15 15 15 / 10%) 0px 2px 4px;

`;

export const Paper = (props: any) => {
  return <StyledPaper {...props} />;
};
