import * as React from "react";
import MainRTableDiv from "./MainTable";
import { Tag, StyleTable } from "../../../styled";
import _ from "lodash";
import { useHistory, useParams } from "react-router-dom";
import styled from "styled-components";
import { useState } from "react";
import { FiEdit3 } from "react-icons/fi";

const Select = styled.select`
  border-color: #b5b5b5;
  border-radius: 3px;
  &: visited {
    border-color: red;
    border-radius: 3px;
  }
`;

function RTableDiv(props: any) {
  const RTag = ({ values }: any) => {
    return (
      <>
        {values?.map((value: any, idx: any) => {
          return !value?.val ? (
            <></>
          ) : (
            <Tag key={idx} id={value?.id}>
              {value?.val}
            </Tag>
          );
        })}
      </>
    );
  };
  const { path, block, part } = useParams<{
    path?: string;
    block?: string;
    part?: string;
  }>();
  const history = useHistory();

  const columns = React.useMemo(
    () => [
      {
        Header: "group",
        //@ts-ignore
        accessor: (worker: any) =>
          worker.cdm_positionworkerassignmentmaps[0]?.cdm_jobposition
            ?.cdm_department?.cdm_name,
        canGroupBy: true,
        Cell: ({ value }: any) => <div>{value}</div>,
      },
      {
        Header: "name",
        accessor: (worker: any) => worker?.cdm_fullname,
        Cell: ({ value, row }: any) => {
          return (
            <div
              onClick={() =>
                //@ts-ignore
                history.push(`/${path}/${row.original.cdm_workerid}/info`)
              }
              style={{ whiteSpace: "nowrap", cursor: "pointer" }}
            >
              {value}
            </div>
          );
        },
        canGroupBy: false,
      },
      {
        Header: "position",
        accessor: (worker: any) =>
          worker.cdm_positionworkerassignmentmaps[0]?.cdm_jobposition
            ?.cdm_description,
        Cell: ({ value }: any) => (
          <div style={{ whiteSpace: "nowrap" }}>{value}</div>
        ),
        canGroupBy: false,
      },
      {
        Header: "tags",
        //@ts-ignore
        accessor: (worker: any) => [
          {
            val: worker.cdm_positionworkerassignmentmaps[0]?.cdm_jobposition
              ?.cdm_job?.cdm_jobtype?.cdm_name!,
            id: "JC",
          },
          worker.cdm_type!.toString() === "754400000"
            ? worker.cdm_positionworkerassignmentmaps[0]?.cdm_jobposition
                ?.cdm_description
              ? { val: "Employee", id: "EM" }
              : { val: "Not employed", id: "Nn" }
            : { val: "Contractor", id: "CT" },
          {
            val: props.Teamleads.includes(worker.cdm_workerid) && "Teamlead",
            id: "TL",
          },
        ],
        //@ts-ignore
        Cell: ({ cell: { value } }) => <RTag values={value} />,
        canGroupBy: false,
      },
      {
        Header: "role",
        accessor: (worker: any) =>
          worker.arm_partnerroleasiignment[0]?.role?.name,
        canGroupBy: false,
        Cell: ({ value, row }: any) => {
          const [val, setVal] = useState(value);
          const [col, setCol] = useState("white");
          const opt = ["Founder", "Partner", "Candidate"];

          const onChange = (e: any) => {
            setVal(e.target.value);
            if (e.target.value === value) {
              setCol("white");
            } else if (e.target.value !== value) {
              setCol("red");
            }
          };

          const te = props?.changesArr
            ?.map((t: any) => {
              return t.id;
            })
            .indexOf(row?.original?.cdm_workerid);

          if (props?.changesArr[te]?.new && value === val) {
            props.changesArr.splice(te, 1);
            props.setArr(props.changesArr);
          } 
          
          else if (
            props?.changesArr[te]?.new &&
            props.changesArr[te] !== val
          ) {
            props.changesArr[te].new = val;
          }
          
          else if (value !== val) {
            props.changesArr.push({
              new: val,
              id: row?.original?.cdm_workerid,
              name: row?.original?.cdm_fullname,
            });
            props.setArr(props.changesArr);
          }

          return (
            <div style={{ whiteSpace: "nowrap" }}>
              <Select id="customselect" value={val} onChange={onChange}>
                {opt.map((op: any) => (
                  <option>{op}</option>
                ))}
              </Select>{" "}
              {col === "red" ? <FiEdit3 /> : <></>}
            </div>
          );
        },
      },
      {
        Header: "start date",
        accessor: (worker: any) =>
          worker.arm_partnerroleasiignment[0].start_date,
        Cell: ({ value }: any) => (
          <div style={{ whiteSpace: "nowrap" }}>{value}</div>
        ),
        canGroupBy: false,
      },
      {
        Header: "phone",
        accessor: "cdm_primarytelephone",
        Cell: ({ value }: any) => (
          <div style={{ whiteSpace: "nowrap" }}>{value}</div>
        ),
        canGroupBy: false,
      },
      {
        Header: "email",
        accessor: "cdm_primaryemailaddress",
        canGroupBy: false,
        Cell: ({ value }: any) => <div>{value}</div>,
      },
    ],
    []
  );
  return (
    <StyleTable>
      <MainRTableDiv columns={columns} data={props?.worker} />
    </StyleTable>
  );
}

export default RTableDiv;
