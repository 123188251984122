import React from 'react'
import { Typography } from '@mui/material'
export default function NotFound(){

  return <div style={{position:'absolute', top:0, left:0, right:0, bottom:0, backgroundColor:'#000', display:'flex', justifyContent:'center', alignItems:'center'}}>
    <div>
      <Typography variant="h1" style={{color: 'white'}}>404</Typography>
      <Typography variant="h5" style={{color: 'white'}}>Page not found</Typography>
    </div>
  </div>
}