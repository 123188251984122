import styled from "styled-components";
import { useHistory, useParams } from "react-router-dom";
import {CardContent,CardMedia } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import { H4, Text, HoveredPaper, Link, Tag } from "../styled";




const Focusable = styled.div`
  user-select: none;
  transition: 20ms ease-in 0s;
  cursor: pointer;
  flex-shrink: 1;
  padding: 0px 4px;
  margin-left: -4px;
  border-radius: 3px;
  color: inherit;
  &:hover {
    background-color: rgba(55, 53, 54, 0.08);
  }
`;

const WorkerCard = (props: any) => {
  const { path, block, part } = useParams<{
    path?: string;
    block?: string;
    part?: string;
  }>();

  const history = useHistory();

  return (
    <HoveredPaper
      onClick={() => history.push(`/${path}/${props.worker.cdm_workerid}/info`)}
    >
      <CardMedia
        style={{ borderRadius: "5px 5px 0 0" ,minHeight:'200px'}}
        
        image={
          "https://bby-maps.azureedge.net/users/" +
          props.worker.cdm_workerid +
          ".jpg"
        }
      />
      <CardContent style={{ padding: "0px 16px 24px" }}>
        <H4>{props.worker.cdm_fullname}</H4>

        <Text>
          <Tag id="JC">
            {
              props.worker.cdm_positionworkerassignmentmaps[0].cdm_jobposition
                ?.cdm_job?.cdm_jobtype?.cdm_name!
            }
          </Tag>
          {props.worker.cdm_type!.toString() === "754400000" ? (
            <Tag id="EM">{"Employee"}</Tag>
          ) : (
            <Tag id="CT">{"Contractor"}</Tag>
          )}

          {props.Teamleads.includes(props.worker.cdm_workerid) ? (
            <Tag id="TL">Teamlead</Tag>
          ) : (
            <></>
          )}
        </Text>

        <Text>
          {
            props.worker.cdm_positionworkerassignmentmaps[0].cdm_jobposition
              ?.cdm_description
          }
        </Text>
        <Text>
          {props.worker.cdm_lastnameukr_custom}{" "}
          {props.worker.cdm_firstnameukr_custom}{" "}
          {props.worker.cdm_patronymicukr_custom}
        </Text>
        <Text>
          <Link href={"tel:"+props.worker.cdm_primarytelephone}>
            {props.worker.cdm_primarytelephone}
          </Link>
        </Text>
        <Text>
          <Focusable>
            <Link href={"mailto:"+props.worker.cdm_primaryemailaddress}>
              {props.worker.cdm_primaryemailaddress}
            </Link>
          </Focusable>
        </Text>
      </CardContent>
    </HoveredPaper>
  );
};

export default WorkerCard;
