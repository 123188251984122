import React from "react";
import styled from "styled-components";
import { nav } from "../config";
import Projects from "./ppm/Projects";
import { Route, Switch, useParams } from "react-router-dom";
// import Project from "./ppm/Project";
import Team from "./hr/Team";
import OfficeMap from "./hr/OfficeMap";
import Worker from "./hr/Worker";
import Partners from "./hr/Partners/Partners";
import Dividends from "./ppm/Dividends";
// import WorkerDividends from "./ppm/WorkerDividends";
import Login from "./Login";
import SelectForm from './hr/Forms/MainPage';

interface Ipath {
  path?: string;
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  z-index: 1;
  flex-grow: 1;
  position: relative;
  align-items: center;
  overflow: auto;
  margin-right: 0px;
  margin-bottom: 0px;
  overflow: scroll;
`;

const Content = styled.div`
  width: 768px;
  max-width: 768px;
`;

const PageHeader = styled.div`
  display: flex;
  flex-direction: row;
  height: 64px;
  min-height: 64px;
  max-height: 64px;
  width: 100%;
  margin: 16px 0;
  padding-left: 16px;
  padding-right: 16px;
  justify-content: flex-end;
`;

const H1 = styled.h1`
  text-transform: capitalize;
  font-weight: 700;
`;

const PageContent: React.FC<Ipath> = (props) => {
  const { path, block, part } = useParams<{
    path?: string;
    block?: string;
    part?: string;
  }>();
  console.log(block);
  const page = nav?.blocks
    ?.flatMap((p) =>
      p.content?.find((v) => v.path?.replaceAll("/", "") === path)
    )
    .flatMap((f) => f)
    .filter(Boolean)[0];
  console.log(page);
  return (
    <Wrapper>
      <PageHeader>
        <Login />
      </PageHeader>
      {block ? (
        <Content>
          {/* {path === "projects" && <Project />} */}
          {path === "team" && <Worker />}
          {path === "partners" && <Worker />}
          {/* {path === "dividends" && <WorkerDividends />} */}
        </Content>
      ) : (
        <Content>
          <H1>
            {page?.emoji} {page?.name}
          </H1>
          {page?.description}
          {page?.name === "projects" && <Projects />}
          {page?.name === "team" && <Team />}
          {page?.name === "manage" && <SelectForm />}
          {page?.name === "office map" && <OfficeMap />}
          {page?.name === "dividends" && <Dividends />}
          {page?.name === "partners" && <Partners />}
        </Content>
      )}
    </Wrapper>
  );
};

export default PageContent;
