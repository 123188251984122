import {
  useWorkerDetailInfoBasicQuery,
  useMyTestQuery,
} from "../../generated/graphql";
import styled from "styled-components";
import { useHistory, useParams } from "react-router-dom";
import { Avatar } from "@mui/material";
import { H1, Text, Link } from "../styled";
import WorkerCompensation from "./WorkerCompensation";
import WorkerLeave from "./WorkerLeave";
import WorkerPositions from "./WorkerPositions";
import WorkerPayHistory from "./WorkerPayHistory";

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 2vw;
  align-items: center;
`;
const Nav = styled.div`
  display: flex;
  direction: row;
  margin: 15px 0;
`;

const Button = styled.button`
  border-top: none;
  border-left: none;
  border-right: none;
  padding: 10px 0;
  background-color: white;
  border-bottom: solid 2px white;
  cursor: pointer;
  margin: 0 10px;
  &:hover {
    background-color: #efefef;
  }
  &:active {
    border-bottom: solid 2px black;
  }
  &:focus {
    border-bottom: solid 2px black;
  }
`;

const Worker = (props: any) => {
  const { path, block, part } = useParams<{
    path?: string;
    block?: string;
    part?: string;
  }>();
  const history = useHistory();
  const { loading, data } = useWorkerDetailInfoBasicQuery({
    variables: { id: block },
    context: {
      headers: {
        "x-hasura-role": "me",
      },
    },
  });

  let workerNav;

  const { data: user1 } = useMyTestQuery({
    variables: { id: block },
    context: {
      headers: {
        "x-hasura-role": "me",
      },
    },
  });

  if (user1 && user1!.users!) {
    if (user1!.users!.length > 0) {
      workerNav = [
        { name: "Compensation plan", url: "plan" },
        { name: "Leave plan", url: "leave" },
        { name: "Position history", url: "positions" },
      ];
    } else {
      workerNav = [{ name: "Position history", url: "positions" }];
    }
  } else {
    workerNav = [{ name: "Position history", url: "positions" }];
  }

  const options: any = { dateStyle: "short" };

  const worker = data?.hr_cdm_workers[0];
  const wname_ukr = worker?.cdm_firstnameukr_custom;
  const wsurname_ukr = worker?.cdm_lastnameukr_custom;
  const wemail = worker?.cdm_primaryemailaddress;
  const wphone = worker?.cdm_primarytelephone;
  const wbirthdate = new Date(worker?.cdm_birthdate).toLocaleString(
    "ru",
    options
  );
  const wpos =
    worker?.cdm_positionworkerassignmentmaps[0]?.cdm_jobposition
      ?.cdm_description;
  const wpatr = worker?.cdm_patronymicukr_custom;
  const wid = worker?.cdm_workerid;

  return (
    <>
      <H1>{worker?.cdm_fullname}</H1>
      <Grid style={{ marginBottom: "18px" }}>
        <div>
          <Avatar
            src={
              "https://bby-maps.azureedge.net/users/" + wid + ".jpg"
            }
            style={{ height: "200px", width: "200px" }}
          />
        </div>
        <div>
          <p>
            {" "}
            {wsurname_ukr} {wname_ukr} {wpatr}
          </p>
          <p> {wpos} </p>
          <Text>
            <Link href={"tel:" + wphone}>{wphone}</Link>
          </Text>
          <Text>
            <Link href={"mailto:" + wemail}>{wemail}</Link>
          </Text>
          <p> {wbirthdate} </p>
        </div>
      </Grid>
      <Nav>
        {workerNav?.map((nav) => (
          <Button onClick={() => history.push(`/${path}/${block}/${nav.url}`)}>
            {nav.name}
          </Button>
        ))}
      </Nav>

      {part === "plan" && (
        <>
          {" "}
          <WorkerCompensation />
        </>
      )}
      {part === "leave" && (
        <>
          {" "}
          <WorkerLeave />
        </>
      )}
      {part === "positions" && (
        <>
          {" "}
          <WorkerPositions />
        </>
      )}
      {part === "payhistory" && <WorkerPayHistory />}
    </>
  );
};

export default Worker;
