import React, { useEffect } from "react";
import NotFound from "./pages/404";
import { BrowserRouter, Route, Switch, useHistory } from "react-router-dom";

import Page from "./components/Page";
import LoginPage from "./components/Login/LoginPage";
import AuthGate from "./components/AuthGate";

const App = () => {
  return (
    <BrowserRouter>
      <AuthGate>
        <Switch>
          <Route exact path="/">
            <Page />
          </Route>
          <Route exact path="/login">
            <LoginPage />
          </Route>
          <Route exact path="/:path">
            <Page />
          </Route>
          <Route exact path="/:path/:block">
            <Page />
          </Route>
          <Route exact path="/:path/:block/:part">
            <Page />
          </Route>
          <Route>
            <NotFound />
          </Route>
        </Switch>
      </AuthGate>
    </BrowserRouter>
  );
};

export default App;
