import { atom } from "recoil";
import { UserQuery } from "generated";
import { Requests } from "utils/types";

export const user = atom({
  key: "user",
  default: undefined as UserQuery["users_by_pk"],
});

export const requests = atom({
  key: "requests",
  default: {
    requests: [
      {
        request: {
          name: "PartnerAssignmentRequest",
          url: "some endpoint",
        },
        approvers: ["m.holovko@archimatika.com", "yu.paziura@archimatika.com"],
        recievers: ["yu.paziura@archimatika.com"],
      },
    ],
  } as Requests,
});
