import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions =  {}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  bigint: any;
  citext: any;
  date: any;
  float8: any;
  jsonb: any;
  smallint: any;
  timestamp: any;
  timestamptz: any;
  uuid: any;
};


/** Boolean expression to compare columns of type "Boolean". All fields are combined with logical 'AND'. */
export type Boolean_Comparison_Exp = {
  _eq?: Maybe<Scalars['Boolean']>;
  _gt?: Maybe<Scalars['Boolean']>;
  _gte?: Maybe<Scalars['Boolean']>;
  _in?: Maybe<Array<Scalars['Boolean']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['Boolean']>;
  _lte?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Scalars['Boolean']>;
  _nin?: Maybe<Array<Scalars['Boolean']>>;
};

export type Div = {
  ItemInternalId: Scalars['String'];
  /** An object relationship */
  account: Auth_Accounts;
  budget_revision: Scalars['String'];
  coef_cashlees_to_cash_planned: Scalars['String'];
  coef_cashless_to_cash: Scalars['String'];
  currency_code: Scalars['String'];
  dividend_planned_cashless_vat: Scalars['String'];
  dividend_proportion: Scalars['String'];
  dividends_1C: Scalars['String'];
  dividends_accrued_by_closed_projects_cash: Scalars['String'];
  dividends_accrued_cash: Scalars['String'];
  dividends_accrued_cashless_vat: Scalars['String'];
  dividends_accrued_in_prev_cash: Scalars['String'];
  dividends_paid_cash: Scalars['String'];
  dividends_paid_cashless: Scalars['String'];
  dividends_planned_cash: Scalars['String'];
  dividends_worker_email: Scalars['String'];
  dividends_worker_id: Scalars['String'];
  dividends_worker_name: Scalars['String'];
  docs_completion_percent: Scalars['String'];
  leftover_accrual_cash: Scalars['String'];
  leftover_by_closed_projects_cash: Scalars['String'];
  leftover_paid_cash: Scalars['String'];
  leftover_planned_to_pay_cash: Scalars['String'];
  macroproject_name: Scalars['String'];
  odata_etag: Scalars['String'];
  payment_accrued_last_docdate: Scalars['String'];
  payment_accrued_percent: Scalars['String'];
  payment_accrued_percent_2021: Scalars['String'];
  percent_accrual_cash: Scalars['String'];
  percent_paid_cash: Scalars['String'];
  project_enddate: Scalars['String'];
  project_lead_name: Scalars['String'];
  project_manager_name: Scalars['String'];
  project_name: Scalars['String'];
  residual_payout_ratio: Scalars['String'];
  user_role: Scalars['String'];
  user_role_index: Scalars['String'];
  wrok_completion_percent: Scalars['String'];
};

/** Boolean expression to compare columns of type "Float". All fields are combined with logical 'AND'. */
export type Float_Comparison_Exp = {
  _eq?: Maybe<Scalars['Float']>;
  _gt?: Maybe<Scalars['Float']>;
  _gte?: Maybe<Scalars['Float']>;
  _in?: Maybe<Array<Scalars['Float']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['Float']>;
  _lte?: Maybe<Scalars['Float']>;
  _neq?: Maybe<Scalars['Float']>;
  _nin?: Maybe<Array<Scalars['Float']>>;
};

/** Boolean expression to compare columns of type "Int". All fields are combined with logical 'AND'. */
export type Int_Comparison_Exp = {
  _eq?: Maybe<Scalars['Int']>;
  _gt?: Maybe<Scalars['Int']>;
  _gte?: Maybe<Scalars['Int']>;
  _in?: Maybe<Array<Scalars['Int']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['Int']>;
  _lte?: Maybe<Scalars['Int']>;
  _neq?: Maybe<Scalars['Int']>;
  _nin?: Maybe<Array<Scalars['Int']>>;
};

/** Boolean expression to compare columns of type "String". All fields are combined with logical 'AND'. */
export type String_Comparison_Exp = {
  _eq?: Maybe<Scalars['String']>;
  _gt?: Maybe<Scalars['String']>;
  _gte?: Maybe<Scalars['String']>;
  /** does the column match the given case-insensitive pattern */
  _ilike?: Maybe<Scalars['String']>;
  _in?: Maybe<Array<Scalars['String']>>;
  /** does the column match the given POSIX regular expression, case insensitive */
  _iregex?: Maybe<Scalars['String']>;
  _is_null?: Maybe<Scalars['Boolean']>;
  /** does the column match the given pattern */
  _like?: Maybe<Scalars['String']>;
  _lt?: Maybe<Scalars['String']>;
  _lte?: Maybe<Scalars['String']>;
  _neq?: Maybe<Scalars['String']>;
  /** does the column NOT match the given case-insensitive pattern */
  _nilike?: Maybe<Scalars['String']>;
  _nin?: Maybe<Array<Scalars['String']>>;
  /** does the column NOT match the given POSIX regular expression, case insensitive */
  _niregex?: Maybe<Scalars['String']>;
  /** does the column NOT match the given pattern */
  _nlike?: Maybe<Scalars['String']>;
  /** does the column NOT match the given POSIX regular expression, case sensitive */
  _nregex?: Maybe<Scalars['String']>;
  /** does the column NOT match the given SQL regular expression */
  _nsimilar?: Maybe<Scalars['String']>;
  /** does the column match the given POSIX regular expression, case sensitive */
  _regex?: Maybe<Scalars['String']>;
  /** does the column match the given SQL regular expression */
  _similar?: Maybe<Scalars['String']>;
};

/** columns and relationships of "auth.account_providers" */
export type Auth_Account_Providers = {
  /** An object relationship */
  account: Auth_Accounts;
  account_id: Scalars['uuid'];
  auth_provider: Scalars['String'];
  auth_provider_unique_id: Scalars['String'];
  created_at: Scalars['timestamptz'];
  id: Scalars['uuid'];
  /** An object relationship */
  provider: Auth_Providers;
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "auth.account_providers" */
export type Auth_Account_Providers_Aggregate = {
  aggregate?: Maybe<Auth_Account_Providers_Aggregate_Fields>;
  nodes: Array<Auth_Account_Providers>;
};

/** aggregate fields of "auth.account_providers" */
export type Auth_Account_Providers_Aggregate_Fields = {
  count: Scalars['Int'];
  max?: Maybe<Auth_Account_Providers_Max_Fields>;
  min?: Maybe<Auth_Account_Providers_Min_Fields>;
};


/** aggregate fields of "auth.account_providers" */
export type Auth_Account_Providers_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Auth_Account_Providers_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "auth.account_providers" */
export type Auth_Account_Providers_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Auth_Account_Providers_Max_Order_By>;
  min?: Maybe<Auth_Account_Providers_Min_Order_By>;
};

/** input type for inserting array relation for remote table "auth.account_providers" */
export type Auth_Account_Providers_Arr_Rel_Insert_Input = {
  data: Array<Auth_Account_Providers_Insert_Input>;
  /** on conflict condition */
  on_conflict?: Maybe<Auth_Account_Providers_On_Conflict>;
};

/** Boolean expression to filter rows from the table "auth.account_providers". All fields are combined with a logical 'AND'. */
export type Auth_Account_Providers_Bool_Exp = {
  _and?: Maybe<Array<Auth_Account_Providers_Bool_Exp>>;
  _not?: Maybe<Auth_Account_Providers_Bool_Exp>;
  _or?: Maybe<Array<Auth_Account_Providers_Bool_Exp>>;
  account?: Maybe<Auth_Accounts_Bool_Exp>;
  account_id?: Maybe<Uuid_Comparison_Exp>;
  auth_provider?: Maybe<String_Comparison_Exp>;
  auth_provider_unique_id?: Maybe<String_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  provider?: Maybe<Auth_Providers_Bool_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "auth.account_providers" */
export enum Auth_Account_Providers_Constraint {
  /** unique or primary key constraint */
  AccountProvidersAccountIdAuthProviderKey = 'account_providers_account_id_auth_provider_key',
  /** unique or primary key constraint */
  AccountProvidersAuthProviderAuthProviderUniqueIdKey = 'account_providers_auth_provider_auth_provider_unique_id_key',
  /** unique or primary key constraint */
  AccountProvidersPkey = 'account_providers_pkey'
}

/** input type for inserting data into table "auth.account_providers" */
export type Auth_Account_Providers_Insert_Input = {
  account?: Maybe<Auth_Accounts_Obj_Rel_Insert_Input>;
  account_id?: Maybe<Scalars['uuid']>;
  auth_provider?: Maybe<Scalars['String']>;
  auth_provider_unique_id?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  provider?: Maybe<Auth_Providers_Obj_Rel_Insert_Input>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Auth_Account_Providers_Max_Fields = {
  account_id?: Maybe<Scalars['uuid']>;
  auth_provider?: Maybe<Scalars['String']>;
  auth_provider_unique_id?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "auth.account_providers" */
export type Auth_Account_Providers_Max_Order_By = {
  account_id?: Maybe<Order_By>;
  auth_provider?: Maybe<Order_By>;
  auth_provider_unique_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Auth_Account_Providers_Min_Fields = {
  account_id?: Maybe<Scalars['uuid']>;
  auth_provider?: Maybe<Scalars['String']>;
  auth_provider_unique_id?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "auth.account_providers" */
export type Auth_Account_Providers_Min_Order_By = {
  account_id?: Maybe<Order_By>;
  auth_provider?: Maybe<Order_By>;
  auth_provider_unique_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "auth.account_providers" */
export type Auth_Account_Providers_Mutation_Response = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Auth_Account_Providers>;
};

/** on conflict condition type for table "auth.account_providers" */
export type Auth_Account_Providers_On_Conflict = {
  constraint: Auth_Account_Providers_Constraint;
  update_columns?: Array<Auth_Account_Providers_Update_Column>;
  where?: Maybe<Auth_Account_Providers_Bool_Exp>;
};

/** Ordering options when selecting data from "auth.account_providers". */
export type Auth_Account_Providers_Order_By = {
  account?: Maybe<Auth_Accounts_Order_By>;
  account_id?: Maybe<Order_By>;
  auth_provider?: Maybe<Order_By>;
  auth_provider_unique_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  provider?: Maybe<Auth_Providers_Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: auth_account_providers */
export type Auth_Account_Providers_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "auth.account_providers" */
export enum Auth_Account_Providers_Select_Column {
  /** column name */
  AccountId = 'account_id',
  /** column name */
  AuthProvider = 'auth_provider',
  /** column name */
  AuthProviderUniqueId = 'auth_provider_unique_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "auth.account_providers" */
export type Auth_Account_Providers_Set_Input = {
  account_id?: Maybe<Scalars['uuid']>;
  auth_provider?: Maybe<Scalars['String']>;
  auth_provider_unique_id?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** update columns of table "auth.account_providers" */
export enum Auth_Account_Providers_Update_Column {
  /** column name */
  AccountId = 'account_id',
  /** column name */
  AuthProvider = 'auth_provider',
  /** column name */
  AuthProviderUniqueId = 'auth_provider_unique_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** columns and relationships of "auth.account_roles" */
export type Auth_Account_Roles = {
  /** An object relationship */
  account: Auth_Accounts;
  account_id: Scalars['uuid'];
  created_at: Scalars['timestamptz'];
  id: Scalars['uuid'];
  role: Scalars['String'];
  /** An object relationship */
  roleByRole: Auth_Roles;
};

/** aggregated selection of "auth.account_roles" */
export type Auth_Account_Roles_Aggregate = {
  aggregate?: Maybe<Auth_Account_Roles_Aggregate_Fields>;
  nodes: Array<Auth_Account_Roles>;
};

/** aggregate fields of "auth.account_roles" */
export type Auth_Account_Roles_Aggregate_Fields = {
  count: Scalars['Int'];
  max?: Maybe<Auth_Account_Roles_Max_Fields>;
  min?: Maybe<Auth_Account_Roles_Min_Fields>;
};


/** aggregate fields of "auth.account_roles" */
export type Auth_Account_Roles_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Auth_Account_Roles_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "auth.account_roles" */
export type Auth_Account_Roles_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Auth_Account_Roles_Max_Order_By>;
  min?: Maybe<Auth_Account_Roles_Min_Order_By>;
};

/** input type for inserting array relation for remote table "auth.account_roles" */
export type Auth_Account_Roles_Arr_Rel_Insert_Input = {
  data: Array<Auth_Account_Roles_Insert_Input>;
  /** on conflict condition */
  on_conflict?: Maybe<Auth_Account_Roles_On_Conflict>;
};

/** Boolean expression to filter rows from the table "auth.account_roles". All fields are combined with a logical 'AND'. */
export type Auth_Account_Roles_Bool_Exp = {
  _and?: Maybe<Array<Auth_Account_Roles_Bool_Exp>>;
  _not?: Maybe<Auth_Account_Roles_Bool_Exp>;
  _or?: Maybe<Array<Auth_Account_Roles_Bool_Exp>>;
  account?: Maybe<Auth_Accounts_Bool_Exp>;
  account_id?: Maybe<Uuid_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  role?: Maybe<String_Comparison_Exp>;
  roleByRole?: Maybe<Auth_Roles_Bool_Exp>;
};

/** unique or primary key constraints on table "auth.account_roles" */
export enum Auth_Account_Roles_Constraint {
  /** unique or primary key constraint */
  AccountRolesPkey = 'account_roles_pkey',
  /** unique or primary key constraint */
  UserRolesAccountIdRoleKey = 'user_roles_account_id_role_key'
}

/** input type for inserting data into table "auth.account_roles" */
export type Auth_Account_Roles_Insert_Input = {
  account?: Maybe<Auth_Accounts_Obj_Rel_Insert_Input>;
  account_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  role?: Maybe<Scalars['String']>;
  roleByRole?: Maybe<Auth_Roles_Obj_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Auth_Account_Roles_Max_Fields = {
  account_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  role?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "auth.account_roles" */
export type Auth_Account_Roles_Max_Order_By = {
  account_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  role?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Auth_Account_Roles_Min_Fields = {
  account_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  role?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "auth.account_roles" */
export type Auth_Account_Roles_Min_Order_By = {
  account_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  role?: Maybe<Order_By>;
};

/** response of any mutation on the table "auth.account_roles" */
export type Auth_Account_Roles_Mutation_Response = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Auth_Account_Roles>;
};

/** on conflict condition type for table "auth.account_roles" */
export type Auth_Account_Roles_On_Conflict = {
  constraint: Auth_Account_Roles_Constraint;
  update_columns?: Array<Auth_Account_Roles_Update_Column>;
  where?: Maybe<Auth_Account_Roles_Bool_Exp>;
};

/** Ordering options when selecting data from "auth.account_roles". */
export type Auth_Account_Roles_Order_By = {
  account?: Maybe<Auth_Accounts_Order_By>;
  account_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  role?: Maybe<Order_By>;
  roleByRole?: Maybe<Auth_Roles_Order_By>;
};

/** primary key columns input for table: auth_account_roles */
export type Auth_Account_Roles_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "auth.account_roles" */
export enum Auth_Account_Roles_Select_Column {
  /** column name */
  AccountId = 'account_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Role = 'role'
}

/** input type for updating data in table "auth.account_roles" */
export type Auth_Account_Roles_Set_Input = {
  account_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['uuid']>;
  role?: Maybe<Scalars['String']>;
};

/** update columns of table "auth.account_roles" */
export enum Auth_Account_Roles_Update_Column {
  /** column name */
  AccountId = 'account_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Role = 'role'
}

/** columns and relationships of "auth.accounts" */
export type Auth_Accounts = {
  /** An array relationship */
  account_providers: Array<Auth_Account_Providers>;
  /** An aggregate relationship */
  account_providers_aggregate: Auth_Account_Providers_Aggregate;
  /** An array relationship */
  account_roles: Array<Auth_Account_Roles>;
  /** An aggregate relationship */
  account_roles_aggregate: Auth_Account_Roles_Aggregate;
  active: Scalars['Boolean'];
  /** An object relationship */
  cdm_workers?: Maybe<Hr_Cdm_Workers>;
  created_at: Scalars['timestamptz'];
  custom_register_data?: Maybe<Scalars['jsonb']>;
  default_role: Scalars['String'];
  email?: Maybe<Scalars['citext']>;
  id: Scalars['uuid'];
  is_anonymous: Scalars['Boolean'];
  mfa_enabled: Scalars['Boolean'];
  new_email?: Maybe<Scalars['citext']>;
  otp_secret?: Maybe<Scalars['String']>;
  password_hash?: Maybe<Scalars['String']>;
  /** An array relationship */
  refresh_tokens: Array<Auth_Refresh_Tokens>;
  /** An aggregate relationship */
  refresh_tokens_aggregate: Auth_Refresh_Tokens_Aggregate;
  /** An object relationship */
  role: Auth_Roles;
  ticket: Scalars['uuid'];
  ticket_expires_at: Scalars['timestamptz'];
  updated_at: Scalars['timestamptz'];
  /** An object relationship */
  user: Users;
  user_id: Scalars['uuid'];
};


/** columns and relationships of "auth.accounts" */
export type Auth_AccountsAccount_ProvidersArgs = {
  distinct_on?: Maybe<Array<Auth_Account_Providers_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Auth_Account_Providers_Order_By>>;
  where?: Maybe<Auth_Account_Providers_Bool_Exp>;
};


/** columns and relationships of "auth.accounts" */
export type Auth_AccountsAccount_Providers_AggregateArgs = {
  distinct_on?: Maybe<Array<Auth_Account_Providers_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Auth_Account_Providers_Order_By>>;
  where?: Maybe<Auth_Account_Providers_Bool_Exp>;
};


/** columns and relationships of "auth.accounts" */
export type Auth_AccountsAccount_RolesArgs = {
  distinct_on?: Maybe<Array<Auth_Account_Roles_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Auth_Account_Roles_Order_By>>;
  where?: Maybe<Auth_Account_Roles_Bool_Exp>;
};


/** columns and relationships of "auth.accounts" */
export type Auth_AccountsAccount_Roles_AggregateArgs = {
  distinct_on?: Maybe<Array<Auth_Account_Roles_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Auth_Account_Roles_Order_By>>;
  where?: Maybe<Auth_Account_Roles_Bool_Exp>;
};


/** columns and relationships of "auth.accounts" */
export type Auth_AccountsCustom_Register_DataArgs = {
  path?: Maybe<Scalars['String']>;
};


/** columns and relationships of "auth.accounts" */
export type Auth_AccountsRefresh_TokensArgs = {
  distinct_on?: Maybe<Array<Auth_Refresh_Tokens_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Auth_Refresh_Tokens_Order_By>>;
  where?: Maybe<Auth_Refresh_Tokens_Bool_Exp>;
};


/** columns and relationships of "auth.accounts" */
export type Auth_AccountsRefresh_Tokens_AggregateArgs = {
  distinct_on?: Maybe<Array<Auth_Refresh_Tokens_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Auth_Refresh_Tokens_Order_By>>;
  where?: Maybe<Auth_Refresh_Tokens_Bool_Exp>;
};

/** aggregated selection of "auth.accounts" */
export type Auth_Accounts_Aggregate = {
  aggregate?: Maybe<Auth_Accounts_Aggregate_Fields>;
  nodes: Array<Auth_Accounts>;
};

/** aggregate fields of "auth.accounts" */
export type Auth_Accounts_Aggregate_Fields = {
  count: Scalars['Int'];
  max?: Maybe<Auth_Accounts_Max_Fields>;
  min?: Maybe<Auth_Accounts_Min_Fields>;
};


/** aggregate fields of "auth.accounts" */
export type Auth_Accounts_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Auth_Accounts_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "auth.accounts" */
export type Auth_Accounts_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Auth_Accounts_Max_Order_By>;
  min?: Maybe<Auth_Accounts_Min_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Auth_Accounts_Append_Input = {
  custom_register_data?: Maybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "auth.accounts" */
export type Auth_Accounts_Arr_Rel_Insert_Input = {
  data: Array<Auth_Accounts_Insert_Input>;
  /** on conflict condition */
  on_conflict?: Maybe<Auth_Accounts_On_Conflict>;
};

/** Boolean expression to filter rows from the table "auth.accounts". All fields are combined with a logical 'AND'. */
export type Auth_Accounts_Bool_Exp = {
  _and?: Maybe<Array<Auth_Accounts_Bool_Exp>>;
  _not?: Maybe<Auth_Accounts_Bool_Exp>;
  _or?: Maybe<Array<Auth_Accounts_Bool_Exp>>;
  account_providers?: Maybe<Auth_Account_Providers_Bool_Exp>;
  account_roles?: Maybe<Auth_Account_Roles_Bool_Exp>;
  active?: Maybe<Boolean_Comparison_Exp>;
  cdm_workers?: Maybe<Hr_Cdm_Workers_Bool_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  custom_register_data?: Maybe<Jsonb_Comparison_Exp>;
  default_role?: Maybe<String_Comparison_Exp>;
  email?: Maybe<Citext_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  is_anonymous?: Maybe<Boolean_Comparison_Exp>;
  mfa_enabled?: Maybe<Boolean_Comparison_Exp>;
  new_email?: Maybe<Citext_Comparison_Exp>;
  otp_secret?: Maybe<String_Comparison_Exp>;
  password_hash?: Maybe<String_Comparison_Exp>;
  refresh_tokens?: Maybe<Auth_Refresh_Tokens_Bool_Exp>;
  role?: Maybe<Auth_Roles_Bool_Exp>;
  ticket?: Maybe<Uuid_Comparison_Exp>;
  ticket_expires_at?: Maybe<Timestamptz_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  user?: Maybe<Users_Bool_Exp>;
  user_id?: Maybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "auth.accounts" */
export enum Auth_Accounts_Constraint {
  /** unique or primary key constraint */
  AccountsEmailKey = 'accounts_email_key',
  /** unique or primary key constraint */
  AccountsNewEmailKey = 'accounts_new_email_key',
  /** unique or primary key constraint */
  AccountsPkey = 'accounts_pkey',
  /** unique or primary key constraint */
  AccountsUserIdKey = 'accounts_user_id_key'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Auth_Accounts_Delete_At_Path_Input = {
  custom_register_data?: Maybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Auth_Accounts_Delete_Elem_Input = {
  custom_register_data?: Maybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Auth_Accounts_Delete_Key_Input = {
  custom_register_data?: Maybe<Scalars['String']>;
};

/** input type for inserting data into table "auth.accounts" */
export type Auth_Accounts_Insert_Input = {
  account_providers?: Maybe<Auth_Account_Providers_Arr_Rel_Insert_Input>;
  account_roles?: Maybe<Auth_Account_Roles_Arr_Rel_Insert_Input>;
  active?: Maybe<Scalars['Boolean']>;
  cdm_workers?: Maybe<Hr_Cdm_Workers_Obj_Rel_Insert_Input>;
  created_at?: Maybe<Scalars['timestamptz']>;
  custom_register_data?: Maybe<Scalars['jsonb']>;
  default_role?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['citext']>;
  id?: Maybe<Scalars['uuid']>;
  is_anonymous?: Maybe<Scalars['Boolean']>;
  mfa_enabled?: Maybe<Scalars['Boolean']>;
  new_email?: Maybe<Scalars['citext']>;
  otp_secret?: Maybe<Scalars['String']>;
  password_hash?: Maybe<Scalars['String']>;
  refresh_tokens?: Maybe<Auth_Refresh_Tokens_Arr_Rel_Insert_Input>;
  role?: Maybe<Auth_Roles_Obj_Rel_Insert_Input>;
  ticket?: Maybe<Scalars['uuid']>;
  ticket_expires_at?: Maybe<Scalars['timestamptz']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user?: Maybe<Users_Obj_Rel_Insert_Input>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Auth_Accounts_Max_Fields = {
  created_at?: Maybe<Scalars['timestamptz']>;
  default_role?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['citext']>;
  id?: Maybe<Scalars['uuid']>;
  new_email?: Maybe<Scalars['citext']>;
  otp_secret?: Maybe<Scalars['String']>;
  password_hash?: Maybe<Scalars['String']>;
  ticket?: Maybe<Scalars['uuid']>;
  ticket_expires_at?: Maybe<Scalars['timestamptz']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "auth.accounts" */
export type Auth_Accounts_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  default_role?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  new_email?: Maybe<Order_By>;
  otp_secret?: Maybe<Order_By>;
  password_hash?: Maybe<Order_By>;
  ticket?: Maybe<Order_By>;
  ticket_expires_at?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Auth_Accounts_Min_Fields = {
  created_at?: Maybe<Scalars['timestamptz']>;
  default_role?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['citext']>;
  id?: Maybe<Scalars['uuid']>;
  new_email?: Maybe<Scalars['citext']>;
  otp_secret?: Maybe<Scalars['String']>;
  password_hash?: Maybe<Scalars['String']>;
  ticket?: Maybe<Scalars['uuid']>;
  ticket_expires_at?: Maybe<Scalars['timestamptz']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "auth.accounts" */
export type Auth_Accounts_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  default_role?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  new_email?: Maybe<Order_By>;
  otp_secret?: Maybe<Order_By>;
  password_hash?: Maybe<Order_By>;
  ticket?: Maybe<Order_By>;
  ticket_expires_at?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "auth.accounts" */
export type Auth_Accounts_Mutation_Response = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Auth_Accounts>;
};

/** input type for inserting object relation for remote table "auth.accounts" */
export type Auth_Accounts_Obj_Rel_Insert_Input = {
  data: Auth_Accounts_Insert_Input;
  /** on conflict condition */
  on_conflict?: Maybe<Auth_Accounts_On_Conflict>;
};

/** on conflict condition type for table "auth.accounts" */
export type Auth_Accounts_On_Conflict = {
  constraint: Auth_Accounts_Constraint;
  update_columns?: Array<Auth_Accounts_Update_Column>;
  where?: Maybe<Auth_Accounts_Bool_Exp>;
};

/** Ordering options when selecting data from "auth.accounts". */
export type Auth_Accounts_Order_By = {
  account_providers_aggregate?: Maybe<Auth_Account_Providers_Aggregate_Order_By>;
  account_roles_aggregate?: Maybe<Auth_Account_Roles_Aggregate_Order_By>;
  active?: Maybe<Order_By>;
  cdm_workers?: Maybe<Hr_Cdm_Workers_Order_By>;
  created_at?: Maybe<Order_By>;
  custom_register_data?: Maybe<Order_By>;
  default_role?: Maybe<Order_By>;
  email?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  is_anonymous?: Maybe<Order_By>;
  mfa_enabled?: Maybe<Order_By>;
  new_email?: Maybe<Order_By>;
  otp_secret?: Maybe<Order_By>;
  password_hash?: Maybe<Order_By>;
  refresh_tokens_aggregate?: Maybe<Auth_Refresh_Tokens_Aggregate_Order_By>;
  role?: Maybe<Auth_Roles_Order_By>;
  ticket?: Maybe<Order_By>;
  ticket_expires_at?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  user?: Maybe<Users_Order_By>;
  user_id?: Maybe<Order_By>;
};

/** primary key columns input for table: auth_accounts */
export type Auth_Accounts_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Auth_Accounts_Prepend_Input = {
  custom_register_data?: Maybe<Scalars['jsonb']>;
};

/** select columns of table "auth.accounts" */
export enum Auth_Accounts_Select_Column {
  /** column name */
  Active = 'active',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CustomRegisterData = 'custom_register_data',
  /** column name */
  DefaultRole = 'default_role',
  /** column name */
  Email = 'email',
  /** column name */
  Id = 'id',
  /** column name */
  IsAnonymous = 'is_anonymous',
  /** column name */
  MfaEnabled = 'mfa_enabled',
  /** column name */
  NewEmail = 'new_email',
  /** column name */
  OtpSecret = 'otp_secret',
  /** column name */
  PasswordHash = 'password_hash',
  /** column name */
  Ticket = 'ticket',
  /** column name */
  TicketExpiresAt = 'ticket_expires_at',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "auth.accounts" */
export type Auth_Accounts_Set_Input = {
  active?: Maybe<Scalars['Boolean']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  custom_register_data?: Maybe<Scalars['jsonb']>;
  default_role?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['citext']>;
  id?: Maybe<Scalars['uuid']>;
  is_anonymous?: Maybe<Scalars['Boolean']>;
  mfa_enabled?: Maybe<Scalars['Boolean']>;
  new_email?: Maybe<Scalars['citext']>;
  otp_secret?: Maybe<Scalars['String']>;
  password_hash?: Maybe<Scalars['String']>;
  ticket?: Maybe<Scalars['uuid']>;
  ticket_expires_at?: Maybe<Scalars['timestamptz']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['uuid']>;
};

/** update columns of table "auth.accounts" */
export enum Auth_Accounts_Update_Column {
  /** column name */
  Active = 'active',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CustomRegisterData = 'custom_register_data',
  /** column name */
  DefaultRole = 'default_role',
  /** column name */
  Email = 'email',
  /** column name */
  Id = 'id',
  /** column name */
  IsAnonymous = 'is_anonymous',
  /** column name */
  MfaEnabled = 'mfa_enabled',
  /** column name */
  NewEmail = 'new_email',
  /** column name */
  OtpSecret = 'otp_secret',
  /** column name */
  PasswordHash = 'password_hash',
  /** column name */
  Ticket = 'ticket',
  /** column name */
  TicketExpiresAt = 'ticket_expires_at',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** columns and relationships of "auth.migrations" */
export type Auth_Migrations = {
  executed_at?: Maybe<Scalars['timestamp']>;
  hash: Scalars['String'];
  id: Scalars['Int'];
  name: Scalars['String'];
};

/** aggregated selection of "auth.migrations" */
export type Auth_Migrations_Aggregate = {
  aggregate?: Maybe<Auth_Migrations_Aggregate_Fields>;
  nodes: Array<Auth_Migrations>;
};

/** aggregate fields of "auth.migrations" */
export type Auth_Migrations_Aggregate_Fields = {
  avg?: Maybe<Auth_Migrations_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Auth_Migrations_Max_Fields>;
  min?: Maybe<Auth_Migrations_Min_Fields>;
  stddev?: Maybe<Auth_Migrations_Stddev_Fields>;
  stddev_pop?: Maybe<Auth_Migrations_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Auth_Migrations_Stddev_Samp_Fields>;
  sum?: Maybe<Auth_Migrations_Sum_Fields>;
  var_pop?: Maybe<Auth_Migrations_Var_Pop_Fields>;
  var_samp?: Maybe<Auth_Migrations_Var_Samp_Fields>;
  variance?: Maybe<Auth_Migrations_Variance_Fields>;
};


/** aggregate fields of "auth.migrations" */
export type Auth_Migrations_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Auth_Migrations_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Auth_Migrations_Avg_Fields = {
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "auth.migrations". All fields are combined with a logical 'AND'. */
export type Auth_Migrations_Bool_Exp = {
  _and?: Maybe<Array<Auth_Migrations_Bool_Exp>>;
  _not?: Maybe<Auth_Migrations_Bool_Exp>;
  _or?: Maybe<Array<Auth_Migrations_Bool_Exp>>;
  executed_at?: Maybe<Timestamp_Comparison_Exp>;
  hash?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "auth.migrations" */
export enum Auth_Migrations_Constraint {
  /** unique or primary key constraint */
  MigrationsNameKey = 'migrations_name_key',
  /** unique or primary key constraint */
  MigrationsPkey = 'migrations_pkey'
}

/** input type for incrementing numeric columns in table "auth.migrations" */
export type Auth_Migrations_Inc_Input = {
  id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "auth.migrations" */
export type Auth_Migrations_Insert_Input = {
  executed_at?: Maybe<Scalars['timestamp']>;
  hash?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Auth_Migrations_Max_Fields = {
  executed_at?: Maybe<Scalars['timestamp']>;
  hash?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Auth_Migrations_Min_Fields = {
  executed_at?: Maybe<Scalars['timestamp']>;
  hash?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "auth.migrations" */
export type Auth_Migrations_Mutation_Response = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Auth_Migrations>;
};

/** on conflict condition type for table "auth.migrations" */
export type Auth_Migrations_On_Conflict = {
  constraint: Auth_Migrations_Constraint;
  update_columns?: Array<Auth_Migrations_Update_Column>;
  where?: Maybe<Auth_Migrations_Bool_Exp>;
};

/** Ordering options when selecting data from "auth.migrations". */
export type Auth_Migrations_Order_By = {
  executed_at?: Maybe<Order_By>;
  hash?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
};

/** primary key columns input for table: auth_migrations */
export type Auth_Migrations_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "auth.migrations" */
export enum Auth_Migrations_Select_Column {
  /** column name */
  ExecutedAt = 'executed_at',
  /** column name */
  Hash = 'hash',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name'
}

/** input type for updating data in table "auth.migrations" */
export type Auth_Migrations_Set_Input = {
  executed_at?: Maybe<Scalars['timestamp']>;
  hash?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Auth_Migrations_Stddev_Fields = {
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Auth_Migrations_Stddev_Pop_Fields = {
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Auth_Migrations_Stddev_Samp_Fields = {
  id?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Auth_Migrations_Sum_Fields = {
  id?: Maybe<Scalars['Int']>;
};

/** update columns of table "auth.migrations" */
export enum Auth_Migrations_Update_Column {
  /** column name */
  ExecutedAt = 'executed_at',
  /** column name */
  Hash = 'hash',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name'
}

/** aggregate var_pop on columns */
export type Auth_Migrations_Var_Pop_Fields = {
  id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Auth_Migrations_Var_Samp_Fields = {
  id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Auth_Migrations_Variance_Fields = {
  id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "auth.providers" */
export type Auth_Providers = {
  /** An array relationship */
  account_providers: Array<Auth_Account_Providers>;
  /** An aggregate relationship */
  account_providers_aggregate: Auth_Account_Providers_Aggregate;
  provider: Scalars['String'];
};


/** columns and relationships of "auth.providers" */
export type Auth_ProvidersAccount_ProvidersArgs = {
  distinct_on?: Maybe<Array<Auth_Account_Providers_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Auth_Account_Providers_Order_By>>;
  where?: Maybe<Auth_Account_Providers_Bool_Exp>;
};


/** columns and relationships of "auth.providers" */
export type Auth_ProvidersAccount_Providers_AggregateArgs = {
  distinct_on?: Maybe<Array<Auth_Account_Providers_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Auth_Account_Providers_Order_By>>;
  where?: Maybe<Auth_Account_Providers_Bool_Exp>;
};

/** aggregated selection of "auth.providers" */
export type Auth_Providers_Aggregate = {
  aggregate?: Maybe<Auth_Providers_Aggregate_Fields>;
  nodes: Array<Auth_Providers>;
};

/** aggregate fields of "auth.providers" */
export type Auth_Providers_Aggregate_Fields = {
  count: Scalars['Int'];
  max?: Maybe<Auth_Providers_Max_Fields>;
  min?: Maybe<Auth_Providers_Min_Fields>;
};


/** aggregate fields of "auth.providers" */
export type Auth_Providers_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Auth_Providers_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "auth.providers". All fields are combined with a logical 'AND'. */
export type Auth_Providers_Bool_Exp = {
  _and?: Maybe<Array<Auth_Providers_Bool_Exp>>;
  _not?: Maybe<Auth_Providers_Bool_Exp>;
  _or?: Maybe<Array<Auth_Providers_Bool_Exp>>;
  account_providers?: Maybe<Auth_Account_Providers_Bool_Exp>;
  provider?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "auth.providers" */
export enum Auth_Providers_Constraint {
  /** unique or primary key constraint */
  ProvidersPkey = 'providers_pkey'
}

/** input type for inserting data into table "auth.providers" */
export type Auth_Providers_Insert_Input = {
  account_providers?: Maybe<Auth_Account_Providers_Arr_Rel_Insert_Input>;
  provider?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Auth_Providers_Max_Fields = {
  provider?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Auth_Providers_Min_Fields = {
  provider?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "auth.providers" */
export type Auth_Providers_Mutation_Response = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Auth_Providers>;
};

/** input type for inserting object relation for remote table "auth.providers" */
export type Auth_Providers_Obj_Rel_Insert_Input = {
  data: Auth_Providers_Insert_Input;
  /** on conflict condition */
  on_conflict?: Maybe<Auth_Providers_On_Conflict>;
};

/** on conflict condition type for table "auth.providers" */
export type Auth_Providers_On_Conflict = {
  constraint: Auth_Providers_Constraint;
  update_columns?: Array<Auth_Providers_Update_Column>;
  where?: Maybe<Auth_Providers_Bool_Exp>;
};

/** Ordering options when selecting data from "auth.providers". */
export type Auth_Providers_Order_By = {
  account_providers_aggregate?: Maybe<Auth_Account_Providers_Aggregate_Order_By>;
  provider?: Maybe<Order_By>;
};

/** primary key columns input for table: auth_providers */
export type Auth_Providers_Pk_Columns_Input = {
  provider: Scalars['String'];
};

/** select columns of table "auth.providers" */
export enum Auth_Providers_Select_Column {
  /** column name */
  Provider = 'provider'
}

/** input type for updating data in table "auth.providers" */
export type Auth_Providers_Set_Input = {
  provider?: Maybe<Scalars['String']>;
};

/** update columns of table "auth.providers" */
export enum Auth_Providers_Update_Column {
  /** column name */
  Provider = 'provider'
}

/** columns and relationships of "auth.refresh_tokens" */
export type Auth_Refresh_Tokens = {
  /** An object relationship */
  account: Auth_Accounts;
  account_id: Scalars['uuid'];
  created_at: Scalars['timestamptz'];
  expires_at: Scalars['timestamptz'];
  refresh_token: Scalars['uuid'];
};

/** aggregated selection of "auth.refresh_tokens" */
export type Auth_Refresh_Tokens_Aggregate = {
  aggregate?: Maybe<Auth_Refresh_Tokens_Aggregate_Fields>;
  nodes: Array<Auth_Refresh_Tokens>;
};

/** aggregate fields of "auth.refresh_tokens" */
export type Auth_Refresh_Tokens_Aggregate_Fields = {
  count: Scalars['Int'];
  max?: Maybe<Auth_Refresh_Tokens_Max_Fields>;
  min?: Maybe<Auth_Refresh_Tokens_Min_Fields>;
};


/** aggregate fields of "auth.refresh_tokens" */
export type Auth_Refresh_Tokens_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Auth_Refresh_Tokens_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "auth.refresh_tokens" */
export type Auth_Refresh_Tokens_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Auth_Refresh_Tokens_Max_Order_By>;
  min?: Maybe<Auth_Refresh_Tokens_Min_Order_By>;
};

/** input type for inserting array relation for remote table "auth.refresh_tokens" */
export type Auth_Refresh_Tokens_Arr_Rel_Insert_Input = {
  data: Array<Auth_Refresh_Tokens_Insert_Input>;
  /** on conflict condition */
  on_conflict?: Maybe<Auth_Refresh_Tokens_On_Conflict>;
};

/** Boolean expression to filter rows from the table "auth.refresh_tokens". All fields are combined with a logical 'AND'. */
export type Auth_Refresh_Tokens_Bool_Exp = {
  _and?: Maybe<Array<Auth_Refresh_Tokens_Bool_Exp>>;
  _not?: Maybe<Auth_Refresh_Tokens_Bool_Exp>;
  _or?: Maybe<Array<Auth_Refresh_Tokens_Bool_Exp>>;
  account?: Maybe<Auth_Accounts_Bool_Exp>;
  account_id?: Maybe<Uuid_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  expires_at?: Maybe<Timestamptz_Comparison_Exp>;
  refresh_token?: Maybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "auth.refresh_tokens" */
export enum Auth_Refresh_Tokens_Constraint {
  /** unique or primary key constraint */
  RefreshTokensPkey = 'refresh_tokens_pkey'
}

/** input type for inserting data into table "auth.refresh_tokens" */
export type Auth_Refresh_Tokens_Insert_Input = {
  account?: Maybe<Auth_Accounts_Obj_Rel_Insert_Input>;
  account_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  expires_at?: Maybe<Scalars['timestamptz']>;
  refresh_token?: Maybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Auth_Refresh_Tokens_Max_Fields = {
  account_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  expires_at?: Maybe<Scalars['timestamptz']>;
  refresh_token?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "auth.refresh_tokens" */
export type Auth_Refresh_Tokens_Max_Order_By = {
  account_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  expires_at?: Maybe<Order_By>;
  refresh_token?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Auth_Refresh_Tokens_Min_Fields = {
  account_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  expires_at?: Maybe<Scalars['timestamptz']>;
  refresh_token?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "auth.refresh_tokens" */
export type Auth_Refresh_Tokens_Min_Order_By = {
  account_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  expires_at?: Maybe<Order_By>;
  refresh_token?: Maybe<Order_By>;
};

/** response of any mutation on the table "auth.refresh_tokens" */
export type Auth_Refresh_Tokens_Mutation_Response = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Auth_Refresh_Tokens>;
};

/** on conflict condition type for table "auth.refresh_tokens" */
export type Auth_Refresh_Tokens_On_Conflict = {
  constraint: Auth_Refresh_Tokens_Constraint;
  update_columns?: Array<Auth_Refresh_Tokens_Update_Column>;
  where?: Maybe<Auth_Refresh_Tokens_Bool_Exp>;
};

/** Ordering options when selecting data from "auth.refresh_tokens". */
export type Auth_Refresh_Tokens_Order_By = {
  account?: Maybe<Auth_Accounts_Order_By>;
  account_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  expires_at?: Maybe<Order_By>;
  refresh_token?: Maybe<Order_By>;
};

/** primary key columns input for table: auth_refresh_tokens */
export type Auth_Refresh_Tokens_Pk_Columns_Input = {
  refresh_token: Scalars['uuid'];
};

/** select columns of table "auth.refresh_tokens" */
export enum Auth_Refresh_Tokens_Select_Column {
  /** column name */
  AccountId = 'account_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  ExpiresAt = 'expires_at',
  /** column name */
  RefreshToken = 'refresh_token'
}

/** input type for updating data in table "auth.refresh_tokens" */
export type Auth_Refresh_Tokens_Set_Input = {
  account_id?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  expires_at?: Maybe<Scalars['timestamptz']>;
  refresh_token?: Maybe<Scalars['uuid']>;
};

/** update columns of table "auth.refresh_tokens" */
export enum Auth_Refresh_Tokens_Update_Column {
  /** column name */
  AccountId = 'account_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  ExpiresAt = 'expires_at',
  /** column name */
  RefreshToken = 'refresh_token'
}

/** columns and relationships of "auth.roles" */
export type Auth_Roles = {
  /** An array relationship */
  account_roles: Array<Auth_Account_Roles>;
  /** An aggregate relationship */
  account_roles_aggregate: Auth_Account_Roles_Aggregate;
  /** An array relationship */
  accounts: Array<Auth_Accounts>;
  /** An aggregate relationship */
  accounts_aggregate: Auth_Accounts_Aggregate;
  role: Scalars['String'];
};


/** columns and relationships of "auth.roles" */
export type Auth_RolesAccount_RolesArgs = {
  distinct_on?: Maybe<Array<Auth_Account_Roles_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Auth_Account_Roles_Order_By>>;
  where?: Maybe<Auth_Account_Roles_Bool_Exp>;
};


/** columns and relationships of "auth.roles" */
export type Auth_RolesAccount_Roles_AggregateArgs = {
  distinct_on?: Maybe<Array<Auth_Account_Roles_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Auth_Account_Roles_Order_By>>;
  where?: Maybe<Auth_Account_Roles_Bool_Exp>;
};


/** columns and relationships of "auth.roles" */
export type Auth_RolesAccountsArgs = {
  distinct_on?: Maybe<Array<Auth_Accounts_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Auth_Accounts_Order_By>>;
  where?: Maybe<Auth_Accounts_Bool_Exp>;
};


/** columns and relationships of "auth.roles" */
export type Auth_RolesAccounts_AggregateArgs = {
  distinct_on?: Maybe<Array<Auth_Accounts_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Auth_Accounts_Order_By>>;
  where?: Maybe<Auth_Accounts_Bool_Exp>;
};

/** aggregated selection of "auth.roles" */
export type Auth_Roles_Aggregate = {
  aggregate?: Maybe<Auth_Roles_Aggregate_Fields>;
  nodes: Array<Auth_Roles>;
};

/** aggregate fields of "auth.roles" */
export type Auth_Roles_Aggregate_Fields = {
  count: Scalars['Int'];
  max?: Maybe<Auth_Roles_Max_Fields>;
  min?: Maybe<Auth_Roles_Min_Fields>;
};


/** aggregate fields of "auth.roles" */
export type Auth_Roles_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Auth_Roles_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "auth.roles". All fields are combined with a logical 'AND'. */
export type Auth_Roles_Bool_Exp = {
  _and?: Maybe<Array<Auth_Roles_Bool_Exp>>;
  _not?: Maybe<Auth_Roles_Bool_Exp>;
  _or?: Maybe<Array<Auth_Roles_Bool_Exp>>;
  account_roles?: Maybe<Auth_Account_Roles_Bool_Exp>;
  accounts?: Maybe<Auth_Accounts_Bool_Exp>;
  role?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "auth.roles" */
export enum Auth_Roles_Constraint {
  /** unique or primary key constraint */
  RolesPkey = 'roles_pkey'
}

/** input type for inserting data into table "auth.roles" */
export type Auth_Roles_Insert_Input = {
  account_roles?: Maybe<Auth_Account_Roles_Arr_Rel_Insert_Input>;
  accounts?: Maybe<Auth_Accounts_Arr_Rel_Insert_Input>;
  role?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Auth_Roles_Max_Fields = {
  role?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Auth_Roles_Min_Fields = {
  role?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "auth.roles" */
export type Auth_Roles_Mutation_Response = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Auth_Roles>;
};

/** input type for inserting object relation for remote table "auth.roles" */
export type Auth_Roles_Obj_Rel_Insert_Input = {
  data: Auth_Roles_Insert_Input;
  /** on conflict condition */
  on_conflict?: Maybe<Auth_Roles_On_Conflict>;
};

/** on conflict condition type for table "auth.roles" */
export type Auth_Roles_On_Conflict = {
  constraint: Auth_Roles_Constraint;
  update_columns?: Array<Auth_Roles_Update_Column>;
  where?: Maybe<Auth_Roles_Bool_Exp>;
};

/** Ordering options when selecting data from "auth.roles". */
export type Auth_Roles_Order_By = {
  account_roles_aggregate?: Maybe<Auth_Account_Roles_Aggregate_Order_By>;
  accounts_aggregate?: Maybe<Auth_Accounts_Aggregate_Order_By>;
  role?: Maybe<Order_By>;
};

/** primary key columns input for table: auth_roles */
export type Auth_Roles_Pk_Columns_Input = {
  role: Scalars['String'];
};

/** select columns of table "auth.roles" */
export enum Auth_Roles_Select_Column {
  /** column name */
  Role = 'role'
}

/** input type for updating data in table "auth.roles" */
export type Auth_Roles_Set_Input = {
  role?: Maybe<Scalars['String']>;
};

/** update columns of table "auth.roles" */
export enum Auth_Roles_Update_Column {
  /** column name */
  Role = 'role'
}


/** Boolean expression to compare columns of type "bigint". All fields are combined with logical 'AND'. */
export type Bigint_Comparison_Exp = {
  _eq?: Maybe<Scalars['bigint']>;
  _gt?: Maybe<Scalars['bigint']>;
  _gte?: Maybe<Scalars['bigint']>;
  _in?: Maybe<Array<Scalars['bigint']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['bigint']>;
  _lte?: Maybe<Scalars['bigint']>;
  _neq?: Maybe<Scalars['bigint']>;
  _nin?: Maybe<Array<Scalars['bigint']>>;
};

/** columns and relationships of "budg_data" */
export type Budg_Data = {
  adm_add?: Maybe<Scalars['float8']>;
  adm_arm?: Maybe<Scalars['float8']>;
  adm_reserve?: Maybe<Scalars['float8']>;
  adm_reserve_arm?: Maybe<Scalars['float8']>;
  adm_reserve_sub?: Maybe<Scalars['float8']>;
  adm_sub?: Maybe<Scalars['float8']>;
  budget_id?: Maybe<Scalars['uuid']>;
  frf?: Maybe<Scalars['float8']>;
  ge_transit?: Maybe<Scalars['float8']>;
  gi?: Maybe<Scalars['float8']>;
  gi_init?: Maybe<Scalars['float8']>;
  gi_transit?: Maybe<Scalars['float8']>;
  id: Scalars['uuid'];
  modified_at?: Maybe<Scalars['timestamptz']>;
  modified_by?: Maybe<Scalars['uuid']>;
  mpf?: Maybe<Scalars['float8']>;
  omf_add?: Maybe<Scalars['float8']>;
  omf_arm?: Maybe<Scalars['float8']>;
  omf_arm_premium?: Maybe<Scalars['float8']>;
  omf_reserve?: Maybe<Scalars['float8']>;
  omf_reserve_arm?: Maybe<Scalars['float8']>;
  omf_reserve_sub?: Maybe<Scalars['float8']>;
  omf_sub?: Maybe<Scalars['float8']>;
  profit_init?: Maybe<Scalars['float8']>;
  record_created_at?: Maybe<Scalars['timestamptz']>;
  rnd?: Maybe<Scalars['float8']>;
  soc?: Maybe<Scalars['float8']>;
};

/** aggregated selection of "budg_data" */
export type Budg_Data_Aggregate = {
  aggregate?: Maybe<Budg_Data_Aggregate_Fields>;
  nodes: Array<Budg_Data>;
};

/** aggregate fields of "budg_data" */
export type Budg_Data_Aggregate_Fields = {
  avg?: Maybe<Budg_Data_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Budg_Data_Max_Fields>;
  min?: Maybe<Budg_Data_Min_Fields>;
  stddev?: Maybe<Budg_Data_Stddev_Fields>;
  stddev_pop?: Maybe<Budg_Data_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Budg_Data_Stddev_Samp_Fields>;
  sum?: Maybe<Budg_Data_Sum_Fields>;
  var_pop?: Maybe<Budg_Data_Var_Pop_Fields>;
  var_samp?: Maybe<Budg_Data_Var_Samp_Fields>;
  variance?: Maybe<Budg_Data_Variance_Fields>;
};


/** aggregate fields of "budg_data" */
export type Budg_Data_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Budg_Data_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Budg_Data_Avg_Fields = {
  adm_add?: Maybe<Scalars['Float']>;
  adm_arm?: Maybe<Scalars['Float']>;
  adm_reserve?: Maybe<Scalars['Float']>;
  adm_reserve_arm?: Maybe<Scalars['Float']>;
  adm_reserve_sub?: Maybe<Scalars['Float']>;
  adm_sub?: Maybe<Scalars['Float']>;
  frf?: Maybe<Scalars['Float']>;
  ge_transit?: Maybe<Scalars['Float']>;
  gi?: Maybe<Scalars['Float']>;
  gi_init?: Maybe<Scalars['Float']>;
  gi_transit?: Maybe<Scalars['Float']>;
  mpf?: Maybe<Scalars['Float']>;
  omf_add?: Maybe<Scalars['Float']>;
  omf_arm?: Maybe<Scalars['Float']>;
  omf_arm_premium?: Maybe<Scalars['Float']>;
  omf_reserve?: Maybe<Scalars['Float']>;
  omf_reserve_arm?: Maybe<Scalars['Float']>;
  omf_reserve_sub?: Maybe<Scalars['Float']>;
  omf_sub?: Maybe<Scalars['Float']>;
  profit_init?: Maybe<Scalars['Float']>;
  rnd?: Maybe<Scalars['Float']>;
  soc?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "budg_data". All fields are combined with a logical 'AND'. */
export type Budg_Data_Bool_Exp = {
  _and?: Maybe<Array<Budg_Data_Bool_Exp>>;
  _not?: Maybe<Budg_Data_Bool_Exp>;
  _or?: Maybe<Array<Budg_Data_Bool_Exp>>;
  adm_add?: Maybe<Float8_Comparison_Exp>;
  adm_arm?: Maybe<Float8_Comparison_Exp>;
  adm_reserve?: Maybe<Float8_Comparison_Exp>;
  adm_reserve_arm?: Maybe<Float8_Comparison_Exp>;
  adm_reserve_sub?: Maybe<Float8_Comparison_Exp>;
  adm_sub?: Maybe<Float8_Comparison_Exp>;
  budget_id?: Maybe<Uuid_Comparison_Exp>;
  frf?: Maybe<Float8_Comparison_Exp>;
  ge_transit?: Maybe<Float8_Comparison_Exp>;
  gi?: Maybe<Float8_Comparison_Exp>;
  gi_init?: Maybe<Float8_Comparison_Exp>;
  gi_transit?: Maybe<Float8_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  modified_at?: Maybe<Timestamptz_Comparison_Exp>;
  modified_by?: Maybe<Uuid_Comparison_Exp>;
  mpf?: Maybe<Float8_Comparison_Exp>;
  omf_add?: Maybe<Float8_Comparison_Exp>;
  omf_arm?: Maybe<Float8_Comparison_Exp>;
  omf_arm_premium?: Maybe<Float8_Comparison_Exp>;
  omf_reserve?: Maybe<Float8_Comparison_Exp>;
  omf_reserve_arm?: Maybe<Float8_Comparison_Exp>;
  omf_reserve_sub?: Maybe<Float8_Comparison_Exp>;
  omf_sub?: Maybe<Float8_Comparison_Exp>;
  profit_init?: Maybe<Float8_Comparison_Exp>;
  record_created_at?: Maybe<Timestamptz_Comparison_Exp>;
  rnd?: Maybe<Float8_Comparison_Exp>;
  soc?: Maybe<Float8_Comparison_Exp>;
};

/** unique or primary key constraints on table "budg_data" */
export enum Budg_Data_Constraint {
  /** unique or primary key constraint */
  BudgDataPkey = 'budg_data_pkey'
}

/** input type for incrementing numeric columns in table "budg_data" */
export type Budg_Data_Inc_Input = {
  adm_add?: Maybe<Scalars['float8']>;
  adm_arm?: Maybe<Scalars['float8']>;
  adm_reserve?: Maybe<Scalars['float8']>;
  adm_reserve_arm?: Maybe<Scalars['float8']>;
  adm_reserve_sub?: Maybe<Scalars['float8']>;
  adm_sub?: Maybe<Scalars['float8']>;
  frf?: Maybe<Scalars['float8']>;
  ge_transit?: Maybe<Scalars['float8']>;
  gi?: Maybe<Scalars['float8']>;
  gi_init?: Maybe<Scalars['float8']>;
  gi_transit?: Maybe<Scalars['float8']>;
  mpf?: Maybe<Scalars['float8']>;
  omf_add?: Maybe<Scalars['float8']>;
  omf_arm?: Maybe<Scalars['float8']>;
  omf_arm_premium?: Maybe<Scalars['float8']>;
  omf_reserve?: Maybe<Scalars['float8']>;
  omf_reserve_arm?: Maybe<Scalars['float8']>;
  omf_reserve_sub?: Maybe<Scalars['float8']>;
  omf_sub?: Maybe<Scalars['float8']>;
  profit_init?: Maybe<Scalars['float8']>;
  rnd?: Maybe<Scalars['float8']>;
  soc?: Maybe<Scalars['float8']>;
};

/** input type for inserting data into table "budg_data" */
export type Budg_Data_Insert_Input = {
  adm_add?: Maybe<Scalars['float8']>;
  adm_arm?: Maybe<Scalars['float8']>;
  adm_reserve?: Maybe<Scalars['float8']>;
  adm_reserve_arm?: Maybe<Scalars['float8']>;
  adm_reserve_sub?: Maybe<Scalars['float8']>;
  adm_sub?: Maybe<Scalars['float8']>;
  budget_id?: Maybe<Scalars['uuid']>;
  frf?: Maybe<Scalars['float8']>;
  ge_transit?: Maybe<Scalars['float8']>;
  gi?: Maybe<Scalars['float8']>;
  gi_init?: Maybe<Scalars['float8']>;
  gi_transit?: Maybe<Scalars['float8']>;
  id?: Maybe<Scalars['uuid']>;
  modified_at?: Maybe<Scalars['timestamptz']>;
  modified_by?: Maybe<Scalars['uuid']>;
  mpf?: Maybe<Scalars['float8']>;
  omf_add?: Maybe<Scalars['float8']>;
  omf_arm?: Maybe<Scalars['float8']>;
  omf_arm_premium?: Maybe<Scalars['float8']>;
  omf_reserve?: Maybe<Scalars['float8']>;
  omf_reserve_arm?: Maybe<Scalars['float8']>;
  omf_reserve_sub?: Maybe<Scalars['float8']>;
  omf_sub?: Maybe<Scalars['float8']>;
  profit_init?: Maybe<Scalars['float8']>;
  record_created_at?: Maybe<Scalars['timestamptz']>;
  rnd?: Maybe<Scalars['float8']>;
  soc?: Maybe<Scalars['float8']>;
};

/** aggregate max on columns */
export type Budg_Data_Max_Fields = {
  adm_add?: Maybe<Scalars['float8']>;
  adm_arm?: Maybe<Scalars['float8']>;
  adm_reserve?: Maybe<Scalars['float8']>;
  adm_reserve_arm?: Maybe<Scalars['float8']>;
  adm_reserve_sub?: Maybe<Scalars['float8']>;
  adm_sub?: Maybe<Scalars['float8']>;
  budget_id?: Maybe<Scalars['uuid']>;
  frf?: Maybe<Scalars['float8']>;
  ge_transit?: Maybe<Scalars['float8']>;
  gi?: Maybe<Scalars['float8']>;
  gi_init?: Maybe<Scalars['float8']>;
  gi_transit?: Maybe<Scalars['float8']>;
  id?: Maybe<Scalars['uuid']>;
  modified_at?: Maybe<Scalars['timestamptz']>;
  modified_by?: Maybe<Scalars['uuid']>;
  mpf?: Maybe<Scalars['float8']>;
  omf_add?: Maybe<Scalars['float8']>;
  omf_arm?: Maybe<Scalars['float8']>;
  omf_arm_premium?: Maybe<Scalars['float8']>;
  omf_reserve?: Maybe<Scalars['float8']>;
  omf_reserve_arm?: Maybe<Scalars['float8']>;
  omf_reserve_sub?: Maybe<Scalars['float8']>;
  omf_sub?: Maybe<Scalars['float8']>;
  profit_init?: Maybe<Scalars['float8']>;
  record_created_at?: Maybe<Scalars['timestamptz']>;
  rnd?: Maybe<Scalars['float8']>;
  soc?: Maybe<Scalars['float8']>;
};

/** aggregate min on columns */
export type Budg_Data_Min_Fields = {
  adm_add?: Maybe<Scalars['float8']>;
  adm_arm?: Maybe<Scalars['float8']>;
  adm_reserve?: Maybe<Scalars['float8']>;
  adm_reserve_arm?: Maybe<Scalars['float8']>;
  adm_reserve_sub?: Maybe<Scalars['float8']>;
  adm_sub?: Maybe<Scalars['float8']>;
  budget_id?: Maybe<Scalars['uuid']>;
  frf?: Maybe<Scalars['float8']>;
  ge_transit?: Maybe<Scalars['float8']>;
  gi?: Maybe<Scalars['float8']>;
  gi_init?: Maybe<Scalars['float8']>;
  gi_transit?: Maybe<Scalars['float8']>;
  id?: Maybe<Scalars['uuid']>;
  modified_at?: Maybe<Scalars['timestamptz']>;
  modified_by?: Maybe<Scalars['uuid']>;
  mpf?: Maybe<Scalars['float8']>;
  omf_add?: Maybe<Scalars['float8']>;
  omf_arm?: Maybe<Scalars['float8']>;
  omf_arm_premium?: Maybe<Scalars['float8']>;
  omf_reserve?: Maybe<Scalars['float8']>;
  omf_reserve_arm?: Maybe<Scalars['float8']>;
  omf_reserve_sub?: Maybe<Scalars['float8']>;
  omf_sub?: Maybe<Scalars['float8']>;
  profit_init?: Maybe<Scalars['float8']>;
  record_created_at?: Maybe<Scalars['timestamptz']>;
  rnd?: Maybe<Scalars['float8']>;
  soc?: Maybe<Scalars['float8']>;
};

/** response of any mutation on the table "budg_data" */
export type Budg_Data_Mutation_Response = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Budg_Data>;
};

/** on conflict condition type for table "budg_data" */
export type Budg_Data_On_Conflict = {
  constraint: Budg_Data_Constraint;
  update_columns?: Array<Budg_Data_Update_Column>;
  where?: Maybe<Budg_Data_Bool_Exp>;
};

/** Ordering options when selecting data from "budg_data". */
export type Budg_Data_Order_By = {
  adm_add?: Maybe<Order_By>;
  adm_arm?: Maybe<Order_By>;
  adm_reserve?: Maybe<Order_By>;
  adm_reserve_arm?: Maybe<Order_By>;
  adm_reserve_sub?: Maybe<Order_By>;
  adm_sub?: Maybe<Order_By>;
  budget_id?: Maybe<Order_By>;
  frf?: Maybe<Order_By>;
  ge_transit?: Maybe<Order_By>;
  gi?: Maybe<Order_By>;
  gi_init?: Maybe<Order_By>;
  gi_transit?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_at?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  mpf?: Maybe<Order_By>;
  omf_add?: Maybe<Order_By>;
  omf_arm?: Maybe<Order_By>;
  omf_arm_premium?: Maybe<Order_By>;
  omf_reserve?: Maybe<Order_By>;
  omf_reserve_arm?: Maybe<Order_By>;
  omf_reserve_sub?: Maybe<Order_By>;
  omf_sub?: Maybe<Order_By>;
  profit_init?: Maybe<Order_By>;
  record_created_at?: Maybe<Order_By>;
  rnd?: Maybe<Order_By>;
  soc?: Maybe<Order_By>;
};

/** primary key columns input for table: budg_data */
export type Budg_Data_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "budg_data" */
export enum Budg_Data_Select_Column {
  /** column name */
  AdmAdd = 'adm_add',
  /** column name */
  AdmArm = 'adm_arm',
  /** column name */
  AdmReserve = 'adm_reserve',
  /** column name */
  AdmReserveArm = 'adm_reserve_arm',
  /** column name */
  AdmReserveSub = 'adm_reserve_sub',
  /** column name */
  AdmSub = 'adm_sub',
  /** column name */
  BudgetId = 'budget_id',
  /** column name */
  Frf = 'frf',
  /** column name */
  GeTransit = 'ge_transit',
  /** column name */
  Gi = 'gi',
  /** column name */
  GiInit = 'gi_init',
  /** column name */
  GiTransit = 'gi_transit',
  /** column name */
  Id = 'id',
  /** column name */
  ModifiedAt = 'modified_at',
  /** column name */
  ModifiedBy = 'modified_by',
  /** column name */
  Mpf = 'mpf',
  /** column name */
  OmfAdd = 'omf_add',
  /** column name */
  OmfArm = 'omf_arm',
  /** column name */
  OmfArmPremium = 'omf_arm_premium',
  /** column name */
  OmfReserve = 'omf_reserve',
  /** column name */
  OmfReserveArm = 'omf_reserve_arm',
  /** column name */
  OmfReserveSub = 'omf_reserve_sub',
  /** column name */
  OmfSub = 'omf_sub',
  /** column name */
  ProfitInit = 'profit_init',
  /** column name */
  RecordCreatedAt = 'record_created_at',
  /** column name */
  Rnd = 'rnd',
  /** column name */
  Soc = 'soc'
}

/** input type for updating data in table "budg_data" */
export type Budg_Data_Set_Input = {
  adm_add?: Maybe<Scalars['float8']>;
  adm_arm?: Maybe<Scalars['float8']>;
  adm_reserve?: Maybe<Scalars['float8']>;
  adm_reserve_arm?: Maybe<Scalars['float8']>;
  adm_reserve_sub?: Maybe<Scalars['float8']>;
  adm_sub?: Maybe<Scalars['float8']>;
  budget_id?: Maybe<Scalars['uuid']>;
  frf?: Maybe<Scalars['float8']>;
  ge_transit?: Maybe<Scalars['float8']>;
  gi?: Maybe<Scalars['float8']>;
  gi_init?: Maybe<Scalars['float8']>;
  gi_transit?: Maybe<Scalars['float8']>;
  id?: Maybe<Scalars['uuid']>;
  modified_at?: Maybe<Scalars['timestamptz']>;
  modified_by?: Maybe<Scalars['uuid']>;
  mpf?: Maybe<Scalars['float8']>;
  omf_add?: Maybe<Scalars['float8']>;
  omf_arm?: Maybe<Scalars['float8']>;
  omf_arm_premium?: Maybe<Scalars['float8']>;
  omf_reserve?: Maybe<Scalars['float8']>;
  omf_reserve_arm?: Maybe<Scalars['float8']>;
  omf_reserve_sub?: Maybe<Scalars['float8']>;
  omf_sub?: Maybe<Scalars['float8']>;
  profit_init?: Maybe<Scalars['float8']>;
  record_created_at?: Maybe<Scalars['timestamptz']>;
  rnd?: Maybe<Scalars['float8']>;
  soc?: Maybe<Scalars['float8']>;
};

/** aggregate stddev on columns */
export type Budg_Data_Stddev_Fields = {
  adm_add?: Maybe<Scalars['Float']>;
  adm_arm?: Maybe<Scalars['Float']>;
  adm_reserve?: Maybe<Scalars['Float']>;
  adm_reserve_arm?: Maybe<Scalars['Float']>;
  adm_reserve_sub?: Maybe<Scalars['Float']>;
  adm_sub?: Maybe<Scalars['Float']>;
  frf?: Maybe<Scalars['Float']>;
  ge_transit?: Maybe<Scalars['Float']>;
  gi?: Maybe<Scalars['Float']>;
  gi_init?: Maybe<Scalars['Float']>;
  gi_transit?: Maybe<Scalars['Float']>;
  mpf?: Maybe<Scalars['Float']>;
  omf_add?: Maybe<Scalars['Float']>;
  omf_arm?: Maybe<Scalars['Float']>;
  omf_arm_premium?: Maybe<Scalars['Float']>;
  omf_reserve?: Maybe<Scalars['Float']>;
  omf_reserve_arm?: Maybe<Scalars['Float']>;
  omf_reserve_sub?: Maybe<Scalars['Float']>;
  omf_sub?: Maybe<Scalars['Float']>;
  profit_init?: Maybe<Scalars['Float']>;
  rnd?: Maybe<Scalars['Float']>;
  soc?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Budg_Data_Stddev_Pop_Fields = {
  adm_add?: Maybe<Scalars['Float']>;
  adm_arm?: Maybe<Scalars['Float']>;
  adm_reserve?: Maybe<Scalars['Float']>;
  adm_reserve_arm?: Maybe<Scalars['Float']>;
  adm_reserve_sub?: Maybe<Scalars['Float']>;
  adm_sub?: Maybe<Scalars['Float']>;
  frf?: Maybe<Scalars['Float']>;
  ge_transit?: Maybe<Scalars['Float']>;
  gi?: Maybe<Scalars['Float']>;
  gi_init?: Maybe<Scalars['Float']>;
  gi_transit?: Maybe<Scalars['Float']>;
  mpf?: Maybe<Scalars['Float']>;
  omf_add?: Maybe<Scalars['Float']>;
  omf_arm?: Maybe<Scalars['Float']>;
  omf_arm_premium?: Maybe<Scalars['Float']>;
  omf_reserve?: Maybe<Scalars['Float']>;
  omf_reserve_arm?: Maybe<Scalars['Float']>;
  omf_reserve_sub?: Maybe<Scalars['Float']>;
  omf_sub?: Maybe<Scalars['Float']>;
  profit_init?: Maybe<Scalars['Float']>;
  rnd?: Maybe<Scalars['Float']>;
  soc?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Budg_Data_Stddev_Samp_Fields = {
  adm_add?: Maybe<Scalars['Float']>;
  adm_arm?: Maybe<Scalars['Float']>;
  adm_reserve?: Maybe<Scalars['Float']>;
  adm_reserve_arm?: Maybe<Scalars['Float']>;
  adm_reserve_sub?: Maybe<Scalars['Float']>;
  adm_sub?: Maybe<Scalars['Float']>;
  frf?: Maybe<Scalars['Float']>;
  ge_transit?: Maybe<Scalars['Float']>;
  gi?: Maybe<Scalars['Float']>;
  gi_init?: Maybe<Scalars['Float']>;
  gi_transit?: Maybe<Scalars['Float']>;
  mpf?: Maybe<Scalars['Float']>;
  omf_add?: Maybe<Scalars['Float']>;
  omf_arm?: Maybe<Scalars['Float']>;
  omf_arm_premium?: Maybe<Scalars['Float']>;
  omf_reserve?: Maybe<Scalars['Float']>;
  omf_reserve_arm?: Maybe<Scalars['Float']>;
  omf_reserve_sub?: Maybe<Scalars['Float']>;
  omf_sub?: Maybe<Scalars['Float']>;
  profit_init?: Maybe<Scalars['Float']>;
  rnd?: Maybe<Scalars['Float']>;
  soc?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Budg_Data_Sum_Fields = {
  adm_add?: Maybe<Scalars['float8']>;
  adm_arm?: Maybe<Scalars['float8']>;
  adm_reserve?: Maybe<Scalars['float8']>;
  adm_reserve_arm?: Maybe<Scalars['float8']>;
  adm_reserve_sub?: Maybe<Scalars['float8']>;
  adm_sub?: Maybe<Scalars['float8']>;
  frf?: Maybe<Scalars['float8']>;
  ge_transit?: Maybe<Scalars['float8']>;
  gi?: Maybe<Scalars['float8']>;
  gi_init?: Maybe<Scalars['float8']>;
  gi_transit?: Maybe<Scalars['float8']>;
  mpf?: Maybe<Scalars['float8']>;
  omf_add?: Maybe<Scalars['float8']>;
  omf_arm?: Maybe<Scalars['float8']>;
  omf_arm_premium?: Maybe<Scalars['float8']>;
  omf_reserve?: Maybe<Scalars['float8']>;
  omf_reserve_arm?: Maybe<Scalars['float8']>;
  omf_reserve_sub?: Maybe<Scalars['float8']>;
  omf_sub?: Maybe<Scalars['float8']>;
  profit_init?: Maybe<Scalars['float8']>;
  rnd?: Maybe<Scalars['float8']>;
  soc?: Maybe<Scalars['float8']>;
};

/** update columns of table "budg_data" */
export enum Budg_Data_Update_Column {
  /** column name */
  AdmAdd = 'adm_add',
  /** column name */
  AdmArm = 'adm_arm',
  /** column name */
  AdmReserve = 'adm_reserve',
  /** column name */
  AdmReserveArm = 'adm_reserve_arm',
  /** column name */
  AdmReserveSub = 'adm_reserve_sub',
  /** column name */
  AdmSub = 'adm_sub',
  /** column name */
  BudgetId = 'budget_id',
  /** column name */
  Frf = 'frf',
  /** column name */
  GeTransit = 'ge_transit',
  /** column name */
  Gi = 'gi',
  /** column name */
  GiInit = 'gi_init',
  /** column name */
  GiTransit = 'gi_transit',
  /** column name */
  Id = 'id',
  /** column name */
  ModifiedAt = 'modified_at',
  /** column name */
  ModifiedBy = 'modified_by',
  /** column name */
  Mpf = 'mpf',
  /** column name */
  OmfAdd = 'omf_add',
  /** column name */
  OmfArm = 'omf_arm',
  /** column name */
  OmfArmPremium = 'omf_arm_premium',
  /** column name */
  OmfReserve = 'omf_reserve',
  /** column name */
  OmfReserveArm = 'omf_reserve_arm',
  /** column name */
  OmfReserveSub = 'omf_reserve_sub',
  /** column name */
  OmfSub = 'omf_sub',
  /** column name */
  ProfitInit = 'profit_init',
  /** column name */
  RecordCreatedAt = 'record_created_at',
  /** column name */
  Rnd = 'rnd',
  /** column name */
  Soc = 'soc'
}

/** aggregate var_pop on columns */
export type Budg_Data_Var_Pop_Fields = {
  adm_add?: Maybe<Scalars['Float']>;
  adm_arm?: Maybe<Scalars['Float']>;
  adm_reserve?: Maybe<Scalars['Float']>;
  adm_reserve_arm?: Maybe<Scalars['Float']>;
  adm_reserve_sub?: Maybe<Scalars['Float']>;
  adm_sub?: Maybe<Scalars['Float']>;
  frf?: Maybe<Scalars['Float']>;
  ge_transit?: Maybe<Scalars['Float']>;
  gi?: Maybe<Scalars['Float']>;
  gi_init?: Maybe<Scalars['Float']>;
  gi_transit?: Maybe<Scalars['Float']>;
  mpf?: Maybe<Scalars['Float']>;
  omf_add?: Maybe<Scalars['Float']>;
  omf_arm?: Maybe<Scalars['Float']>;
  omf_arm_premium?: Maybe<Scalars['Float']>;
  omf_reserve?: Maybe<Scalars['Float']>;
  omf_reserve_arm?: Maybe<Scalars['Float']>;
  omf_reserve_sub?: Maybe<Scalars['Float']>;
  omf_sub?: Maybe<Scalars['Float']>;
  profit_init?: Maybe<Scalars['Float']>;
  rnd?: Maybe<Scalars['Float']>;
  soc?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Budg_Data_Var_Samp_Fields = {
  adm_add?: Maybe<Scalars['Float']>;
  adm_arm?: Maybe<Scalars['Float']>;
  adm_reserve?: Maybe<Scalars['Float']>;
  adm_reserve_arm?: Maybe<Scalars['Float']>;
  adm_reserve_sub?: Maybe<Scalars['Float']>;
  adm_sub?: Maybe<Scalars['Float']>;
  frf?: Maybe<Scalars['Float']>;
  ge_transit?: Maybe<Scalars['Float']>;
  gi?: Maybe<Scalars['Float']>;
  gi_init?: Maybe<Scalars['Float']>;
  gi_transit?: Maybe<Scalars['Float']>;
  mpf?: Maybe<Scalars['Float']>;
  omf_add?: Maybe<Scalars['Float']>;
  omf_arm?: Maybe<Scalars['Float']>;
  omf_arm_premium?: Maybe<Scalars['Float']>;
  omf_reserve?: Maybe<Scalars['Float']>;
  omf_reserve_arm?: Maybe<Scalars['Float']>;
  omf_reserve_sub?: Maybe<Scalars['Float']>;
  omf_sub?: Maybe<Scalars['Float']>;
  profit_init?: Maybe<Scalars['Float']>;
  rnd?: Maybe<Scalars['Float']>;
  soc?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Budg_Data_Variance_Fields = {
  adm_add?: Maybe<Scalars['Float']>;
  adm_arm?: Maybe<Scalars['Float']>;
  adm_reserve?: Maybe<Scalars['Float']>;
  adm_reserve_arm?: Maybe<Scalars['Float']>;
  adm_reserve_sub?: Maybe<Scalars['Float']>;
  adm_sub?: Maybe<Scalars['Float']>;
  frf?: Maybe<Scalars['Float']>;
  ge_transit?: Maybe<Scalars['Float']>;
  gi?: Maybe<Scalars['Float']>;
  gi_init?: Maybe<Scalars['Float']>;
  gi_transit?: Maybe<Scalars['Float']>;
  mpf?: Maybe<Scalars['Float']>;
  omf_add?: Maybe<Scalars['Float']>;
  omf_arm?: Maybe<Scalars['Float']>;
  omf_arm_premium?: Maybe<Scalars['Float']>;
  omf_reserve?: Maybe<Scalars['Float']>;
  omf_reserve_arm?: Maybe<Scalars['Float']>;
  omf_reserve_sub?: Maybe<Scalars['Float']>;
  omf_sub?: Maybe<Scalars['Float']>;
  profit_init?: Maybe<Scalars['Float']>;
  rnd?: Maybe<Scalars['Float']>;
  soc?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "budget.metadata" */
export type Budget_Metadata = {
  currency_code?: Maybe<Scalars['String']>;
  data_errors?: Maybe<Scalars['String']>;
  data_path?: Maybe<Scalars['String']>;
  /** Project_id, revision */
  id: Scalars['uuid'];
  modified_at?: Maybe<Scalars['timestamptz']>;
  modified_by?: Maybe<Scalars['uuid']>;
  project_end_date?: Maybe<Scalars['timestamptz']>;
  /** PWA project guid */
  project_id?: Maybe<Scalars['uuid']>;
  record_created_at?: Maybe<Scalars['timestamptz']>;
  /** Budget revision number */
  revision?: Maybe<Scalars['smallint']>;
  /** BL template version */
  template?: Maybe<Scalars['String']>;
};

/** aggregated selection of "budget.metadata" */
export type Budget_Metadata_Aggregate = {
  aggregate?: Maybe<Budget_Metadata_Aggregate_Fields>;
  nodes: Array<Budget_Metadata>;
};

/** aggregate fields of "budget.metadata" */
export type Budget_Metadata_Aggregate_Fields = {
  avg?: Maybe<Budget_Metadata_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Budget_Metadata_Max_Fields>;
  min?: Maybe<Budget_Metadata_Min_Fields>;
  stddev?: Maybe<Budget_Metadata_Stddev_Fields>;
  stddev_pop?: Maybe<Budget_Metadata_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Budget_Metadata_Stddev_Samp_Fields>;
  sum?: Maybe<Budget_Metadata_Sum_Fields>;
  var_pop?: Maybe<Budget_Metadata_Var_Pop_Fields>;
  var_samp?: Maybe<Budget_Metadata_Var_Samp_Fields>;
  variance?: Maybe<Budget_Metadata_Variance_Fields>;
};


/** aggregate fields of "budget.metadata" */
export type Budget_Metadata_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Budget_Metadata_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Budget_Metadata_Avg_Fields = {
  /** Budget revision number */
  revision?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "budget.metadata". All fields are combined with a logical 'AND'. */
export type Budget_Metadata_Bool_Exp = {
  _and?: Maybe<Array<Budget_Metadata_Bool_Exp>>;
  _not?: Maybe<Budget_Metadata_Bool_Exp>;
  _or?: Maybe<Array<Budget_Metadata_Bool_Exp>>;
  currency_code?: Maybe<String_Comparison_Exp>;
  data_errors?: Maybe<String_Comparison_Exp>;
  data_path?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  modified_at?: Maybe<Timestamptz_Comparison_Exp>;
  modified_by?: Maybe<Uuid_Comparison_Exp>;
  project_end_date?: Maybe<Timestamptz_Comparison_Exp>;
  project_id?: Maybe<Uuid_Comparison_Exp>;
  record_created_at?: Maybe<Timestamptz_Comparison_Exp>;
  revision?: Maybe<Smallint_Comparison_Exp>;
  template?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "budget.metadata" */
export enum Budget_Metadata_Constraint {
  /** unique or primary key constraint */
  MetadataPkey = 'metadata_pkey'
}

/** input type for incrementing numeric columns in table "budget.metadata" */
export type Budget_Metadata_Inc_Input = {
  /** Budget revision number */
  revision?: Maybe<Scalars['smallint']>;
};

/** input type for inserting data into table "budget.metadata" */
export type Budget_Metadata_Insert_Input = {
  currency_code?: Maybe<Scalars['String']>;
  data_errors?: Maybe<Scalars['String']>;
  data_path?: Maybe<Scalars['String']>;
  /** Project_id, revision */
  id?: Maybe<Scalars['uuid']>;
  modified_at?: Maybe<Scalars['timestamptz']>;
  modified_by?: Maybe<Scalars['uuid']>;
  project_end_date?: Maybe<Scalars['timestamptz']>;
  /** PWA project guid */
  project_id?: Maybe<Scalars['uuid']>;
  record_created_at?: Maybe<Scalars['timestamptz']>;
  /** Budget revision number */
  revision?: Maybe<Scalars['smallint']>;
  /** BL template version */
  template?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Budget_Metadata_Max_Fields = {
  currency_code?: Maybe<Scalars['String']>;
  data_errors?: Maybe<Scalars['String']>;
  data_path?: Maybe<Scalars['String']>;
  /** Project_id, revision */
  id?: Maybe<Scalars['uuid']>;
  modified_at?: Maybe<Scalars['timestamptz']>;
  modified_by?: Maybe<Scalars['uuid']>;
  project_end_date?: Maybe<Scalars['timestamptz']>;
  /** PWA project guid */
  project_id?: Maybe<Scalars['uuid']>;
  record_created_at?: Maybe<Scalars['timestamptz']>;
  /** Budget revision number */
  revision?: Maybe<Scalars['smallint']>;
  /** BL template version */
  template?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Budget_Metadata_Min_Fields = {
  currency_code?: Maybe<Scalars['String']>;
  data_errors?: Maybe<Scalars['String']>;
  data_path?: Maybe<Scalars['String']>;
  /** Project_id, revision */
  id?: Maybe<Scalars['uuid']>;
  modified_at?: Maybe<Scalars['timestamptz']>;
  modified_by?: Maybe<Scalars['uuid']>;
  project_end_date?: Maybe<Scalars['timestamptz']>;
  /** PWA project guid */
  project_id?: Maybe<Scalars['uuid']>;
  record_created_at?: Maybe<Scalars['timestamptz']>;
  /** Budget revision number */
  revision?: Maybe<Scalars['smallint']>;
  /** BL template version */
  template?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "budget.metadata" */
export type Budget_Metadata_Mutation_Response = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Budget_Metadata>;
};

/** on conflict condition type for table "budget.metadata" */
export type Budget_Metadata_On_Conflict = {
  constraint: Budget_Metadata_Constraint;
  update_columns?: Array<Budget_Metadata_Update_Column>;
  where?: Maybe<Budget_Metadata_Bool_Exp>;
};

/** Ordering options when selecting data from "budget.metadata". */
export type Budget_Metadata_Order_By = {
  currency_code?: Maybe<Order_By>;
  data_errors?: Maybe<Order_By>;
  data_path?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  modified_at?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  project_end_date?: Maybe<Order_By>;
  project_id?: Maybe<Order_By>;
  record_created_at?: Maybe<Order_By>;
  revision?: Maybe<Order_By>;
  template?: Maybe<Order_By>;
};

/** primary key columns input for table: budget_metadata */
export type Budget_Metadata_Pk_Columns_Input = {
  /** Project_id, revision */
  id: Scalars['uuid'];
};

/** select columns of table "budget.metadata" */
export enum Budget_Metadata_Select_Column {
  /** column name */
  CurrencyCode = 'currency_code',
  /** column name */
  DataErrors = 'data_errors',
  /** column name */
  DataPath = 'data_path',
  /** column name */
  Id = 'id',
  /** column name */
  ModifiedAt = 'modified_at',
  /** column name */
  ModifiedBy = 'modified_by',
  /** column name */
  ProjectEndDate = 'project_end_date',
  /** column name */
  ProjectId = 'project_id',
  /** column name */
  RecordCreatedAt = 'record_created_at',
  /** column name */
  Revision = 'revision',
  /** column name */
  Template = 'template'
}

/** input type for updating data in table "budget.metadata" */
export type Budget_Metadata_Set_Input = {
  currency_code?: Maybe<Scalars['String']>;
  data_errors?: Maybe<Scalars['String']>;
  data_path?: Maybe<Scalars['String']>;
  /** Project_id, revision */
  id?: Maybe<Scalars['uuid']>;
  modified_at?: Maybe<Scalars['timestamptz']>;
  modified_by?: Maybe<Scalars['uuid']>;
  project_end_date?: Maybe<Scalars['timestamptz']>;
  /** PWA project guid */
  project_id?: Maybe<Scalars['uuid']>;
  record_created_at?: Maybe<Scalars['timestamptz']>;
  /** Budget revision number */
  revision?: Maybe<Scalars['smallint']>;
  /** BL template version */
  template?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Budget_Metadata_Stddev_Fields = {
  /** Budget revision number */
  revision?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Budget_Metadata_Stddev_Pop_Fields = {
  /** Budget revision number */
  revision?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Budget_Metadata_Stddev_Samp_Fields = {
  /** Budget revision number */
  revision?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Budget_Metadata_Sum_Fields = {
  /** Budget revision number */
  revision?: Maybe<Scalars['smallint']>;
};

/** update columns of table "budget.metadata" */
export enum Budget_Metadata_Update_Column {
  /** column name */
  CurrencyCode = 'currency_code',
  /** column name */
  DataErrors = 'data_errors',
  /** column name */
  DataPath = 'data_path',
  /** column name */
  Id = 'id',
  /** column name */
  ModifiedAt = 'modified_at',
  /** column name */
  ModifiedBy = 'modified_by',
  /** column name */
  ProjectEndDate = 'project_end_date',
  /** column name */
  ProjectId = 'project_id',
  /** column name */
  RecordCreatedAt = 'record_created_at',
  /** column name */
  Revision = 'revision',
  /** column name */
  Template = 'template'
}

/** aggregate var_pop on columns */
export type Budget_Metadata_Var_Pop_Fields = {
  /** Budget revision number */
  revision?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Budget_Metadata_Var_Samp_Fields = {
  /** Budget revision number */
  revision?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Budget_Metadata_Variance_Fields = {
  /** Budget revision number */
  revision?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "budget.parameters" */
export type Budget_Parameters = {
  category_id?: Maybe<Scalars['uuid']>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['uuid'];
  name_en?: Maybe<Scalars['String']>;
  name_logic?: Maybe<Scalars['String']>;
  name_ru?: Maybe<Scalars['String']>;
  name_ua?: Maybe<Scalars['String']>;
  /** An array relationship */
  parameters_data: Array<Budget_Parameters_Data>;
  /** An aggregate relationship */
  parameters_data_aggregate: Budget_Parameters_Data_Aggregate;
};


/** columns and relationships of "budget.parameters" */
export type Budget_ParametersParameters_DataArgs = {
  distinct_on?: Maybe<Array<Budget_Parameters_Data_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Budget_Parameters_Data_Order_By>>;
  where?: Maybe<Budget_Parameters_Data_Bool_Exp>;
};


/** columns and relationships of "budget.parameters" */
export type Budget_ParametersParameters_Data_AggregateArgs = {
  distinct_on?: Maybe<Array<Budget_Parameters_Data_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Budget_Parameters_Data_Order_By>>;
  where?: Maybe<Budget_Parameters_Data_Bool_Exp>;
};

/** aggregated selection of "budget.parameters" */
export type Budget_Parameters_Aggregate = {
  aggregate?: Maybe<Budget_Parameters_Aggregate_Fields>;
  nodes: Array<Budget_Parameters>;
};

/** aggregate fields of "budget.parameters" */
export type Budget_Parameters_Aggregate_Fields = {
  count: Scalars['Int'];
  max?: Maybe<Budget_Parameters_Max_Fields>;
  min?: Maybe<Budget_Parameters_Min_Fields>;
};


/** aggregate fields of "budget.parameters" */
export type Budget_Parameters_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Budget_Parameters_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "budget.parameters". All fields are combined with a logical 'AND'. */
export type Budget_Parameters_Bool_Exp = {
  _and?: Maybe<Array<Budget_Parameters_Bool_Exp>>;
  _not?: Maybe<Budget_Parameters_Bool_Exp>;
  _or?: Maybe<Array<Budget_Parameters_Bool_Exp>>;
  category_id?: Maybe<Uuid_Comparison_Exp>;
  description?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  name_en?: Maybe<String_Comparison_Exp>;
  name_logic?: Maybe<String_Comparison_Exp>;
  name_ru?: Maybe<String_Comparison_Exp>;
  name_ua?: Maybe<String_Comparison_Exp>;
  parameters_data?: Maybe<Budget_Parameters_Data_Bool_Exp>;
};

/** columns and relationships of "budget.parameters_data" */
export type Budget_Parameters_Data = {
  budget_id?: Maybe<Scalars['uuid']>;
  id: Scalars['uuid'];
  key_id?: Maybe<Scalars['uuid']>;
  modified_at?: Maybe<Scalars['timestamptz']>;
  modified_by?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  parameter?: Maybe<Budget_Parameters>;
  parameter_id?: Maybe<Scalars['uuid']>;
  value?: Maybe<Scalars['String']>;
  version?: Maybe<Scalars['smallint']>;
};

/** aggregated selection of "budget.parameters_data" */
export type Budget_Parameters_Data_Aggregate = {
  aggregate?: Maybe<Budget_Parameters_Data_Aggregate_Fields>;
  nodes: Array<Budget_Parameters_Data>;
};

/** aggregate fields of "budget.parameters_data" */
export type Budget_Parameters_Data_Aggregate_Fields = {
  avg?: Maybe<Budget_Parameters_Data_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Budget_Parameters_Data_Max_Fields>;
  min?: Maybe<Budget_Parameters_Data_Min_Fields>;
  stddev?: Maybe<Budget_Parameters_Data_Stddev_Fields>;
  stddev_pop?: Maybe<Budget_Parameters_Data_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Budget_Parameters_Data_Stddev_Samp_Fields>;
  sum?: Maybe<Budget_Parameters_Data_Sum_Fields>;
  var_pop?: Maybe<Budget_Parameters_Data_Var_Pop_Fields>;
  var_samp?: Maybe<Budget_Parameters_Data_Var_Samp_Fields>;
  variance?: Maybe<Budget_Parameters_Data_Variance_Fields>;
};


/** aggregate fields of "budget.parameters_data" */
export type Budget_Parameters_Data_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Budget_Parameters_Data_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "budget.parameters_data" */
export type Budget_Parameters_Data_Aggregate_Order_By = {
  avg?: Maybe<Budget_Parameters_Data_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Budget_Parameters_Data_Max_Order_By>;
  min?: Maybe<Budget_Parameters_Data_Min_Order_By>;
  stddev?: Maybe<Budget_Parameters_Data_Stddev_Order_By>;
  stddev_pop?: Maybe<Budget_Parameters_Data_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Budget_Parameters_Data_Stddev_Samp_Order_By>;
  sum?: Maybe<Budget_Parameters_Data_Sum_Order_By>;
  var_pop?: Maybe<Budget_Parameters_Data_Var_Pop_Order_By>;
  var_samp?: Maybe<Budget_Parameters_Data_Var_Samp_Order_By>;
  variance?: Maybe<Budget_Parameters_Data_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "budget.parameters_data" */
export type Budget_Parameters_Data_Arr_Rel_Insert_Input = {
  data: Array<Budget_Parameters_Data_Insert_Input>;
};

/** aggregate avg on columns */
export type Budget_Parameters_Data_Avg_Fields = {
  version?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "budget.parameters_data" */
export type Budget_Parameters_Data_Avg_Order_By = {
  version?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "budget.parameters_data". All fields are combined with a logical 'AND'. */
export type Budget_Parameters_Data_Bool_Exp = {
  _and?: Maybe<Array<Budget_Parameters_Data_Bool_Exp>>;
  _not?: Maybe<Budget_Parameters_Data_Bool_Exp>;
  _or?: Maybe<Array<Budget_Parameters_Data_Bool_Exp>>;
  budget_id?: Maybe<Uuid_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  key_id?: Maybe<Uuid_Comparison_Exp>;
  modified_at?: Maybe<Timestamptz_Comparison_Exp>;
  modified_by?: Maybe<Uuid_Comparison_Exp>;
  parameter?: Maybe<Budget_Parameters_Bool_Exp>;
  parameter_id?: Maybe<Uuid_Comparison_Exp>;
  value?: Maybe<String_Comparison_Exp>;
  version?: Maybe<Smallint_Comparison_Exp>;
};

/** input type for incrementing numeric columns in table "budget.parameters_data" */
export type Budget_Parameters_Data_Inc_Input = {
  version?: Maybe<Scalars['smallint']>;
};

/** input type for inserting data into table "budget.parameters_data" */
export type Budget_Parameters_Data_Insert_Input = {
  budget_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  key_id?: Maybe<Scalars['uuid']>;
  modified_at?: Maybe<Scalars['timestamptz']>;
  modified_by?: Maybe<Scalars['uuid']>;
  parameter?: Maybe<Budget_Parameters_Obj_Rel_Insert_Input>;
  parameter_id?: Maybe<Scalars['uuid']>;
  value?: Maybe<Scalars['String']>;
  version?: Maybe<Scalars['smallint']>;
};

/** aggregate max on columns */
export type Budget_Parameters_Data_Max_Fields = {
  budget_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  key_id?: Maybe<Scalars['uuid']>;
  modified_at?: Maybe<Scalars['timestamptz']>;
  modified_by?: Maybe<Scalars['uuid']>;
  parameter_id?: Maybe<Scalars['uuid']>;
  value?: Maybe<Scalars['String']>;
  version?: Maybe<Scalars['smallint']>;
};

/** order by max() on columns of table "budget.parameters_data" */
export type Budget_Parameters_Data_Max_Order_By = {
  budget_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  key_id?: Maybe<Order_By>;
  modified_at?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  parameter_id?: Maybe<Order_By>;
  value?: Maybe<Order_By>;
  version?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Budget_Parameters_Data_Min_Fields = {
  budget_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  key_id?: Maybe<Scalars['uuid']>;
  modified_at?: Maybe<Scalars['timestamptz']>;
  modified_by?: Maybe<Scalars['uuid']>;
  parameter_id?: Maybe<Scalars['uuid']>;
  value?: Maybe<Scalars['String']>;
  version?: Maybe<Scalars['smallint']>;
};

/** order by min() on columns of table "budget.parameters_data" */
export type Budget_Parameters_Data_Min_Order_By = {
  budget_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  key_id?: Maybe<Order_By>;
  modified_at?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  parameter_id?: Maybe<Order_By>;
  value?: Maybe<Order_By>;
  version?: Maybe<Order_By>;
};

/** response of any mutation on the table "budget.parameters_data" */
export type Budget_Parameters_Data_Mutation_Response = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Budget_Parameters_Data>;
};

/** Ordering options when selecting data from "budget.parameters_data". */
export type Budget_Parameters_Data_Order_By = {
  budget_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  key_id?: Maybe<Order_By>;
  modified_at?: Maybe<Order_By>;
  modified_by?: Maybe<Order_By>;
  parameter?: Maybe<Budget_Parameters_Order_By>;
  parameter_id?: Maybe<Order_By>;
  value?: Maybe<Order_By>;
  version?: Maybe<Order_By>;
};

/** select columns of table "budget.parameters_data" */
export enum Budget_Parameters_Data_Select_Column {
  /** column name */
  BudgetId = 'budget_id',
  /** column name */
  Id = 'id',
  /** column name */
  KeyId = 'key_id',
  /** column name */
  ModifiedAt = 'modified_at',
  /** column name */
  ModifiedBy = 'modified_by',
  /** column name */
  ParameterId = 'parameter_id',
  /** column name */
  Value = 'value',
  /** column name */
  Version = 'version'
}

/** input type for updating data in table "budget.parameters_data" */
export type Budget_Parameters_Data_Set_Input = {
  budget_id?: Maybe<Scalars['uuid']>;
  id?: Maybe<Scalars['uuid']>;
  key_id?: Maybe<Scalars['uuid']>;
  modified_at?: Maybe<Scalars['timestamptz']>;
  modified_by?: Maybe<Scalars['uuid']>;
  parameter_id?: Maybe<Scalars['uuid']>;
  value?: Maybe<Scalars['String']>;
  version?: Maybe<Scalars['smallint']>;
};

/** aggregate stddev on columns */
export type Budget_Parameters_Data_Stddev_Fields = {
  version?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "budget.parameters_data" */
export type Budget_Parameters_Data_Stddev_Order_By = {
  version?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Budget_Parameters_Data_Stddev_Pop_Fields = {
  version?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "budget.parameters_data" */
export type Budget_Parameters_Data_Stddev_Pop_Order_By = {
  version?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Budget_Parameters_Data_Stddev_Samp_Fields = {
  version?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "budget.parameters_data" */
export type Budget_Parameters_Data_Stddev_Samp_Order_By = {
  version?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Budget_Parameters_Data_Sum_Fields = {
  version?: Maybe<Scalars['smallint']>;
};

/** order by sum() on columns of table "budget.parameters_data" */
export type Budget_Parameters_Data_Sum_Order_By = {
  version?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Budget_Parameters_Data_Var_Pop_Fields = {
  version?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "budget.parameters_data" */
export type Budget_Parameters_Data_Var_Pop_Order_By = {
  version?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Budget_Parameters_Data_Var_Samp_Fields = {
  version?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "budget.parameters_data" */
export type Budget_Parameters_Data_Var_Samp_Order_By = {
  version?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Budget_Parameters_Data_Variance_Fields = {
  version?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "budget.parameters_data" */
export type Budget_Parameters_Data_Variance_Order_By = {
  version?: Maybe<Order_By>;
};

/** input type for inserting data into table "budget.parameters" */
export type Budget_Parameters_Insert_Input = {
  category_id?: Maybe<Scalars['uuid']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  name_en?: Maybe<Scalars['String']>;
  name_logic?: Maybe<Scalars['String']>;
  name_ru?: Maybe<Scalars['String']>;
  name_ua?: Maybe<Scalars['String']>;
  parameters_data?: Maybe<Budget_Parameters_Data_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Budget_Parameters_Max_Fields = {
  category_id?: Maybe<Scalars['uuid']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  name_en?: Maybe<Scalars['String']>;
  name_logic?: Maybe<Scalars['String']>;
  name_ru?: Maybe<Scalars['String']>;
  name_ua?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Budget_Parameters_Min_Fields = {
  category_id?: Maybe<Scalars['uuid']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  name_en?: Maybe<Scalars['String']>;
  name_logic?: Maybe<Scalars['String']>;
  name_ru?: Maybe<Scalars['String']>;
  name_ua?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "budget.parameters" */
export type Budget_Parameters_Mutation_Response = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Budget_Parameters>;
};

/** input type for inserting object relation for remote table "budget.parameters" */
export type Budget_Parameters_Obj_Rel_Insert_Input = {
  data: Budget_Parameters_Insert_Input;
};

/** Ordering options when selecting data from "budget.parameters". */
export type Budget_Parameters_Order_By = {
  category_id?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  name_en?: Maybe<Order_By>;
  name_logic?: Maybe<Order_By>;
  name_ru?: Maybe<Order_By>;
  name_ua?: Maybe<Order_By>;
  parameters_data_aggregate?: Maybe<Budget_Parameters_Data_Aggregate_Order_By>;
};

/** select columns of table "budget.parameters" */
export enum Budget_Parameters_Select_Column {
  /** column name */
  CategoryId = 'category_id',
  /** column name */
  Description = 'description',
  /** column name */
  Id = 'id',
  /** column name */
  NameEn = 'name_en',
  /** column name */
  NameLogic = 'name_logic',
  /** column name */
  NameRu = 'name_ru',
  /** column name */
  NameUa = 'name_ua'
}

/** input type for updating data in table "budget.parameters" */
export type Budget_Parameters_Set_Input = {
  category_id?: Maybe<Scalars['uuid']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  name_en?: Maybe<Scalars['String']>;
  name_logic?: Maybe<Scalars['String']>;
  name_ru?: Maybe<Scalars['String']>;
  name_ua?: Maybe<Scalars['String']>;
};


/** Boolean expression to compare columns of type "citext". All fields are combined with logical 'AND'. */
export type Citext_Comparison_Exp = {
  _eq?: Maybe<Scalars['citext']>;
  _gt?: Maybe<Scalars['citext']>;
  _gte?: Maybe<Scalars['citext']>;
  /** does the column match the given case-insensitive pattern */
  _ilike?: Maybe<Scalars['citext']>;
  _in?: Maybe<Array<Scalars['citext']>>;
  /** does the column match the given POSIX regular expression, case insensitive */
  _iregex?: Maybe<Scalars['citext']>;
  _is_null?: Maybe<Scalars['Boolean']>;
  /** does the column match the given pattern */
  _like?: Maybe<Scalars['citext']>;
  _lt?: Maybe<Scalars['citext']>;
  _lte?: Maybe<Scalars['citext']>;
  _neq?: Maybe<Scalars['citext']>;
  /** does the column NOT match the given case-insensitive pattern */
  _nilike?: Maybe<Scalars['citext']>;
  _nin?: Maybe<Array<Scalars['citext']>>;
  /** does the column NOT match the given POSIX regular expression, case insensitive */
  _niregex?: Maybe<Scalars['citext']>;
  /** does the column NOT match the given pattern */
  _nlike?: Maybe<Scalars['citext']>;
  /** does the column NOT match the given POSIX regular expression, case sensitive */
  _nregex?: Maybe<Scalars['citext']>;
  /** does the column NOT match the given SQL regular expression */
  _nsimilar?: Maybe<Scalars['citext']>;
  /** does the column match the given POSIX regular expression, case sensitive */
  _regex?: Maybe<Scalars['citext']>;
  /** does the column match the given SQL regular expression */
  _similar?: Maybe<Scalars['citext']>;
};


/** Boolean expression to compare columns of type "date". All fields are combined with logical 'AND'. */
export type Date_Comparison_Exp = {
  _eq?: Maybe<Scalars['date']>;
  _gt?: Maybe<Scalars['date']>;
  _gte?: Maybe<Scalars['date']>;
  _in?: Maybe<Array<Scalars['date']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['date']>;
  _lte?: Maybe<Scalars['date']>;
  _neq?: Maybe<Scalars['date']>;
  _nin?: Maybe<Array<Scalars['date']>>;
};

/** columns and relationships of "dividends" */
export type Dividends = {
  ItemInternalId?: Maybe<Scalars['String']>;
  /** An object relationship */
  account?: Maybe<Auth_Accounts>;
  budget_revision?: Maybe<Scalars['String']>;
  coef_cashlees_to_cash_planned?: Maybe<Scalars['String']>;
  coef_cashless_to_cash?: Maybe<Scalars['String']>;
  currency_code?: Maybe<Scalars['String']>;
  dividend_planned_cashless_vat?: Maybe<Scalars['String']>;
  dividend_proportion?: Maybe<Scalars['String']>;
  dividends_1C?: Maybe<Scalars['String']>;
  dividends_accrued_by_closed_projects_cash?: Maybe<Scalars['String']>;
  dividends_accrued_cash?: Maybe<Scalars['String']>;
  dividends_accrued_cashless_vat?: Maybe<Scalars['String']>;
  dividends_accrued_in_prev_cash?: Maybe<Scalars['String']>;
  dividends_paid_cash?: Maybe<Scalars['String']>;
  dividends_paid_cashless?: Maybe<Scalars['String']>;
  dividends_planned_cash?: Maybe<Scalars['String']>;
  dividends_worker_email?: Maybe<Scalars['String']>;
  dividends_worker_id?: Maybe<Scalars['String']>;
  dividends_worker_name?: Maybe<Scalars['String']>;
  docs_completion_percent?: Maybe<Scalars['String']>;
  leftover_accrual_cash?: Maybe<Scalars['String']>;
  leftover_by_closed_projects_cash?: Maybe<Scalars['String']>;
  leftover_paid_cash?: Maybe<Scalars['String']>;
  leftover_planned_to_pay_cash?: Maybe<Scalars['String']>;
  macroproject_name?: Maybe<Scalars['String']>;
  payment_accrued_last_docdate?: Maybe<Scalars['String']>;
  payment_accrued_percent?: Maybe<Scalars['String']>;
  payment_accrued_percent_2021?: Maybe<Scalars['String']>;
  percent_accrual_cash?: Maybe<Scalars['String']>;
  percent_paid_cash?: Maybe<Scalars['String']>;
  project_enddate?: Maybe<Scalars['String']>;
  project_lead_name?: Maybe<Scalars['String']>;
  project_manager_name?: Maybe<Scalars['String']>;
  project_name?: Maybe<Scalars['String']>;
  residual_payout_ratio?: Maybe<Scalars['String']>;
  user_role?: Maybe<Scalars['String']>;
  user_role_index?: Maybe<Scalars['String']>;
  wrok_completion_percent?: Maybe<Scalars['String']>;
};

/** aggregated selection of "dividends" */
export type Dividends_Aggregate = {
  aggregate?: Maybe<Dividends_Aggregate_Fields>;
  nodes: Array<Dividends>;
};

/** aggregate fields of "dividends" */
export type Dividends_Aggregate_Fields = {
  count: Scalars['Int'];
  max?: Maybe<Dividends_Max_Fields>;
  min?: Maybe<Dividends_Min_Fields>;
};


/** aggregate fields of "dividends" */
export type Dividends_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Dividends_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "dividends". All fields are combined with a logical 'AND'. */
export type Dividends_Bool_Exp = {
  ItemInternalId?: Maybe<String_Comparison_Exp>;
  _and?: Maybe<Array<Dividends_Bool_Exp>>;
  _not?: Maybe<Dividends_Bool_Exp>;
  _or?: Maybe<Array<Dividends_Bool_Exp>>;
  account?: Maybe<Auth_Accounts_Bool_Exp>;
  budget_revision?: Maybe<String_Comparison_Exp>;
  coef_cashlees_to_cash_planned?: Maybe<String_Comparison_Exp>;
  coef_cashless_to_cash?: Maybe<String_Comparison_Exp>;
  currency_code?: Maybe<String_Comparison_Exp>;
  dividend_planned_cashless_vat?: Maybe<String_Comparison_Exp>;
  dividend_proportion?: Maybe<String_Comparison_Exp>;
  dividends_1C?: Maybe<String_Comparison_Exp>;
  dividends_accrued_by_closed_projects_cash?: Maybe<String_Comparison_Exp>;
  dividends_accrued_cash?: Maybe<String_Comparison_Exp>;
  dividends_accrued_cashless_vat?: Maybe<String_Comparison_Exp>;
  dividends_accrued_in_prev_cash?: Maybe<String_Comparison_Exp>;
  dividends_paid_cash?: Maybe<String_Comparison_Exp>;
  dividends_paid_cashless?: Maybe<String_Comparison_Exp>;
  dividends_planned_cash?: Maybe<String_Comparison_Exp>;
  dividends_worker_email?: Maybe<String_Comparison_Exp>;
  dividends_worker_id?: Maybe<String_Comparison_Exp>;
  dividends_worker_name?: Maybe<String_Comparison_Exp>;
  docs_completion_percent?: Maybe<String_Comparison_Exp>;
  leftover_accrual_cash?: Maybe<String_Comparison_Exp>;
  leftover_by_closed_projects_cash?: Maybe<String_Comparison_Exp>;
  leftover_paid_cash?: Maybe<String_Comparison_Exp>;
  leftover_planned_to_pay_cash?: Maybe<String_Comparison_Exp>;
  macroproject_name?: Maybe<String_Comparison_Exp>;
  payment_accrued_last_docdate?: Maybe<String_Comparison_Exp>;
  payment_accrued_percent?: Maybe<String_Comparison_Exp>;
  payment_accrued_percent_2021?: Maybe<String_Comparison_Exp>;
  percent_accrual_cash?: Maybe<String_Comparison_Exp>;
  percent_paid_cash?: Maybe<String_Comparison_Exp>;
  project_enddate?: Maybe<String_Comparison_Exp>;
  project_lead_name?: Maybe<String_Comparison_Exp>;
  project_manager_name?: Maybe<String_Comparison_Exp>;
  project_name?: Maybe<String_Comparison_Exp>;
  residual_payout_ratio?: Maybe<String_Comparison_Exp>;
  user_role?: Maybe<String_Comparison_Exp>;
  user_role_index?: Maybe<String_Comparison_Exp>;
  wrok_completion_percent?: Maybe<String_Comparison_Exp>;
};

/** input type for inserting data into table "dividends" */
export type Dividends_Insert_Input = {
  ItemInternalId?: Maybe<Scalars['String']>;
  account?: Maybe<Auth_Accounts_Obj_Rel_Insert_Input>;
  budget_revision?: Maybe<Scalars['String']>;
  coef_cashlees_to_cash_planned?: Maybe<Scalars['String']>;
  coef_cashless_to_cash?: Maybe<Scalars['String']>;
  currency_code?: Maybe<Scalars['String']>;
  dividend_planned_cashless_vat?: Maybe<Scalars['String']>;
  dividend_proportion?: Maybe<Scalars['String']>;
  dividends_1C?: Maybe<Scalars['String']>;
  dividends_accrued_by_closed_projects_cash?: Maybe<Scalars['String']>;
  dividends_accrued_cash?: Maybe<Scalars['String']>;
  dividends_accrued_cashless_vat?: Maybe<Scalars['String']>;
  dividends_accrued_in_prev_cash?: Maybe<Scalars['String']>;
  dividends_paid_cash?: Maybe<Scalars['String']>;
  dividends_paid_cashless?: Maybe<Scalars['String']>;
  dividends_planned_cash?: Maybe<Scalars['String']>;
  dividends_worker_email?: Maybe<Scalars['String']>;
  dividends_worker_id?: Maybe<Scalars['String']>;
  dividends_worker_name?: Maybe<Scalars['String']>;
  docs_completion_percent?: Maybe<Scalars['String']>;
  leftover_accrual_cash?: Maybe<Scalars['String']>;
  leftover_by_closed_projects_cash?: Maybe<Scalars['String']>;
  leftover_paid_cash?: Maybe<Scalars['String']>;
  leftover_planned_to_pay_cash?: Maybe<Scalars['String']>;
  macroproject_name?: Maybe<Scalars['String']>;
  payment_accrued_last_docdate?: Maybe<Scalars['String']>;
  payment_accrued_percent?: Maybe<Scalars['String']>;
  payment_accrued_percent_2021?: Maybe<Scalars['String']>;
  percent_accrual_cash?: Maybe<Scalars['String']>;
  percent_paid_cash?: Maybe<Scalars['String']>;
  project_enddate?: Maybe<Scalars['String']>;
  project_lead_name?: Maybe<Scalars['String']>;
  project_manager_name?: Maybe<Scalars['String']>;
  project_name?: Maybe<Scalars['String']>;
  residual_payout_ratio?: Maybe<Scalars['String']>;
  user_role?: Maybe<Scalars['String']>;
  user_role_index?: Maybe<Scalars['String']>;
  wrok_completion_percent?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Dividends_Max_Fields = {
  ItemInternalId?: Maybe<Scalars['String']>;
  budget_revision?: Maybe<Scalars['String']>;
  coef_cashlees_to_cash_planned?: Maybe<Scalars['String']>;
  coef_cashless_to_cash?: Maybe<Scalars['String']>;
  currency_code?: Maybe<Scalars['String']>;
  dividend_planned_cashless_vat?: Maybe<Scalars['String']>;
  dividend_proportion?: Maybe<Scalars['String']>;
  dividends_1C?: Maybe<Scalars['String']>;
  dividends_accrued_by_closed_projects_cash?: Maybe<Scalars['String']>;
  dividends_accrued_cash?: Maybe<Scalars['String']>;
  dividends_accrued_cashless_vat?: Maybe<Scalars['String']>;
  dividends_accrued_in_prev_cash?: Maybe<Scalars['String']>;
  dividends_paid_cash?: Maybe<Scalars['String']>;
  dividends_paid_cashless?: Maybe<Scalars['String']>;
  dividends_planned_cash?: Maybe<Scalars['String']>;
  dividends_worker_email?: Maybe<Scalars['String']>;
  dividends_worker_id?: Maybe<Scalars['String']>;
  dividends_worker_name?: Maybe<Scalars['String']>;
  docs_completion_percent?: Maybe<Scalars['String']>;
  leftover_accrual_cash?: Maybe<Scalars['String']>;
  leftover_by_closed_projects_cash?: Maybe<Scalars['String']>;
  leftover_paid_cash?: Maybe<Scalars['String']>;
  leftover_planned_to_pay_cash?: Maybe<Scalars['String']>;
  macroproject_name?: Maybe<Scalars['String']>;
  payment_accrued_last_docdate?: Maybe<Scalars['String']>;
  payment_accrued_percent?: Maybe<Scalars['String']>;
  payment_accrued_percent_2021?: Maybe<Scalars['String']>;
  percent_accrual_cash?: Maybe<Scalars['String']>;
  percent_paid_cash?: Maybe<Scalars['String']>;
  project_enddate?: Maybe<Scalars['String']>;
  project_lead_name?: Maybe<Scalars['String']>;
  project_manager_name?: Maybe<Scalars['String']>;
  project_name?: Maybe<Scalars['String']>;
  residual_payout_ratio?: Maybe<Scalars['String']>;
  user_role?: Maybe<Scalars['String']>;
  user_role_index?: Maybe<Scalars['String']>;
  wrok_completion_percent?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Dividends_Min_Fields = {
  ItemInternalId?: Maybe<Scalars['String']>;
  budget_revision?: Maybe<Scalars['String']>;
  coef_cashlees_to_cash_planned?: Maybe<Scalars['String']>;
  coef_cashless_to_cash?: Maybe<Scalars['String']>;
  currency_code?: Maybe<Scalars['String']>;
  dividend_planned_cashless_vat?: Maybe<Scalars['String']>;
  dividend_proportion?: Maybe<Scalars['String']>;
  dividends_1C?: Maybe<Scalars['String']>;
  dividends_accrued_by_closed_projects_cash?: Maybe<Scalars['String']>;
  dividends_accrued_cash?: Maybe<Scalars['String']>;
  dividends_accrued_cashless_vat?: Maybe<Scalars['String']>;
  dividends_accrued_in_prev_cash?: Maybe<Scalars['String']>;
  dividends_paid_cash?: Maybe<Scalars['String']>;
  dividends_paid_cashless?: Maybe<Scalars['String']>;
  dividends_planned_cash?: Maybe<Scalars['String']>;
  dividends_worker_email?: Maybe<Scalars['String']>;
  dividends_worker_id?: Maybe<Scalars['String']>;
  dividends_worker_name?: Maybe<Scalars['String']>;
  docs_completion_percent?: Maybe<Scalars['String']>;
  leftover_accrual_cash?: Maybe<Scalars['String']>;
  leftover_by_closed_projects_cash?: Maybe<Scalars['String']>;
  leftover_paid_cash?: Maybe<Scalars['String']>;
  leftover_planned_to_pay_cash?: Maybe<Scalars['String']>;
  macroproject_name?: Maybe<Scalars['String']>;
  payment_accrued_last_docdate?: Maybe<Scalars['String']>;
  payment_accrued_percent?: Maybe<Scalars['String']>;
  payment_accrued_percent_2021?: Maybe<Scalars['String']>;
  percent_accrual_cash?: Maybe<Scalars['String']>;
  percent_paid_cash?: Maybe<Scalars['String']>;
  project_enddate?: Maybe<Scalars['String']>;
  project_lead_name?: Maybe<Scalars['String']>;
  project_manager_name?: Maybe<Scalars['String']>;
  project_name?: Maybe<Scalars['String']>;
  residual_payout_ratio?: Maybe<Scalars['String']>;
  user_role?: Maybe<Scalars['String']>;
  user_role_index?: Maybe<Scalars['String']>;
  wrok_completion_percent?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "dividends" */
export type Dividends_Mutation_Response = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Dividends>;
};

/** Ordering options when selecting data from "dividends". */
export type Dividends_Order_By = {
  ItemInternalId?: Maybe<Order_By>;
  account?: Maybe<Auth_Accounts_Order_By>;
  budget_revision?: Maybe<Order_By>;
  coef_cashlees_to_cash_planned?: Maybe<Order_By>;
  coef_cashless_to_cash?: Maybe<Order_By>;
  currency_code?: Maybe<Order_By>;
  dividend_planned_cashless_vat?: Maybe<Order_By>;
  dividend_proportion?: Maybe<Order_By>;
  dividends_1C?: Maybe<Order_By>;
  dividends_accrued_by_closed_projects_cash?: Maybe<Order_By>;
  dividends_accrued_cash?: Maybe<Order_By>;
  dividends_accrued_cashless_vat?: Maybe<Order_By>;
  dividends_accrued_in_prev_cash?: Maybe<Order_By>;
  dividends_paid_cash?: Maybe<Order_By>;
  dividends_paid_cashless?: Maybe<Order_By>;
  dividends_planned_cash?: Maybe<Order_By>;
  dividends_worker_email?: Maybe<Order_By>;
  dividends_worker_id?: Maybe<Order_By>;
  dividends_worker_name?: Maybe<Order_By>;
  docs_completion_percent?: Maybe<Order_By>;
  leftover_accrual_cash?: Maybe<Order_By>;
  leftover_by_closed_projects_cash?: Maybe<Order_By>;
  leftover_paid_cash?: Maybe<Order_By>;
  leftover_planned_to_pay_cash?: Maybe<Order_By>;
  macroproject_name?: Maybe<Order_By>;
  payment_accrued_last_docdate?: Maybe<Order_By>;
  payment_accrued_percent?: Maybe<Order_By>;
  payment_accrued_percent_2021?: Maybe<Order_By>;
  percent_accrual_cash?: Maybe<Order_By>;
  percent_paid_cash?: Maybe<Order_By>;
  project_enddate?: Maybe<Order_By>;
  project_lead_name?: Maybe<Order_By>;
  project_manager_name?: Maybe<Order_By>;
  project_name?: Maybe<Order_By>;
  residual_payout_ratio?: Maybe<Order_By>;
  user_role?: Maybe<Order_By>;
  user_role_index?: Maybe<Order_By>;
  wrok_completion_percent?: Maybe<Order_By>;
};

/** select columns of table "dividends" */
export enum Dividends_Select_Column {
  /** column name */
  ItemInternalId = 'ItemInternalId',
  /** column name */
  BudgetRevision = 'budget_revision',
  /** column name */
  CoefCashleesToCashPlanned = 'coef_cashlees_to_cash_planned',
  /** column name */
  CoefCashlessToCash = 'coef_cashless_to_cash',
  /** column name */
  CurrencyCode = 'currency_code',
  /** column name */
  DividendPlannedCashlessVat = 'dividend_planned_cashless_vat',
  /** column name */
  DividendProportion = 'dividend_proportion',
  /** column name */
  Dividends_1C = 'dividends_1C',
  /** column name */
  DividendsAccruedByClosedProjectsCash = 'dividends_accrued_by_closed_projects_cash',
  /** column name */
  DividendsAccruedCash = 'dividends_accrued_cash',
  /** column name */
  DividendsAccruedCashlessVat = 'dividends_accrued_cashless_vat',
  /** column name */
  DividendsAccruedInPrevCash = 'dividends_accrued_in_prev_cash',
  /** column name */
  DividendsPaidCash = 'dividends_paid_cash',
  /** column name */
  DividendsPaidCashless = 'dividends_paid_cashless',
  /** column name */
  DividendsPlannedCash = 'dividends_planned_cash',
  /** column name */
  DividendsWorkerEmail = 'dividends_worker_email',
  /** column name */
  DividendsWorkerId = 'dividends_worker_id',
  /** column name */
  DividendsWorkerName = 'dividends_worker_name',
  /** column name */
  DocsCompletionPercent = 'docs_completion_percent',
  /** column name */
  LeftoverAccrualCash = 'leftover_accrual_cash',
  /** column name */
  LeftoverByClosedProjectsCash = 'leftover_by_closed_projects_cash',
  /** column name */
  LeftoverPaidCash = 'leftover_paid_cash',
  /** column name */
  LeftoverPlannedToPayCash = 'leftover_planned_to_pay_cash',
  /** column name */
  MacroprojectName = 'macroproject_name',
  /** column name */
  PaymentAccruedLastDocdate = 'payment_accrued_last_docdate',
  /** column name */
  PaymentAccruedPercent = 'payment_accrued_percent',
  /** column name */
  PaymentAccruedPercent_2021 = 'payment_accrued_percent_2021',
  /** column name */
  PercentAccrualCash = 'percent_accrual_cash',
  /** column name */
  PercentPaidCash = 'percent_paid_cash',
  /** column name */
  ProjectEnddate = 'project_enddate',
  /** column name */
  ProjectLeadName = 'project_lead_name',
  /** column name */
  ProjectManagerName = 'project_manager_name',
  /** column name */
  ProjectName = 'project_name',
  /** column name */
  ResidualPayoutRatio = 'residual_payout_ratio',
  /** column name */
  UserRole = 'user_role',
  /** column name */
  UserRoleIndex = 'user_role_index',
  /** column name */
  WrokCompletionPercent = 'wrok_completion_percent'
}

/** input type for updating data in table "dividends" */
export type Dividends_Set_Input = {
  ItemInternalId?: Maybe<Scalars['String']>;
  budget_revision?: Maybe<Scalars['String']>;
  coef_cashlees_to_cash_planned?: Maybe<Scalars['String']>;
  coef_cashless_to_cash?: Maybe<Scalars['String']>;
  currency_code?: Maybe<Scalars['String']>;
  dividend_planned_cashless_vat?: Maybe<Scalars['String']>;
  dividend_proportion?: Maybe<Scalars['String']>;
  dividends_1C?: Maybe<Scalars['String']>;
  dividends_accrued_by_closed_projects_cash?: Maybe<Scalars['String']>;
  dividends_accrued_cash?: Maybe<Scalars['String']>;
  dividends_accrued_cashless_vat?: Maybe<Scalars['String']>;
  dividends_accrued_in_prev_cash?: Maybe<Scalars['String']>;
  dividends_paid_cash?: Maybe<Scalars['String']>;
  dividends_paid_cashless?: Maybe<Scalars['String']>;
  dividends_planned_cash?: Maybe<Scalars['String']>;
  dividends_worker_email?: Maybe<Scalars['String']>;
  dividends_worker_id?: Maybe<Scalars['String']>;
  dividends_worker_name?: Maybe<Scalars['String']>;
  docs_completion_percent?: Maybe<Scalars['String']>;
  leftover_accrual_cash?: Maybe<Scalars['String']>;
  leftover_by_closed_projects_cash?: Maybe<Scalars['String']>;
  leftover_paid_cash?: Maybe<Scalars['String']>;
  leftover_planned_to_pay_cash?: Maybe<Scalars['String']>;
  macroproject_name?: Maybe<Scalars['String']>;
  payment_accrued_last_docdate?: Maybe<Scalars['String']>;
  payment_accrued_percent?: Maybe<Scalars['String']>;
  payment_accrued_percent_2021?: Maybe<Scalars['String']>;
  percent_accrual_cash?: Maybe<Scalars['String']>;
  percent_paid_cash?: Maybe<Scalars['String']>;
  project_enddate?: Maybe<Scalars['String']>;
  project_lead_name?: Maybe<Scalars['String']>;
  project_manager_name?: Maybe<Scalars['String']>;
  project_name?: Maybe<Scalars['String']>;
  residual_payout_ratio?: Maybe<Scalars['String']>;
  user_role?: Maybe<Scalars['String']>;
  user_role_index?: Maybe<Scalars['String']>;
  wrok_completion_percent?: Maybe<Scalars['String']>;
};


/** Boolean expression to compare columns of type "float8". All fields are combined with logical 'AND'. */
export type Float8_Comparison_Exp = {
  _eq?: Maybe<Scalars['float8']>;
  _gt?: Maybe<Scalars['float8']>;
  _gte?: Maybe<Scalars['float8']>;
  _in?: Maybe<Array<Scalars['float8']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['float8']>;
  _lte?: Maybe<Scalars['float8']>;
  _neq?: Maybe<Scalars['float8']>;
  _nin?: Maybe<Array<Scalars['float8']>>;
};

/** columns and relationships of "hr.arm_partnerroleassignment" */
export type Hr_Arm_Partnerroleassignment = {
  end_date?: Maybe<Scalars['date']>;
  id: Scalars['uuid'];
  /** An object relationship */
  role?: Maybe<Hr_Arm_Partners>;
  role_id?: Maybe<Scalars['uuid']>;
  start_date?: Maybe<Scalars['date']>;
  /** An object relationship */
  worker?: Maybe<Hr_Cdm_Workers>;
  worker_id?: Maybe<Scalars['uuid']>;
};

/** aggregated selection of "hr.arm_partnerroleassignment" */
export type Hr_Arm_Partnerroleassignment_Aggregate = {
  aggregate?: Maybe<Hr_Arm_Partnerroleassignment_Aggregate_Fields>;
  nodes: Array<Hr_Arm_Partnerroleassignment>;
};

/** aggregate fields of "hr.arm_partnerroleassignment" */
export type Hr_Arm_Partnerroleassignment_Aggregate_Fields = {
  count: Scalars['Int'];
  max?: Maybe<Hr_Arm_Partnerroleassignment_Max_Fields>;
  min?: Maybe<Hr_Arm_Partnerroleassignment_Min_Fields>;
};


/** aggregate fields of "hr.arm_partnerroleassignment" */
export type Hr_Arm_Partnerroleassignment_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Hr_Arm_Partnerroleassignment_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "hr.arm_partnerroleassignment" */
export type Hr_Arm_Partnerroleassignment_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Hr_Arm_Partnerroleassignment_Max_Order_By>;
  min?: Maybe<Hr_Arm_Partnerroleassignment_Min_Order_By>;
};

/** input type for inserting array relation for remote table "hr.arm_partnerroleassignment" */
export type Hr_Arm_Partnerroleassignment_Arr_Rel_Insert_Input = {
  data: Array<Hr_Arm_Partnerroleassignment_Insert_Input>;
};

/** Boolean expression to filter rows from the table "hr.arm_partnerroleassignment". All fields are combined with a logical 'AND'. */
export type Hr_Arm_Partnerroleassignment_Bool_Exp = {
  _and?: Maybe<Array<Hr_Arm_Partnerroleassignment_Bool_Exp>>;
  _not?: Maybe<Hr_Arm_Partnerroleassignment_Bool_Exp>;
  _or?: Maybe<Array<Hr_Arm_Partnerroleassignment_Bool_Exp>>;
  end_date?: Maybe<Date_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  role?: Maybe<Hr_Arm_Partners_Bool_Exp>;
  role_id?: Maybe<Uuid_Comparison_Exp>;
  start_date?: Maybe<Date_Comparison_Exp>;
  worker?: Maybe<Hr_Cdm_Workers_Bool_Exp>;
  worker_id?: Maybe<Uuid_Comparison_Exp>;
};

/** input type for inserting data into table "hr.arm_partnerroleassignment" */
export type Hr_Arm_Partnerroleassignment_Insert_Input = {
  end_date?: Maybe<Scalars['date']>;
  id?: Maybe<Scalars['uuid']>;
  role?: Maybe<Hr_Arm_Partners_Obj_Rel_Insert_Input>;
  role_id?: Maybe<Scalars['uuid']>;
  start_date?: Maybe<Scalars['date']>;
  worker?: Maybe<Hr_Cdm_Workers_Obj_Rel_Insert_Input>;
  worker_id?: Maybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Hr_Arm_Partnerroleassignment_Max_Fields = {
  end_date?: Maybe<Scalars['date']>;
  id?: Maybe<Scalars['uuid']>;
  role_id?: Maybe<Scalars['uuid']>;
  start_date?: Maybe<Scalars['date']>;
  worker_id?: Maybe<Scalars['uuid']>;
};

/** order by max() on columns of table "hr.arm_partnerroleassignment" */
export type Hr_Arm_Partnerroleassignment_Max_Order_By = {
  end_date?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  role_id?: Maybe<Order_By>;
  start_date?: Maybe<Order_By>;
  worker_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Hr_Arm_Partnerroleassignment_Min_Fields = {
  end_date?: Maybe<Scalars['date']>;
  id?: Maybe<Scalars['uuid']>;
  role_id?: Maybe<Scalars['uuid']>;
  start_date?: Maybe<Scalars['date']>;
  worker_id?: Maybe<Scalars['uuid']>;
};

/** order by min() on columns of table "hr.arm_partnerroleassignment" */
export type Hr_Arm_Partnerroleassignment_Min_Order_By = {
  end_date?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  role_id?: Maybe<Order_By>;
  start_date?: Maybe<Order_By>;
  worker_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "hr.arm_partnerroleassignment" */
export type Hr_Arm_Partnerroleassignment_Mutation_Response = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Hr_Arm_Partnerroleassignment>;
};

/** Ordering options when selecting data from "hr.arm_partnerroleassignment". */
export type Hr_Arm_Partnerroleassignment_Order_By = {
  end_date?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  role?: Maybe<Hr_Arm_Partners_Order_By>;
  role_id?: Maybe<Order_By>;
  start_date?: Maybe<Order_By>;
  worker?: Maybe<Hr_Cdm_Workers_Order_By>;
  worker_id?: Maybe<Order_By>;
};

/** select columns of table "hr.arm_partnerroleassignment" */
export enum Hr_Arm_Partnerroleassignment_Select_Column {
  /** column name */
  EndDate = 'end_date',
  /** column name */
  Id = 'id',
  /** column name */
  RoleId = 'role_id',
  /** column name */
  StartDate = 'start_date',
  /** column name */
  WorkerId = 'worker_id'
}

/** input type for updating data in table "hr.arm_partnerroleassignment" */
export type Hr_Arm_Partnerroleassignment_Set_Input = {
  end_date?: Maybe<Scalars['date']>;
  id?: Maybe<Scalars['uuid']>;
  role_id?: Maybe<Scalars['uuid']>;
  start_date?: Maybe<Scalars['date']>;
  worker_id?: Maybe<Scalars['uuid']>;
};

/** columns and relationships of "hr.arm_partners" */
export type Hr_Arm_Partners = {
  id: Scalars['uuid'];
  name?: Maybe<Scalars['String']>;
  name_en?: Maybe<Scalars['String']>;
  name_ru?: Maybe<Scalars['String']>;
  name_ua?: Maybe<Scalars['String']>;
  /** An array relationship */
  partners: Array<Hr_Arm_Partnerroleassignment>;
  /** An aggregate relationship */
  partners_aggregate: Hr_Arm_Partnerroleassignment_Aggregate;
};


/** columns and relationships of "hr.arm_partners" */
export type Hr_Arm_PartnersPartnersArgs = {
  distinct_on?: Maybe<Array<Hr_Arm_Partnerroleassignment_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Hr_Arm_Partnerroleassignment_Order_By>>;
  where?: Maybe<Hr_Arm_Partnerroleassignment_Bool_Exp>;
};


/** columns and relationships of "hr.arm_partners" */
export type Hr_Arm_PartnersPartners_AggregateArgs = {
  distinct_on?: Maybe<Array<Hr_Arm_Partnerroleassignment_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Hr_Arm_Partnerroleassignment_Order_By>>;
  where?: Maybe<Hr_Arm_Partnerroleassignment_Bool_Exp>;
};

/** aggregated selection of "hr.arm_partners" */
export type Hr_Arm_Partners_Aggregate = {
  aggregate?: Maybe<Hr_Arm_Partners_Aggregate_Fields>;
  nodes: Array<Hr_Arm_Partners>;
};

/** aggregate fields of "hr.arm_partners" */
export type Hr_Arm_Partners_Aggregate_Fields = {
  count: Scalars['Int'];
  max?: Maybe<Hr_Arm_Partners_Max_Fields>;
  min?: Maybe<Hr_Arm_Partners_Min_Fields>;
};


/** aggregate fields of "hr.arm_partners" */
export type Hr_Arm_Partners_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Hr_Arm_Partners_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "hr.arm_partners". All fields are combined with a logical 'AND'. */
export type Hr_Arm_Partners_Bool_Exp = {
  _and?: Maybe<Array<Hr_Arm_Partners_Bool_Exp>>;
  _not?: Maybe<Hr_Arm_Partners_Bool_Exp>;
  _or?: Maybe<Array<Hr_Arm_Partners_Bool_Exp>>;
  id?: Maybe<Uuid_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  name_en?: Maybe<String_Comparison_Exp>;
  name_ru?: Maybe<String_Comparison_Exp>;
  name_ua?: Maybe<String_Comparison_Exp>;
  partners?: Maybe<Hr_Arm_Partnerroleassignment_Bool_Exp>;
};

/** input type for inserting data into table "hr.arm_partners" */
export type Hr_Arm_Partners_Insert_Input = {
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  name_en?: Maybe<Scalars['String']>;
  name_ru?: Maybe<Scalars['String']>;
  name_ua?: Maybe<Scalars['String']>;
  partners?: Maybe<Hr_Arm_Partnerroleassignment_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Hr_Arm_Partners_Max_Fields = {
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  name_en?: Maybe<Scalars['String']>;
  name_ru?: Maybe<Scalars['String']>;
  name_ua?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Hr_Arm_Partners_Min_Fields = {
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  name_en?: Maybe<Scalars['String']>;
  name_ru?: Maybe<Scalars['String']>;
  name_ua?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "hr.arm_partners" */
export type Hr_Arm_Partners_Mutation_Response = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Hr_Arm_Partners>;
};

/** input type for inserting object relation for remote table "hr.arm_partners" */
export type Hr_Arm_Partners_Obj_Rel_Insert_Input = {
  data: Hr_Arm_Partners_Insert_Input;
};

/** Ordering options when selecting data from "hr.arm_partners". */
export type Hr_Arm_Partners_Order_By = {
  id?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  name_en?: Maybe<Order_By>;
  name_ru?: Maybe<Order_By>;
  name_ua?: Maybe<Order_By>;
  partners_aggregate?: Maybe<Hr_Arm_Partnerroleassignment_Aggregate_Order_By>;
};

/** select columns of table "hr.arm_partners" */
export enum Hr_Arm_Partners_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  NameEn = 'name_en',
  /** column name */
  NameRu = 'name_ru',
  /** column name */
  NameUa = 'name_ua'
}

/** input type for updating data in table "hr.arm_partners" */
export type Hr_Arm_Partners_Set_Input = {
  id?: Maybe<Scalars['uuid']>;
  name?: Maybe<Scalars['String']>;
  name_en?: Maybe<Scalars['String']>;
  name_ru?: Maybe<Scalars['String']>;
  name_ua?: Maybe<Scalars['String']>;
};

/** columns and relationships of "hr.arm_teamleadassignment" */
export type Hr_Arm_Teamleadassignment = {
  _cdm_departmentid?: Maybe<Scalars['uuid']>;
  _cdm_wokerid?: Maybe<Scalars['uuid']>;
  arm_teamleadassignmentid: Scalars['uuid'];
  /** An object relationship */
  cdm_department?: Maybe<Hr_Cdm_Departments>;
  /** An object relationship */
  teamlead?: Maybe<Hr_Cdm_Workers>;
  valid_from?: Maybe<Scalars['timestamptz']>;
  valid_to?: Maybe<Scalars['timestamptz']>;
};

/** aggregated selection of "hr.arm_teamleadassignment" */
export type Hr_Arm_Teamleadassignment_Aggregate = {
  aggregate?: Maybe<Hr_Arm_Teamleadassignment_Aggregate_Fields>;
  nodes: Array<Hr_Arm_Teamleadassignment>;
};

/** aggregate fields of "hr.arm_teamleadassignment" */
export type Hr_Arm_Teamleadassignment_Aggregate_Fields = {
  count: Scalars['Int'];
  max?: Maybe<Hr_Arm_Teamleadassignment_Max_Fields>;
  min?: Maybe<Hr_Arm_Teamleadassignment_Min_Fields>;
};


/** aggregate fields of "hr.arm_teamleadassignment" */
export type Hr_Arm_Teamleadassignment_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Hr_Arm_Teamleadassignment_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "hr.arm_teamleadassignment" */
export type Hr_Arm_Teamleadassignment_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Hr_Arm_Teamleadassignment_Max_Order_By>;
  min?: Maybe<Hr_Arm_Teamleadassignment_Min_Order_By>;
};

/** input type for inserting array relation for remote table "hr.arm_teamleadassignment" */
export type Hr_Arm_Teamleadassignment_Arr_Rel_Insert_Input = {
  data: Array<Hr_Arm_Teamleadassignment_Insert_Input>;
};

/** Boolean expression to filter rows from the table "hr.arm_teamleadassignment". All fields are combined with a logical 'AND'. */
export type Hr_Arm_Teamleadassignment_Bool_Exp = {
  _and?: Maybe<Array<Hr_Arm_Teamleadassignment_Bool_Exp>>;
  _cdm_departmentid?: Maybe<Uuid_Comparison_Exp>;
  _cdm_wokerid?: Maybe<Uuid_Comparison_Exp>;
  _not?: Maybe<Hr_Arm_Teamleadassignment_Bool_Exp>;
  _or?: Maybe<Array<Hr_Arm_Teamleadassignment_Bool_Exp>>;
  arm_teamleadassignmentid?: Maybe<Uuid_Comparison_Exp>;
  cdm_department?: Maybe<Hr_Cdm_Departments_Bool_Exp>;
  teamlead?: Maybe<Hr_Cdm_Workers_Bool_Exp>;
  valid_from?: Maybe<Timestamptz_Comparison_Exp>;
  valid_to?: Maybe<Timestamptz_Comparison_Exp>;
};

/** input type for inserting data into table "hr.arm_teamleadassignment" */
export type Hr_Arm_Teamleadassignment_Insert_Input = {
  _cdm_departmentid?: Maybe<Scalars['uuid']>;
  _cdm_wokerid?: Maybe<Scalars['uuid']>;
  arm_teamleadassignmentid?: Maybe<Scalars['uuid']>;
  cdm_department?: Maybe<Hr_Cdm_Departments_Obj_Rel_Insert_Input>;
  teamlead?: Maybe<Hr_Cdm_Workers_Obj_Rel_Insert_Input>;
  valid_from?: Maybe<Scalars['timestamptz']>;
  valid_to?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Hr_Arm_Teamleadassignment_Max_Fields = {
  _cdm_departmentid?: Maybe<Scalars['uuid']>;
  _cdm_wokerid?: Maybe<Scalars['uuid']>;
  arm_teamleadassignmentid?: Maybe<Scalars['uuid']>;
  valid_from?: Maybe<Scalars['timestamptz']>;
  valid_to?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "hr.arm_teamleadassignment" */
export type Hr_Arm_Teamleadassignment_Max_Order_By = {
  _cdm_departmentid?: Maybe<Order_By>;
  _cdm_wokerid?: Maybe<Order_By>;
  arm_teamleadassignmentid?: Maybe<Order_By>;
  valid_from?: Maybe<Order_By>;
  valid_to?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Hr_Arm_Teamleadassignment_Min_Fields = {
  _cdm_departmentid?: Maybe<Scalars['uuid']>;
  _cdm_wokerid?: Maybe<Scalars['uuid']>;
  arm_teamleadassignmentid?: Maybe<Scalars['uuid']>;
  valid_from?: Maybe<Scalars['timestamptz']>;
  valid_to?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "hr.arm_teamleadassignment" */
export type Hr_Arm_Teamleadassignment_Min_Order_By = {
  _cdm_departmentid?: Maybe<Order_By>;
  _cdm_wokerid?: Maybe<Order_By>;
  arm_teamleadassignmentid?: Maybe<Order_By>;
  valid_from?: Maybe<Order_By>;
  valid_to?: Maybe<Order_By>;
};

/** response of any mutation on the table "hr.arm_teamleadassignment" */
export type Hr_Arm_Teamleadassignment_Mutation_Response = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Hr_Arm_Teamleadassignment>;
};

/** Ordering options when selecting data from "hr.arm_teamleadassignment". */
export type Hr_Arm_Teamleadassignment_Order_By = {
  _cdm_departmentid?: Maybe<Order_By>;
  _cdm_wokerid?: Maybe<Order_By>;
  arm_teamleadassignmentid?: Maybe<Order_By>;
  cdm_department?: Maybe<Hr_Cdm_Departments_Order_By>;
  teamlead?: Maybe<Hr_Cdm_Workers_Order_By>;
  valid_from?: Maybe<Order_By>;
  valid_to?: Maybe<Order_By>;
};

/** select columns of table "hr.arm_teamleadassignment" */
export enum Hr_Arm_Teamleadassignment_Select_Column {
  /** column name */
  CdmDepartmentid = '_cdm_departmentid',
  /** column name */
  CdmWokerid = '_cdm_wokerid',
  /** column name */
  ArmTeamleadassignmentid = 'arm_teamleadassignmentid',
  /** column name */
  ValidFrom = 'valid_from',
  /** column name */
  ValidTo = 'valid_to'
}

/** input type for updating data in table "hr.arm_teamleadassignment" */
export type Hr_Arm_Teamleadassignment_Set_Input = {
  _cdm_departmentid?: Maybe<Scalars['uuid']>;
  _cdm_wokerid?: Maybe<Scalars['uuid']>;
  arm_teamleadassignmentid?: Maybe<Scalars['uuid']>;
  valid_from?: Maybe<Scalars['timestamptz']>;
  valid_to?: Maybe<Scalars['timestamptz']>;
};

/** columns and relationships of "hr.cdm_compensationfixedplans" */
export type Hr_Cdm_Compensationfixedplans = {
  _cdm_company_value?: Maybe<Scalars['uuid']>;
  _cdm_compensationgrid_value?: Maybe<Scalars['uuid']>;
  _cdm_payfrequency_value?: Maybe<Scalars['uuid']>;
  _cdm_referencepointsetupline_value?: Maybe<Scalars['uuid']>;
  _cdm_transactioncurrencyid_value?: Maybe<Scalars['uuid']>;
  _createdby_value?: Maybe<Scalars['uuid']>;
  _createdonbehalfby_value?: Maybe<Scalars['timestamptz']>;
  _modifiedby_value?: Maybe<Scalars['uuid']>;
  _modifiedonbehalfby_value?: Maybe<Scalars['timestamptz']>;
  _ownerid_value?: Maybe<Scalars['uuid']>;
  _owningbusinessunit_value?: Maybe<Scalars['uuid']>;
  _owningteam_value?: Maybe<Scalars['String']>;
  _owninguser_value?: Maybe<Scalars['uuid']>;
  cdm_compensationfixedplanid: Scalars['uuid'];
  cdm_description?: Maybe<Scalars['String']>;
  cdm_effectivedate?: Maybe<Scalars['timestamptz']>;
  cdm_expirationdate?: Maybe<Scalars['timestamptz']>;
  cdm_hirerule?: Maybe<Scalars['String']>;
  cdm_name?: Maybe<Scalars['String']>;
  cdm_outofrangetolerance?: Maybe<Scalars['String']>;
  cdm_recommendationallowed?: Maybe<Scalars['String']>;
  cdm_type?: Maybe<Scalars['String']>;
  /** An array relationship */
  cdm_workerfixedcompensations: Array<Hr_Cdm_Workerfixedcompensations>;
  /** An aggregate relationship */
  cdm_workerfixedcompensations_aggregate: Hr_Cdm_Workerfixedcompensations_Aggregate;
  createdon?: Maybe<Scalars['timestamptz']>;
  importsequencenumber?: Maybe<Scalars['String']>;
  modifiedon?: Maybe<Scalars['timestamptz']>;
  overriddencreatedon?: Maybe<Scalars['timestamptz']>;
  statecode?: Maybe<Scalars['String']>;
  statuscode?: Maybe<Scalars['String']>;
  timezoneruleversionnumber?: Maybe<Scalars['String']>;
  utcconversiontimezonecode?: Maybe<Scalars['String']>;
  versionnumber?: Maybe<Scalars['String']>;
};


/** columns and relationships of "hr.cdm_compensationfixedplans" */
export type Hr_Cdm_CompensationfixedplansCdm_WorkerfixedcompensationsArgs = {
  distinct_on?: Maybe<Array<Hr_Cdm_Workerfixedcompensations_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Hr_Cdm_Workerfixedcompensations_Order_By>>;
  where?: Maybe<Hr_Cdm_Workerfixedcompensations_Bool_Exp>;
};


/** columns and relationships of "hr.cdm_compensationfixedplans" */
export type Hr_Cdm_CompensationfixedplansCdm_Workerfixedcompensations_AggregateArgs = {
  distinct_on?: Maybe<Array<Hr_Cdm_Workerfixedcompensations_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Hr_Cdm_Workerfixedcompensations_Order_By>>;
  where?: Maybe<Hr_Cdm_Workerfixedcompensations_Bool_Exp>;
};

/** aggregated selection of "hr.cdm_compensationfixedplans" */
export type Hr_Cdm_Compensationfixedplans_Aggregate = {
  aggregate?: Maybe<Hr_Cdm_Compensationfixedplans_Aggregate_Fields>;
  nodes: Array<Hr_Cdm_Compensationfixedplans>;
};

/** aggregate fields of "hr.cdm_compensationfixedplans" */
export type Hr_Cdm_Compensationfixedplans_Aggregate_Fields = {
  count: Scalars['Int'];
  max?: Maybe<Hr_Cdm_Compensationfixedplans_Max_Fields>;
  min?: Maybe<Hr_Cdm_Compensationfixedplans_Min_Fields>;
};


/** aggregate fields of "hr.cdm_compensationfixedplans" */
export type Hr_Cdm_Compensationfixedplans_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Hr_Cdm_Compensationfixedplans_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "hr.cdm_compensationfixedplans". All fields are combined with a logical 'AND'. */
export type Hr_Cdm_Compensationfixedplans_Bool_Exp = {
  _and?: Maybe<Array<Hr_Cdm_Compensationfixedplans_Bool_Exp>>;
  _cdm_company_value?: Maybe<Uuid_Comparison_Exp>;
  _cdm_compensationgrid_value?: Maybe<Uuid_Comparison_Exp>;
  _cdm_payfrequency_value?: Maybe<Uuid_Comparison_Exp>;
  _cdm_referencepointsetupline_value?: Maybe<Uuid_Comparison_Exp>;
  _cdm_transactioncurrencyid_value?: Maybe<Uuid_Comparison_Exp>;
  _createdby_value?: Maybe<Uuid_Comparison_Exp>;
  _createdonbehalfby_value?: Maybe<Timestamptz_Comparison_Exp>;
  _modifiedby_value?: Maybe<Uuid_Comparison_Exp>;
  _modifiedonbehalfby_value?: Maybe<Timestamptz_Comparison_Exp>;
  _not?: Maybe<Hr_Cdm_Compensationfixedplans_Bool_Exp>;
  _or?: Maybe<Array<Hr_Cdm_Compensationfixedplans_Bool_Exp>>;
  _ownerid_value?: Maybe<Uuid_Comparison_Exp>;
  _owningbusinessunit_value?: Maybe<Uuid_Comparison_Exp>;
  _owningteam_value?: Maybe<String_Comparison_Exp>;
  _owninguser_value?: Maybe<Uuid_Comparison_Exp>;
  cdm_compensationfixedplanid?: Maybe<Uuid_Comparison_Exp>;
  cdm_description?: Maybe<String_Comparison_Exp>;
  cdm_effectivedate?: Maybe<Timestamptz_Comparison_Exp>;
  cdm_expirationdate?: Maybe<Timestamptz_Comparison_Exp>;
  cdm_hirerule?: Maybe<String_Comparison_Exp>;
  cdm_name?: Maybe<String_Comparison_Exp>;
  cdm_outofrangetolerance?: Maybe<String_Comparison_Exp>;
  cdm_recommendationallowed?: Maybe<String_Comparison_Exp>;
  cdm_type?: Maybe<String_Comparison_Exp>;
  cdm_workerfixedcompensations?: Maybe<Hr_Cdm_Workerfixedcompensations_Bool_Exp>;
  createdon?: Maybe<Timestamptz_Comparison_Exp>;
  importsequencenumber?: Maybe<String_Comparison_Exp>;
  modifiedon?: Maybe<Timestamptz_Comparison_Exp>;
  overriddencreatedon?: Maybe<Timestamptz_Comparison_Exp>;
  statecode?: Maybe<String_Comparison_Exp>;
  statuscode?: Maybe<String_Comparison_Exp>;
  timezoneruleversionnumber?: Maybe<String_Comparison_Exp>;
  utcconversiontimezonecode?: Maybe<String_Comparison_Exp>;
  versionnumber?: Maybe<String_Comparison_Exp>;
};

/** input type for inserting data into table "hr.cdm_compensationfixedplans" */
export type Hr_Cdm_Compensationfixedplans_Insert_Input = {
  _cdm_company_value?: Maybe<Scalars['uuid']>;
  _cdm_compensationgrid_value?: Maybe<Scalars['uuid']>;
  _cdm_payfrequency_value?: Maybe<Scalars['uuid']>;
  _cdm_referencepointsetupline_value?: Maybe<Scalars['uuid']>;
  _cdm_transactioncurrencyid_value?: Maybe<Scalars['uuid']>;
  _createdby_value?: Maybe<Scalars['uuid']>;
  _createdonbehalfby_value?: Maybe<Scalars['timestamptz']>;
  _modifiedby_value?: Maybe<Scalars['uuid']>;
  _modifiedonbehalfby_value?: Maybe<Scalars['timestamptz']>;
  _ownerid_value?: Maybe<Scalars['uuid']>;
  _owningbusinessunit_value?: Maybe<Scalars['uuid']>;
  _owningteam_value?: Maybe<Scalars['String']>;
  _owninguser_value?: Maybe<Scalars['uuid']>;
  cdm_compensationfixedplanid?: Maybe<Scalars['uuid']>;
  cdm_description?: Maybe<Scalars['String']>;
  cdm_effectivedate?: Maybe<Scalars['timestamptz']>;
  cdm_expirationdate?: Maybe<Scalars['timestamptz']>;
  cdm_hirerule?: Maybe<Scalars['String']>;
  cdm_name?: Maybe<Scalars['String']>;
  cdm_outofrangetolerance?: Maybe<Scalars['String']>;
  cdm_recommendationallowed?: Maybe<Scalars['String']>;
  cdm_type?: Maybe<Scalars['String']>;
  cdm_workerfixedcompensations?: Maybe<Hr_Cdm_Workerfixedcompensations_Arr_Rel_Insert_Input>;
  createdon?: Maybe<Scalars['timestamptz']>;
  importsequencenumber?: Maybe<Scalars['String']>;
  modifiedon?: Maybe<Scalars['timestamptz']>;
  overriddencreatedon?: Maybe<Scalars['timestamptz']>;
  statecode?: Maybe<Scalars['String']>;
  statuscode?: Maybe<Scalars['String']>;
  timezoneruleversionnumber?: Maybe<Scalars['String']>;
  utcconversiontimezonecode?: Maybe<Scalars['String']>;
  versionnumber?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Hr_Cdm_Compensationfixedplans_Max_Fields = {
  _cdm_company_value?: Maybe<Scalars['uuid']>;
  _cdm_compensationgrid_value?: Maybe<Scalars['uuid']>;
  _cdm_payfrequency_value?: Maybe<Scalars['uuid']>;
  _cdm_referencepointsetupline_value?: Maybe<Scalars['uuid']>;
  _cdm_transactioncurrencyid_value?: Maybe<Scalars['uuid']>;
  _createdby_value?: Maybe<Scalars['uuid']>;
  _createdonbehalfby_value?: Maybe<Scalars['timestamptz']>;
  _modifiedby_value?: Maybe<Scalars['uuid']>;
  _modifiedonbehalfby_value?: Maybe<Scalars['timestamptz']>;
  _ownerid_value?: Maybe<Scalars['uuid']>;
  _owningbusinessunit_value?: Maybe<Scalars['uuid']>;
  _owningteam_value?: Maybe<Scalars['String']>;
  _owninguser_value?: Maybe<Scalars['uuid']>;
  cdm_compensationfixedplanid?: Maybe<Scalars['uuid']>;
  cdm_description?: Maybe<Scalars['String']>;
  cdm_effectivedate?: Maybe<Scalars['timestamptz']>;
  cdm_expirationdate?: Maybe<Scalars['timestamptz']>;
  cdm_hirerule?: Maybe<Scalars['String']>;
  cdm_name?: Maybe<Scalars['String']>;
  cdm_outofrangetolerance?: Maybe<Scalars['String']>;
  cdm_recommendationallowed?: Maybe<Scalars['String']>;
  cdm_type?: Maybe<Scalars['String']>;
  createdon?: Maybe<Scalars['timestamptz']>;
  importsequencenumber?: Maybe<Scalars['String']>;
  modifiedon?: Maybe<Scalars['timestamptz']>;
  overriddencreatedon?: Maybe<Scalars['timestamptz']>;
  statecode?: Maybe<Scalars['String']>;
  statuscode?: Maybe<Scalars['String']>;
  timezoneruleversionnumber?: Maybe<Scalars['String']>;
  utcconversiontimezonecode?: Maybe<Scalars['String']>;
  versionnumber?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Hr_Cdm_Compensationfixedplans_Min_Fields = {
  _cdm_company_value?: Maybe<Scalars['uuid']>;
  _cdm_compensationgrid_value?: Maybe<Scalars['uuid']>;
  _cdm_payfrequency_value?: Maybe<Scalars['uuid']>;
  _cdm_referencepointsetupline_value?: Maybe<Scalars['uuid']>;
  _cdm_transactioncurrencyid_value?: Maybe<Scalars['uuid']>;
  _createdby_value?: Maybe<Scalars['uuid']>;
  _createdonbehalfby_value?: Maybe<Scalars['timestamptz']>;
  _modifiedby_value?: Maybe<Scalars['uuid']>;
  _modifiedonbehalfby_value?: Maybe<Scalars['timestamptz']>;
  _ownerid_value?: Maybe<Scalars['uuid']>;
  _owningbusinessunit_value?: Maybe<Scalars['uuid']>;
  _owningteam_value?: Maybe<Scalars['String']>;
  _owninguser_value?: Maybe<Scalars['uuid']>;
  cdm_compensationfixedplanid?: Maybe<Scalars['uuid']>;
  cdm_description?: Maybe<Scalars['String']>;
  cdm_effectivedate?: Maybe<Scalars['timestamptz']>;
  cdm_expirationdate?: Maybe<Scalars['timestamptz']>;
  cdm_hirerule?: Maybe<Scalars['String']>;
  cdm_name?: Maybe<Scalars['String']>;
  cdm_outofrangetolerance?: Maybe<Scalars['String']>;
  cdm_recommendationallowed?: Maybe<Scalars['String']>;
  cdm_type?: Maybe<Scalars['String']>;
  createdon?: Maybe<Scalars['timestamptz']>;
  importsequencenumber?: Maybe<Scalars['String']>;
  modifiedon?: Maybe<Scalars['timestamptz']>;
  overriddencreatedon?: Maybe<Scalars['timestamptz']>;
  statecode?: Maybe<Scalars['String']>;
  statuscode?: Maybe<Scalars['String']>;
  timezoneruleversionnumber?: Maybe<Scalars['String']>;
  utcconversiontimezonecode?: Maybe<Scalars['String']>;
  versionnumber?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "hr.cdm_compensationfixedplans" */
export type Hr_Cdm_Compensationfixedplans_Mutation_Response = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Hr_Cdm_Compensationfixedplans>;
};

/** input type for inserting object relation for remote table "hr.cdm_compensationfixedplans" */
export type Hr_Cdm_Compensationfixedplans_Obj_Rel_Insert_Input = {
  data: Hr_Cdm_Compensationfixedplans_Insert_Input;
};

/** Ordering options when selecting data from "hr.cdm_compensationfixedplans". */
export type Hr_Cdm_Compensationfixedplans_Order_By = {
  _cdm_company_value?: Maybe<Order_By>;
  _cdm_compensationgrid_value?: Maybe<Order_By>;
  _cdm_payfrequency_value?: Maybe<Order_By>;
  _cdm_referencepointsetupline_value?: Maybe<Order_By>;
  _cdm_transactioncurrencyid_value?: Maybe<Order_By>;
  _createdby_value?: Maybe<Order_By>;
  _createdonbehalfby_value?: Maybe<Order_By>;
  _modifiedby_value?: Maybe<Order_By>;
  _modifiedonbehalfby_value?: Maybe<Order_By>;
  _ownerid_value?: Maybe<Order_By>;
  _owningbusinessunit_value?: Maybe<Order_By>;
  _owningteam_value?: Maybe<Order_By>;
  _owninguser_value?: Maybe<Order_By>;
  cdm_compensationfixedplanid?: Maybe<Order_By>;
  cdm_description?: Maybe<Order_By>;
  cdm_effectivedate?: Maybe<Order_By>;
  cdm_expirationdate?: Maybe<Order_By>;
  cdm_hirerule?: Maybe<Order_By>;
  cdm_name?: Maybe<Order_By>;
  cdm_outofrangetolerance?: Maybe<Order_By>;
  cdm_recommendationallowed?: Maybe<Order_By>;
  cdm_type?: Maybe<Order_By>;
  cdm_workerfixedcompensations_aggregate?: Maybe<Hr_Cdm_Workerfixedcompensations_Aggregate_Order_By>;
  createdon?: Maybe<Order_By>;
  importsequencenumber?: Maybe<Order_By>;
  modifiedon?: Maybe<Order_By>;
  overriddencreatedon?: Maybe<Order_By>;
  statecode?: Maybe<Order_By>;
  statuscode?: Maybe<Order_By>;
  timezoneruleversionnumber?: Maybe<Order_By>;
  utcconversiontimezonecode?: Maybe<Order_By>;
  versionnumber?: Maybe<Order_By>;
};

/** select columns of table "hr.cdm_compensationfixedplans" */
export enum Hr_Cdm_Compensationfixedplans_Select_Column {
  /** column name */
  CdmCompanyValue = '_cdm_company_value',
  /** column name */
  CdmCompensationgridValue = '_cdm_compensationgrid_value',
  /** column name */
  CdmPayfrequencyValue = '_cdm_payfrequency_value',
  /** column name */
  CdmReferencepointsetuplineValue = '_cdm_referencepointsetupline_value',
  /** column name */
  CdmTransactioncurrencyidValue = '_cdm_transactioncurrencyid_value',
  /** column name */
  CreatedbyValue = '_createdby_value',
  /** column name */
  CreatedonbehalfbyValue = '_createdonbehalfby_value',
  /** column name */
  ModifiedbyValue = '_modifiedby_value',
  /** column name */
  ModifiedonbehalfbyValue = '_modifiedonbehalfby_value',
  /** column name */
  OwneridValue = '_ownerid_value',
  /** column name */
  OwningbusinessunitValue = '_owningbusinessunit_value',
  /** column name */
  OwningteamValue = '_owningteam_value',
  /** column name */
  OwninguserValue = '_owninguser_value',
  /** column name */
  CdmCompensationfixedplanid = 'cdm_compensationfixedplanid',
  /** column name */
  CdmDescription = 'cdm_description',
  /** column name */
  CdmEffectivedate = 'cdm_effectivedate',
  /** column name */
  CdmExpirationdate = 'cdm_expirationdate',
  /** column name */
  CdmHirerule = 'cdm_hirerule',
  /** column name */
  CdmName = 'cdm_name',
  /** column name */
  CdmOutofrangetolerance = 'cdm_outofrangetolerance',
  /** column name */
  CdmRecommendationallowed = 'cdm_recommendationallowed',
  /** column name */
  CdmType = 'cdm_type',
  /** column name */
  Createdon = 'createdon',
  /** column name */
  Importsequencenumber = 'importsequencenumber',
  /** column name */
  Modifiedon = 'modifiedon',
  /** column name */
  Overriddencreatedon = 'overriddencreatedon',
  /** column name */
  Statecode = 'statecode',
  /** column name */
  Statuscode = 'statuscode',
  /** column name */
  Timezoneruleversionnumber = 'timezoneruleversionnumber',
  /** column name */
  Utcconversiontimezonecode = 'utcconversiontimezonecode',
  /** column name */
  Versionnumber = 'versionnumber'
}

/** input type for updating data in table "hr.cdm_compensationfixedplans" */
export type Hr_Cdm_Compensationfixedplans_Set_Input = {
  _cdm_company_value?: Maybe<Scalars['uuid']>;
  _cdm_compensationgrid_value?: Maybe<Scalars['uuid']>;
  _cdm_payfrequency_value?: Maybe<Scalars['uuid']>;
  _cdm_referencepointsetupline_value?: Maybe<Scalars['uuid']>;
  _cdm_transactioncurrencyid_value?: Maybe<Scalars['uuid']>;
  _createdby_value?: Maybe<Scalars['uuid']>;
  _createdonbehalfby_value?: Maybe<Scalars['timestamptz']>;
  _modifiedby_value?: Maybe<Scalars['uuid']>;
  _modifiedonbehalfby_value?: Maybe<Scalars['timestamptz']>;
  _ownerid_value?: Maybe<Scalars['uuid']>;
  _owningbusinessunit_value?: Maybe<Scalars['uuid']>;
  _owningteam_value?: Maybe<Scalars['String']>;
  _owninguser_value?: Maybe<Scalars['uuid']>;
  cdm_compensationfixedplanid?: Maybe<Scalars['uuid']>;
  cdm_description?: Maybe<Scalars['String']>;
  cdm_effectivedate?: Maybe<Scalars['timestamptz']>;
  cdm_expirationdate?: Maybe<Scalars['timestamptz']>;
  cdm_hirerule?: Maybe<Scalars['String']>;
  cdm_name?: Maybe<Scalars['String']>;
  cdm_outofrangetolerance?: Maybe<Scalars['String']>;
  cdm_recommendationallowed?: Maybe<Scalars['String']>;
  cdm_type?: Maybe<Scalars['String']>;
  createdon?: Maybe<Scalars['timestamptz']>;
  importsequencenumber?: Maybe<Scalars['String']>;
  modifiedon?: Maybe<Scalars['timestamptz']>;
  overriddencreatedon?: Maybe<Scalars['timestamptz']>;
  statecode?: Maybe<Scalars['String']>;
  statuscode?: Maybe<Scalars['String']>;
  timezoneruleversionnumber?: Maybe<Scalars['String']>;
  utcconversiontimezonecode?: Maybe<Scalars['String']>;
  versionnumber?: Maybe<Scalars['String']>;
};

/** columns and relationships of "hr.cdm_compensationlevels" */
export type Hr_Cdm_Compensationlevels = {
  _createdby_value?: Maybe<Scalars['uuid']>;
  _createdonbehalfby_value?: Maybe<Scalars['timestamptz']>;
  _modifiedby_value?: Maybe<Scalars['uuid']>;
  _modifiedonbehalfby_value?: Maybe<Scalars['timestamptz']>;
  _ownerid_value?: Maybe<Scalars['uuid']>;
  _owningbusinessunit_value?: Maybe<Scalars['uuid']>;
  _owningteam_value?: Maybe<Scalars['String']>;
  _owninguser_value?: Maybe<Scalars['uuid']>;
  cdm_compensationlevelid: Scalars['uuid'];
  cdm_description?: Maybe<Scalars['String']>;
  cdm_name?: Maybe<Scalars['String']>;
  cdm_type?: Maybe<Scalars['String']>;
  /** An array relationship */
  cdm_workerfixedcompensations: Array<Hr_Cdm_Workerfixedcompensations>;
  /** An aggregate relationship */
  cdm_workerfixedcompensations_aggregate: Hr_Cdm_Workerfixedcompensations_Aggregate;
  createdon?: Maybe<Scalars['timestamptz']>;
  importsequencenumber?: Maybe<Scalars['String']>;
  modifiedon?: Maybe<Scalars['timestamptz']>;
  overriddencreatedon?: Maybe<Scalars['timestamptz']>;
  statecode?: Maybe<Scalars['String']>;
  statuscode?: Maybe<Scalars['String']>;
  timezoneruleversionnumber?: Maybe<Scalars['String']>;
  utcconversiontimezonecode?: Maybe<Scalars['String']>;
  versionnumber?: Maybe<Scalars['String']>;
};


/** columns and relationships of "hr.cdm_compensationlevels" */
export type Hr_Cdm_CompensationlevelsCdm_WorkerfixedcompensationsArgs = {
  distinct_on?: Maybe<Array<Hr_Cdm_Workerfixedcompensations_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Hr_Cdm_Workerfixedcompensations_Order_By>>;
  where?: Maybe<Hr_Cdm_Workerfixedcompensations_Bool_Exp>;
};


/** columns and relationships of "hr.cdm_compensationlevels" */
export type Hr_Cdm_CompensationlevelsCdm_Workerfixedcompensations_AggregateArgs = {
  distinct_on?: Maybe<Array<Hr_Cdm_Workerfixedcompensations_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Hr_Cdm_Workerfixedcompensations_Order_By>>;
  where?: Maybe<Hr_Cdm_Workerfixedcompensations_Bool_Exp>;
};

/** aggregated selection of "hr.cdm_compensationlevels" */
export type Hr_Cdm_Compensationlevels_Aggregate = {
  aggregate?: Maybe<Hr_Cdm_Compensationlevels_Aggregate_Fields>;
  nodes: Array<Hr_Cdm_Compensationlevels>;
};

/** aggregate fields of "hr.cdm_compensationlevels" */
export type Hr_Cdm_Compensationlevels_Aggregate_Fields = {
  count: Scalars['Int'];
  max?: Maybe<Hr_Cdm_Compensationlevels_Max_Fields>;
  min?: Maybe<Hr_Cdm_Compensationlevels_Min_Fields>;
};


/** aggregate fields of "hr.cdm_compensationlevels" */
export type Hr_Cdm_Compensationlevels_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Hr_Cdm_Compensationlevels_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "hr.cdm_compensationlevels". All fields are combined with a logical 'AND'. */
export type Hr_Cdm_Compensationlevels_Bool_Exp = {
  _and?: Maybe<Array<Hr_Cdm_Compensationlevels_Bool_Exp>>;
  _createdby_value?: Maybe<Uuid_Comparison_Exp>;
  _createdonbehalfby_value?: Maybe<Timestamptz_Comparison_Exp>;
  _modifiedby_value?: Maybe<Uuid_Comparison_Exp>;
  _modifiedonbehalfby_value?: Maybe<Timestamptz_Comparison_Exp>;
  _not?: Maybe<Hr_Cdm_Compensationlevels_Bool_Exp>;
  _or?: Maybe<Array<Hr_Cdm_Compensationlevels_Bool_Exp>>;
  _ownerid_value?: Maybe<Uuid_Comparison_Exp>;
  _owningbusinessunit_value?: Maybe<Uuid_Comparison_Exp>;
  _owningteam_value?: Maybe<String_Comparison_Exp>;
  _owninguser_value?: Maybe<Uuid_Comparison_Exp>;
  cdm_compensationlevelid?: Maybe<Uuid_Comparison_Exp>;
  cdm_description?: Maybe<String_Comparison_Exp>;
  cdm_name?: Maybe<String_Comparison_Exp>;
  cdm_type?: Maybe<String_Comparison_Exp>;
  cdm_workerfixedcompensations?: Maybe<Hr_Cdm_Workerfixedcompensations_Bool_Exp>;
  createdon?: Maybe<Timestamptz_Comparison_Exp>;
  importsequencenumber?: Maybe<String_Comparison_Exp>;
  modifiedon?: Maybe<Timestamptz_Comparison_Exp>;
  overriddencreatedon?: Maybe<Timestamptz_Comparison_Exp>;
  statecode?: Maybe<String_Comparison_Exp>;
  statuscode?: Maybe<String_Comparison_Exp>;
  timezoneruleversionnumber?: Maybe<String_Comparison_Exp>;
  utcconversiontimezonecode?: Maybe<String_Comparison_Exp>;
  versionnumber?: Maybe<String_Comparison_Exp>;
};

/** input type for inserting data into table "hr.cdm_compensationlevels" */
export type Hr_Cdm_Compensationlevels_Insert_Input = {
  _createdby_value?: Maybe<Scalars['uuid']>;
  _createdonbehalfby_value?: Maybe<Scalars['timestamptz']>;
  _modifiedby_value?: Maybe<Scalars['uuid']>;
  _modifiedonbehalfby_value?: Maybe<Scalars['timestamptz']>;
  _ownerid_value?: Maybe<Scalars['uuid']>;
  _owningbusinessunit_value?: Maybe<Scalars['uuid']>;
  _owningteam_value?: Maybe<Scalars['String']>;
  _owninguser_value?: Maybe<Scalars['uuid']>;
  cdm_compensationlevelid?: Maybe<Scalars['uuid']>;
  cdm_description?: Maybe<Scalars['String']>;
  cdm_name?: Maybe<Scalars['String']>;
  cdm_type?: Maybe<Scalars['String']>;
  cdm_workerfixedcompensations?: Maybe<Hr_Cdm_Workerfixedcompensations_Arr_Rel_Insert_Input>;
  createdon?: Maybe<Scalars['timestamptz']>;
  importsequencenumber?: Maybe<Scalars['String']>;
  modifiedon?: Maybe<Scalars['timestamptz']>;
  overriddencreatedon?: Maybe<Scalars['timestamptz']>;
  statecode?: Maybe<Scalars['String']>;
  statuscode?: Maybe<Scalars['String']>;
  timezoneruleversionnumber?: Maybe<Scalars['String']>;
  utcconversiontimezonecode?: Maybe<Scalars['String']>;
  versionnumber?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Hr_Cdm_Compensationlevels_Max_Fields = {
  _createdby_value?: Maybe<Scalars['uuid']>;
  _createdonbehalfby_value?: Maybe<Scalars['timestamptz']>;
  _modifiedby_value?: Maybe<Scalars['uuid']>;
  _modifiedonbehalfby_value?: Maybe<Scalars['timestamptz']>;
  _ownerid_value?: Maybe<Scalars['uuid']>;
  _owningbusinessunit_value?: Maybe<Scalars['uuid']>;
  _owningteam_value?: Maybe<Scalars['String']>;
  _owninguser_value?: Maybe<Scalars['uuid']>;
  cdm_compensationlevelid?: Maybe<Scalars['uuid']>;
  cdm_description?: Maybe<Scalars['String']>;
  cdm_name?: Maybe<Scalars['String']>;
  cdm_type?: Maybe<Scalars['String']>;
  createdon?: Maybe<Scalars['timestamptz']>;
  importsequencenumber?: Maybe<Scalars['String']>;
  modifiedon?: Maybe<Scalars['timestamptz']>;
  overriddencreatedon?: Maybe<Scalars['timestamptz']>;
  statecode?: Maybe<Scalars['String']>;
  statuscode?: Maybe<Scalars['String']>;
  timezoneruleversionnumber?: Maybe<Scalars['String']>;
  utcconversiontimezonecode?: Maybe<Scalars['String']>;
  versionnumber?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Hr_Cdm_Compensationlevels_Min_Fields = {
  _createdby_value?: Maybe<Scalars['uuid']>;
  _createdonbehalfby_value?: Maybe<Scalars['timestamptz']>;
  _modifiedby_value?: Maybe<Scalars['uuid']>;
  _modifiedonbehalfby_value?: Maybe<Scalars['timestamptz']>;
  _ownerid_value?: Maybe<Scalars['uuid']>;
  _owningbusinessunit_value?: Maybe<Scalars['uuid']>;
  _owningteam_value?: Maybe<Scalars['String']>;
  _owninguser_value?: Maybe<Scalars['uuid']>;
  cdm_compensationlevelid?: Maybe<Scalars['uuid']>;
  cdm_description?: Maybe<Scalars['String']>;
  cdm_name?: Maybe<Scalars['String']>;
  cdm_type?: Maybe<Scalars['String']>;
  createdon?: Maybe<Scalars['timestamptz']>;
  importsequencenumber?: Maybe<Scalars['String']>;
  modifiedon?: Maybe<Scalars['timestamptz']>;
  overriddencreatedon?: Maybe<Scalars['timestamptz']>;
  statecode?: Maybe<Scalars['String']>;
  statuscode?: Maybe<Scalars['String']>;
  timezoneruleversionnumber?: Maybe<Scalars['String']>;
  utcconversiontimezonecode?: Maybe<Scalars['String']>;
  versionnumber?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "hr.cdm_compensationlevels" */
export type Hr_Cdm_Compensationlevels_Mutation_Response = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Hr_Cdm_Compensationlevels>;
};

/** input type for inserting object relation for remote table "hr.cdm_compensationlevels" */
export type Hr_Cdm_Compensationlevels_Obj_Rel_Insert_Input = {
  data: Hr_Cdm_Compensationlevels_Insert_Input;
};

/** Ordering options when selecting data from "hr.cdm_compensationlevels". */
export type Hr_Cdm_Compensationlevels_Order_By = {
  _createdby_value?: Maybe<Order_By>;
  _createdonbehalfby_value?: Maybe<Order_By>;
  _modifiedby_value?: Maybe<Order_By>;
  _modifiedonbehalfby_value?: Maybe<Order_By>;
  _ownerid_value?: Maybe<Order_By>;
  _owningbusinessunit_value?: Maybe<Order_By>;
  _owningteam_value?: Maybe<Order_By>;
  _owninguser_value?: Maybe<Order_By>;
  cdm_compensationlevelid?: Maybe<Order_By>;
  cdm_description?: Maybe<Order_By>;
  cdm_name?: Maybe<Order_By>;
  cdm_type?: Maybe<Order_By>;
  cdm_workerfixedcompensations_aggregate?: Maybe<Hr_Cdm_Workerfixedcompensations_Aggregate_Order_By>;
  createdon?: Maybe<Order_By>;
  importsequencenumber?: Maybe<Order_By>;
  modifiedon?: Maybe<Order_By>;
  overriddencreatedon?: Maybe<Order_By>;
  statecode?: Maybe<Order_By>;
  statuscode?: Maybe<Order_By>;
  timezoneruleversionnumber?: Maybe<Order_By>;
  utcconversiontimezonecode?: Maybe<Order_By>;
  versionnumber?: Maybe<Order_By>;
};

/** select columns of table "hr.cdm_compensationlevels" */
export enum Hr_Cdm_Compensationlevels_Select_Column {
  /** column name */
  CreatedbyValue = '_createdby_value',
  /** column name */
  CreatedonbehalfbyValue = '_createdonbehalfby_value',
  /** column name */
  ModifiedbyValue = '_modifiedby_value',
  /** column name */
  ModifiedonbehalfbyValue = '_modifiedonbehalfby_value',
  /** column name */
  OwneridValue = '_ownerid_value',
  /** column name */
  OwningbusinessunitValue = '_owningbusinessunit_value',
  /** column name */
  OwningteamValue = '_owningteam_value',
  /** column name */
  OwninguserValue = '_owninguser_value',
  /** column name */
  CdmCompensationlevelid = 'cdm_compensationlevelid',
  /** column name */
  CdmDescription = 'cdm_description',
  /** column name */
  CdmName = 'cdm_name',
  /** column name */
  CdmType = 'cdm_type',
  /** column name */
  Createdon = 'createdon',
  /** column name */
  Importsequencenumber = 'importsequencenumber',
  /** column name */
  Modifiedon = 'modifiedon',
  /** column name */
  Overriddencreatedon = 'overriddencreatedon',
  /** column name */
  Statecode = 'statecode',
  /** column name */
  Statuscode = 'statuscode',
  /** column name */
  Timezoneruleversionnumber = 'timezoneruleversionnumber',
  /** column name */
  Utcconversiontimezonecode = 'utcconversiontimezonecode',
  /** column name */
  Versionnumber = 'versionnumber'
}

/** input type for updating data in table "hr.cdm_compensationlevels" */
export type Hr_Cdm_Compensationlevels_Set_Input = {
  _createdby_value?: Maybe<Scalars['uuid']>;
  _createdonbehalfby_value?: Maybe<Scalars['timestamptz']>;
  _modifiedby_value?: Maybe<Scalars['uuid']>;
  _modifiedonbehalfby_value?: Maybe<Scalars['timestamptz']>;
  _ownerid_value?: Maybe<Scalars['uuid']>;
  _owningbusinessunit_value?: Maybe<Scalars['uuid']>;
  _owningteam_value?: Maybe<Scalars['String']>;
  _owninguser_value?: Maybe<Scalars['uuid']>;
  cdm_compensationlevelid?: Maybe<Scalars['uuid']>;
  cdm_description?: Maybe<Scalars['String']>;
  cdm_name?: Maybe<Scalars['String']>;
  cdm_type?: Maybe<Scalars['String']>;
  createdon?: Maybe<Scalars['timestamptz']>;
  importsequencenumber?: Maybe<Scalars['String']>;
  modifiedon?: Maybe<Scalars['timestamptz']>;
  overriddencreatedon?: Maybe<Scalars['timestamptz']>;
  statecode?: Maybe<Scalars['String']>;
  statuscode?: Maybe<Scalars['String']>;
  timezoneruleversionnumber?: Maybe<Scalars['String']>;
  utcconversiontimezonecode?: Maybe<Scalars['String']>;
  versionnumber?: Maybe<Scalars['String']>;
};

/** columns and relationships of "hr.cdm_departments" */
export type Hr_Cdm_Departments = {
  _cdm_parentdepartmentid_value?: Maybe<Scalars['uuid']>;
  _createdby_value?: Maybe<Scalars['uuid']>;
  _createdonbehalfby_value?: Maybe<Scalars['timestamptz']>;
  _modifiedby_value?: Maybe<Scalars['uuid']>;
  _modifiedonbehalfby_value?: Maybe<Scalars['timestamptz']>;
  _ownerid_value?: Maybe<Scalars['uuid']>;
  _owningbusinessunit_value?: Maybe<Scalars['uuid']>;
  _owningteam_value?: Maybe<Scalars['String']>;
  _owninguser_value?: Maybe<Scalars['uuid']>;
  arm_description?: Maybe<Scalars['String']>;
  /** An array relationship */
  arm_teamleadassignmemts: Array<Hr_Arm_Teamleadassignment>;
  /** An aggregate relationship */
  arm_teamleadassignmemts_aggregate: Hr_Arm_Teamleadassignment_Aggregate;
  arm_teamleadid?: Maybe<Scalars['uuid']>;
  cdm_departmentid: Scalars['uuid'];
  cdm_departmentnumber?: Maybe<Scalars['String']>;
  cdm_description?: Maybe<Scalars['String']>;
  /** An array relationship */
  cdm_jobpositions: Array<Hr_Cdm_Jobpositions>;
  /** An aggregate relationship */
  cdm_jobpositions_aggregate: Hr_Cdm_Jobpositions_Aggregate;
  cdm_name?: Maybe<Scalars['String']>;
  crbf6_department_group?: Maybe<Scalars['String']>;
  createdon?: Maybe<Scalars['timestamptz']>;
  importsequencenumber?: Maybe<Scalars['String']>;
  modifiedon?: Maybe<Scalars['timestamptz']>;
  overriddencreatedon?: Maybe<Scalars['timestamptz']>;
  statecode?: Maybe<Scalars['String']>;
  statuscode?: Maybe<Scalars['String']>;
  timezoneruleversionnumber?: Maybe<Scalars['String']>;
  utcconversiontimezonecode?: Maybe<Scalars['String']>;
  versionnumber?: Maybe<Scalars['String']>;
};


/** columns and relationships of "hr.cdm_departments" */
export type Hr_Cdm_DepartmentsArm_TeamleadassignmemtsArgs = {
  distinct_on?: Maybe<Array<Hr_Arm_Teamleadassignment_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Hr_Arm_Teamleadassignment_Order_By>>;
  where?: Maybe<Hr_Arm_Teamleadassignment_Bool_Exp>;
};


/** columns and relationships of "hr.cdm_departments" */
export type Hr_Cdm_DepartmentsArm_Teamleadassignmemts_AggregateArgs = {
  distinct_on?: Maybe<Array<Hr_Arm_Teamleadassignment_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Hr_Arm_Teamleadassignment_Order_By>>;
  where?: Maybe<Hr_Arm_Teamleadassignment_Bool_Exp>;
};


/** columns and relationships of "hr.cdm_departments" */
export type Hr_Cdm_DepartmentsCdm_JobpositionsArgs = {
  distinct_on?: Maybe<Array<Hr_Cdm_Jobpositions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Hr_Cdm_Jobpositions_Order_By>>;
  where?: Maybe<Hr_Cdm_Jobpositions_Bool_Exp>;
};


/** columns and relationships of "hr.cdm_departments" */
export type Hr_Cdm_DepartmentsCdm_Jobpositions_AggregateArgs = {
  distinct_on?: Maybe<Array<Hr_Cdm_Jobpositions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Hr_Cdm_Jobpositions_Order_By>>;
  where?: Maybe<Hr_Cdm_Jobpositions_Bool_Exp>;
};

/** aggregated selection of "hr.cdm_departments" */
export type Hr_Cdm_Departments_Aggregate = {
  aggregate?: Maybe<Hr_Cdm_Departments_Aggregate_Fields>;
  nodes: Array<Hr_Cdm_Departments>;
};

/** aggregate fields of "hr.cdm_departments" */
export type Hr_Cdm_Departments_Aggregate_Fields = {
  count: Scalars['Int'];
  max?: Maybe<Hr_Cdm_Departments_Max_Fields>;
  min?: Maybe<Hr_Cdm_Departments_Min_Fields>;
};


/** aggregate fields of "hr.cdm_departments" */
export type Hr_Cdm_Departments_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Hr_Cdm_Departments_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "hr.cdm_departments". All fields are combined with a logical 'AND'. */
export type Hr_Cdm_Departments_Bool_Exp = {
  _and?: Maybe<Array<Hr_Cdm_Departments_Bool_Exp>>;
  _cdm_parentdepartmentid_value?: Maybe<Uuid_Comparison_Exp>;
  _createdby_value?: Maybe<Uuid_Comparison_Exp>;
  _createdonbehalfby_value?: Maybe<Timestamptz_Comparison_Exp>;
  _modifiedby_value?: Maybe<Uuid_Comparison_Exp>;
  _modifiedonbehalfby_value?: Maybe<Timestamptz_Comparison_Exp>;
  _not?: Maybe<Hr_Cdm_Departments_Bool_Exp>;
  _or?: Maybe<Array<Hr_Cdm_Departments_Bool_Exp>>;
  _ownerid_value?: Maybe<Uuid_Comparison_Exp>;
  _owningbusinessunit_value?: Maybe<Uuid_Comparison_Exp>;
  _owningteam_value?: Maybe<String_Comparison_Exp>;
  _owninguser_value?: Maybe<Uuid_Comparison_Exp>;
  arm_description?: Maybe<String_Comparison_Exp>;
  arm_teamleadassignmemts?: Maybe<Hr_Arm_Teamleadassignment_Bool_Exp>;
  arm_teamleadid?: Maybe<Uuid_Comparison_Exp>;
  cdm_departmentid?: Maybe<Uuid_Comparison_Exp>;
  cdm_departmentnumber?: Maybe<String_Comparison_Exp>;
  cdm_description?: Maybe<String_Comparison_Exp>;
  cdm_jobpositions?: Maybe<Hr_Cdm_Jobpositions_Bool_Exp>;
  cdm_name?: Maybe<String_Comparison_Exp>;
  crbf6_department_group?: Maybe<String_Comparison_Exp>;
  createdon?: Maybe<Timestamptz_Comparison_Exp>;
  importsequencenumber?: Maybe<String_Comparison_Exp>;
  modifiedon?: Maybe<Timestamptz_Comparison_Exp>;
  overriddencreatedon?: Maybe<Timestamptz_Comparison_Exp>;
  statecode?: Maybe<String_Comparison_Exp>;
  statuscode?: Maybe<String_Comparison_Exp>;
  timezoneruleversionnumber?: Maybe<String_Comparison_Exp>;
  utcconversiontimezonecode?: Maybe<String_Comparison_Exp>;
  versionnumber?: Maybe<String_Comparison_Exp>;
};

/** input type for inserting data into table "hr.cdm_departments" */
export type Hr_Cdm_Departments_Insert_Input = {
  _cdm_parentdepartmentid_value?: Maybe<Scalars['uuid']>;
  _createdby_value?: Maybe<Scalars['uuid']>;
  _createdonbehalfby_value?: Maybe<Scalars['timestamptz']>;
  _modifiedby_value?: Maybe<Scalars['uuid']>;
  _modifiedonbehalfby_value?: Maybe<Scalars['timestamptz']>;
  _ownerid_value?: Maybe<Scalars['uuid']>;
  _owningbusinessunit_value?: Maybe<Scalars['uuid']>;
  _owningteam_value?: Maybe<Scalars['String']>;
  _owninguser_value?: Maybe<Scalars['uuid']>;
  arm_description?: Maybe<Scalars['String']>;
  arm_teamleadassignmemts?: Maybe<Hr_Arm_Teamleadassignment_Arr_Rel_Insert_Input>;
  arm_teamleadid?: Maybe<Scalars['uuid']>;
  cdm_departmentid?: Maybe<Scalars['uuid']>;
  cdm_departmentnumber?: Maybe<Scalars['String']>;
  cdm_description?: Maybe<Scalars['String']>;
  cdm_jobpositions?: Maybe<Hr_Cdm_Jobpositions_Arr_Rel_Insert_Input>;
  cdm_name?: Maybe<Scalars['String']>;
  crbf6_department_group?: Maybe<Scalars['String']>;
  createdon?: Maybe<Scalars['timestamptz']>;
  importsequencenumber?: Maybe<Scalars['String']>;
  modifiedon?: Maybe<Scalars['timestamptz']>;
  overriddencreatedon?: Maybe<Scalars['timestamptz']>;
  statecode?: Maybe<Scalars['String']>;
  statuscode?: Maybe<Scalars['String']>;
  timezoneruleversionnumber?: Maybe<Scalars['String']>;
  utcconversiontimezonecode?: Maybe<Scalars['String']>;
  versionnumber?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Hr_Cdm_Departments_Max_Fields = {
  _cdm_parentdepartmentid_value?: Maybe<Scalars['uuid']>;
  _createdby_value?: Maybe<Scalars['uuid']>;
  _createdonbehalfby_value?: Maybe<Scalars['timestamptz']>;
  _modifiedby_value?: Maybe<Scalars['uuid']>;
  _modifiedonbehalfby_value?: Maybe<Scalars['timestamptz']>;
  _ownerid_value?: Maybe<Scalars['uuid']>;
  _owningbusinessunit_value?: Maybe<Scalars['uuid']>;
  _owningteam_value?: Maybe<Scalars['String']>;
  _owninguser_value?: Maybe<Scalars['uuid']>;
  arm_description?: Maybe<Scalars['String']>;
  arm_teamleadid?: Maybe<Scalars['uuid']>;
  cdm_departmentid?: Maybe<Scalars['uuid']>;
  cdm_departmentnumber?: Maybe<Scalars['String']>;
  cdm_description?: Maybe<Scalars['String']>;
  cdm_name?: Maybe<Scalars['String']>;
  crbf6_department_group?: Maybe<Scalars['String']>;
  createdon?: Maybe<Scalars['timestamptz']>;
  importsequencenumber?: Maybe<Scalars['String']>;
  modifiedon?: Maybe<Scalars['timestamptz']>;
  overriddencreatedon?: Maybe<Scalars['timestamptz']>;
  statecode?: Maybe<Scalars['String']>;
  statuscode?: Maybe<Scalars['String']>;
  timezoneruleversionnumber?: Maybe<Scalars['String']>;
  utcconversiontimezonecode?: Maybe<Scalars['String']>;
  versionnumber?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Hr_Cdm_Departments_Min_Fields = {
  _cdm_parentdepartmentid_value?: Maybe<Scalars['uuid']>;
  _createdby_value?: Maybe<Scalars['uuid']>;
  _createdonbehalfby_value?: Maybe<Scalars['timestamptz']>;
  _modifiedby_value?: Maybe<Scalars['uuid']>;
  _modifiedonbehalfby_value?: Maybe<Scalars['timestamptz']>;
  _ownerid_value?: Maybe<Scalars['uuid']>;
  _owningbusinessunit_value?: Maybe<Scalars['uuid']>;
  _owningteam_value?: Maybe<Scalars['String']>;
  _owninguser_value?: Maybe<Scalars['uuid']>;
  arm_description?: Maybe<Scalars['String']>;
  arm_teamleadid?: Maybe<Scalars['uuid']>;
  cdm_departmentid?: Maybe<Scalars['uuid']>;
  cdm_departmentnumber?: Maybe<Scalars['String']>;
  cdm_description?: Maybe<Scalars['String']>;
  cdm_name?: Maybe<Scalars['String']>;
  crbf6_department_group?: Maybe<Scalars['String']>;
  createdon?: Maybe<Scalars['timestamptz']>;
  importsequencenumber?: Maybe<Scalars['String']>;
  modifiedon?: Maybe<Scalars['timestamptz']>;
  overriddencreatedon?: Maybe<Scalars['timestamptz']>;
  statecode?: Maybe<Scalars['String']>;
  statuscode?: Maybe<Scalars['String']>;
  timezoneruleversionnumber?: Maybe<Scalars['String']>;
  utcconversiontimezonecode?: Maybe<Scalars['String']>;
  versionnumber?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "hr.cdm_departments" */
export type Hr_Cdm_Departments_Mutation_Response = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Hr_Cdm_Departments>;
};

/** input type for inserting object relation for remote table "hr.cdm_departments" */
export type Hr_Cdm_Departments_Obj_Rel_Insert_Input = {
  data: Hr_Cdm_Departments_Insert_Input;
};

/** Ordering options when selecting data from "hr.cdm_departments". */
export type Hr_Cdm_Departments_Order_By = {
  _cdm_parentdepartmentid_value?: Maybe<Order_By>;
  _createdby_value?: Maybe<Order_By>;
  _createdonbehalfby_value?: Maybe<Order_By>;
  _modifiedby_value?: Maybe<Order_By>;
  _modifiedonbehalfby_value?: Maybe<Order_By>;
  _ownerid_value?: Maybe<Order_By>;
  _owningbusinessunit_value?: Maybe<Order_By>;
  _owningteam_value?: Maybe<Order_By>;
  _owninguser_value?: Maybe<Order_By>;
  arm_description?: Maybe<Order_By>;
  arm_teamleadassignmemts_aggregate?: Maybe<Hr_Arm_Teamleadassignment_Aggregate_Order_By>;
  arm_teamleadid?: Maybe<Order_By>;
  cdm_departmentid?: Maybe<Order_By>;
  cdm_departmentnumber?: Maybe<Order_By>;
  cdm_description?: Maybe<Order_By>;
  cdm_jobpositions_aggregate?: Maybe<Hr_Cdm_Jobpositions_Aggregate_Order_By>;
  cdm_name?: Maybe<Order_By>;
  crbf6_department_group?: Maybe<Order_By>;
  createdon?: Maybe<Order_By>;
  importsequencenumber?: Maybe<Order_By>;
  modifiedon?: Maybe<Order_By>;
  overriddencreatedon?: Maybe<Order_By>;
  statecode?: Maybe<Order_By>;
  statuscode?: Maybe<Order_By>;
  timezoneruleversionnumber?: Maybe<Order_By>;
  utcconversiontimezonecode?: Maybe<Order_By>;
  versionnumber?: Maybe<Order_By>;
};

/** select columns of table "hr.cdm_departments" */
export enum Hr_Cdm_Departments_Select_Column {
  /** column name */
  CdmParentdepartmentidValue = '_cdm_parentdepartmentid_value',
  /** column name */
  CreatedbyValue = '_createdby_value',
  /** column name */
  CreatedonbehalfbyValue = '_createdonbehalfby_value',
  /** column name */
  ModifiedbyValue = '_modifiedby_value',
  /** column name */
  ModifiedonbehalfbyValue = '_modifiedonbehalfby_value',
  /** column name */
  OwneridValue = '_ownerid_value',
  /** column name */
  OwningbusinessunitValue = '_owningbusinessunit_value',
  /** column name */
  OwningteamValue = '_owningteam_value',
  /** column name */
  OwninguserValue = '_owninguser_value',
  /** column name */
  ArmDescription = 'arm_description',
  /** column name */
  ArmTeamleadid = 'arm_teamleadid',
  /** column name */
  CdmDepartmentid = 'cdm_departmentid',
  /** column name */
  CdmDepartmentnumber = 'cdm_departmentnumber',
  /** column name */
  CdmDescription = 'cdm_description',
  /** column name */
  CdmName = 'cdm_name',
  /** column name */
  Crbf6DepartmentGroup = 'crbf6_department_group',
  /** column name */
  Createdon = 'createdon',
  /** column name */
  Importsequencenumber = 'importsequencenumber',
  /** column name */
  Modifiedon = 'modifiedon',
  /** column name */
  Overriddencreatedon = 'overriddencreatedon',
  /** column name */
  Statecode = 'statecode',
  /** column name */
  Statuscode = 'statuscode',
  /** column name */
  Timezoneruleversionnumber = 'timezoneruleversionnumber',
  /** column name */
  Utcconversiontimezonecode = 'utcconversiontimezonecode',
  /** column name */
  Versionnumber = 'versionnumber'
}

/** input type for updating data in table "hr.cdm_departments" */
export type Hr_Cdm_Departments_Set_Input = {
  _cdm_parentdepartmentid_value?: Maybe<Scalars['uuid']>;
  _createdby_value?: Maybe<Scalars['uuid']>;
  _createdonbehalfby_value?: Maybe<Scalars['timestamptz']>;
  _modifiedby_value?: Maybe<Scalars['uuid']>;
  _modifiedonbehalfby_value?: Maybe<Scalars['timestamptz']>;
  _ownerid_value?: Maybe<Scalars['uuid']>;
  _owningbusinessunit_value?: Maybe<Scalars['uuid']>;
  _owningteam_value?: Maybe<Scalars['String']>;
  _owninguser_value?: Maybe<Scalars['uuid']>;
  arm_description?: Maybe<Scalars['String']>;
  arm_teamleadid?: Maybe<Scalars['uuid']>;
  cdm_departmentid?: Maybe<Scalars['uuid']>;
  cdm_departmentnumber?: Maybe<Scalars['String']>;
  cdm_description?: Maybe<Scalars['String']>;
  cdm_name?: Maybe<Scalars['String']>;
  crbf6_department_group?: Maybe<Scalars['String']>;
  createdon?: Maybe<Scalars['timestamptz']>;
  importsequencenumber?: Maybe<Scalars['String']>;
  modifiedon?: Maybe<Scalars['timestamptz']>;
  overriddencreatedon?: Maybe<Scalars['timestamptz']>;
  statecode?: Maybe<Scalars['String']>;
  statuscode?: Maybe<Scalars['String']>;
  timezoneruleversionnumber?: Maybe<Scalars['String']>;
  utcconversiontimezonecode?: Maybe<Scalars['String']>;
  versionnumber?: Maybe<Scalars['String']>;
};

/** columns and relationships of "hr.cdm_employments" */
export type Hr_Cdm_Employments = {
  _cdm_companyid_value?: Maybe<Scalars['uuid']>;
  _cdm_transitionreasoncode_value?: Maybe<Scalars['uuid']>;
  _cdm_workerid_value?: Maybe<Scalars['uuid']>;
  _createdby_value?: Maybe<Scalars['uuid']>;
  _createdonbehalfby_value?: Maybe<Scalars['timestamptz']>;
  _modifiedby_value?: Maybe<Scalars['uuid']>;
  _modifiedonbehalfby_value?: Maybe<Scalars['timestamptz']>;
  _ownerid_value?: Maybe<Scalars['uuid']>;
  _owningbusinessunit_value?: Maybe<Scalars['uuid']>;
  _owningteam_value?: Maybe<Scalars['String']>;
  _owninguser_value?: Maybe<Scalars['uuid']>;
  cdm_adjustedworkerstartdate?: Maybe<Scalars['timestamptz']>;
  cdm_dimensiondisplayvalue?: Maybe<Scalars['String']>;
  cdm_employernoticeamount?: Maybe<Scalars['String']>;
  cdm_employerunitofnotice?: Maybe<Scalars['String']>;
  cdm_employmentenddate?: Maybe<Scalars['timestamptz']>;
  cdm_employmentid: Scalars['uuid'];
  cdm_employmentnumber?: Maybe<Scalars['String']>;
  cdm_employmentstartdate?: Maybe<Scalars['timestamptz']>;
  cdm_lastdateworked?: Maybe<Scalars['timestamptz']>;
  cdm_probationenddate?: Maybe<Scalars['timestamptz']>;
  cdm_transitiondate?: Maybe<Scalars['timestamptz']>;
  cdm_validfrom?: Maybe<Scalars['timestamptz']>;
  cdm_validto?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  cdm_worker?: Maybe<Hr_Cdm_Workers>;
  cdm_workernoticeamount?: Maybe<Scalars['String']>;
  cdm_workerstartdate?: Maybe<Scalars['timestamptz']>;
  cdm_workertype?: Maybe<Scalars['String']>;
  cdm_workerunitofnotice?: Maybe<Scalars['String']>;
  createdon?: Maybe<Scalars['timestamptz']>;
  importsequencenumber?: Maybe<Scalars['String']>;
  modifiedon?: Maybe<Scalars['timestamptz']>;
  overriddencreatedon?: Maybe<Scalars['timestamptz']>;
  statecode?: Maybe<Scalars['String']>;
  statuscode?: Maybe<Scalars['String']>;
  timezoneruleversionnumber?: Maybe<Scalars['String']>;
  utcconversiontimezonecode?: Maybe<Scalars['String']>;
  versionnumber?: Maybe<Scalars['String']>;
};

/** aggregated selection of "hr.cdm_employments" */
export type Hr_Cdm_Employments_Aggregate = {
  aggregate?: Maybe<Hr_Cdm_Employments_Aggregate_Fields>;
  nodes: Array<Hr_Cdm_Employments>;
};

/** aggregate fields of "hr.cdm_employments" */
export type Hr_Cdm_Employments_Aggregate_Fields = {
  count: Scalars['Int'];
  max?: Maybe<Hr_Cdm_Employments_Max_Fields>;
  min?: Maybe<Hr_Cdm_Employments_Min_Fields>;
};


/** aggregate fields of "hr.cdm_employments" */
export type Hr_Cdm_Employments_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Hr_Cdm_Employments_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "hr.cdm_employments" */
export type Hr_Cdm_Employments_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Hr_Cdm_Employments_Max_Order_By>;
  min?: Maybe<Hr_Cdm_Employments_Min_Order_By>;
};

/** input type for inserting array relation for remote table "hr.cdm_employments" */
export type Hr_Cdm_Employments_Arr_Rel_Insert_Input = {
  data: Array<Hr_Cdm_Employments_Insert_Input>;
};

/** Boolean expression to filter rows from the table "hr.cdm_employments". All fields are combined with a logical 'AND'. */
export type Hr_Cdm_Employments_Bool_Exp = {
  _and?: Maybe<Array<Hr_Cdm_Employments_Bool_Exp>>;
  _cdm_companyid_value?: Maybe<Uuid_Comparison_Exp>;
  _cdm_transitionreasoncode_value?: Maybe<Uuid_Comparison_Exp>;
  _cdm_workerid_value?: Maybe<Uuid_Comparison_Exp>;
  _createdby_value?: Maybe<Uuid_Comparison_Exp>;
  _createdonbehalfby_value?: Maybe<Timestamptz_Comparison_Exp>;
  _modifiedby_value?: Maybe<Uuid_Comparison_Exp>;
  _modifiedonbehalfby_value?: Maybe<Timestamptz_Comparison_Exp>;
  _not?: Maybe<Hr_Cdm_Employments_Bool_Exp>;
  _or?: Maybe<Array<Hr_Cdm_Employments_Bool_Exp>>;
  _ownerid_value?: Maybe<Uuid_Comparison_Exp>;
  _owningbusinessunit_value?: Maybe<Uuid_Comparison_Exp>;
  _owningteam_value?: Maybe<String_Comparison_Exp>;
  _owninguser_value?: Maybe<Uuid_Comparison_Exp>;
  cdm_adjustedworkerstartdate?: Maybe<Timestamptz_Comparison_Exp>;
  cdm_dimensiondisplayvalue?: Maybe<String_Comparison_Exp>;
  cdm_employernoticeamount?: Maybe<String_Comparison_Exp>;
  cdm_employerunitofnotice?: Maybe<String_Comparison_Exp>;
  cdm_employmentenddate?: Maybe<Timestamptz_Comparison_Exp>;
  cdm_employmentid?: Maybe<Uuid_Comparison_Exp>;
  cdm_employmentnumber?: Maybe<String_Comparison_Exp>;
  cdm_employmentstartdate?: Maybe<Timestamptz_Comparison_Exp>;
  cdm_lastdateworked?: Maybe<Timestamptz_Comparison_Exp>;
  cdm_probationenddate?: Maybe<Timestamptz_Comparison_Exp>;
  cdm_transitiondate?: Maybe<Timestamptz_Comparison_Exp>;
  cdm_validfrom?: Maybe<Timestamptz_Comparison_Exp>;
  cdm_validto?: Maybe<Timestamptz_Comparison_Exp>;
  cdm_worker?: Maybe<Hr_Cdm_Workers_Bool_Exp>;
  cdm_workernoticeamount?: Maybe<String_Comparison_Exp>;
  cdm_workerstartdate?: Maybe<Timestamptz_Comparison_Exp>;
  cdm_workertype?: Maybe<String_Comparison_Exp>;
  cdm_workerunitofnotice?: Maybe<String_Comparison_Exp>;
  createdon?: Maybe<Timestamptz_Comparison_Exp>;
  importsequencenumber?: Maybe<String_Comparison_Exp>;
  modifiedon?: Maybe<Timestamptz_Comparison_Exp>;
  overriddencreatedon?: Maybe<Timestamptz_Comparison_Exp>;
  statecode?: Maybe<String_Comparison_Exp>;
  statuscode?: Maybe<String_Comparison_Exp>;
  timezoneruleversionnumber?: Maybe<String_Comparison_Exp>;
  utcconversiontimezonecode?: Maybe<String_Comparison_Exp>;
  versionnumber?: Maybe<String_Comparison_Exp>;
};

/** input type for inserting data into table "hr.cdm_employments" */
export type Hr_Cdm_Employments_Insert_Input = {
  _cdm_companyid_value?: Maybe<Scalars['uuid']>;
  _cdm_transitionreasoncode_value?: Maybe<Scalars['uuid']>;
  _cdm_workerid_value?: Maybe<Scalars['uuid']>;
  _createdby_value?: Maybe<Scalars['uuid']>;
  _createdonbehalfby_value?: Maybe<Scalars['timestamptz']>;
  _modifiedby_value?: Maybe<Scalars['uuid']>;
  _modifiedonbehalfby_value?: Maybe<Scalars['timestamptz']>;
  _ownerid_value?: Maybe<Scalars['uuid']>;
  _owningbusinessunit_value?: Maybe<Scalars['uuid']>;
  _owningteam_value?: Maybe<Scalars['String']>;
  _owninguser_value?: Maybe<Scalars['uuid']>;
  cdm_adjustedworkerstartdate?: Maybe<Scalars['timestamptz']>;
  cdm_dimensiondisplayvalue?: Maybe<Scalars['String']>;
  cdm_employernoticeamount?: Maybe<Scalars['String']>;
  cdm_employerunitofnotice?: Maybe<Scalars['String']>;
  cdm_employmentenddate?: Maybe<Scalars['timestamptz']>;
  cdm_employmentid?: Maybe<Scalars['uuid']>;
  cdm_employmentnumber?: Maybe<Scalars['String']>;
  cdm_employmentstartdate?: Maybe<Scalars['timestamptz']>;
  cdm_lastdateworked?: Maybe<Scalars['timestamptz']>;
  cdm_probationenddate?: Maybe<Scalars['timestamptz']>;
  cdm_transitiondate?: Maybe<Scalars['timestamptz']>;
  cdm_validfrom?: Maybe<Scalars['timestamptz']>;
  cdm_validto?: Maybe<Scalars['timestamptz']>;
  cdm_worker?: Maybe<Hr_Cdm_Workers_Obj_Rel_Insert_Input>;
  cdm_workernoticeamount?: Maybe<Scalars['String']>;
  cdm_workerstartdate?: Maybe<Scalars['timestamptz']>;
  cdm_workertype?: Maybe<Scalars['String']>;
  cdm_workerunitofnotice?: Maybe<Scalars['String']>;
  createdon?: Maybe<Scalars['timestamptz']>;
  importsequencenumber?: Maybe<Scalars['String']>;
  modifiedon?: Maybe<Scalars['timestamptz']>;
  overriddencreatedon?: Maybe<Scalars['timestamptz']>;
  statecode?: Maybe<Scalars['String']>;
  statuscode?: Maybe<Scalars['String']>;
  timezoneruleversionnumber?: Maybe<Scalars['String']>;
  utcconversiontimezonecode?: Maybe<Scalars['String']>;
  versionnumber?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Hr_Cdm_Employments_Max_Fields = {
  _cdm_companyid_value?: Maybe<Scalars['uuid']>;
  _cdm_transitionreasoncode_value?: Maybe<Scalars['uuid']>;
  _cdm_workerid_value?: Maybe<Scalars['uuid']>;
  _createdby_value?: Maybe<Scalars['uuid']>;
  _createdonbehalfby_value?: Maybe<Scalars['timestamptz']>;
  _modifiedby_value?: Maybe<Scalars['uuid']>;
  _modifiedonbehalfby_value?: Maybe<Scalars['timestamptz']>;
  _ownerid_value?: Maybe<Scalars['uuid']>;
  _owningbusinessunit_value?: Maybe<Scalars['uuid']>;
  _owningteam_value?: Maybe<Scalars['String']>;
  _owninguser_value?: Maybe<Scalars['uuid']>;
  cdm_adjustedworkerstartdate?: Maybe<Scalars['timestamptz']>;
  cdm_dimensiondisplayvalue?: Maybe<Scalars['String']>;
  cdm_employernoticeamount?: Maybe<Scalars['String']>;
  cdm_employerunitofnotice?: Maybe<Scalars['String']>;
  cdm_employmentenddate?: Maybe<Scalars['timestamptz']>;
  cdm_employmentid?: Maybe<Scalars['uuid']>;
  cdm_employmentnumber?: Maybe<Scalars['String']>;
  cdm_employmentstartdate?: Maybe<Scalars['timestamptz']>;
  cdm_lastdateworked?: Maybe<Scalars['timestamptz']>;
  cdm_probationenddate?: Maybe<Scalars['timestamptz']>;
  cdm_transitiondate?: Maybe<Scalars['timestamptz']>;
  cdm_validfrom?: Maybe<Scalars['timestamptz']>;
  cdm_validto?: Maybe<Scalars['timestamptz']>;
  cdm_workernoticeamount?: Maybe<Scalars['String']>;
  cdm_workerstartdate?: Maybe<Scalars['timestamptz']>;
  cdm_workertype?: Maybe<Scalars['String']>;
  cdm_workerunitofnotice?: Maybe<Scalars['String']>;
  createdon?: Maybe<Scalars['timestamptz']>;
  importsequencenumber?: Maybe<Scalars['String']>;
  modifiedon?: Maybe<Scalars['timestamptz']>;
  overriddencreatedon?: Maybe<Scalars['timestamptz']>;
  statecode?: Maybe<Scalars['String']>;
  statuscode?: Maybe<Scalars['String']>;
  timezoneruleversionnumber?: Maybe<Scalars['String']>;
  utcconversiontimezonecode?: Maybe<Scalars['String']>;
  versionnumber?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "hr.cdm_employments" */
export type Hr_Cdm_Employments_Max_Order_By = {
  _cdm_companyid_value?: Maybe<Order_By>;
  _cdm_transitionreasoncode_value?: Maybe<Order_By>;
  _cdm_workerid_value?: Maybe<Order_By>;
  _createdby_value?: Maybe<Order_By>;
  _createdonbehalfby_value?: Maybe<Order_By>;
  _modifiedby_value?: Maybe<Order_By>;
  _modifiedonbehalfby_value?: Maybe<Order_By>;
  _ownerid_value?: Maybe<Order_By>;
  _owningbusinessunit_value?: Maybe<Order_By>;
  _owningteam_value?: Maybe<Order_By>;
  _owninguser_value?: Maybe<Order_By>;
  cdm_adjustedworkerstartdate?: Maybe<Order_By>;
  cdm_dimensiondisplayvalue?: Maybe<Order_By>;
  cdm_employernoticeamount?: Maybe<Order_By>;
  cdm_employerunitofnotice?: Maybe<Order_By>;
  cdm_employmentenddate?: Maybe<Order_By>;
  cdm_employmentid?: Maybe<Order_By>;
  cdm_employmentnumber?: Maybe<Order_By>;
  cdm_employmentstartdate?: Maybe<Order_By>;
  cdm_lastdateworked?: Maybe<Order_By>;
  cdm_probationenddate?: Maybe<Order_By>;
  cdm_transitiondate?: Maybe<Order_By>;
  cdm_validfrom?: Maybe<Order_By>;
  cdm_validto?: Maybe<Order_By>;
  cdm_workernoticeamount?: Maybe<Order_By>;
  cdm_workerstartdate?: Maybe<Order_By>;
  cdm_workertype?: Maybe<Order_By>;
  cdm_workerunitofnotice?: Maybe<Order_By>;
  createdon?: Maybe<Order_By>;
  importsequencenumber?: Maybe<Order_By>;
  modifiedon?: Maybe<Order_By>;
  overriddencreatedon?: Maybe<Order_By>;
  statecode?: Maybe<Order_By>;
  statuscode?: Maybe<Order_By>;
  timezoneruleversionnumber?: Maybe<Order_By>;
  utcconversiontimezonecode?: Maybe<Order_By>;
  versionnumber?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Hr_Cdm_Employments_Min_Fields = {
  _cdm_companyid_value?: Maybe<Scalars['uuid']>;
  _cdm_transitionreasoncode_value?: Maybe<Scalars['uuid']>;
  _cdm_workerid_value?: Maybe<Scalars['uuid']>;
  _createdby_value?: Maybe<Scalars['uuid']>;
  _createdonbehalfby_value?: Maybe<Scalars['timestamptz']>;
  _modifiedby_value?: Maybe<Scalars['uuid']>;
  _modifiedonbehalfby_value?: Maybe<Scalars['timestamptz']>;
  _ownerid_value?: Maybe<Scalars['uuid']>;
  _owningbusinessunit_value?: Maybe<Scalars['uuid']>;
  _owningteam_value?: Maybe<Scalars['String']>;
  _owninguser_value?: Maybe<Scalars['uuid']>;
  cdm_adjustedworkerstartdate?: Maybe<Scalars['timestamptz']>;
  cdm_dimensiondisplayvalue?: Maybe<Scalars['String']>;
  cdm_employernoticeamount?: Maybe<Scalars['String']>;
  cdm_employerunitofnotice?: Maybe<Scalars['String']>;
  cdm_employmentenddate?: Maybe<Scalars['timestamptz']>;
  cdm_employmentid?: Maybe<Scalars['uuid']>;
  cdm_employmentnumber?: Maybe<Scalars['String']>;
  cdm_employmentstartdate?: Maybe<Scalars['timestamptz']>;
  cdm_lastdateworked?: Maybe<Scalars['timestamptz']>;
  cdm_probationenddate?: Maybe<Scalars['timestamptz']>;
  cdm_transitiondate?: Maybe<Scalars['timestamptz']>;
  cdm_validfrom?: Maybe<Scalars['timestamptz']>;
  cdm_validto?: Maybe<Scalars['timestamptz']>;
  cdm_workernoticeamount?: Maybe<Scalars['String']>;
  cdm_workerstartdate?: Maybe<Scalars['timestamptz']>;
  cdm_workertype?: Maybe<Scalars['String']>;
  cdm_workerunitofnotice?: Maybe<Scalars['String']>;
  createdon?: Maybe<Scalars['timestamptz']>;
  importsequencenumber?: Maybe<Scalars['String']>;
  modifiedon?: Maybe<Scalars['timestamptz']>;
  overriddencreatedon?: Maybe<Scalars['timestamptz']>;
  statecode?: Maybe<Scalars['String']>;
  statuscode?: Maybe<Scalars['String']>;
  timezoneruleversionnumber?: Maybe<Scalars['String']>;
  utcconversiontimezonecode?: Maybe<Scalars['String']>;
  versionnumber?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "hr.cdm_employments" */
export type Hr_Cdm_Employments_Min_Order_By = {
  _cdm_companyid_value?: Maybe<Order_By>;
  _cdm_transitionreasoncode_value?: Maybe<Order_By>;
  _cdm_workerid_value?: Maybe<Order_By>;
  _createdby_value?: Maybe<Order_By>;
  _createdonbehalfby_value?: Maybe<Order_By>;
  _modifiedby_value?: Maybe<Order_By>;
  _modifiedonbehalfby_value?: Maybe<Order_By>;
  _ownerid_value?: Maybe<Order_By>;
  _owningbusinessunit_value?: Maybe<Order_By>;
  _owningteam_value?: Maybe<Order_By>;
  _owninguser_value?: Maybe<Order_By>;
  cdm_adjustedworkerstartdate?: Maybe<Order_By>;
  cdm_dimensiondisplayvalue?: Maybe<Order_By>;
  cdm_employernoticeamount?: Maybe<Order_By>;
  cdm_employerunitofnotice?: Maybe<Order_By>;
  cdm_employmentenddate?: Maybe<Order_By>;
  cdm_employmentid?: Maybe<Order_By>;
  cdm_employmentnumber?: Maybe<Order_By>;
  cdm_employmentstartdate?: Maybe<Order_By>;
  cdm_lastdateworked?: Maybe<Order_By>;
  cdm_probationenddate?: Maybe<Order_By>;
  cdm_transitiondate?: Maybe<Order_By>;
  cdm_validfrom?: Maybe<Order_By>;
  cdm_validto?: Maybe<Order_By>;
  cdm_workernoticeamount?: Maybe<Order_By>;
  cdm_workerstartdate?: Maybe<Order_By>;
  cdm_workertype?: Maybe<Order_By>;
  cdm_workerunitofnotice?: Maybe<Order_By>;
  createdon?: Maybe<Order_By>;
  importsequencenumber?: Maybe<Order_By>;
  modifiedon?: Maybe<Order_By>;
  overriddencreatedon?: Maybe<Order_By>;
  statecode?: Maybe<Order_By>;
  statuscode?: Maybe<Order_By>;
  timezoneruleversionnumber?: Maybe<Order_By>;
  utcconversiontimezonecode?: Maybe<Order_By>;
  versionnumber?: Maybe<Order_By>;
};

/** response of any mutation on the table "hr.cdm_employments" */
export type Hr_Cdm_Employments_Mutation_Response = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Hr_Cdm_Employments>;
};

/** Ordering options when selecting data from "hr.cdm_employments". */
export type Hr_Cdm_Employments_Order_By = {
  _cdm_companyid_value?: Maybe<Order_By>;
  _cdm_transitionreasoncode_value?: Maybe<Order_By>;
  _cdm_workerid_value?: Maybe<Order_By>;
  _createdby_value?: Maybe<Order_By>;
  _createdonbehalfby_value?: Maybe<Order_By>;
  _modifiedby_value?: Maybe<Order_By>;
  _modifiedonbehalfby_value?: Maybe<Order_By>;
  _ownerid_value?: Maybe<Order_By>;
  _owningbusinessunit_value?: Maybe<Order_By>;
  _owningteam_value?: Maybe<Order_By>;
  _owninguser_value?: Maybe<Order_By>;
  cdm_adjustedworkerstartdate?: Maybe<Order_By>;
  cdm_dimensiondisplayvalue?: Maybe<Order_By>;
  cdm_employernoticeamount?: Maybe<Order_By>;
  cdm_employerunitofnotice?: Maybe<Order_By>;
  cdm_employmentenddate?: Maybe<Order_By>;
  cdm_employmentid?: Maybe<Order_By>;
  cdm_employmentnumber?: Maybe<Order_By>;
  cdm_employmentstartdate?: Maybe<Order_By>;
  cdm_lastdateworked?: Maybe<Order_By>;
  cdm_probationenddate?: Maybe<Order_By>;
  cdm_transitiondate?: Maybe<Order_By>;
  cdm_validfrom?: Maybe<Order_By>;
  cdm_validto?: Maybe<Order_By>;
  cdm_worker?: Maybe<Hr_Cdm_Workers_Order_By>;
  cdm_workernoticeamount?: Maybe<Order_By>;
  cdm_workerstartdate?: Maybe<Order_By>;
  cdm_workertype?: Maybe<Order_By>;
  cdm_workerunitofnotice?: Maybe<Order_By>;
  createdon?: Maybe<Order_By>;
  importsequencenumber?: Maybe<Order_By>;
  modifiedon?: Maybe<Order_By>;
  overriddencreatedon?: Maybe<Order_By>;
  statecode?: Maybe<Order_By>;
  statuscode?: Maybe<Order_By>;
  timezoneruleversionnumber?: Maybe<Order_By>;
  utcconversiontimezonecode?: Maybe<Order_By>;
  versionnumber?: Maybe<Order_By>;
};

/** select columns of table "hr.cdm_employments" */
export enum Hr_Cdm_Employments_Select_Column {
  /** column name */
  CdmCompanyidValue = '_cdm_companyid_value',
  /** column name */
  CdmTransitionreasoncodeValue = '_cdm_transitionreasoncode_value',
  /** column name */
  CdmWorkeridValue = '_cdm_workerid_value',
  /** column name */
  CreatedbyValue = '_createdby_value',
  /** column name */
  CreatedonbehalfbyValue = '_createdonbehalfby_value',
  /** column name */
  ModifiedbyValue = '_modifiedby_value',
  /** column name */
  ModifiedonbehalfbyValue = '_modifiedonbehalfby_value',
  /** column name */
  OwneridValue = '_ownerid_value',
  /** column name */
  OwningbusinessunitValue = '_owningbusinessunit_value',
  /** column name */
  OwningteamValue = '_owningteam_value',
  /** column name */
  OwninguserValue = '_owninguser_value',
  /** column name */
  CdmAdjustedworkerstartdate = 'cdm_adjustedworkerstartdate',
  /** column name */
  CdmDimensiondisplayvalue = 'cdm_dimensiondisplayvalue',
  /** column name */
  CdmEmployernoticeamount = 'cdm_employernoticeamount',
  /** column name */
  CdmEmployerunitofnotice = 'cdm_employerunitofnotice',
  /** column name */
  CdmEmploymentenddate = 'cdm_employmentenddate',
  /** column name */
  CdmEmploymentid = 'cdm_employmentid',
  /** column name */
  CdmEmploymentnumber = 'cdm_employmentnumber',
  /** column name */
  CdmEmploymentstartdate = 'cdm_employmentstartdate',
  /** column name */
  CdmLastdateworked = 'cdm_lastdateworked',
  /** column name */
  CdmProbationenddate = 'cdm_probationenddate',
  /** column name */
  CdmTransitiondate = 'cdm_transitiondate',
  /** column name */
  CdmValidfrom = 'cdm_validfrom',
  /** column name */
  CdmValidto = 'cdm_validto',
  /** column name */
  CdmWorkernoticeamount = 'cdm_workernoticeamount',
  /** column name */
  CdmWorkerstartdate = 'cdm_workerstartdate',
  /** column name */
  CdmWorkertype = 'cdm_workertype',
  /** column name */
  CdmWorkerunitofnotice = 'cdm_workerunitofnotice',
  /** column name */
  Createdon = 'createdon',
  /** column name */
  Importsequencenumber = 'importsequencenumber',
  /** column name */
  Modifiedon = 'modifiedon',
  /** column name */
  Overriddencreatedon = 'overriddencreatedon',
  /** column name */
  Statecode = 'statecode',
  /** column name */
  Statuscode = 'statuscode',
  /** column name */
  Timezoneruleversionnumber = 'timezoneruleversionnumber',
  /** column name */
  Utcconversiontimezonecode = 'utcconversiontimezonecode',
  /** column name */
  Versionnumber = 'versionnumber'
}

/** input type for updating data in table "hr.cdm_employments" */
export type Hr_Cdm_Employments_Set_Input = {
  _cdm_companyid_value?: Maybe<Scalars['uuid']>;
  _cdm_transitionreasoncode_value?: Maybe<Scalars['uuid']>;
  _cdm_workerid_value?: Maybe<Scalars['uuid']>;
  _createdby_value?: Maybe<Scalars['uuid']>;
  _createdonbehalfby_value?: Maybe<Scalars['timestamptz']>;
  _modifiedby_value?: Maybe<Scalars['uuid']>;
  _modifiedonbehalfby_value?: Maybe<Scalars['timestamptz']>;
  _ownerid_value?: Maybe<Scalars['uuid']>;
  _owningbusinessunit_value?: Maybe<Scalars['uuid']>;
  _owningteam_value?: Maybe<Scalars['String']>;
  _owninguser_value?: Maybe<Scalars['uuid']>;
  cdm_adjustedworkerstartdate?: Maybe<Scalars['timestamptz']>;
  cdm_dimensiondisplayvalue?: Maybe<Scalars['String']>;
  cdm_employernoticeamount?: Maybe<Scalars['String']>;
  cdm_employerunitofnotice?: Maybe<Scalars['String']>;
  cdm_employmentenddate?: Maybe<Scalars['timestamptz']>;
  cdm_employmentid?: Maybe<Scalars['uuid']>;
  cdm_employmentnumber?: Maybe<Scalars['String']>;
  cdm_employmentstartdate?: Maybe<Scalars['timestamptz']>;
  cdm_lastdateworked?: Maybe<Scalars['timestamptz']>;
  cdm_probationenddate?: Maybe<Scalars['timestamptz']>;
  cdm_transitiondate?: Maybe<Scalars['timestamptz']>;
  cdm_validfrom?: Maybe<Scalars['timestamptz']>;
  cdm_validto?: Maybe<Scalars['timestamptz']>;
  cdm_workernoticeamount?: Maybe<Scalars['String']>;
  cdm_workerstartdate?: Maybe<Scalars['timestamptz']>;
  cdm_workertype?: Maybe<Scalars['String']>;
  cdm_workerunitofnotice?: Maybe<Scalars['String']>;
  createdon?: Maybe<Scalars['timestamptz']>;
  importsequencenumber?: Maybe<Scalars['String']>;
  modifiedon?: Maybe<Scalars['timestamptz']>;
  overriddencreatedon?: Maybe<Scalars['timestamptz']>;
  statecode?: Maybe<Scalars['String']>;
  statuscode?: Maybe<Scalars['String']>;
  timezoneruleversionnumber?: Maybe<Scalars['String']>;
  utcconversiontimezonecode?: Maybe<Scalars['String']>;
  versionnumber?: Maybe<Scalars['String']>;
};

/** columns and relationships of "hr.cdm_jobpositions" */
export type Hr_Cdm_Jobpositions = {
  _cdm_compensationregionid_value?: Maybe<Scalars['uuid']>;
  _cdm_departmentid_value?: Maybe<Scalars['uuid']>;
  _cdm_jobid_value?: Maybe<Scalars['uuid']>;
  _cdm_parentjobpositionid_value?: Maybe<Scalars['uuid']>;
  _cdm_positiontypeid_value?: Maybe<Scalars['uuid']>;
  _cdm_titleid_value?: Maybe<Scalars['uuid']>;
  _createdby_value?: Maybe<Scalars['uuid']>;
  _createdonbehalfby_value?: Maybe<Scalars['timestamptz']>;
  _modifiedby_value?: Maybe<Scalars['uuid']>;
  _modifiedonbehalfby_value?: Maybe<Scalars['timestamptz']>;
  _ownerid_value?: Maybe<Scalars['uuid']>;
  _owningbusinessunit_value?: Maybe<Scalars['uuid']>;
  _owningteam_value?: Maybe<Scalars['String']>;
  _owninguser_value?: Maybe<Scalars['uuid']>;
  cdm_activation?: Maybe<Scalars['timestamptz']>;
  cdm_availableforassignment?: Maybe<Scalars['timestamptz']>;
  cdm_customdescription_custom?: Maybe<Scalars['String']>;
  /** An object relationship */
  cdm_department?: Maybe<Hr_Cdm_Departments>;
  cdm_description?: Maybe<Scalars['String']>;
  cdm_fulltimeequivalent?: Maybe<Scalars['String']>;
  /** An object relationship */
  cdm_job?: Maybe<Hr_Cdm_Jobs>;
  cdm_jobpositionid: Scalars['uuid'];
  cdm_jobpositionnumber?: Maybe<Scalars['String']>;
  /** An array relationship */
  cdm_positionworkerassignmentmaps: Array<Hr_Cdm_Positionworkerassignmentmaps>;
  /** An aggregate relationship */
  cdm_positionworkerassignmentmaps_aggregate: Hr_Cdm_Positionworkerassignmentmaps_Aggregate;
  cdm_retirement?: Maybe<Scalars['timestamptz']>;
  cdm_tablecode_custom?: Maybe<Scalars['String']>;
  cdm_title?: Maybe<Scalars['String']>;
  cdm_validfrom?: Maybe<Scalars['timestamptz']>;
  cdm_validto?: Maybe<Scalars['timestamptz']>;
  /** An array relationship */
  cdm_workerfixedcompensations: Array<Hr_Cdm_Workerfixedcompensations>;
  /** An aggregate relationship */
  cdm_workerfixedcompensations_aggregate: Hr_Cdm_Workerfixedcompensations_Aggregate;
  createdon?: Maybe<Scalars['timestamptz']>;
  importsequencenumber?: Maybe<Scalars['String']>;
  modifiedon?: Maybe<Scalars['timestamptz']>;
  msdyn_additionalmetadata?: Maybe<Scalars['String']>;
  overriddencreatedon?: Maybe<Scalars['timestamptz']>;
  statecode?: Maybe<Scalars['String']>;
  statuscode?: Maybe<Scalars['String']>;
  timezoneruleversionnumber?: Maybe<Scalars['String']>;
  utcconversiontimezonecode?: Maybe<Scalars['String']>;
  versionnumber?: Maybe<Scalars['String']>;
};


/** columns and relationships of "hr.cdm_jobpositions" */
export type Hr_Cdm_JobpositionsCdm_PositionworkerassignmentmapsArgs = {
  distinct_on?: Maybe<Array<Hr_Cdm_Positionworkerassignmentmaps_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Hr_Cdm_Positionworkerassignmentmaps_Order_By>>;
  where?: Maybe<Hr_Cdm_Positionworkerassignmentmaps_Bool_Exp>;
};


/** columns and relationships of "hr.cdm_jobpositions" */
export type Hr_Cdm_JobpositionsCdm_Positionworkerassignmentmaps_AggregateArgs = {
  distinct_on?: Maybe<Array<Hr_Cdm_Positionworkerassignmentmaps_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Hr_Cdm_Positionworkerassignmentmaps_Order_By>>;
  where?: Maybe<Hr_Cdm_Positionworkerassignmentmaps_Bool_Exp>;
};


/** columns and relationships of "hr.cdm_jobpositions" */
export type Hr_Cdm_JobpositionsCdm_WorkerfixedcompensationsArgs = {
  distinct_on?: Maybe<Array<Hr_Cdm_Workerfixedcompensations_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Hr_Cdm_Workerfixedcompensations_Order_By>>;
  where?: Maybe<Hr_Cdm_Workerfixedcompensations_Bool_Exp>;
};


/** columns and relationships of "hr.cdm_jobpositions" */
export type Hr_Cdm_JobpositionsCdm_Workerfixedcompensations_AggregateArgs = {
  distinct_on?: Maybe<Array<Hr_Cdm_Workerfixedcompensations_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Hr_Cdm_Workerfixedcompensations_Order_By>>;
  where?: Maybe<Hr_Cdm_Workerfixedcompensations_Bool_Exp>;
};

/** aggregated selection of "hr.cdm_jobpositions" */
export type Hr_Cdm_Jobpositions_Aggregate = {
  aggregate?: Maybe<Hr_Cdm_Jobpositions_Aggregate_Fields>;
  nodes: Array<Hr_Cdm_Jobpositions>;
};

/** aggregate fields of "hr.cdm_jobpositions" */
export type Hr_Cdm_Jobpositions_Aggregate_Fields = {
  count: Scalars['Int'];
  max?: Maybe<Hr_Cdm_Jobpositions_Max_Fields>;
  min?: Maybe<Hr_Cdm_Jobpositions_Min_Fields>;
};


/** aggregate fields of "hr.cdm_jobpositions" */
export type Hr_Cdm_Jobpositions_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Hr_Cdm_Jobpositions_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "hr.cdm_jobpositions" */
export type Hr_Cdm_Jobpositions_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Hr_Cdm_Jobpositions_Max_Order_By>;
  min?: Maybe<Hr_Cdm_Jobpositions_Min_Order_By>;
};

/** input type for inserting array relation for remote table "hr.cdm_jobpositions" */
export type Hr_Cdm_Jobpositions_Arr_Rel_Insert_Input = {
  data: Array<Hr_Cdm_Jobpositions_Insert_Input>;
};

/** Boolean expression to filter rows from the table "hr.cdm_jobpositions". All fields are combined with a logical 'AND'. */
export type Hr_Cdm_Jobpositions_Bool_Exp = {
  _and?: Maybe<Array<Hr_Cdm_Jobpositions_Bool_Exp>>;
  _cdm_compensationregionid_value?: Maybe<Uuid_Comparison_Exp>;
  _cdm_departmentid_value?: Maybe<Uuid_Comparison_Exp>;
  _cdm_jobid_value?: Maybe<Uuid_Comparison_Exp>;
  _cdm_parentjobpositionid_value?: Maybe<Uuid_Comparison_Exp>;
  _cdm_positiontypeid_value?: Maybe<Uuid_Comparison_Exp>;
  _cdm_titleid_value?: Maybe<Uuid_Comparison_Exp>;
  _createdby_value?: Maybe<Uuid_Comparison_Exp>;
  _createdonbehalfby_value?: Maybe<Timestamptz_Comparison_Exp>;
  _modifiedby_value?: Maybe<Uuid_Comparison_Exp>;
  _modifiedonbehalfby_value?: Maybe<Timestamptz_Comparison_Exp>;
  _not?: Maybe<Hr_Cdm_Jobpositions_Bool_Exp>;
  _or?: Maybe<Array<Hr_Cdm_Jobpositions_Bool_Exp>>;
  _ownerid_value?: Maybe<Uuid_Comparison_Exp>;
  _owningbusinessunit_value?: Maybe<Uuid_Comparison_Exp>;
  _owningteam_value?: Maybe<String_Comparison_Exp>;
  _owninguser_value?: Maybe<Uuid_Comparison_Exp>;
  cdm_activation?: Maybe<Timestamptz_Comparison_Exp>;
  cdm_availableforassignment?: Maybe<Timestamptz_Comparison_Exp>;
  cdm_customdescription_custom?: Maybe<String_Comparison_Exp>;
  cdm_department?: Maybe<Hr_Cdm_Departments_Bool_Exp>;
  cdm_description?: Maybe<String_Comparison_Exp>;
  cdm_fulltimeequivalent?: Maybe<String_Comparison_Exp>;
  cdm_job?: Maybe<Hr_Cdm_Jobs_Bool_Exp>;
  cdm_jobpositionid?: Maybe<Uuid_Comparison_Exp>;
  cdm_jobpositionnumber?: Maybe<String_Comparison_Exp>;
  cdm_positionworkerassignmentmaps?: Maybe<Hr_Cdm_Positionworkerassignmentmaps_Bool_Exp>;
  cdm_retirement?: Maybe<Timestamptz_Comparison_Exp>;
  cdm_tablecode_custom?: Maybe<String_Comparison_Exp>;
  cdm_title?: Maybe<String_Comparison_Exp>;
  cdm_validfrom?: Maybe<Timestamptz_Comparison_Exp>;
  cdm_validto?: Maybe<Timestamptz_Comparison_Exp>;
  cdm_workerfixedcompensations?: Maybe<Hr_Cdm_Workerfixedcompensations_Bool_Exp>;
  createdon?: Maybe<Timestamptz_Comparison_Exp>;
  importsequencenumber?: Maybe<String_Comparison_Exp>;
  modifiedon?: Maybe<Timestamptz_Comparison_Exp>;
  msdyn_additionalmetadata?: Maybe<String_Comparison_Exp>;
  overriddencreatedon?: Maybe<Timestamptz_Comparison_Exp>;
  statecode?: Maybe<String_Comparison_Exp>;
  statuscode?: Maybe<String_Comparison_Exp>;
  timezoneruleversionnumber?: Maybe<String_Comparison_Exp>;
  utcconversiontimezonecode?: Maybe<String_Comparison_Exp>;
  versionnumber?: Maybe<String_Comparison_Exp>;
};

/** input type for inserting data into table "hr.cdm_jobpositions" */
export type Hr_Cdm_Jobpositions_Insert_Input = {
  _cdm_compensationregionid_value?: Maybe<Scalars['uuid']>;
  _cdm_departmentid_value?: Maybe<Scalars['uuid']>;
  _cdm_jobid_value?: Maybe<Scalars['uuid']>;
  _cdm_parentjobpositionid_value?: Maybe<Scalars['uuid']>;
  _cdm_positiontypeid_value?: Maybe<Scalars['uuid']>;
  _cdm_titleid_value?: Maybe<Scalars['uuid']>;
  _createdby_value?: Maybe<Scalars['uuid']>;
  _createdonbehalfby_value?: Maybe<Scalars['timestamptz']>;
  _modifiedby_value?: Maybe<Scalars['uuid']>;
  _modifiedonbehalfby_value?: Maybe<Scalars['timestamptz']>;
  _ownerid_value?: Maybe<Scalars['uuid']>;
  _owningbusinessunit_value?: Maybe<Scalars['uuid']>;
  _owningteam_value?: Maybe<Scalars['String']>;
  _owninguser_value?: Maybe<Scalars['uuid']>;
  cdm_activation?: Maybe<Scalars['timestamptz']>;
  cdm_availableforassignment?: Maybe<Scalars['timestamptz']>;
  cdm_customdescription_custom?: Maybe<Scalars['String']>;
  cdm_department?: Maybe<Hr_Cdm_Departments_Obj_Rel_Insert_Input>;
  cdm_description?: Maybe<Scalars['String']>;
  cdm_fulltimeequivalent?: Maybe<Scalars['String']>;
  cdm_job?: Maybe<Hr_Cdm_Jobs_Obj_Rel_Insert_Input>;
  cdm_jobpositionid?: Maybe<Scalars['uuid']>;
  cdm_jobpositionnumber?: Maybe<Scalars['String']>;
  cdm_positionworkerassignmentmaps?: Maybe<Hr_Cdm_Positionworkerassignmentmaps_Arr_Rel_Insert_Input>;
  cdm_retirement?: Maybe<Scalars['timestamptz']>;
  cdm_tablecode_custom?: Maybe<Scalars['String']>;
  cdm_title?: Maybe<Scalars['String']>;
  cdm_validfrom?: Maybe<Scalars['timestamptz']>;
  cdm_validto?: Maybe<Scalars['timestamptz']>;
  cdm_workerfixedcompensations?: Maybe<Hr_Cdm_Workerfixedcompensations_Arr_Rel_Insert_Input>;
  createdon?: Maybe<Scalars['timestamptz']>;
  importsequencenumber?: Maybe<Scalars['String']>;
  modifiedon?: Maybe<Scalars['timestamptz']>;
  msdyn_additionalmetadata?: Maybe<Scalars['String']>;
  overriddencreatedon?: Maybe<Scalars['timestamptz']>;
  statecode?: Maybe<Scalars['String']>;
  statuscode?: Maybe<Scalars['String']>;
  timezoneruleversionnumber?: Maybe<Scalars['String']>;
  utcconversiontimezonecode?: Maybe<Scalars['String']>;
  versionnumber?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Hr_Cdm_Jobpositions_Max_Fields = {
  _cdm_compensationregionid_value?: Maybe<Scalars['uuid']>;
  _cdm_departmentid_value?: Maybe<Scalars['uuid']>;
  _cdm_jobid_value?: Maybe<Scalars['uuid']>;
  _cdm_parentjobpositionid_value?: Maybe<Scalars['uuid']>;
  _cdm_positiontypeid_value?: Maybe<Scalars['uuid']>;
  _cdm_titleid_value?: Maybe<Scalars['uuid']>;
  _createdby_value?: Maybe<Scalars['uuid']>;
  _createdonbehalfby_value?: Maybe<Scalars['timestamptz']>;
  _modifiedby_value?: Maybe<Scalars['uuid']>;
  _modifiedonbehalfby_value?: Maybe<Scalars['timestamptz']>;
  _ownerid_value?: Maybe<Scalars['uuid']>;
  _owningbusinessunit_value?: Maybe<Scalars['uuid']>;
  _owningteam_value?: Maybe<Scalars['String']>;
  _owninguser_value?: Maybe<Scalars['uuid']>;
  cdm_activation?: Maybe<Scalars['timestamptz']>;
  cdm_availableforassignment?: Maybe<Scalars['timestamptz']>;
  cdm_customdescription_custom?: Maybe<Scalars['String']>;
  cdm_description?: Maybe<Scalars['String']>;
  cdm_fulltimeequivalent?: Maybe<Scalars['String']>;
  cdm_jobpositionid?: Maybe<Scalars['uuid']>;
  cdm_jobpositionnumber?: Maybe<Scalars['String']>;
  cdm_retirement?: Maybe<Scalars['timestamptz']>;
  cdm_tablecode_custom?: Maybe<Scalars['String']>;
  cdm_title?: Maybe<Scalars['String']>;
  cdm_validfrom?: Maybe<Scalars['timestamptz']>;
  cdm_validto?: Maybe<Scalars['timestamptz']>;
  createdon?: Maybe<Scalars['timestamptz']>;
  importsequencenumber?: Maybe<Scalars['String']>;
  modifiedon?: Maybe<Scalars['timestamptz']>;
  msdyn_additionalmetadata?: Maybe<Scalars['String']>;
  overriddencreatedon?: Maybe<Scalars['timestamptz']>;
  statecode?: Maybe<Scalars['String']>;
  statuscode?: Maybe<Scalars['String']>;
  timezoneruleversionnumber?: Maybe<Scalars['String']>;
  utcconversiontimezonecode?: Maybe<Scalars['String']>;
  versionnumber?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "hr.cdm_jobpositions" */
export type Hr_Cdm_Jobpositions_Max_Order_By = {
  _cdm_compensationregionid_value?: Maybe<Order_By>;
  _cdm_departmentid_value?: Maybe<Order_By>;
  _cdm_jobid_value?: Maybe<Order_By>;
  _cdm_parentjobpositionid_value?: Maybe<Order_By>;
  _cdm_positiontypeid_value?: Maybe<Order_By>;
  _cdm_titleid_value?: Maybe<Order_By>;
  _createdby_value?: Maybe<Order_By>;
  _createdonbehalfby_value?: Maybe<Order_By>;
  _modifiedby_value?: Maybe<Order_By>;
  _modifiedonbehalfby_value?: Maybe<Order_By>;
  _ownerid_value?: Maybe<Order_By>;
  _owningbusinessunit_value?: Maybe<Order_By>;
  _owningteam_value?: Maybe<Order_By>;
  _owninguser_value?: Maybe<Order_By>;
  cdm_activation?: Maybe<Order_By>;
  cdm_availableforassignment?: Maybe<Order_By>;
  cdm_customdescription_custom?: Maybe<Order_By>;
  cdm_description?: Maybe<Order_By>;
  cdm_fulltimeequivalent?: Maybe<Order_By>;
  cdm_jobpositionid?: Maybe<Order_By>;
  cdm_jobpositionnumber?: Maybe<Order_By>;
  cdm_retirement?: Maybe<Order_By>;
  cdm_tablecode_custom?: Maybe<Order_By>;
  cdm_title?: Maybe<Order_By>;
  cdm_validfrom?: Maybe<Order_By>;
  cdm_validto?: Maybe<Order_By>;
  createdon?: Maybe<Order_By>;
  importsequencenumber?: Maybe<Order_By>;
  modifiedon?: Maybe<Order_By>;
  msdyn_additionalmetadata?: Maybe<Order_By>;
  overriddencreatedon?: Maybe<Order_By>;
  statecode?: Maybe<Order_By>;
  statuscode?: Maybe<Order_By>;
  timezoneruleversionnumber?: Maybe<Order_By>;
  utcconversiontimezonecode?: Maybe<Order_By>;
  versionnumber?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Hr_Cdm_Jobpositions_Min_Fields = {
  _cdm_compensationregionid_value?: Maybe<Scalars['uuid']>;
  _cdm_departmentid_value?: Maybe<Scalars['uuid']>;
  _cdm_jobid_value?: Maybe<Scalars['uuid']>;
  _cdm_parentjobpositionid_value?: Maybe<Scalars['uuid']>;
  _cdm_positiontypeid_value?: Maybe<Scalars['uuid']>;
  _cdm_titleid_value?: Maybe<Scalars['uuid']>;
  _createdby_value?: Maybe<Scalars['uuid']>;
  _createdonbehalfby_value?: Maybe<Scalars['timestamptz']>;
  _modifiedby_value?: Maybe<Scalars['uuid']>;
  _modifiedonbehalfby_value?: Maybe<Scalars['timestamptz']>;
  _ownerid_value?: Maybe<Scalars['uuid']>;
  _owningbusinessunit_value?: Maybe<Scalars['uuid']>;
  _owningteam_value?: Maybe<Scalars['String']>;
  _owninguser_value?: Maybe<Scalars['uuid']>;
  cdm_activation?: Maybe<Scalars['timestamptz']>;
  cdm_availableforassignment?: Maybe<Scalars['timestamptz']>;
  cdm_customdescription_custom?: Maybe<Scalars['String']>;
  cdm_description?: Maybe<Scalars['String']>;
  cdm_fulltimeequivalent?: Maybe<Scalars['String']>;
  cdm_jobpositionid?: Maybe<Scalars['uuid']>;
  cdm_jobpositionnumber?: Maybe<Scalars['String']>;
  cdm_retirement?: Maybe<Scalars['timestamptz']>;
  cdm_tablecode_custom?: Maybe<Scalars['String']>;
  cdm_title?: Maybe<Scalars['String']>;
  cdm_validfrom?: Maybe<Scalars['timestamptz']>;
  cdm_validto?: Maybe<Scalars['timestamptz']>;
  createdon?: Maybe<Scalars['timestamptz']>;
  importsequencenumber?: Maybe<Scalars['String']>;
  modifiedon?: Maybe<Scalars['timestamptz']>;
  msdyn_additionalmetadata?: Maybe<Scalars['String']>;
  overriddencreatedon?: Maybe<Scalars['timestamptz']>;
  statecode?: Maybe<Scalars['String']>;
  statuscode?: Maybe<Scalars['String']>;
  timezoneruleversionnumber?: Maybe<Scalars['String']>;
  utcconversiontimezonecode?: Maybe<Scalars['String']>;
  versionnumber?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "hr.cdm_jobpositions" */
export type Hr_Cdm_Jobpositions_Min_Order_By = {
  _cdm_compensationregionid_value?: Maybe<Order_By>;
  _cdm_departmentid_value?: Maybe<Order_By>;
  _cdm_jobid_value?: Maybe<Order_By>;
  _cdm_parentjobpositionid_value?: Maybe<Order_By>;
  _cdm_positiontypeid_value?: Maybe<Order_By>;
  _cdm_titleid_value?: Maybe<Order_By>;
  _createdby_value?: Maybe<Order_By>;
  _createdonbehalfby_value?: Maybe<Order_By>;
  _modifiedby_value?: Maybe<Order_By>;
  _modifiedonbehalfby_value?: Maybe<Order_By>;
  _ownerid_value?: Maybe<Order_By>;
  _owningbusinessunit_value?: Maybe<Order_By>;
  _owningteam_value?: Maybe<Order_By>;
  _owninguser_value?: Maybe<Order_By>;
  cdm_activation?: Maybe<Order_By>;
  cdm_availableforassignment?: Maybe<Order_By>;
  cdm_customdescription_custom?: Maybe<Order_By>;
  cdm_description?: Maybe<Order_By>;
  cdm_fulltimeequivalent?: Maybe<Order_By>;
  cdm_jobpositionid?: Maybe<Order_By>;
  cdm_jobpositionnumber?: Maybe<Order_By>;
  cdm_retirement?: Maybe<Order_By>;
  cdm_tablecode_custom?: Maybe<Order_By>;
  cdm_title?: Maybe<Order_By>;
  cdm_validfrom?: Maybe<Order_By>;
  cdm_validto?: Maybe<Order_By>;
  createdon?: Maybe<Order_By>;
  importsequencenumber?: Maybe<Order_By>;
  modifiedon?: Maybe<Order_By>;
  msdyn_additionalmetadata?: Maybe<Order_By>;
  overriddencreatedon?: Maybe<Order_By>;
  statecode?: Maybe<Order_By>;
  statuscode?: Maybe<Order_By>;
  timezoneruleversionnumber?: Maybe<Order_By>;
  utcconversiontimezonecode?: Maybe<Order_By>;
  versionnumber?: Maybe<Order_By>;
};

/** response of any mutation on the table "hr.cdm_jobpositions" */
export type Hr_Cdm_Jobpositions_Mutation_Response = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Hr_Cdm_Jobpositions>;
};

/** input type for inserting object relation for remote table "hr.cdm_jobpositions" */
export type Hr_Cdm_Jobpositions_Obj_Rel_Insert_Input = {
  data: Hr_Cdm_Jobpositions_Insert_Input;
};

/** Ordering options when selecting data from "hr.cdm_jobpositions". */
export type Hr_Cdm_Jobpositions_Order_By = {
  _cdm_compensationregionid_value?: Maybe<Order_By>;
  _cdm_departmentid_value?: Maybe<Order_By>;
  _cdm_jobid_value?: Maybe<Order_By>;
  _cdm_parentjobpositionid_value?: Maybe<Order_By>;
  _cdm_positiontypeid_value?: Maybe<Order_By>;
  _cdm_titleid_value?: Maybe<Order_By>;
  _createdby_value?: Maybe<Order_By>;
  _createdonbehalfby_value?: Maybe<Order_By>;
  _modifiedby_value?: Maybe<Order_By>;
  _modifiedonbehalfby_value?: Maybe<Order_By>;
  _ownerid_value?: Maybe<Order_By>;
  _owningbusinessunit_value?: Maybe<Order_By>;
  _owningteam_value?: Maybe<Order_By>;
  _owninguser_value?: Maybe<Order_By>;
  cdm_activation?: Maybe<Order_By>;
  cdm_availableforassignment?: Maybe<Order_By>;
  cdm_customdescription_custom?: Maybe<Order_By>;
  cdm_department?: Maybe<Hr_Cdm_Departments_Order_By>;
  cdm_description?: Maybe<Order_By>;
  cdm_fulltimeequivalent?: Maybe<Order_By>;
  cdm_job?: Maybe<Hr_Cdm_Jobs_Order_By>;
  cdm_jobpositionid?: Maybe<Order_By>;
  cdm_jobpositionnumber?: Maybe<Order_By>;
  cdm_positionworkerassignmentmaps_aggregate?: Maybe<Hr_Cdm_Positionworkerassignmentmaps_Aggregate_Order_By>;
  cdm_retirement?: Maybe<Order_By>;
  cdm_tablecode_custom?: Maybe<Order_By>;
  cdm_title?: Maybe<Order_By>;
  cdm_validfrom?: Maybe<Order_By>;
  cdm_validto?: Maybe<Order_By>;
  cdm_workerfixedcompensations_aggregate?: Maybe<Hr_Cdm_Workerfixedcompensations_Aggregate_Order_By>;
  createdon?: Maybe<Order_By>;
  importsequencenumber?: Maybe<Order_By>;
  modifiedon?: Maybe<Order_By>;
  msdyn_additionalmetadata?: Maybe<Order_By>;
  overriddencreatedon?: Maybe<Order_By>;
  statecode?: Maybe<Order_By>;
  statuscode?: Maybe<Order_By>;
  timezoneruleversionnumber?: Maybe<Order_By>;
  utcconversiontimezonecode?: Maybe<Order_By>;
  versionnumber?: Maybe<Order_By>;
};

/** select columns of table "hr.cdm_jobpositions" */
export enum Hr_Cdm_Jobpositions_Select_Column {
  /** column name */
  CdmCompensationregionidValue = '_cdm_compensationregionid_value',
  /** column name */
  CdmDepartmentidValue = '_cdm_departmentid_value',
  /** column name */
  CdmJobidValue = '_cdm_jobid_value',
  /** column name */
  CdmParentjobpositionidValue = '_cdm_parentjobpositionid_value',
  /** column name */
  CdmPositiontypeidValue = '_cdm_positiontypeid_value',
  /** column name */
  CdmTitleidValue = '_cdm_titleid_value',
  /** column name */
  CreatedbyValue = '_createdby_value',
  /** column name */
  CreatedonbehalfbyValue = '_createdonbehalfby_value',
  /** column name */
  ModifiedbyValue = '_modifiedby_value',
  /** column name */
  ModifiedonbehalfbyValue = '_modifiedonbehalfby_value',
  /** column name */
  OwneridValue = '_ownerid_value',
  /** column name */
  OwningbusinessunitValue = '_owningbusinessunit_value',
  /** column name */
  OwningteamValue = '_owningteam_value',
  /** column name */
  OwninguserValue = '_owninguser_value',
  /** column name */
  CdmActivation = 'cdm_activation',
  /** column name */
  CdmAvailableforassignment = 'cdm_availableforassignment',
  /** column name */
  CdmCustomdescriptionCustom = 'cdm_customdescription_custom',
  /** column name */
  CdmDescription = 'cdm_description',
  /** column name */
  CdmFulltimeequivalent = 'cdm_fulltimeequivalent',
  /** column name */
  CdmJobpositionid = 'cdm_jobpositionid',
  /** column name */
  CdmJobpositionnumber = 'cdm_jobpositionnumber',
  /** column name */
  CdmRetirement = 'cdm_retirement',
  /** column name */
  CdmTablecodeCustom = 'cdm_tablecode_custom',
  /** column name */
  CdmTitle = 'cdm_title',
  /** column name */
  CdmValidfrom = 'cdm_validfrom',
  /** column name */
  CdmValidto = 'cdm_validto',
  /** column name */
  Createdon = 'createdon',
  /** column name */
  Importsequencenumber = 'importsequencenumber',
  /** column name */
  Modifiedon = 'modifiedon',
  /** column name */
  MsdynAdditionalmetadata = 'msdyn_additionalmetadata',
  /** column name */
  Overriddencreatedon = 'overriddencreatedon',
  /** column name */
  Statecode = 'statecode',
  /** column name */
  Statuscode = 'statuscode',
  /** column name */
  Timezoneruleversionnumber = 'timezoneruleversionnumber',
  /** column name */
  Utcconversiontimezonecode = 'utcconversiontimezonecode',
  /** column name */
  Versionnumber = 'versionnumber'
}

/** input type for updating data in table "hr.cdm_jobpositions" */
export type Hr_Cdm_Jobpositions_Set_Input = {
  _cdm_compensationregionid_value?: Maybe<Scalars['uuid']>;
  _cdm_departmentid_value?: Maybe<Scalars['uuid']>;
  _cdm_jobid_value?: Maybe<Scalars['uuid']>;
  _cdm_parentjobpositionid_value?: Maybe<Scalars['uuid']>;
  _cdm_positiontypeid_value?: Maybe<Scalars['uuid']>;
  _cdm_titleid_value?: Maybe<Scalars['uuid']>;
  _createdby_value?: Maybe<Scalars['uuid']>;
  _createdonbehalfby_value?: Maybe<Scalars['timestamptz']>;
  _modifiedby_value?: Maybe<Scalars['uuid']>;
  _modifiedonbehalfby_value?: Maybe<Scalars['timestamptz']>;
  _ownerid_value?: Maybe<Scalars['uuid']>;
  _owningbusinessunit_value?: Maybe<Scalars['uuid']>;
  _owningteam_value?: Maybe<Scalars['String']>;
  _owninguser_value?: Maybe<Scalars['uuid']>;
  cdm_activation?: Maybe<Scalars['timestamptz']>;
  cdm_availableforassignment?: Maybe<Scalars['timestamptz']>;
  cdm_customdescription_custom?: Maybe<Scalars['String']>;
  cdm_description?: Maybe<Scalars['String']>;
  cdm_fulltimeequivalent?: Maybe<Scalars['String']>;
  cdm_jobpositionid?: Maybe<Scalars['uuid']>;
  cdm_jobpositionnumber?: Maybe<Scalars['String']>;
  cdm_retirement?: Maybe<Scalars['timestamptz']>;
  cdm_tablecode_custom?: Maybe<Scalars['String']>;
  cdm_title?: Maybe<Scalars['String']>;
  cdm_validfrom?: Maybe<Scalars['timestamptz']>;
  cdm_validto?: Maybe<Scalars['timestamptz']>;
  createdon?: Maybe<Scalars['timestamptz']>;
  importsequencenumber?: Maybe<Scalars['String']>;
  modifiedon?: Maybe<Scalars['timestamptz']>;
  msdyn_additionalmetadata?: Maybe<Scalars['String']>;
  overriddencreatedon?: Maybe<Scalars['timestamptz']>;
  statecode?: Maybe<Scalars['String']>;
  statuscode?: Maybe<Scalars['String']>;
  timezoneruleversionnumber?: Maybe<Scalars['String']>;
  utcconversiontimezonecode?: Maybe<Scalars['String']>;
  versionnumber?: Maybe<Scalars['String']>;
};

/** columns and relationships of "hr.cdm_jobs" */
export type Hr_Cdm_Jobs = {
  _cdm_jobfunctionid_value?: Maybe<Scalars['uuid']>;
  _cdm_jobtypeid_value?: Maybe<Scalars['uuid']>;
  _cdm_titleid_value?: Maybe<Scalars['uuid']>;
  _createdby_value?: Maybe<Scalars['uuid']>;
  _createdonbehalfby_value?: Maybe<Scalars['timestamptz']>;
  _modifiedby_value?: Maybe<Scalars['uuid']>;
  _modifiedonbehalfby_value?: Maybe<Scalars['timestamptz']>;
  _ownerid_value?: Maybe<Scalars['uuid']>;
  _owningbusinessunit_value?: Maybe<Scalars['uuid']>;
  _owningteam_value?: Maybe<Scalars['String']>;
  _owninguser_value?: Maybe<Scalars['uuid']>;
  cdm_allowunlimitedpositions?: Maybe<Scalars['String']>;
  cdm_defaultfulltimeequivalent?: Maybe<Scalars['String']>;
  cdm_description?: Maybe<Scalars['String']>;
  cdm_jobdescription?: Maybe<Scalars['String']>;
  cdm_jobid: Scalars['uuid'];
  /** An array relationship */
  cdm_jobpositions: Array<Hr_Cdm_Jobpositions>;
  /** An aggregate relationship */
  cdm_jobpositions_aggregate: Hr_Cdm_Jobpositions_Aggregate;
  /** An object relationship */
  cdm_jobtype?: Maybe<Hr_Cdm_Jobtypes>;
  cdm_maximumnumberofpositions?: Maybe<Scalars['String']>;
  cdm_name?: Maybe<Scalars['String']>;
  cdm_title?: Maybe<Scalars['String']>;
  cdm_validfrom?: Maybe<Scalars['timestamptz']>;
  cdm_validto?: Maybe<Scalars['timestamptz']>;
  createdon?: Maybe<Scalars['timestamptz']>;
  importsequencenumber?: Maybe<Scalars['String']>;
  modifiedon?: Maybe<Scalars['String']>;
  overriddencreatedon?: Maybe<Scalars['timestamptz']>;
  statecode?: Maybe<Scalars['String']>;
  statuscode?: Maybe<Scalars['String']>;
  timezoneruleversionnumber?: Maybe<Scalars['String']>;
  utcconversiontimezonecode?: Maybe<Scalars['String']>;
  versionnumber?: Maybe<Scalars['String']>;
};


/** columns and relationships of "hr.cdm_jobs" */
export type Hr_Cdm_JobsCdm_JobpositionsArgs = {
  distinct_on?: Maybe<Array<Hr_Cdm_Jobpositions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Hr_Cdm_Jobpositions_Order_By>>;
  where?: Maybe<Hr_Cdm_Jobpositions_Bool_Exp>;
};


/** columns and relationships of "hr.cdm_jobs" */
export type Hr_Cdm_JobsCdm_Jobpositions_AggregateArgs = {
  distinct_on?: Maybe<Array<Hr_Cdm_Jobpositions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Hr_Cdm_Jobpositions_Order_By>>;
  where?: Maybe<Hr_Cdm_Jobpositions_Bool_Exp>;
};

/** aggregated selection of "hr.cdm_jobs" */
export type Hr_Cdm_Jobs_Aggregate = {
  aggregate?: Maybe<Hr_Cdm_Jobs_Aggregate_Fields>;
  nodes: Array<Hr_Cdm_Jobs>;
};

/** aggregate fields of "hr.cdm_jobs" */
export type Hr_Cdm_Jobs_Aggregate_Fields = {
  count: Scalars['Int'];
  max?: Maybe<Hr_Cdm_Jobs_Max_Fields>;
  min?: Maybe<Hr_Cdm_Jobs_Min_Fields>;
};


/** aggregate fields of "hr.cdm_jobs" */
export type Hr_Cdm_Jobs_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Hr_Cdm_Jobs_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "hr.cdm_jobs" */
export type Hr_Cdm_Jobs_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Hr_Cdm_Jobs_Max_Order_By>;
  min?: Maybe<Hr_Cdm_Jobs_Min_Order_By>;
};

/** input type for inserting array relation for remote table "hr.cdm_jobs" */
export type Hr_Cdm_Jobs_Arr_Rel_Insert_Input = {
  data: Array<Hr_Cdm_Jobs_Insert_Input>;
};

/** Boolean expression to filter rows from the table "hr.cdm_jobs". All fields are combined with a logical 'AND'. */
export type Hr_Cdm_Jobs_Bool_Exp = {
  _and?: Maybe<Array<Hr_Cdm_Jobs_Bool_Exp>>;
  _cdm_jobfunctionid_value?: Maybe<Uuid_Comparison_Exp>;
  _cdm_jobtypeid_value?: Maybe<Uuid_Comparison_Exp>;
  _cdm_titleid_value?: Maybe<Uuid_Comparison_Exp>;
  _createdby_value?: Maybe<Uuid_Comparison_Exp>;
  _createdonbehalfby_value?: Maybe<Timestamptz_Comparison_Exp>;
  _modifiedby_value?: Maybe<Uuid_Comparison_Exp>;
  _modifiedonbehalfby_value?: Maybe<Timestamptz_Comparison_Exp>;
  _not?: Maybe<Hr_Cdm_Jobs_Bool_Exp>;
  _or?: Maybe<Array<Hr_Cdm_Jobs_Bool_Exp>>;
  _ownerid_value?: Maybe<Uuid_Comparison_Exp>;
  _owningbusinessunit_value?: Maybe<Uuid_Comparison_Exp>;
  _owningteam_value?: Maybe<String_Comparison_Exp>;
  _owninguser_value?: Maybe<Uuid_Comparison_Exp>;
  cdm_allowunlimitedpositions?: Maybe<String_Comparison_Exp>;
  cdm_defaultfulltimeequivalent?: Maybe<String_Comparison_Exp>;
  cdm_description?: Maybe<String_Comparison_Exp>;
  cdm_jobdescription?: Maybe<String_Comparison_Exp>;
  cdm_jobid?: Maybe<Uuid_Comparison_Exp>;
  cdm_jobpositions?: Maybe<Hr_Cdm_Jobpositions_Bool_Exp>;
  cdm_jobtype?: Maybe<Hr_Cdm_Jobtypes_Bool_Exp>;
  cdm_maximumnumberofpositions?: Maybe<String_Comparison_Exp>;
  cdm_name?: Maybe<String_Comparison_Exp>;
  cdm_title?: Maybe<String_Comparison_Exp>;
  cdm_validfrom?: Maybe<Timestamptz_Comparison_Exp>;
  cdm_validto?: Maybe<Timestamptz_Comparison_Exp>;
  createdon?: Maybe<Timestamptz_Comparison_Exp>;
  importsequencenumber?: Maybe<String_Comparison_Exp>;
  modifiedon?: Maybe<String_Comparison_Exp>;
  overriddencreatedon?: Maybe<Timestamptz_Comparison_Exp>;
  statecode?: Maybe<String_Comparison_Exp>;
  statuscode?: Maybe<String_Comparison_Exp>;
  timezoneruleversionnumber?: Maybe<String_Comparison_Exp>;
  utcconversiontimezonecode?: Maybe<String_Comparison_Exp>;
  versionnumber?: Maybe<String_Comparison_Exp>;
};

/** input type for inserting data into table "hr.cdm_jobs" */
export type Hr_Cdm_Jobs_Insert_Input = {
  _cdm_jobfunctionid_value?: Maybe<Scalars['uuid']>;
  _cdm_jobtypeid_value?: Maybe<Scalars['uuid']>;
  _cdm_titleid_value?: Maybe<Scalars['uuid']>;
  _createdby_value?: Maybe<Scalars['uuid']>;
  _createdonbehalfby_value?: Maybe<Scalars['timestamptz']>;
  _modifiedby_value?: Maybe<Scalars['uuid']>;
  _modifiedonbehalfby_value?: Maybe<Scalars['timestamptz']>;
  _ownerid_value?: Maybe<Scalars['uuid']>;
  _owningbusinessunit_value?: Maybe<Scalars['uuid']>;
  _owningteam_value?: Maybe<Scalars['String']>;
  _owninguser_value?: Maybe<Scalars['uuid']>;
  cdm_allowunlimitedpositions?: Maybe<Scalars['String']>;
  cdm_defaultfulltimeequivalent?: Maybe<Scalars['String']>;
  cdm_description?: Maybe<Scalars['String']>;
  cdm_jobdescription?: Maybe<Scalars['String']>;
  cdm_jobid?: Maybe<Scalars['uuid']>;
  cdm_jobpositions?: Maybe<Hr_Cdm_Jobpositions_Arr_Rel_Insert_Input>;
  cdm_jobtype?: Maybe<Hr_Cdm_Jobtypes_Obj_Rel_Insert_Input>;
  cdm_maximumnumberofpositions?: Maybe<Scalars['String']>;
  cdm_name?: Maybe<Scalars['String']>;
  cdm_title?: Maybe<Scalars['String']>;
  cdm_validfrom?: Maybe<Scalars['timestamptz']>;
  cdm_validto?: Maybe<Scalars['timestamptz']>;
  createdon?: Maybe<Scalars['timestamptz']>;
  importsequencenumber?: Maybe<Scalars['String']>;
  modifiedon?: Maybe<Scalars['String']>;
  overriddencreatedon?: Maybe<Scalars['timestamptz']>;
  statecode?: Maybe<Scalars['String']>;
  statuscode?: Maybe<Scalars['String']>;
  timezoneruleversionnumber?: Maybe<Scalars['String']>;
  utcconversiontimezonecode?: Maybe<Scalars['String']>;
  versionnumber?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Hr_Cdm_Jobs_Max_Fields = {
  _cdm_jobfunctionid_value?: Maybe<Scalars['uuid']>;
  _cdm_jobtypeid_value?: Maybe<Scalars['uuid']>;
  _cdm_titleid_value?: Maybe<Scalars['uuid']>;
  _createdby_value?: Maybe<Scalars['uuid']>;
  _createdonbehalfby_value?: Maybe<Scalars['timestamptz']>;
  _modifiedby_value?: Maybe<Scalars['uuid']>;
  _modifiedonbehalfby_value?: Maybe<Scalars['timestamptz']>;
  _ownerid_value?: Maybe<Scalars['uuid']>;
  _owningbusinessunit_value?: Maybe<Scalars['uuid']>;
  _owningteam_value?: Maybe<Scalars['String']>;
  _owninguser_value?: Maybe<Scalars['uuid']>;
  cdm_allowunlimitedpositions?: Maybe<Scalars['String']>;
  cdm_defaultfulltimeequivalent?: Maybe<Scalars['String']>;
  cdm_description?: Maybe<Scalars['String']>;
  cdm_jobdescription?: Maybe<Scalars['String']>;
  cdm_jobid?: Maybe<Scalars['uuid']>;
  cdm_maximumnumberofpositions?: Maybe<Scalars['String']>;
  cdm_name?: Maybe<Scalars['String']>;
  cdm_title?: Maybe<Scalars['String']>;
  cdm_validfrom?: Maybe<Scalars['timestamptz']>;
  cdm_validto?: Maybe<Scalars['timestamptz']>;
  createdon?: Maybe<Scalars['timestamptz']>;
  importsequencenumber?: Maybe<Scalars['String']>;
  modifiedon?: Maybe<Scalars['String']>;
  overriddencreatedon?: Maybe<Scalars['timestamptz']>;
  statecode?: Maybe<Scalars['String']>;
  statuscode?: Maybe<Scalars['String']>;
  timezoneruleversionnumber?: Maybe<Scalars['String']>;
  utcconversiontimezonecode?: Maybe<Scalars['String']>;
  versionnumber?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "hr.cdm_jobs" */
export type Hr_Cdm_Jobs_Max_Order_By = {
  _cdm_jobfunctionid_value?: Maybe<Order_By>;
  _cdm_jobtypeid_value?: Maybe<Order_By>;
  _cdm_titleid_value?: Maybe<Order_By>;
  _createdby_value?: Maybe<Order_By>;
  _createdonbehalfby_value?: Maybe<Order_By>;
  _modifiedby_value?: Maybe<Order_By>;
  _modifiedonbehalfby_value?: Maybe<Order_By>;
  _ownerid_value?: Maybe<Order_By>;
  _owningbusinessunit_value?: Maybe<Order_By>;
  _owningteam_value?: Maybe<Order_By>;
  _owninguser_value?: Maybe<Order_By>;
  cdm_allowunlimitedpositions?: Maybe<Order_By>;
  cdm_defaultfulltimeequivalent?: Maybe<Order_By>;
  cdm_description?: Maybe<Order_By>;
  cdm_jobdescription?: Maybe<Order_By>;
  cdm_jobid?: Maybe<Order_By>;
  cdm_maximumnumberofpositions?: Maybe<Order_By>;
  cdm_name?: Maybe<Order_By>;
  cdm_title?: Maybe<Order_By>;
  cdm_validfrom?: Maybe<Order_By>;
  cdm_validto?: Maybe<Order_By>;
  createdon?: Maybe<Order_By>;
  importsequencenumber?: Maybe<Order_By>;
  modifiedon?: Maybe<Order_By>;
  overriddencreatedon?: Maybe<Order_By>;
  statecode?: Maybe<Order_By>;
  statuscode?: Maybe<Order_By>;
  timezoneruleversionnumber?: Maybe<Order_By>;
  utcconversiontimezonecode?: Maybe<Order_By>;
  versionnumber?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Hr_Cdm_Jobs_Min_Fields = {
  _cdm_jobfunctionid_value?: Maybe<Scalars['uuid']>;
  _cdm_jobtypeid_value?: Maybe<Scalars['uuid']>;
  _cdm_titleid_value?: Maybe<Scalars['uuid']>;
  _createdby_value?: Maybe<Scalars['uuid']>;
  _createdonbehalfby_value?: Maybe<Scalars['timestamptz']>;
  _modifiedby_value?: Maybe<Scalars['uuid']>;
  _modifiedonbehalfby_value?: Maybe<Scalars['timestamptz']>;
  _ownerid_value?: Maybe<Scalars['uuid']>;
  _owningbusinessunit_value?: Maybe<Scalars['uuid']>;
  _owningteam_value?: Maybe<Scalars['String']>;
  _owninguser_value?: Maybe<Scalars['uuid']>;
  cdm_allowunlimitedpositions?: Maybe<Scalars['String']>;
  cdm_defaultfulltimeequivalent?: Maybe<Scalars['String']>;
  cdm_description?: Maybe<Scalars['String']>;
  cdm_jobdescription?: Maybe<Scalars['String']>;
  cdm_jobid?: Maybe<Scalars['uuid']>;
  cdm_maximumnumberofpositions?: Maybe<Scalars['String']>;
  cdm_name?: Maybe<Scalars['String']>;
  cdm_title?: Maybe<Scalars['String']>;
  cdm_validfrom?: Maybe<Scalars['timestamptz']>;
  cdm_validto?: Maybe<Scalars['timestamptz']>;
  createdon?: Maybe<Scalars['timestamptz']>;
  importsequencenumber?: Maybe<Scalars['String']>;
  modifiedon?: Maybe<Scalars['String']>;
  overriddencreatedon?: Maybe<Scalars['timestamptz']>;
  statecode?: Maybe<Scalars['String']>;
  statuscode?: Maybe<Scalars['String']>;
  timezoneruleversionnumber?: Maybe<Scalars['String']>;
  utcconversiontimezonecode?: Maybe<Scalars['String']>;
  versionnumber?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "hr.cdm_jobs" */
export type Hr_Cdm_Jobs_Min_Order_By = {
  _cdm_jobfunctionid_value?: Maybe<Order_By>;
  _cdm_jobtypeid_value?: Maybe<Order_By>;
  _cdm_titleid_value?: Maybe<Order_By>;
  _createdby_value?: Maybe<Order_By>;
  _createdonbehalfby_value?: Maybe<Order_By>;
  _modifiedby_value?: Maybe<Order_By>;
  _modifiedonbehalfby_value?: Maybe<Order_By>;
  _ownerid_value?: Maybe<Order_By>;
  _owningbusinessunit_value?: Maybe<Order_By>;
  _owningteam_value?: Maybe<Order_By>;
  _owninguser_value?: Maybe<Order_By>;
  cdm_allowunlimitedpositions?: Maybe<Order_By>;
  cdm_defaultfulltimeequivalent?: Maybe<Order_By>;
  cdm_description?: Maybe<Order_By>;
  cdm_jobdescription?: Maybe<Order_By>;
  cdm_jobid?: Maybe<Order_By>;
  cdm_maximumnumberofpositions?: Maybe<Order_By>;
  cdm_name?: Maybe<Order_By>;
  cdm_title?: Maybe<Order_By>;
  cdm_validfrom?: Maybe<Order_By>;
  cdm_validto?: Maybe<Order_By>;
  createdon?: Maybe<Order_By>;
  importsequencenumber?: Maybe<Order_By>;
  modifiedon?: Maybe<Order_By>;
  overriddencreatedon?: Maybe<Order_By>;
  statecode?: Maybe<Order_By>;
  statuscode?: Maybe<Order_By>;
  timezoneruleversionnumber?: Maybe<Order_By>;
  utcconversiontimezonecode?: Maybe<Order_By>;
  versionnumber?: Maybe<Order_By>;
};

/** response of any mutation on the table "hr.cdm_jobs" */
export type Hr_Cdm_Jobs_Mutation_Response = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Hr_Cdm_Jobs>;
};

/** input type for inserting object relation for remote table "hr.cdm_jobs" */
export type Hr_Cdm_Jobs_Obj_Rel_Insert_Input = {
  data: Hr_Cdm_Jobs_Insert_Input;
};

/** Ordering options when selecting data from "hr.cdm_jobs". */
export type Hr_Cdm_Jobs_Order_By = {
  _cdm_jobfunctionid_value?: Maybe<Order_By>;
  _cdm_jobtypeid_value?: Maybe<Order_By>;
  _cdm_titleid_value?: Maybe<Order_By>;
  _createdby_value?: Maybe<Order_By>;
  _createdonbehalfby_value?: Maybe<Order_By>;
  _modifiedby_value?: Maybe<Order_By>;
  _modifiedonbehalfby_value?: Maybe<Order_By>;
  _ownerid_value?: Maybe<Order_By>;
  _owningbusinessunit_value?: Maybe<Order_By>;
  _owningteam_value?: Maybe<Order_By>;
  _owninguser_value?: Maybe<Order_By>;
  cdm_allowunlimitedpositions?: Maybe<Order_By>;
  cdm_defaultfulltimeequivalent?: Maybe<Order_By>;
  cdm_description?: Maybe<Order_By>;
  cdm_jobdescription?: Maybe<Order_By>;
  cdm_jobid?: Maybe<Order_By>;
  cdm_jobpositions_aggregate?: Maybe<Hr_Cdm_Jobpositions_Aggregate_Order_By>;
  cdm_jobtype?: Maybe<Hr_Cdm_Jobtypes_Order_By>;
  cdm_maximumnumberofpositions?: Maybe<Order_By>;
  cdm_name?: Maybe<Order_By>;
  cdm_title?: Maybe<Order_By>;
  cdm_validfrom?: Maybe<Order_By>;
  cdm_validto?: Maybe<Order_By>;
  createdon?: Maybe<Order_By>;
  importsequencenumber?: Maybe<Order_By>;
  modifiedon?: Maybe<Order_By>;
  overriddencreatedon?: Maybe<Order_By>;
  statecode?: Maybe<Order_By>;
  statuscode?: Maybe<Order_By>;
  timezoneruleversionnumber?: Maybe<Order_By>;
  utcconversiontimezonecode?: Maybe<Order_By>;
  versionnumber?: Maybe<Order_By>;
};

/** select columns of table "hr.cdm_jobs" */
export enum Hr_Cdm_Jobs_Select_Column {
  /** column name */
  CdmJobfunctionidValue = '_cdm_jobfunctionid_value',
  /** column name */
  CdmJobtypeidValue = '_cdm_jobtypeid_value',
  /** column name */
  CdmTitleidValue = '_cdm_titleid_value',
  /** column name */
  CreatedbyValue = '_createdby_value',
  /** column name */
  CreatedonbehalfbyValue = '_createdonbehalfby_value',
  /** column name */
  ModifiedbyValue = '_modifiedby_value',
  /** column name */
  ModifiedonbehalfbyValue = '_modifiedonbehalfby_value',
  /** column name */
  OwneridValue = '_ownerid_value',
  /** column name */
  OwningbusinessunitValue = '_owningbusinessunit_value',
  /** column name */
  OwningteamValue = '_owningteam_value',
  /** column name */
  OwninguserValue = '_owninguser_value',
  /** column name */
  CdmAllowunlimitedpositions = 'cdm_allowunlimitedpositions',
  /** column name */
  CdmDefaultfulltimeequivalent = 'cdm_defaultfulltimeequivalent',
  /** column name */
  CdmDescription = 'cdm_description',
  /** column name */
  CdmJobdescription = 'cdm_jobdescription',
  /** column name */
  CdmJobid = 'cdm_jobid',
  /** column name */
  CdmMaximumnumberofpositions = 'cdm_maximumnumberofpositions',
  /** column name */
  CdmName = 'cdm_name',
  /** column name */
  CdmTitle = 'cdm_title',
  /** column name */
  CdmValidfrom = 'cdm_validfrom',
  /** column name */
  CdmValidto = 'cdm_validto',
  /** column name */
  Createdon = 'createdon',
  /** column name */
  Importsequencenumber = 'importsequencenumber',
  /** column name */
  Modifiedon = 'modifiedon',
  /** column name */
  Overriddencreatedon = 'overriddencreatedon',
  /** column name */
  Statecode = 'statecode',
  /** column name */
  Statuscode = 'statuscode',
  /** column name */
  Timezoneruleversionnumber = 'timezoneruleversionnumber',
  /** column name */
  Utcconversiontimezonecode = 'utcconversiontimezonecode',
  /** column name */
  Versionnumber = 'versionnumber'
}

/** input type for updating data in table "hr.cdm_jobs" */
export type Hr_Cdm_Jobs_Set_Input = {
  _cdm_jobfunctionid_value?: Maybe<Scalars['uuid']>;
  _cdm_jobtypeid_value?: Maybe<Scalars['uuid']>;
  _cdm_titleid_value?: Maybe<Scalars['uuid']>;
  _createdby_value?: Maybe<Scalars['uuid']>;
  _createdonbehalfby_value?: Maybe<Scalars['timestamptz']>;
  _modifiedby_value?: Maybe<Scalars['uuid']>;
  _modifiedonbehalfby_value?: Maybe<Scalars['timestamptz']>;
  _ownerid_value?: Maybe<Scalars['uuid']>;
  _owningbusinessunit_value?: Maybe<Scalars['uuid']>;
  _owningteam_value?: Maybe<Scalars['String']>;
  _owninguser_value?: Maybe<Scalars['uuid']>;
  cdm_allowunlimitedpositions?: Maybe<Scalars['String']>;
  cdm_defaultfulltimeequivalent?: Maybe<Scalars['String']>;
  cdm_description?: Maybe<Scalars['String']>;
  cdm_jobdescription?: Maybe<Scalars['String']>;
  cdm_jobid?: Maybe<Scalars['uuid']>;
  cdm_maximumnumberofpositions?: Maybe<Scalars['String']>;
  cdm_name?: Maybe<Scalars['String']>;
  cdm_title?: Maybe<Scalars['String']>;
  cdm_validfrom?: Maybe<Scalars['timestamptz']>;
  cdm_validto?: Maybe<Scalars['timestamptz']>;
  createdon?: Maybe<Scalars['timestamptz']>;
  importsequencenumber?: Maybe<Scalars['String']>;
  modifiedon?: Maybe<Scalars['String']>;
  overriddencreatedon?: Maybe<Scalars['timestamptz']>;
  statecode?: Maybe<Scalars['String']>;
  statuscode?: Maybe<Scalars['String']>;
  timezoneruleversionnumber?: Maybe<Scalars['String']>;
  utcconversiontimezonecode?: Maybe<Scalars['String']>;
  versionnumber?: Maybe<Scalars['String']>;
};

/** columns and relationships of "hr.cdm_jobtypes" */
export type Hr_Cdm_Jobtypes = {
  _createdby_value?: Maybe<Scalars['uuid']>;
  _createdonbehalfby_value?: Maybe<Scalars['timestamptz']>;
  _modifiedby_value?: Maybe<Scalars['uuid']>;
  _modifiedonbehalfby_value?: Maybe<Scalars['timestamptz']>;
  _ownerid_value?: Maybe<Scalars['uuid']>;
  _owningbusinessunit_value?: Maybe<Scalars['uuid']>;
  _owningteam_value?: Maybe<Scalars['String']>;
  _owninguser_value?: Maybe<Scalars['uuid']>;
  cdm_description?: Maybe<Scalars['String']>;
  cdm_exemptstatus?: Maybe<Scalars['String']>;
  /** An array relationship */
  cdm_jobs: Array<Hr_Cdm_Jobs>;
  /** An aggregate relationship */
  cdm_jobs_aggregate: Hr_Cdm_Jobs_Aggregate;
  cdm_jobtypeid: Scalars['uuid'];
  cdm_name?: Maybe<Scalars['String']>;
  createdon?: Maybe<Scalars['timestamptz']>;
  importsequencenumber?: Maybe<Scalars['String']>;
  modifiedon?: Maybe<Scalars['timestamptz']>;
  overriddencreatedon?: Maybe<Scalars['timestamptz']>;
  statecode?: Maybe<Scalars['String']>;
  statuscode?: Maybe<Scalars['String']>;
  timezoneruleversionnumber?: Maybe<Scalars['String']>;
  utcconversiontimezonecode?: Maybe<Scalars['String']>;
  versionnumber?: Maybe<Scalars['String']>;
};


/** columns and relationships of "hr.cdm_jobtypes" */
export type Hr_Cdm_JobtypesCdm_JobsArgs = {
  distinct_on?: Maybe<Array<Hr_Cdm_Jobs_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Hr_Cdm_Jobs_Order_By>>;
  where?: Maybe<Hr_Cdm_Jobs_Bool_Exp>;
};


/** columns and relationships of "hr.cdm_jobtypes" */
export type Hr_Cdm_JobtypesCdm_Jobs_AggregateArgs = {
  distinct_on?: Maybe<Array<Hr_Cdm_Jobs_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Hr_Cdm_Jobs_Order_By>>;
  where?: Maybe<Hr_Cdm_Jobs_Bool_Exp>;
};

/** aggregated selection of "hr.cdm_jobtypes" */
export type Hr_Cdm_Jobtypes_Aggregate = {
  aggregate?: Maybe<Hr_Cdm_Jobtypes_Aggregate_Fields>;
  nodes: Array<Hr_Cdm_Jobtypes>;
};

/** aggregate fields of "hr.cdm_jobtypes" */
export type Hr_Cdm_Jobtypes_Aggregate_Fields = {
  count: Scalars['Int'];
  max?: Maybe<Hr_Cdm_Jobtypes_Max_Fields>;
  min?: Maybe<Hr_Cdm_Jobtypes_Min_Fields>;
};


/** aggregate fields of "hr.cdm_jobtypes" */
export type Hr_Cdm_Jobtypes_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Hr_Cdm_Jobtypes_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "hr.cdm_jobtypes". All fields are combined with a logical 'AND'. */
export type Hr_Cdm_Jobtypes_Bool_Exp = {
  _and?: Maybe<Array<Hr_Cdm_Jobtypes_Bool_Exp>>;
  _createdby_value?: Maybe<Uuid_Comparison_Exp>;
  _createdonbehalfby_value?: Maybe<Timestamptz_Comparison_Exp>;
  _modifiedby_value?: Maybe<Uuid_Comparison_Exp>;
  _modifiedonbehalfby_value?: Maybe<Timestamptz_Comparison_Exp>;
  _not?: Maybe<Hr_Cdm_Jobtypes_Bool_Exp>;
  _or?: Maybe<Array<Hr_Cdm_Jobtypes_Bool_Exp>>;
  _ownerid_value?: Maybe<Uuid_Comparison_Exp>;
  _owningbusinessunit_value?: Maybe<Uuid_Comparison_Exp>;
  _owningteam_value?: Maybe<String_Comparison_Exp>;
  _owninguser_value?: Maybe<Uuid_Comparison_Exp>;
  cdm_description?: Maybe<String_Comparison_Exp>;
  cdm_exemptstatus?: Maybe<String_Comparison_Exp>;
  cdm_jobs?: Maybe<Hr_Cdm_Jobs_Bool_Exp>;
  cdm_jobtypeid?: Maybe<Uuid_Comparison_Exp>;
  cdm_name?: Maybe<String_Comparison_Exp>;
  createdon?: Maybe<Timestamptz_Comparison_Exp>;
  importsequencenumber?: Maybe<String_Comparison_Exp>;
  modifiedon?: Maybe<Timestamptz_Comparison_Exp>;
  overriddencreatedon?: Maybe<Timestamptz_Comparison_Exp>;
  statecode?: Maybe<String_Comparison_Exp>;
  statuscode?: Maybe<String_Comparison_Exp>;
  timezoneruleversionnumber?: Maybe<String_Comparison_Exp>;
  utcconversiontimezonecode?: Maybe<String_Comparison_Exp>;
  versionnumber?: Maybe<String_Comparison_Exp>;
};

/** input type for inserting data into table "hr.cdm_jobtypes" */
export type Hr_Cdm_Jobtypes_Insert_Input = {
  _createdby_value?: Maybe<Scalars['uuid']>;
  _createdonbehalfby_value?: Maybe<Scalars['timestamptz']>;
  _modifiedby_value?: Maybe<Scalars['uuid']>;
  _modifiedonbehalfby_value?: Maybe<Scalars['timestamptz']>;
  _ownerid_value?: Maybe<Scalars['uuid']>;
  _owningbusinessunit_value?: Maybe<Scalars['uuid']>;
  _owningteam_value?: Maybe<Scalars['String']>;
  _owninguser_value?: Maybe<Scalars['uuid']>;
  cdm_description?: Maybe<Scalars['String']>;
  cdm_exemptstatus?: Maybe<Scalars['String']>;
  cdm_jobs?: Maybe<Hr_Cdm_Jobs_Arr_Rel_Insert_Input>;
  cdm_jobtypeid?: Maybe<Scalars['uuid']>;
  cdm_name?: Maybe<Scalars['String']>;
  createdon?: Maybe<Scalars['timestamptz']>;
  importsequencenumber?: Maybe<Scalars['String']>;
  modifiedon?: Maybe<Scalars['timestamptz']>;
  overriddencreatedon?: Maybe<Scalars['timestamptz']>;
  statecode?: Maybe<Scalars['String']>;
  statuscode?: Maybe<Scalars['String']>;
  timezoneruleversionnumber?: Maybe<Scalars['String']>;
  utcconversiontimezonecode?: Maybe<Scalars['String']>;
  versionnumber?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Hr_Cdm_Jobtypes_Max_Fields = {
  _createdby_value?: Maybe<Scalars['uuid']>;
  _createdonbehalfby_value?: Maybe<Scalars['timestamptz']>;
  _modifiedby_value?: Maybe<Scalars['uuid']>;
  _modifiedonbehalfby_value?: Maybe<Scalars['timestamptz']>;
  _ownerid_value?: Maybe<Scalars['uuid']>;
  _owningbusinessunit_value?: Maybe<Scalars['uuid']>;
  _owningteam_value?: Maybe<Scalars['String']>;
  _owninguser_value?: Maybe<Scalars['uuid']>;
  cdm_description?: Maybe<Scalars['String']>;
  cdm_exemptstatus?: Maybe<Scalars['String']>;
  cdm_jobtypeid?: Maybe<Scalars['uuid']>;
  cdm_name?: Maybe<Scalars['String']>;
  createdon?: Maybe<Scalars['timestamptz']>;
  importsequencenumber?: Maybe<Scalars['String']>;
  modifiedon?: Maybe<Scalars['timestamptz']>;
  overriddencreatedon?: Maybe<Scalars['timestamptz']>;
  statecode?: Maybe<Scalars['String']>;
  statuscode?: Maybe<Scalars['String']>;
  timezoneruleversionnumber?: Maybe<Scalars['String']>;
  utcconversiontimezonecode?: Maybe<Scalars['String']>;
  versionnumber?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Hr_Cdm_Jobtypes_Min_Fields = {
  _createdby_value?: Maybe<Scalars['uuid']>;
  _createdonbehalfby_value?: Maybe<Scalars['timestamptz']>;
  _modifiedby_value?: Maybe<Scalars['uuid']>;
  _modifiedonbehalfby_value?: Maybe<Scalars['timestamptz']>;
  _ownerid_value?: Maybe<Scalars['uuid']>;
  _owningbusinessunit_value?: Maybe<Scalars['uuid']>;
  _owningteam_value?: Maybe<Scalars['String']>;
  _owninguser_value?: Maybe<Scalars['uuid']>;
  cdm_description?: Maybe<Scalars['String']>;
  cdm_exemptstatus?: Maybe<Scalars['String']>;
  cdm_jobtypeid?: Maybe<Scalars['uuid']>;
  cdm_name?: Maybe<Scalars['String']>;
  createdon?: Maybe<Scalars['timestamptz']>;
  importsequencenumber?: Maybe<Scalars['String']>;
  modifiedon?: Maybe<Scalars['timestamptz']>;
  overriddencreatedon?: Maybe<Scalars['timestamptz']>;
  statecode?: Maybe<Scalars['String']>;
  statuscode?: Maybe<Scalars['String']>;
  timezoneruleversionnumber?: Maybe<Scalars['String']>;
  utcconversiontimezonecode?: Maybe<Scalars['String']>;
  versionnumber?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "hr.cdm_jobtypes" */
export type Hr_Cdm_Jobtypes_Mutation_Response = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Hr_Cdm_Jobtypes>;
};

/** input type for inserting object relation for remote table "hr.cdm_jobtypes" */
export type Hr_Cdm_Jobtypes_Obj_Rel_Insert_Input = {
  data: Hr_Cdm_Jobtypes_Insert_Input;
};

/** Ordering options when selecting data from "hr.cdm_jobtypes". */
export type Hr_Cdm_Jobtypes_Order_By = {
  _createdby_value?: Maybe<Order_By>;
  _createdonbehalfby_value?: Maybe<Order_By>;
  _modifiedby_value?: Maybe<Order_By>;
  _modifiedonbehalfby_value?: Maybe<Order_By>;
  _ownerid_value?: Maybe<Order_By>;
  _owningbusinessunit_value?: Maybe<Order_By>;
  _owningteam_value?: Maybe<Order_By>;
  _owninguser_value?: Maybe<Order_By>;
  cdm_description?: Maybe<Order_By>;
  cdm_exemptstatus?: Maybe<Order_By>;
  cdm_jobs_aggregate?: Maybe<Hr_Cdm_Jobs_Aggregate_Order_By>;
  cdm_jobtypeid?: Maybe<Order_By>;
  cdm_name?: Maybe<Order_By>;
  createdon?: Maybe<Order_By>;
  importsequencenumber?: Maybe<Order_By>;
  modifiedon?: Maybe<Order_By>;
  overriddencreatedon?: Maybe<Order_By>;
  statecode?: Maybe<Order_By>;
  statuscode?: Maybe<Order_By>;
  timezoneruleversionnumber?: Maybe<Order_By>;
  utcconversiontimezonecode?: Maybe<Order_By>;
  versionnumber?: Maybe<Order_By>;
};

/** select columns of table "hr.cdm_jobtypes" */
export enum Hr_Cdm_Jobtypes_Select_Column {
  /** column name */
  CreatedbyValue = '_createdby_value',
  /** column name */
  CreatedonbehalfbyValue = '_createdonbehalfby_value',
  /** column name */
  ModifiedbyValue = '_modifiedby_value',
  /** column name */
  ModifiedonbehalfbyValue = '_modifiedonbehalfby_value',
  /** column name */
  OwneridValue = '_ownerid_value',
  /** column name */
  OwningbusinessunitValue = '_owningbusinessunit_value',
  /** column name */
  OwningteamValue = '_owningteam_value',
  /** column name */
  OwninguserValue = '_owninguser_value',
  /** column name */
  CdmDescription = 'cdm_description',
  /** column name */
  CdmExemptstatus = 'cdm_exemptstatus',
  /** column name */
  CdmJobtypeid = 'cdm_jobtypeid',
  /** column name */
  CdmName = 'cdm_name',
  /** column name */
  Createdon = 'createdon',
  /** column name */
  Importsequencenumber = 'importsequencenumber',
  /** column name */
  Modifiedon = 'modifiedon',
  /** column name */
  Overriddencreatedon = 'overriddencreatedon',
  /** column name */
  Statecode = 'statecode',
  /** column name */
  Statuscode = 'statuscode',
  /** column name */
  Timezoneruleversionnumber = 'timezoneruleversionnumber',
  /** column name */
  Utcconversiontimezonecode = 'utcconversiontimezonecode',
  /** column name */
  Versionnumber = 'versionnumber'
}

/** input type for updating data in table "hr.cdm_jobtypes" */
export type Hr_Cdm_Jobtypes_Set_Input = {
  _createdby_value?: Maybe<Scalars['uuid']>;
  _createdonbehalfby_value?: Maybe<Scalars['timestamptz']>;
  _modifiedby_value?: Maybe<Scalars['uuid']>;
  _modifiedonbehalfby_value?: Maybe<Scalars['timestamptz']>;
  _ownerid_value?: Maybe<Scalars['uuid']>;
  _owningbusinessunit_value?: Maybe<Scalars['uuid']>;
  _owningteam_value?: Maybe<Scalars['String']>;
  _owninguser_value?: Maybe<Scalars['uuid']>;
  cdm_description?: Maybe<Scalars['String']>;
  cdm_exemptstatus?: Maybe<Scalars['String']>;
  cdm_jobtypeid?: Maybe<Scalars['uuid']>;
  cdm_name?: Maybe<Scalars['String']>;
  createdon?: Maybe<Scalars['timestamptz']>;
  importsequencenumber?: Maybe<Scalars['String']>;
  modifiedon?: Maybe<Scalars['timestamptz']>;
  overriddencreatedon?: Maybe<Scalars['timestamptz']>;
  statecode?: Maybe<Scalars['String']>;
  statuscode?: Maybe<Scalars['String']>;
  timezoneruleversionnumber?: Maybe<Scalars['String']>;
  utcconversiontimezonecode?: Maybe<Scalars['String']>;
  versionnumber?: Maybe<Scalars['String']>;
};

/** columns and relationships of "hr.cdm_leaveenrollments" */
export type Hr_Cdm_Leaveenrollments = {
  _cdm_companyid_value?: Maybe<Scalars['uuid']>;
  _cdm_leaveplanid_value?: Maybe<Scalars['uuid']>;
  _cdm_workerid_value?: Maybe<Scalars['uuid']>;
  _createdby_value?: Maybe<Scalars['uuid']>;
  _createdonbehalfby_value?: Maybe<Scalars['timestamptz']>;
  _modifiedby_value?: Maybe<Scalars['uuid']>;
  _modifiedonbehalfby_value?: Maybe<Scalars['timestamptz']>;
  _ownerid_value?: Maybe<Scalars['uuid']>;
  _owningbusinessunit_value?: Maybe<Scalars['uuid']>;
  _owningteam_value?: Maybe<Scalars['String']>;
  _owninguser_value?: Maybe<Scalars['uuid']>;
  cdm_accrualdatebasis?: Maybe<Scalars['timestamptz']>;
  cdm_accrualstartdate?: Maybe<Scalars['timestamptz']>;
  cdm_customdate?: Maybe<Scalars['timestamptz']>;
  cdm_enddate?: Maybe<Scalars['timestamptz']>;
  cdm_isaccrualsuspended?: Maybe<Scalars['String']>;
  cdm_leaveenrollmentid: Scalars['uuid'];
  cdm_leaveenrollmentnumber?: Maybe<Scalars['String']>;
  /** An object relationship */
  cdm_leaveplan?: Maybe<Hr_Cdm_Leaveplans>;
  cdm_startdate?: Maybe<Scalars['timestamptz']>;
  cdm_tierbasis?: Maybe<Scalars['String']>;
  /** An object relationship */
  cdm_worker?: Maybe<Hr_Cdm_Workers>;
  createdon?: Maybe<Scalars['timestamptz']>;
  importsequencenumber?: Maybe<Scalars['String']>;
  modifiedon?: Maybe<Scalars['timestamptz']>;
  overriddencreatedon?: Maybe<Scalars['timestamptz']>;
  statecode?: Maybe<Scalars['String']>;
  statuscode?: Maybe<Scalars['String']>;
  timezoneruleversionnumber?: Maybe<Scalars['String']>;
  utcconversiontimezonecode?: Maybe<Scalars['String']>;
  versionnumber?: Maybe<Scalars['String']>;
};

/** aggregated selection of "hr.cdm_leaveenrollments" */
export type Hr_Cdm_Leaveenrollments_Aggregate = {
  aggregate?: Maybe<Hr_Cdm_Leaveenrollments_Aggregate_Fields>;
  nodes: Array<Hr_Cdm_Leaveenrollments>;
};

/** aggregate fields of "hr.cdm_leaveenrollments" */
export type Hr_Cdm_Leaveenrollments_Aggregate_Fields = {
  count: Scalars['Int'];
  max?: Maybe<Hr_Cdm_Leaveenrollments_Max_Fields>;
  min?: Maybe<Hr_Cdm_Leaveenrollments_Min_Fields>;
};


/** aggregate fields of "hr.cdm_leaveenrollments" */
export type Hr_Cdm_Leaveenrollments_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Hr_Cdm_Leaveenrollments_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "hr.cdm_leaveenrollments" */
export type Hr_Cdm_Leaveenrollments_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Hr_Cdm_Leaveenrollments_Max_Order_By>;
  min?: Maybe<Hr_Cdm_Leaveenrollments_Min_Order_By>;
};

/** input type for inserting array relation for remote table "hr.cdm_leaveenrollments" */
export type Hr_Cdm_Leaveenrollments_Arr_Rel_Insert_Input = {
  data: Array<Hr_Cdm_Leaveenrollments_Insert_Input>;
};

/** Boolean expression to filter rows from the table "hr.cdm_leaveenrollments". All fields are combined with a logical 'AND'. */
export type Hr_Cdm_Leaveenrollments_Bool_Exp = {
  _and?: Maybe<Array<Hr_Cdm_Leaveenrollments_Bool_Exp>>;
  _cdm_companyid_value?: Maybe<Uuid_Comparison_Exp>;
  _cdm_leaveplanid_value?: Maybe<Uuid_Comparison_Exp>;
  _cdm_workerid_value?: Maybe<Uuid_Comparison_Exp>;
  _createdby_value?: Maybe<Uuid_Comparison_Exp>;
  _createdonbehalfby_value?: Maybe<Timestamptz_Comparison_Exp>;
  _modifiedby_value?: Maybe<Uuid_Comparison_Exp>;
  _modifiedonbehalfby_value?: Maybe<Timestamptz_Comparison_Exp>;
  _not?: Maybe<Hr_Cdm_Leaveenrollments_Bool_Exp>;
  _or?: Maybe<Array<Hr_Cdm_Leaveenrollments_Bool_Exp>>;
  _ownerid_value?: Maybe<Uuid_Comparison_Exp>;
  _owningbusinessunit_value?: Maybe<Uuid_Comparison_Exp>;
  _owningteam_value?: Maybe<String_Comparison_Exp>;
  _owninguser_value?: Maybe<Uuid_Comparison_Exp>;
  cdm_accrualdatebasis?: Maybe<Timestamptz_Comparison_Exp>;
  cdm_accrualstartdate?: Maybe<Timestamptz_Comparison_Exp>;
  cdm_customdate?: Maybe<Timestamptz_Comparison_Exp>;
  cdm_enddate?: Maybe<Timestamptz_Comparison_Exp>;
  cdm_isaccrualsuspended?: Maybe<String_Comparison_Exp>;
  cdm_leaveenrollmentid?: Maybe<Uuid_Comparison_Exp>;
  cdm_leaveenrollmentnumber?: Maybe<String_Comparison_Exp>;
  cdm_leaveplan?: Maybe<Hr_Cdm_Leaveplans_Bool_Exp>;
  cdm_startdate?: Maybe<Timestamptz_Comparison_Exp>;
  cdm_tierbasis?: Maybe<String_Comparison_Exp>;
  cdm_worker?: Maybe<Hr_Cdm_Workers_Bool_Exp>;
  createdon?: Maybe<Timestamptz_Comparison_Exp>;
  importsequencenumber?: Maybe<String_Comparison_Exp>;
  modifiedon?: Maybe<Timestamptz_Comparison_Exp>;
  overriddencreatedon?: Maybe<Timestamptz_Comparison_Exp>;
  statecode?: Maybe<String_Comparison_Exp>;
  statuscode?: Maybe<String_Comparison_Exp>;
  timezoneruleversionnumber?: Maybe<String_Comparison_Exp>;
  utcconversiontimezonecode?: Maybe<String_Comparison_Exp>;
  versionnumber?: Maybe<String_Comparison_Exp>;
};

/** input type for inserting data into table "hr.cdm_leaveenrollments" */
export type Hr_Cdm_Leaveenrollments_Insert_Input = {
  _cdm_companyid_value?: Maybe<Scalars['uuid']>;
  _cdm_leaveplanid_value?: Maybe<Scalars['uuid']>;
  _cdm_workerid_value?: Maybe<Scalars['uuid']>;
  _createdby_value?: Maybe<Scalars['uuid']>;
  _createdonbehalfby_value?: Maybe<Scalars['timestamptz']>;
  _modifiedby_value?: Maybe<Scalars['uuid']>;
  _modifiedonbehalfby_value?: Maybe<Scalars['timestamptz']>;
  _ownerid_value?: Maybe<Scalars['uuid']>;
  _owningbusinessunit_value?: Maybe<Scalars['uuid']>;
  _owningteam_value?: Maybe<Scalars['String']>;
  _owninguser_value?: Maybe<Scalars['uuid']>;
  cdm_accrualdatebasis?: Maybe<Scalars['timestamptz']>;
  cdm_accrualstartdate?: Maybe<Scalars['timestamptz']>;
  cdm_customdate?: Maybe<Scalars['timestamptz']>;
  cdm_enddate?: Maybe<Scalars['timestamptz']>;
  cdm_isaccrualsuspended?: Maybe<Scalars['String']>;
  cdm_leaveenrollmentid?: Maybe<Scalars['uuid']>;
  cdm_leaveenrollmentnumber?: Maybe<Scalars['String']>;
  cdm_leaveplan?: Maybe<Hr_Cdm_Leaveplans_Obj_Rel_Insert_Input>;
  cdm_startdate?: Maybe<Scalars['timestamptz']>;
  cdm_tierbasis?: Maybe<Scalars['String']>;
  cdm_worker?: Maybe<Hr_Cdm_Workers_Obj_Rel_Insert_Input>;
  createdon?: Maybe<Scalars['timestamptz']>;
  importsequencenumber?: Maybe<Scalars['String']>;
  modifiedon?: Maybe<Scalars['timestamptz']>;
  overriddencreatedon?: Maybe<Scalars['timestamptz']>;
  statecode?: Maybe<Scalars['String']>;
  statuscode?: Maybe<Scalars['String']>;
  timezoneruleversionnumber?: Maybe<Scalars['String']>;
  utcconversiontimezonecode?: Maybe<Scalars['String']>;
  versionnumber?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Hr_Cdm_Leaveenrollments_Max_Fields = {
  _cdm_companyid_value?: Maybe<Scalars['uuid']>;
  _cdm_leaveplanid_value?: Maybe<Scalars['uuid']>;
  _cdm_workerid_value?: Maybe<Scalars['uuid']>;
  _createdby_value?: Maybe<Scalars['uuid']>;
  _createdonbehalfby_value?: Maybe<Scalars['timestamptz']>;
  _modifiedby_value?: Maybe<Scalars['uuid']>;
  _modifiedonbehalfby_value?: Maybe<Scalars['timestamptz']>;
  _ownerid_value?: Maybe<Scalars['uuid']>;
  _owningbusinessunit_value?: Maybe<Scalars['uuid']>;
  _owningteam_value?: Maybe<Scalars['String']>;
  _owninguser_value?: Maybe<Scalars['uuid']>;
  cdm_accrualdatebasis?: Maybe<Scalars['timestamptz']>;
  cdm_accrualstartdate?: Maybe<Scalars['timestamptz']>;
  cdm_customdate?: Maybe<Scalars['timestamptz']>;
  cdm_enddate?: Maybe<Scalars['timestamptz']>;
  cdm_isaccrualsuspended?: Maybe<Scalars['String']>;
  cdm_leaveenrollmentid?: Maybe<Scalars['uuid']>;
  cdm_leaveenrollmentnumber?: Maybe<Scalars['String']>;
  cdm_startdate?: Maybe<Scalars['timestamptz']>;
  cdm_tierbasis?: Maybe<Scalars['String']>;
  createdon?: Maybe<Scalars['timestamptz']>;
  importsequencenumber?: Maybe<Scalars['String']>;
  modifiedon?: Maybe<Scalars['timestamptz']>;
  overriddencreatedon?: Maybe<Scalars['timestamptz']>;
  statecode?: Maybe<Scalars['String']>;
  statuscode?: Maybe<Scalars['String']>;
  timezoneruleversionnumber?: Maybe<Scalars['String']>;
  utcconversiontimezonecode?: Maybe<Scalars['String']>;
  versionnumber?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "hr.cdm_leaveenrollments" */
export type Hr_Cdm_Leaveenrollments_Max_Order_By = {
  _cdm_companyid_value?: Maybe<Order_By>;
  _cdm_leaveplanid_value?: Maybe<Order_By>;
  _cdm_workerid_value?: Maybe<Order_By>;
  _createdby_value?: Maybe<Order_By>;
  _createdonbehalfby_value?: Maybe<Order_By>;
  _modifiedby_value?: Maybe<Order_By>;
  _modifiedonbehalfby_value?: Maybe<Order_By>;
  _ownerid_value?: Maybe<Order_By>;
  _owningbusinessunit_value?: Maybe<Order_By>;
  _owningteam_value?: Maybe<Order_By>;
  _owninguser_value?: Maybe<Order_By>;
  cdm_accrualdatebasis?: Maybe<Order_By>;
  cdm_accrualstartdate?: Maybe<Order_By>;
  cdm_customdate?: Maybe<Order_By>;
  cdm_enddate?: Maybe<Order_By>;
  cdm_isaccrualsuspended?: Maybe<Order_By>;
  cdm_leaveenrollmentid?: Maybe<Order_By>;
  cdm_leaveenrollmentnumber?: Maybe<Order_By>;
  cdm_startdate?: Maybe<Order_By>;
  cdm_tierbasis?: Maybe<Order_By>;
  createdon?: Maybe<Order_By>;
  importsequencenumber?: Maybe<Order_By>;
  modifiedon?: Maybe<Order_By>;
  overriddencreatedon?: Maybe<Order_By>;
  statecode?: Maybe<Order_By>;
  statuscode?: Maybe<Order_By>;
  timezoneruleversionnumber?: Maybe<Order_By>;
  utcconversiontimezonecode?: Maybe<Order_By>;
  versionnumber?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Hr_Cdm_Leaveenrollments_Min_Fields = {
  _cdm_companyid_value?: Maybe<Scalars['uuid']>;
  _cdm_leaveplanid_value?: Maybe<Scalars['uuid']>;
  _cdm_workerid_value?: Maybe<Scalars['uuid']>;
  _createdby_value?: Maybe<Scalars['uuid']>;
  _createdonbehalfby_value?: Maybe<Scalars['timestamptz']>;
  _modifiedby_value?: Maybe<Scalars['uuid']>;
  _modifiedonbehalfby_value?: Maybe<Scalars['timestamptz']>;
  _ownerid_value?: Maybe<Scalars['uuid']>;
  _owningbusinessunit_value?: Maybe<Scalars['uuid']>;
  _owningteam_value?: Maybe<Scalars['String']>;
  _owninguser_value?: Maybe<Scalars['uuid']>;
  cdm_accrualdatebasis?: Maybe<Scalars['timestamptz']>;
  cdm_accrualstartdate?: Maybe<Scalars['timestamptz']>;
  cdm_customdate?: Maybe<Scalars['timestamptz']>;
  cdm_enddate?: Maybe<Scalars['timestamptz']>;
  cdm_isaccrualsuspended?: Maybe<Scalars['String']>;
  cdm_leaveenrollmentid?: Maybe<Scalars['uuid']>;
  cdm_leaveenrollmentnumber?: Maybe<Scalars['String']>;
  cdm_startdate?: Maybe<Scalars['timestamptz']>;
  cdm_tierbasis?: Maybe<Scalars['String']>;
  createdon?: Maybe<Scalars['timestamptz']>;
  importsequencenumber?: Maybe<Scalars['String']>;
  modifiedon?: Maybe<Scalars['timestamptz']>;
  overriddencreatedon?: Maybe<Scalars['timestamptz']>;
  statecode?: Maybe<Scalars['String']>;
  statuscode?: Maybe<Scalars['String']>;
  timezoneruleversionnumber?: Maybe<Scalars['String']>;
  utcconversiontimezonecode?: Maybe<Scalars['String']>;
  versionnumber?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "hr.cdm_leaveenrollments" */
export type Hr_Cdm_Leaveenrollments_Min_Order_By = {
  _cdm_companyid_value?: Maybe<Order_By>;
  _cdm_leaveplanid_value?: Maybe<Order_By>;
  _cdm_workerid_value?: Maybe<Order_By>;
  _createdby_value?: Maybe<Order_By>;
  _createdonbehalfby_value?: Maybe<Order_By>;
  _modifiedby_value?: Maybe<Order_By>;
  _modifiedonbehalfby_value?: Maybe<Order_By>;
  _ownerid_value?: Maybe<Order_By>;
  _owningbusinessunit_value?: Maybe<Order_By>;
  _owningteam_value?: Maybe<Order_By>;
  _owninguser_value?: Maybe<Order_By>;
  cdm_accrualdatebasis?: Maybe<Order_By>;
  cdm_accrualstartdate?: Maybe<Order_By>;
  cdm_customdate?: Maybe<Order_By>;
  cdm_enddate?: Maybe<Order_By>;
  cdm_isaccrualsuspended?: Maybe<Order_By>;
  cdm_leaveenrollmentid?: Maybe<Order_By>;
  cdm_leaveenrollmentnumber?: Maybe<Order_By>;
  cdm_startdate?: Maybe<Order_By>;
  cdm_tierbasis?: Maybe<Order_By>;
  createdon?: Maybe<Order_By>;
  importsequencenumber?: Maybe<Order_By>;
  modifiedon?: Maybe<Order_By>;
  overriddencreatedon?: Maybe<Order_By>;
  statecode?: Maybe<Order_By>;
  statuscode?: Maybe<Order_By>;
  timezoneruleversionnumber?: Maybe<Order_By>;
  utcconversiontimezonecode?: Maybe<Order_By>;
  versionnumber?: Maybe<Order_By>;
};

/** response of any mutation on the table "hr.cdm_leaveenrollments" */
export type Hr_Cdm_Leaveenrollments_Mutation_Response = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Hr_Cdm_Leaveenrollments>;
};

/** Ordering options when selecting data from "hr.cdm_leaveenrollments". */
export type Hr_Cdm_Leaveenrollments_Order_By = {
  _cdm_companyid_value?: Maybe<Order_By>;
  _cdm_leaveplanid_value?: Maybe<Order_By>;
  _cdm_workerid_value?: Maybe<Order_By>;
  _createdby_value?: Maybe<Order_By>;
  _createdonbehalfby_value?: Maybe<Order_By>;
  _modifiedby_value?: Maybe<Order_By>;
  _modifiedonbehalfby_value?: Maybe<Order_By>;
  _ownerid_value?: Maybe<Order_By>;
  _owningbusinessunit_value?: Maybe<Order_By>;
  _owningteam_value?: Maybe<Order_By>;
  _owninguser_value?: Maybe<Order_By>;
  cdm_accrualdatebasis?: Maybe<Order_By>;
  cdm_accrualstartdate?: Maybe<Order_By>;
  cdm_customdate?: Maybe<Order_By>;
  cdm_enddate?: Maybe<Order_By>;
  cdm_isaccrualsuspended?: Maybe<Order_By>;
  cdm_leaveenrollmentid?: Maybe<Order_By>;
  cdm_leaveenrollmentnumber?: Maybe<Order_By>;
  cdm_leaveplan?: Maybe<Hr_Cdm_Leaveplans_Order_By>;
  cdm_startdate?: Maybe<Order_By>;
  cdm_tierbasis?: Maybe<Order_By>;
  cdm_worker?: Maybe<Hr_Cdm_Workers_Order_By>;
  createdon?: Maybe<Order_By>;
  importsequencenumber?: Maybe<Order_By>;
  modifiedon?: Maybe<Order_By>;
  overriddencreatedon?: Maybe<Order_By>;
  statecode?: Maybe<Order_By>;
  statuscode?: Maybe<Order_By>;
  timezoneruleversionnumber?: Maybe<Order_By>;
  utcconversiontimezonecode?: Maybe<Order_By>;
  versionnumber?: Maybe<Order_By>;
};

/** select columns of table "hr.cdm_leaveenrollments" */
export enum Hr_Cdm_Leaveenrollments_Select_Column {
  /** column name */
  CdmCompanyidValue = '_cdm_companyid_value',
  /** column name */
  CdmLeaveplanidValue = '_cdm_leaveplanid_value',
  /** column name */
  CdmWorkeridValue = '_cdm_workerid_value',
  /** column name */
  CreatedbyValue = '_createdby_value',
  /** column name */
  CreatedonbehalfbyValue = '_createdonbehalfby_value',
  /** column name */
  ModifiedbyValue = '_modifiedby_value',
  /** column name */
  ModifiedonbehalfbyValue = '_modifiedonbehalfby_value',
  /** column name */
  OwneridValue = '_ownerid_value',
  /** column name */
  OwningbusinessunitValue = '_owningbusinessunit_value',
  /** column name */
  OwningteamValue = '_owningteam_value',
  /** column name */
  OwninguserValue = '_owninguser_value',
  /** column name */
  CdmAccrualdatebasis = 'cdm_accrualdatebasis',
  /** column name */
  CdmAccrualstartdate = 'cdm_accrualstartdate',
  /** column name */
  CdmCustomdate = 'cdm_customdate',
  /** column name */
  CdmEnddate = 'cdm_enddate',
  /** column name */
  CdmIsaccrualsuspended = 'cdm_isaccrualsuspended',
  /** column name */
  CdmLeaveenrollmentid = 'cdm_leaveenrollmentid',
  /** column name */
  CdmLeaveenrollmentnumber = 'cdm_leaveenrollmentnumber',
  /** column name */
  CdmStartdate = 'cdm_startdate',
  /** column name */
  CdmTierbasis = 'cdm_tierbasis',
  /** column name */
  Createdon = 'createdon',
  /** column name */
  Importsequencenumber = 'importsequencenumber',
  /** column name */
  Modifiedon = 'modifiedon',
  /** column name */
  Overriddencreatedon = 'overriddencreatedon',
  /** column name */
  Statecode = 'statecode',
  /** column name */
  Statuscode = 'statuscode',
  /** column name */
  Timezoneruleversionnumber = 'timezoneruleversionnumber',
  /** column name */
  Utcconversiontimezonecode = 'utcconversiontimezonecode',
  /** column name */
  Versionnumber = 'versionnumber'
}

/** input type for updating data in table "hr.cdm_leaveenrollments" */
export type Hr_Cdm_Leaveenrollments_Set_Input = {
  _cdm_companyid_value?: Maybe<Scalars['uuid']>;
  _cdm_leaveplanid_value?: Maybe<Scalars['uuid']>;
  _cdm_workerid_value?: Maybe<Scalars['uuid']>;
  _createdby_value?: Maybe<Scalars['uuid']>;
  _createdonbehalfby_value?: Maybe<Scalars['timestamptz']>;
  _modifiedby_value?: Maybe<Scalars['uuid']>;
  _modifiedonbehalfby_value?: Maybe<Scalars['timestamptz']>;
  _ownerid_value?: Maybe<Scalars['uuid']>;
  _owningbusinessunit_value?: Maybe<Scalars['uuid']>;
  _owningteam_value?: Maybe<Scalars['String']>;
  _owninguser_value?: Maybe<Scalars['uuid']>;
  cdm_accrualdatebasis?: Maybe<Scalars['timestamptz']>;
  cdm_accrualstartdate?: Maybe<Scalars['timestamptz']>;
  cdm_customdate?: Maybe<Scalars['timestamptz']>;
  cdm_enddate?: Maybe<Scalars['timestamptz']>;
  cdm_isaccrualsuspended?: Maybe<Scalars['String']>;
  cdm_leaveenrollmentid?: Maybe<Scalars['uuid']>;
  cdm_leaveenrollmentnumber?: Maybe<Scalars['String']>;
  cdm_startdate?: Maybe<Scalars['timestamptz']>;
  cdm_tierbasis?: Maybe<Scalars['String']>;
  createdon?: Maybe<Scalars['timestamptz']>;
  importsequencenumber?: Maybe<Scalars['String']>;
  modifiedon?: Maybe<Scalars['timestamptz']>;
  overriddencreatedon?: Maybe<Scalars['timestamptz']>;
  statecode?: Maybe<Scalars['String']>;
  statuscode?: Maybe<Scalars['String']>;
  timezoneruleversionnumber?: Maybe<Scalars['String']>;
  utcconversiontimezonecode?: Maybe<Scalars['String']>;
  versionnumber?: Maybe<Scalars['String']>;
};

/** columns and relationships of "hr.cdm_leaveplans" */
export type Hr_Cdm_Leaveplans = {
  _cdm_companyid_value?: Maybe<Scalars['uuid']>;
  _cdm_leavetypeid_value?: Maybe<Scalars['uuid']>;
  _createdby_value?: Maybe<Scalars['uuid']>;
  _createdonbehalfby_value?: Maybe<Scalars['timestamptz']>;
  _modifiedby_value?: Maybe<Scalars['uuid']>;
  _modifiedonbehalfby_value?: Maybe<Scalars['timestamptz']>;
  _ownerid_value?: Maybe<Scalars['uuid']>;
  _owningbusinessunit_value?: Maybe<Scalars['uuid']>;
  _owningteam_value?: Maybe<Scalars['String']>;
  _owninguser_value?: Maybe<Scalars['uuid']>;
  cdm_accrualfrequency?: Maybe<Scalars['String']>;
  cdm_description?: Maybe<Scalars['String']>;
  /** An array relationship */
  cdm_leaveenrollments: Array<Hr_Cdm_Leaveenrollments>;
  /** An aggregate relationship */
  cdm_leaveenrollments_aggregate: Hr_Cdm_Leaveenrollments_Aggregate;
  cdm_leaveplanid: Scalars['uuid'];
  cdm_name?: Maybe<Scalars['String']>;
  cdm_startdate?: Maybe<Scalars['timestamptz']>;
  createdon?: Maybe<Scalars['timestamptz']>;
  importsequencenumber?: Maybe<Scalars['String']>;
  modifiedon?: Maybe<Scalars['timestamptz']>;
  overriddencreatedon?: Maybe<Scalars['timestamptz']>;
  statecode?: Maybe<Scalars['String']>;
  statuscode?: Maybe<Scalars['String']>;
  timezoneruleversionnumber?: Maybe<Scalars['String']>;
  utcconversiontimezonecode?: Maybe<Scalars['String']>;
  versionnumber?: Maybe<Scalars['String']>;
};


/** columns and relationships of "hr.cdm_leaveplans" */
export type Hr_Cdm_LeaveplansCdm_LeaveenrollmentsArgs = {
  distinct_on?: Maybe<Array<Hr_Cdm_Leaveenrollments_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Hr_Cdm_Leaveenrollments_Order_By>>;
  where?: Maybe<Hr_Cdm_Leaveenrollments_Bool_Exp>;
};


/** columns and relationships of "hr.cdm_leaveplans" */
export type Hr_Cdm_LeaveplansCdm_Leaveenrollments_AggregateArgs = {
  distinct_on?: Maybe<Array<Hr_Cdm_Leaveenrollments_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Hr_Cdm_Leaveenrollments_Order_By>>;
  where?: Maybe<Hr_Cdm_Leaveenrollments_Bool_Exp>;
};

/** aggregated selection of "hr.cdm_leaveplans" */
export type Hr_Cdm_Leaveplans_Aggregate = {
  aggregate?: Maybe<Hr_Cdm_Leaveplans_Aggregate_Fields>;
  nodes: Array<Hr_Cdm_Leaveplans>;
};

/** aggregate fields of "hr.cdm_leaveplans" */
export type Hr_Cdm_Leaveplans_Aggregate_Fields = {
  count: Scalars['Int'];
  max?: Maybe<Hr_Cdm_Leaveplans_Max_Fields>;
  min?: Maybe<Hr_Cdm_Leaveplans_Min_Fields>;
};


/** aggregate fields of "hr.cdm_leaveplans" */
export type Hr_Cdm_Leaveplans_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Hr_Cdm_Leaveplans_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "hr.cdm_leaveplans". All fields are combined with a logical 'AND'. */
export type Hr_Cdm_Leaveplans_Bool_Exp = {
  _and?: Maybe<Array<Hr_Cdm_Leaveplans_Bool_Exp>>;
  _cdm_companyid_value?: Maybe<Uuid_Comparison_Exp>;
  _cdm_leavetypeid_value?: Maybe<Uuid_Comparison_Exp>;
  _createdby_value?: Maybe<Uuid_Comparison_Exp>;
  _createdonbehalfby_value?: Maybe<Timestamptz_Comparison_Exp>;
  _modifiedby_value?: Maybe<Uuid_Comparison_Exp>;
  _modifiedonbehalfby_value?: Maybe<Timestamptz_Comparison_Exp>;
  _not?: Maybe<Hr_Cdm_Leaveplans_Bool_Exp>;
  _or?: Maybe<Array<Hr_Cdm_Leaveplans_Bool_Exp>>;
  _ownerid_value?: Maybe<Uuid_Comparison_Exp>;
  _owningbusinessunit_value?: Maybe<Uuid_Comparison_Exp>;
  _owningteam_value?: Maybe<String_Comparison_Exp>;
  _owninguser_value?: Maybe<Uuid_Comparison_Exp>;
  cdm_accrualfrequency?: Maybe<String_Comparison_Exp>;
  cdm_description?: Maybe<String_Comparison_Exp>;
  cdm_leaveenrollments?: Maybe<Hr_Cdm_Leaveenrollments_Bool_Exp>;
  cdm_leaveplanid?: Maybe<Uuid_Comparison_Exp>;
  cdm_name?: Maybe<String_Comparison_Exp>;
  cdm_startdate?: Maybe<Timestamptz_Comparison_Exp>;
  createdon?: Maybe<Timestamptz_Comparison_Exp>;
  importsequencenumber?: Maybe<String_Comparison_Exp>;
  modifiedon?: Maybe<Timestamptz_Comparison_Exp>;
  overriddencreatedon?: Maybe<Timestamptz_Comparison_Exp>;
  statecode?: Maybe<String_Comparison_Exp>;
  statuscode?: Maybe<String_Comparison_Exp>;
  timezoneruleversionnumber?: Maybe<String_Comparison_Exp>;
  utcconversiontimezonecode?: Maybe<String_Comparison_Exp>;
  versionnumber?: Maybe<String_Comparison_Exp>;
};

/** input type for inserting data into table "hr.cdm_leaveplans" */
export type Hr_Cdm_Leaveplans_Insert_Input = {
  _cdm_companyid_value?: Maybe<Scalars['uuid']>;
  _cdm_leavetypeid_value?: Maybe<Scalars['uuid']>;
  _createdby_value?: Maybe<Scalars['uuid']>;
  _createdonbehalfby_value?: Maybe<Scalars['timestamptz']>;
  _modifiedby_value?: Maybe<Scalars['uuid']>;
  _modifiedonbehalfby_value?: Maybe<Scalars['timestamptz']>;
  _ownerid_value?: Maybe<Scalars['uuid']>;
  _owningbusinessunit_value?: Maybe<Scalars['uuid']>;
  _owningteam_value?: Maybe<Scalars['String']>;
  _owninguser_value?: Maybe<Scalars['uuid']>;
  cdm_accrualfrequency?: Maybe<Scalars['String']>;
  cdm_description?: Maybe<Scalars['String']>;
  cdm_leaveenrollments?: Maybe<Hr_Cdm_Leaveenrollments_Arr_Rel_Insert_Input>;
  cdm_leaveplanid?: Maybe<Scalars['uuid']>;
  cdm_name?: Maybe<Scalars['String']>;
  cdm_startdate?: Maybe<Scalars['timestamptz']>;
  createdon?: Maybe<Scalars['timestamptz']>;
  importsequencenumber?: Maybe<Scalars['String']>;
  modifiedon?: Maybe<Scalars['timestamptz']>;
  overriddencreatedon?: Maybe<Scalars['timestamptz']>;
  statecode?: Maybe<Scalars['String']>;
  statuscode?: Maybe<Scalars['String']>;
  timezoneruleversionnumber?: Maybe<Scalars['String']>;
  utcconversiontimezonecode?: Maybe<Scalars['String']>;
  versionnumber?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Hr_Cdm_Leaveplans_Max_Fields = {
  _cdm_companyid_value?: Maybe<Scalars['uuid']>;
  _cdm_leavetypeid_value?: Maybe<Scalars['uuid']>;
  _createdby_value?: Maybe<Scalars['uuid']>;
  _createdonbehalfby_value?: Maybe<Scalars['timestamptz']>;
  _modifiedby_value?: Maybe<Scalars['uuid']>;
  _modifiedonbehalfby_value?: Maybe<Scalars['timestamptz']>;
  _ownerid_value?: Maybe<Scalars['uuid']>;
  _owningbusinessunit_value?: Maybe<Scalars['uuid']>;
  _owningteam_value?: Maybe<Scalars['String']>;
  _owninguser_value?: Maybe<Scalars['uuid']>;
  cdm_accrualfrequency?: Maybe<Scalars['String']>;
  cdm_description?: Maybe<Scalars['String']>;
  cdm_leaveplanid?: Maybe<Scalars['uuid']>;
  cdm_name?: Maybe<Scalars['String']>;
  cdm_startdate?: Maybe<Scalars['timestamptz']>;
  createdon?: Maybe<Scalars['timestamptz']>;
  importsequencenumber?: Maybe<Scalars['String']>;
  modifiedon?: Maybe<Scalars['timestamptz']>;
  overriddencreatedon?: Maybe<Scalars['timestamptz']>;
  statecode?: Maybe<Scalars['String']>;
  statuscode?: Maybe<Scalars['String']>;
  timezoneruleversionnumber?: Maybe<Scalars['String']>;
  utcconversiontimezonecode?: Maybe<Scalars['String']>;
  versionnumber?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Hr_Cdm_Leaveplans_Min_Fields = {
  _cdm_companyid_value?: Maybe<Scalars['uuid']>;
  _cdm_leavetypeid_value?: Maybe<Scalars['uuid']>;
  _createdby_value?: Maybe<Scalars['uuid']>;
  _createdonbehalfby_value?: Maybe<Scalars['timestamptz']>;
  _modifiedby_value?: Maybe<Scalars['uuid']>;
  _modifiedonbehalfby_value?: Maybe<Scalars['timestamptz']>;
  _ownerid_value?: Maybe<Scalars['uuid']>;
  _owningbusinessunit_value?: Maybe<Scalars['uuid']>;
  _owningteam_value?: Maybe<Scalars['String']>;
  _owninguser_value?: Maybe<Scalars['uuid']>;
  cdm_accrualfrequency?: Maybe<Scalars['String']>;
  cdm_description?: Maybe<Scalars['String']>;
  cdm_leaveplanid?: Maybe<Scalars['uuid']>;
  cdm_name?: Maybe<Scalars['String']>;
  cdm_startdate?: Maybe<Scalars['timestamptz']>;
  createdon?: Maybe<Scalars['timestamptz']>;
  importsequencenumber?: Maybe<Scalars['String']>;
  modifiedon?: Maybe<Scalars['timestamptz']>;
  overriddencreatedon?: Maybe<Scalars['timestamptz']>;
  statecode?: Maybe<Scalars['String']>;
  statuscode?: Maybe<Scalars['String']>;
  timezoneruleversionnumber?: Maybe<Scalars['String']>;
  utcconversiontimezonecode?: Maybe<Scalars['String']>;
  versionnumber?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "hr.cdm_leaveplans" */
export type Hr_Cdm_Leaveplans_Mutation_Response = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Hr_Cdm_Leaveplans>;
};

/** input type for inserting object relation for remote table "hr.cdm_leaveplans" */
export type Hr_Cdm_Leaveplans_Obj_Rel_Insert_Input = {
  data: Hr_Cdm_Leaveplans_Insert_Input;
};

/** Ordering options when selecting data from "hr.cdm_leaveplans". */
export type Hr_Cdm_Leaveplans_Order_By = {
  _cdm_companyid_value?: Maybe<Order_By>;
  _cdm_leavetypeid_value?: Maybe<Order_By>;
  _createdby_value?: Maybe<Order_By>;
  _createdonbehalfby_value?: Maybe<Order_By>;
  _modifiedby_value?: Maybe<Order_By>;
  _modifiedonbehalfby_value?: Maybe<Order_By>;
  _ownerid_value?: Maybe<Order_By>;
  _owningbusinessunit_value?: Maybe<Order_By>;
  _owningteam_value?: Maybe<Order_By>;
  _owninguser_value?: Maybe<Order_By>;
  cdm_accrualfrequency?: Maybe<Order_By>;
  cdm_description?: Maybe<Order_By>;
  cdm_leaveenrollments_aggregate?: Maybe<Hr_Cdm_Leaveenrollments_Aggregate_Order_By>;
  cdm_leaveplanid?: Maybe<Order_By>;
  cdm_name?: Maybe<Order_By>;
  cdm_startdate?: Maybe<Order_By>;
  createdon?: Maybe<Order_By>;
  importsequencenumber?: Maybe<Order_By>;
  modifiedon?: Maybe<Order_By>;
  overriddencreatedon?: Maybe<Order_By>;
  statecode?: Maybe<Order_By>;
  statuscode?: Maybe<Order_By>;
  timezoneruleversionnumber?: Maybe<Order_By>;
  utcconversiontimezonecode?: Maybe<Order_By>;
  versionnumber?: Maybe<Order_By>;
};

/** select columns of table "hr.cdm_leaveplans" */
export enum Hr_Cdm_Leaveplans_Select_Column {
  /** column name */
  CdmCompanyidValue = '_cdm_companyid_value',
  /** column name */
  CdmLeavetypeidValue = '_cdm_leavetypeid_value',
  /** column name */
  CreatedbyValue = '_createdby_value',
  /** column name */
  CreatedonbehalfbyValue = '_createdonbehalfby_value',
  /** column name */
  ModifiedbyValue = '_modifiedby_value',
  /** column name */
  ModifiedonbehalfbyValue = '_modifiedonbehalfby_value',
  /** column name */
  OwneridValue = '_ownerid_value',
  /** column name */
  OwningbusinessunitValue = '_owningbusinessunit_value',
  /** column name */
  OwningteamValue = '_owningteam_value',
  /** column name */
  OwninguserValue = '_owninguser_value',
  /** column name */
  CdmAccrualfrequency = 'cdm_accrualfrequency',
  /** column name */
  CdmDescription = 'cdm_description',
  /** column name */
  CdmLeaveplanid = 'cdm_leaveplanid',
  /** column name */
  CdmName = 'cdm_name',
  /** column name */
  CdmStartdate = 'cdm_startdate',
  /** column name */
  Createdon = 'createdon',
  /** column name */
  Importsequencenumber = 'importsequencenumber',
  /** column name */
  Modifiedon = 'modifiedon',
  /** column name */
  Overriddencreatedon = 'overriddencreatedon',
  /** column name */
  Statecode = 'statecode',
  /** column name */
  Statuscode = 'statuscode',
  /** column name */
  Timezoneruleversionnumber = 'timezoneruleversionnumber',
  /** column name */
  Utcconversiontimezonecode = 'utcconversiontimezonecode',
  /** column name */
  Versionnumber = 'versionnumber'
}

/** input type for updating data in table "hr.cdm_leaveplans" */
export type Hr_Cdm_Leaveplans_Set_Input = {
  _cdm_companyid_value?: Maybe<Scalars['uuid']>;
  _cdm_leavetypeid_value?: Maybe<Scalars['uuid']>;
  _createdby_value?: Maybe<Scalars['uuid']>;
  _createdonbehalfby_value?: Maybe<Scalars['timestamptz']>;
  _modifiedby_value?: Maybe<Scalars['uuid']>;
  _modifiedonbehalfby_value?: Maybe<Scalars['timestamptz']>;
  _ownerid_value?: Maybe<Scalars['uuid']>;
  _owningbusinessunit_value?: Maybe<Scalars['uuid']>;
  _owningteam_value?: Maybe<Scalars['String']>;
  _owninguser_value?: Maybe<Scalars['uuid']>;
  cdm_accrualfrequency?: Maybe<Scalars['String']>;
  cdm_description?: Maybe<Scalars['String']>;
  cdm_leaveplanid?: Maybe<Scalars['uuid']>;
  cdm_name?: Maybe<Scalars['String']>;
  cdm_startdate?: Maybe<Scalars['timestamptz']>;
  createdon?: Maybe<Scalars['timestamptz']>;
  importsequencenumber?: Maybe<Scalars['String']>;
  modifiedon?: Maybe<Scalars['timestamptz']>;
  overriddencreatedon?: Maybe<Scalars['timestamptz']>;
  statecode?: Maybe<Scalars['String']>;
  statuscode?: Maybe<Scalars['String']>;
  timezoneruleversionnumber?: Maybe<Scalars['String']>;
  utcconversiontimezonecode?: Maybe<Scalars['String']>;
  versionnumber?: Maybe<Scalars['String']>;
};

/** columns and relationships of "hr.cdm_positionworkerassignmentmaps" */
export type Hr_Cdm_Positionworkerassignmentmaps = {
  _cdm_jobpositionid_value?: Maybe<Scalars['uuid']>;
  _cdm_workerid_value?: Maybe<Scalars['uuid']>;
  _createdby_value?: Maybe<Scalars['uuid']>;
  _createdonbehalfby_value?: Maybe<Scalars['timestamptz']>;
  _modifiedby_value?: Maybe<Scalars['uuid']>;
  _modifiedonbehalfby_value?: Maybe<Scalars['timestamptz']>;
  _ownerid_value?: Maybe<Scalars['uuid']>;
  _owningbusinessunit_value?: Maybe<Scalars['uuid']>;
  _owningteam_value?: Maybe<Scalars['String']>;
  _owninguser_value?: Maybe<Scalars['uuid']>;
  cdm_isprimaryposition?: Maybe<Scalars['String']>;
  /** An object relationship */
  cdm_jobposition?: Maybe<Hr_Cdm_Jobpositions>;
  cdm_positionworkerassignmentmapid: Scalars['uuid'];
  cdm_positionworkerassignmentnumber?: Maybe<Scalars['String']>;
  cdm_validfrom?: Maybe<Scalars['timestamptz']>;
  cdm_validto?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  cdm_worker?: Maybe<Hr_Cdm_Workers>;
  /** An array relationship */
  compensations: Array<Hr_Cdm_Workerfixedcompensations>;
  /** An aggregate relationship */
  compensations_aggregate: Hr_Cdm_Workerfixedcompensations_Aggregate;
  createdon?: Maybe<Scalars['timestamptz']>;
  importsequencenumber?: Maybe<Scalars['String']>;
  modifiedon?: Maybe<Scalars['timestamptz']>;
  overriddencreatedon?: Maybe<Scalars['timestamptz']>;
  statecode?: Maybe<Scalars['String']>;
  statuscode?: Maybe<Scalars['String']>;
  timezoneruleversionnumber?: Maybe<Scalars['String']>;
  utcconversiontimezonecode?: Maybe<Scalars['String']>;
  versionnumber?: Maybe<Scalars['String']>;
};


/** columns and relationships of "hr.cdm_positionworkerassignmentmaps" */
export type Hr_Cdm_PositionworkerassignmentmapsCompensationsArgs = {
  distinct_on?: Maybe<Array<Hr_Cdm_Workerfixedcompensations_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Hr_Cdm_Workerfixedcompensations_Order_By>>;
  where?: Maybe<Hr_Cdm_Workerfixedcompensations_Bool_Exp>;
};


/** columns and relationships of "hr.cdm_positionworkerassignmentmaps" */
export type Hr_Cdm_PositionworkerassignmentmapsCompensations_AggregateArgs = {
  distinct_on?: Maybe<Array<Hr_Cdm_Workerfixedcompensations_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Hr_Cdm_Workerfixedcompensations_Order_By>>;
  where?: Maybe<Hr_Cdm_Workerfixedcompensations_Bool_Exp>;
};

/** aggregated selection of "hr.cdm_positionworkerassignmentmaps" */
export type Hr_Cdm_Positionworkerassignmentmaps_Aggregate = {
  aggregate?: Maybe<Hr_Cdm_Positionworkerassignmentmaps_Aggregate_Fields>;
  nodes: Array<Hr_Cdm_Positionworkerassignmentmaps>;
};

/** aggregate fields of "hr.cdm_positionworkerassignmentmaps" */
export type Hr_Cdm_Positionworkerassignmentmaps_Aggregate_Fields = {
  count: Scalars['Int'];
  max?: Maybe<Hr_Cdm_Positionworkerassignmentmaps_Max_Fields>;
  min?: Maybe<Hr_Cdm_Positionworkerassignmentmaps_Min_Fields>;
};


/** aggregate fields of "hr.cdm_positionworkerassignmentmaps" */
export type Hr_Cdm_Positionworkerassignmentmaps_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Hr_Cdm_Positionworkerassignmentmaps_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "hr.cdm_positionworkerassignmentmaps" */
export type Hr_Cdm_Positionworkerassignmentmaps_Aggregate_Order_By = {
  count?: Maybe<Order_By>;
  max?: Maybe<Hr_Cdm_Positionworkerassignmentmaps_Max_Order_By>;
  min?: Maybe<Hr_Cdm_Positionworkerassignmentmaps_Min_Order_By>;
};

/** input type for inserting array relation for remote table "hr.cdm_positionworkerassignmentmaps" */
export type Hr_Cdm_Positionworkerassignmentmaps_Arr_Rel_Insert_Input = {
  data: Array<Hr_Cdm_Positionworkerassignmentmaps_Insert_Input>;
};

/** Boolean expression to filter rows from the table "hr.cdm_positionworkerassignmentmaps". All fields are combined with a logical 'AND'. */
export type Hr_Cdm_Positionworkerassignmentmaps_Bool_Exp = {
  _and?: Maybe<Array<Hr_Cdm_Positionworkerassignmentmaps_Bool_Exp>>;
  _cdm_jobpositionid_value?: Maybe<Uuid_Comparison_Exp>;
  _cdm_workerid_value?: Maybe<Uuid_Comparison_Exp>;
  _createdby_value?: Maybe<Uuid_Comparison_Exp>;
  _createdonbehalfby_value?: Maybe<Timestamptz_Comparison_Exp>;
  _modifiedby_value?: Maybe<Uuid_Comparison_Exp>;
  _modifiedonbehalfby_value?: Maybe<Timestamptz_Comparison_Exp>;
  _not?: Maybe<Hr_Cdm_Positionworkerassignmentmaps_Bool_Exp>;
  _or?: Maybe<Array<Hr_Cdm_Positionworkerassignmentmaps_Bool_Exp>>;
  _ownerid_value?: Maybe<Uuid_Comparison_Exp>;
  _owningbusinessunit_value?: Maybe<Uuid_Comparison_Exp>;
  _owningteam_value?: Maybe<String_Comparison_Exp>;
  _owninguser_value?: Maybe<Uuid_Comparison_Exp>;
  cdm_isprimaryposition?: Maybe<String_Comparison_Exp>;
  cdm_jobposition?: Maybe<Hr_Cdm_Jobpositions_Bool_Exp>;
  cdm_positionworkerassignmentmapid?: Maybe<Uuid_Comparison_Exp>;
  cdm_positionworkerassignmentnumber?: Maybe<String_Comparison_Exp>;
  cdm_validfrom?: Maybe<Timestamptz_Comparison_Exp>;
  cdm_validto?: Maybe<Timestamptz_Comparison_Exp>;
  cdm_worker?: Maybe<Hr_Cdm_Workers_Bool_Exp>;
  compensations?: Maybe<Hr_Cdm_Workerfixedcompensations_Bool_Exp>;
  createdon?: Maybe<Timestamptz_Comparison_Exp>;
  importsequencenumber?: Maybe<String_Comparison_Exp>;
  modifiedon?: Maybe<Timestamptz_Comparison_Exp>;
  overriddencreatedon?: Maybe<Timestamptz_Comparison_Exp>;
  statecode?: Maybe<String_Comparison_Exp>;
  statuscode?: Maybe<String_Comparison_Exp>;
  timezoneruleversionnumber?: Maybe<String_Comparison_Exp>;
  utcconversiontimezonecode?: Maybe<String_Comparison_Exp>;
  versionnumber?: Maybe<String_Comparison_Exp>;
};

/** input type for inserting data into table "hr.cdm_positionworkerassignmentmaps" */
export type Hr_Cdm_Positionworkerassignmentmaps_Insert_Input = {
  _cdm_jobpositionid_value?: Maybe<Scalars['uuid']>;
  _cdm_workerid_value?: Maybe<Scalars['uuid']>;
  _createdby_value?: Maybe<Scalars['uuid']>;
  _createdonbehalfby_value?: Maybe<Scalars['timestamptz']>;
  _modifiedby_value?: Maybe<Scalars['uuid']>;
  _modifiedonbehalfby_value?: Maybe<Scalars['timestamptz']>;
  _ownerid_value?: Maybe<Scalars['uuid']>;
  _owningbusinessunit_value?: Maybe<Scalars['uuid']>;
  _owningteam_value?: Maybe<Scalars['String']>;
  _owninguser_value?: Maybe<Scalars['uuid']>;
  cdm_isprimaryposition?: Maybe<Scalars['String']>;
  cdm_jobposition?: Maybe<Hr_Cdm_Jobpositions_Obj_Rel_Insert_Input>;
  cdm_positionworkerassignmentmapid?: Maybe<Scalars['uuid']>;
  cdm_positionworkerassignmentnumber?: Maybe<Scalars['String']>;
  cdm_validfrom?: Maybe<Scalars['timestamptz']>;
  cdm_validto?: Maybe<Scalars['timestamptz']>;
  cdm_worker?: Maybe<Hr_Cdm_Workers_Obj_Rel_Insert_Input>;
  compensations?: Maybe<Hr_Cdm_Workerfixedcompensations_Arr_Rel_Insert_Input>;
  createdon?: Maybe<Scalars['timestamptz']>;
  importsequencenumber?: Maybe<Scalars['String']>;
  modifiedon?: Maybe<Scalars['timestamptz']>;
  overriddencreatedon?: Maybe<Scalars['timestamptz']>;
  statecode?: Maybe<Scalars['String']>;
  statuscode?: Maybe<Scalars['String']>;
  timezoneruleversionnumber?: Maybe<Scalars['String']>;
  utcconversiontimezonecode?: Maybe<Scalars['String']>;
  versionnumber?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Hr_Cdm_Positionworkerassignmentmaps_Max_Fields = {
  _cdm_jobpositionid_value?: Maybe<Scalars['uuid']>;
  _cdm_workerid_value?: Maybe<Scalars['uuid']>;
  _createdby_value?: Maybe<Scalars['uuid']>;
  _createdonbehalfby_value?: Maybe<Scalars['timestamptz']>;
  _modifiedby_value?: Maybe<Scalars['uuid']>;
  _modifiedonbehalfby_value?: Maybe<Scalars['timestamptz']>;
  _ownerid_value?: Maybe<Scalars['uuid']>;
  _owningbusinessunit_value?: Maybe<Scalars['uuid']>;
  _owningteam_value?: Maybe<Scalars['String']>;
  _owninguser_value?: Maybe<Scalars['uuid']>;
  cdm_isprimaryposition?: Maybe<Scalars['String']>;
  cdm_positionworkerassignmentmapid?: Maybe<Scalars['uuid']>;
  cdm_positionworkerassignmentnumber?: Maybe<Scalars['String']>;
  cdm_validfrom?: Maybe<Scalars['timestamptz']>;
  cdm_validto?: Maybe<Scalars['timestamptz']>;
  createdon?: Maybe<Scalars['timestamptz']>;
  importsequencenumber?: Maybe<Scalars['String']>;
  modifiedon?: Maybe<Scalars['timestamptz']>;
  overriddencreatedon?: Maybe<Scalars['timestamptz']>;
  statecode?: Maybe<Scalars['String']>;
  statuscode?: Maybe<Scalars['String']>;
  timezoneruleversionnumber?: Maybe<Scalars['String']>;
  utcconversiontimezonecode?: Maybe<Scalars['String']>;
  versionnumber?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "hr.cdm_positionworkerassignmentmaps" */
export type Hr_Cdm_Positionworkerassignmentmaps_Max_Order_By = {
  _cdm_jobpositionid_value?: Maybe<Order_By>;
  _cdm_workerid_value?: Maybe<Order_By>;
  _createdby_value?: Maybe<Order_By>;
  _createdonbehalfby_value?: Maybe<Order_By>;
  _modifiedby_value?: Maybe<Order_By>;
  _modifiedonbehalfby_value?: Maybe<Order_By>;
  _ownerid_value?: Maybe<Order_By>;
  _owningbusinessunit_value?: Maybe<Order_By>;
  _owningteam_value?: Maybe<Order_By>;
  _owninguser_value?: Maybe<Order_By>;
  cdm_isprimaryposition?: Maybe<Order_By>;
  cdm_positionworkerassignmentmapid?: Maybe<Order_By>;
  cdm_positionworkerassignmentnumber?: Maybe<Order_By>;
  cdm_validfrom?: Maybe<Order_By>;
  cdm_validto?: Maybe<Order_By>;
  createdon?: Maybe<Order_By>;
  importsequencenumber?: Maybe<Order_By>;
  modifiedon?: Maybe<Order_By>;
  overriddencreatedon?: Maybe<Order_By>;
  statecode?: Maybe<Order_By>;
  statuscode?: Maybe<Order_By>;
  timezoneruleversionnumber?: Maybe<Order_By>;
  utcconversiontimezonecode?: Maybe<Order_By>;
  versionnumber?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Hr_Cdm_Positionworkerassignmentmaps_Min_Fields = {
  _cdm_jobpositionid_value?: Maybe<Scalars['uuid']>;
  _cdm_workerid_value?: Maybe<Scalars['uuid']>;
  _createdby_value?: Maybe<Scalars['uuid']>;
  _createdonbehalfby_value?: Maybe<Scalars['timestamptz']>;
  _modifiedby_value?: Maybe<Scalars['uuid']>;
  _modifiedonbehalfby_value?: Maybe<Scalars['timestamptz']>;
  _ownerid_value?: Maybe<Scalars['uuid']>;
  _owningbusinessunit_value?: Maybe<Scalars['uuid']>;
  _owningteam_value?: Maybe<Scalars['String']>;
  _owninguser_value?: Maybe<Scalars['uuid']>;
  cdm_isprimaryposition?: Maybe<Scalars['String']>;
  cdm_positionworkerassignmentmapid?: Maybe<Scalars['uuid']>;
  cdm_positionworkerassignmentnumber?: Maybe<Scalars['String']>;
  cdm_validfrom?: Maybe<Scalars['timestamptz']>;
  cdm_validto?: Maybe<Scalars['timestamptz']>;
  createdon?: Maybe<Scalars['timestamptz']>;
  importsequencenumber?: Maybe<Scalars['String']>;
  modifiedon?: Maybe<Scalars['timestamptz']>;
  overriddencreatedon?: Maybe<Scalars['timestamptz']>;
  statecode?: Maybe<Scalars['String']>;
  statuscode?: Maybe<Scalars['String']>;
  timezoneruleversionnumber?: Maybe<Scalars['String']>;
  utcconversiontimezonecode?: Maybe<Scalars['String']>;
  versionnumber?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "hr.cdm_positionworkerassignmentmaps" */
export type Hr_Cdm_Positionworkerassignmentmaps_Min_Order_By = {
  _cdm_jobpositionid_value?: Maybe<Order_By>;
  _cdm_workerid_value?: Maybe<Order_By>;
  _createdby_value?: Maybe<Order_By>;
  _createdonbehalfby_value?: Maybe<Order_By>;
  _modifiedby_value?: Maybe<Order_By>;
  _modifiedonbehalfby_value?: Maybe<Order_By>;
  _ownerid_value?: Maybe<Order_By>;
  _owningbusinessunit_value?: Maybe<Order_By>;
  _owningteam_value?: Maybe<Order_By>;
  _owninguser_value?: Maybe<Order_By>;
  cdm_isprimaryposition?: Maybe<Order_By>;
  cdm_positionworkerassignmentmapid?: Maybe<Order_By>;
  cdm_positionworkerassignmentnumber?: Maybe<Order_By>;
  cdm_validfrom?: Maybe<Order_By>;
  cdm_validto?: Maybe<Order_By>;
  createdon?: Maybe<Order_By>;
  importsequencenumber?: Maybe<Order_By>;
  modifiedon?: Maybe<Order_By>;
  overriddencreatedon?: Maybe<Order_By>;
  statecode?: Maybe<Order_By>;
  statuscode?: Maybe<Order_By>;
  timezoneruleversionnumber?: Maybe<Order_By>;
  utcconversiontimezonecode?: Maybe<Order_By>;
  versionnumber?: Maybe<Order_By>;
};

/** response of any mutation on the table "hr.cdm_positionworkerassignmentmaps" */
export type Hr_Cdm_Positionworkerassignmentmaps_Mutation_Response = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Hr_Cdm_Positionworkerassignmentmaps>;
};

/** Ordering options when selecting data from "hr.cdm_positionworkerassignmentmaps". */
export type Hr_Cdm_Positionworkerassignmentmaps_Order_By = {
  _cdm_jobpositionid_value?: Maybe<Order_By>;
  _cdm_workerid_value?: Maybe<Order_By>;
  _createdby_value?: Maybe<Order_By>;
  _createdonbehalfby_value?: Maybe<Order_By>;
  _modifiedby_value?: Maybe<Order_By>;
  _modifiedonbehalfby_value?: Maybe<Order_By>;
  _ownerid_value?: Maybe<Order_By>;
  _owningbusinessunit_value?: Maybe<Order_By>;
  _owningteam_value?: Maybe<Order_By>;
  _owninguser_value?: Maybe<Order_By>;
  cdm_isprimaryposition?: Maybe<Order_By>;
  cdm_jobposition?: Maybe<Hr_Cdm_Jobpositions_Order_By>;
  cdm_positionworkerassignmentmapid?: Maybe<Order_By>;
  cdm_positionworkerassignmentnumber?: Maybe<Order_By>;
  cdm_validfrom?: Maybe<Order_By>;
  cdm_validto?: Maybe<Order_By>;
  cdm_worker?: Maybe<Hr_Cdm_Workers_Order_By>;
  compensations_aggregate?: Maybe<Hr_Cdm_Workerfixedcompensations_Aggregate_Order_By>;
  createdon?: Maybe<Order_By>;
  importsequencenumber?: Maybe<Order_By>;
  modifiedon?: Maybe<Order_By>;
  overriddencreatedon?: Maybe<Order_By>;
  statecode?: Maybe<Order_By>;
  statuscode?: Maybe<Order_By>;
  timezoneruleversionnumber?: Maybe<Order_By>;
  utcconversiontimezonecode?: Maybe<Order_By>;
  versionnumber?: Maybe<Order_By>;
};

/** select columns of table "hr.cdm_positionworkerassignmentmaps" */
export enum Hr_Cdm_Positionworkerassignmentmaps_Select_Column {
  /** column name */
  CdmJobpositionidValue = '_cdm_jobpositionid_value',
  /** column name */
  CdmWorkeridValue = '_cdm_workerid_value',
  /** column name */
  CreatedbyValue = '_createdby_value',
  /** column name */
  CreatedonbehalfbyValue = '_createdonbehalfby_value',
  /** column name */
  ModifiedbyValue = '_modifiedby_value',
  /** column name */
  ModifiedonbehalfbyValue = '_modifiedonbehalfby_value',
  /** column name */
  OwneridValue = '_ownerid_value',
  /** column name */
  OwningbusinessunitValue = '_owningbusinessunit_value',
  /** column name */
  OwningteamValue = '_owningteam_value',
  /** column name */
  OwninguserValue = '_owninguser_value',
  /** column name */
  CdmIsprimaryposition = 'cdm_isprimaryposition',
  /** column name */
  CdmPositionworkerassignmentmapid = 'cdm_positionworkerassignmentmapid',
  /** column name */
  CdmPositionworkerassignmentnumber = 'cdm_positionworkerassignmentnumber',
  /** column name */
  CdmValidfrom = 'cdm_validfrom',
  /** column name */
  CdmValidto = 'cdm_validto',
  /** column name */
  Createdon = 'createdon',
  /** column name */
  Importsequencenumber = 'importsequencenumber',
  /** column name */
  Modifiedon = 'modifiedon',
  /** column name */
  Overriddencreatedon = 'overriddencreatedon',
  /** column name */
  Statecode = 'statecode',
  /** column name */
  Statuscode = 'statuscode',
  /** column name */
  Timezoneruleversionnumber = 'timezoneruleversionnumber',
  /** column name */
  Utcconversiontimezonecode = 'utcconversiontimezonecode',
  /** column name */
  Versionnumber = 'versionnumber'
}

/** input type for updating data in table "hr.cdm_positionworkerassignmentmaps" */
export type Hr_Cdm_Positionworkerassignmentmaps_Set_Input = {
  _cdm_jobpositionid_value?: Maybe<Scalars['uuid']>;
  _cdm_workerid_value?: Maybe<Scalars['uuid']>;
  _createdby_value?: Maybe<Scalars['uuid']>;
  _createdonbehalfby_value?: Maybe<Scalars['timestamptz']>;
  _modifiedby_value?: Maybe<Scalars['uuid']>;
  _modifiedonbehalfby_value?: Maybe<Scalars['timestamptz']>;
  _ownerid_value?: Maybe<Scalars['uuid']>;
  _owningbusinessunit_value?: Maybe<Scalars['uuid']>;
  _owningteam_value?: Maybe<Scalars['String']>;
  _owninguser_value?: Maybe<Scalars['uuid']>;
  cdm_isprimaryposition?: Maybe<Scalars['String']>;
  cdm_positionworkerassignmentmapid?: Maybe<Scalars['uuid']>;
  cdm_positionworkerassignmentnumber?: Maybe<Scalars['String']>;
  cdm_validfrom?: Maybe<Scalars['timestamptz']>;
  cdm_validto?: Maybe<Scalars['timestamptz']>;
  createdon?: Maybe<Scalars['timestamptz']>;
  importsequencenumber?: Maybe<Scalars['String']>;
  modifiedon?: Maybe<Scalars['timestamptz']>;
  overriddencreatedon?: Maybe<Scalars['timestamptz']>;
  statecode?: Maybe<Scalars['String']>;
  statuscode?: Maybe<Scalars['String']>;
  timezoneruleversionnumber?: Maybe<Scalars['String']>;
  utcconversiontimezonecode?: Maybe<Scalars['String']>;
  versionnumber?: Maybe<Scalars['String']>;
};

/** columns and relationships of "hr.cdm_workerfixedcompensations" */
export type Hr_Cdm_Workerfixedcompensations = {
  _cdm_companyid_value?: Maybe<Scalars['uuid']>;
  _cdm_compensationlevelid_value?: Maybe<Scalars['uuid']>;
  _cdm_eventid_value?: Maybe<Scalars['uuid']>;
  _cdm_payfrequencyid_value?: Maybe<Scalars['uuid']>;
  _cdm_planid_value?: Maybe<Scalars['uuid']>;
  _cdm_positionid_value?: Maybe<Scalars['uuid']>;
  _cdm_referencepointsetuplineid_value?: Maybe<Scalars['uuid']>;
  _cdm_workerid_value?: Maybe<Scalars['uuid']>;
  _createdby_value?: Maybe<Scalars['uuid']>;
  _createdonbehalfby_value?: Maybe<Scalars['timestamptz']>;
  _modifiedby_value?: Maybe<Scalars['uuid']>;
  _modifiedonbehalfby_value?: Maybe<Scalars['timestamptz']>;
  _ownerid_value?: Maybe<Scalars['uuid']>;
  _owningbusinessunit_value?: Maybe<Scalars['uuid']>;
  _owningteam_value?: Maybe<Scalars['String']>;
  _owninguser_value?: Maybe<Scalars['uuid']>;
  _transactioncurrencyid_value?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  cdm_compensationfixedplan?: Maybe<Hr_Cdm_Compensationfixedplans>;
  /** An object relationship */
  cdm_compensationlevel?: Maybe<Hr_Cdm_Compensationlevels>;
  cdm_compensationtype?: Maybe<Scalars['String']>;
  cdm_effectivedate?: Maybe<Scalars['timestamptz']>;
  cdm_expirationdate?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  cdm_jobposition?: Maybe<Hr_Cdm_Jobpositions>;
  cdm_linenumber?: Maybe<Scalars['String']>;
  cdm_payrate?: Maybe<Scalars['String']>;
  cdm_payrate_base?: Maybe<Scalars['Int']>;
  cdm_processtype?: Maybe<Scalars['String']>;
  /** An object relationship */
  cdm_worker?: Maybe<Hr_Cdm_Workers>;
  cdm_workerfixedcompensationid: Scalars['uuid'];
  cdm_workerfixedcompensationnumber?: Maybe<Scalars['String']>;
  createdon?: Maybe<Scalars['timestamptz']>;
  exchangerate?: Maybe<Scalars['String']>;
  importsequencenumber?: Maybe<Scalars['String']>;
  modifiedon?: Maybe<Scalars['timestamptz']>;
  overriddencreatedon?: Maybe<Scalars['timestamptz']>;
  statecode?: Maybe<Scalars['String']>;
  statuscode?: Maybe<Scalars['String']>;
  timezoneruleversionnumber?: Maybe<Scalars['String']>;
  utcconversiontimezonecode?: Maybe<Scalars['String']>;
  versionnumber?: Maybe<Scalars['String']>;
};

/** aggregated selection of "hr.cdm_workerfixedcompensations" */
export type Hr_Cdm_Workerfixedcompensations_Aggregate = {
  aggregate?: Maybe<Hr_Cdm_Workerfixedcompensations_Aggregate_Fields>;
  nodes: Array<Hr_Cdm_Workerfixedcompensations>;
};

/** aggregate fields of "hr.cdm_workerfixedcompensations" */
export type Hr_Cdm_Workerfixedcompensations_Aggregate_Fields = {
  avg?: Maybe<Hr_Cdm_Workerfixedcompensations_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Hr_Cdm_Workerfixedcompensations_Max_Fields>;
  min?: Maybe<Hr_Cdm_Workerfixedcompensations_Min_Fields>;
  stddev?: Maybe<Hr_Cdm_Workerfixedcompensations_Stddev_Fields>;
  stddev_pop?: Maybe<Hr_Cdm_Workerfixedcompensations_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Hr_Cdm_Workerfixedcompensations_Stddev_Samp_Fields>;
  sum?: Maybe<Hr_Cdm_Workerfixedcompensations_Sum_Fields>;
  var_pop?: Maybe<Hr_Cdm_Workerfixedcompensations_Var_Pop_Fields>;
  var_samp?: Maybe<Hr_Cdm_Workerfixedcompensations_Var_Samp_Fields>;
  variance?: Maybe<Hr_Cdm_Workerfixedcompensations_Variance_Fields>;
};


/** aggregate fields of "hr.cdm_workerfixedcompensations" */
export type Hr_Cdm_Workerfixedcompensations_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Hr_Cdm_Workerfixedcompensations_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "hr.cdm_workerfixedcompensations" */
export type Hr_Cdm_Workerfixedcompensations_Aggregate_Order_By = {
  avg?: Maybe<Hr_Cdm_Workerfixedcompensations_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Hr_Cdm_Workerfixedcompensations_Max_Order_By>;
  min?: Maybe<Hr_Cdm_Workerfixedcompensations_Min_Order_By>;
  stddev?: Maybe<Hr_Cdm_Workerfixedcompensations_Stddev_Order_By>;
  stddev_pop?: Maybe<Hr_Cdm_Workerfixedcompensations_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Hr_Cdm_Workerfixedcompensations_Stddev_Samp_Order_By>;
  sum?: Maybe<Hr_Cdm_Workerfixedcompensations_Sum_Order_By>;
  var_pop?: Maybe<Hr_Cdm_Workerfixedcompensations_Var_Pop_Order_By>;
  var_samp?: Maybe<Hr_Cdm_Workerfixedcompensations_Var_Samp_Order_By>;
  variance?: Maybe<Hr_Cdm_Workerfixedcompensations_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "hr.cdm_workerfixedcompensations" */
export type Hr_Cdm_Workerfixedcompensations_Arr_Rel_Insert_Input = {
  data: Array<Hr_Cdm_Workerfixedcompensations_Insert_Input>;
};

/** aggregate avg on columns */
export type Hr_Cdm_Workerfixedcompensations_Avg_Fields = {
  cdm_payrate_base?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "hr.cdm_workerfixedcompensations" */
export type Hr_Cdm_Workerfixedcompensations_Avg_Order_By = {
  cdm_payrate_base?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "hr.cdm_workerfixedcompensations". All fields are combined with a logical 'AND'. */
export type Hr_Cdm_Workerfixedcompensations_Bool_Exp = {
  _and?: Maybe<Array<Hr_Cdm_Workerfixedcompensations_Bool_Exp>>;
  _cdm_companyid_value?: Maybe<Uuid_Comparison_Exp>;
  _cdm_compensationlevelid_value?: Maybe<Uuid_Comparison_Exp>;
  _cdm_eventid_value?: Maybe<Uuid_Comparison_Exp>;
  _cdm_payfrequencyid_value?: Maybe<Uuid_Comparison_Exp>;
  _cdm_planid_value?: Maybe<Uuid_Comparison_Exp>;
  _cdm_positionid_value?: Maybe<Uuid_Comparison_Exp>;
  _cdm_referencepointsetuplineid_value?: Maybe<Uuid_Comparison_Exp>;
  _cdm_workerid_value?: Maybe<Uuid_Comparison_Exp>;
  _createdby_value?: Maybe<Uuid_Comparison_Exp>;
  _createdonbehalfby_value?: Maybe<Timestamptz_Comparison_Exp>;
  _modifiedby_value?: Maybe<Uuid_Comparison_Exp>;
  _modifiedonbehalfby_value?: Maybe<Timestamptz_Comparison_Exp>;
  _not?: Maybe<Hr_Cdm_Workerfixedcompensations_Bool_Exp>;
  _or?: Maybe<Array<Hr_Cdm_Workerfixedcompensations_Bool_Exp>>;
  _ownerid_value?: Maybe<Uuid_Comparison_Exp>;
  _owningbusinessunit_value?: Maybe<Uuid_Comparison_Exp>;
  _owningteam_value?: Maybe<String_Comparison_Exp>;
  _owninguser_value?: Maybe<Uuid_Comparison_Exp>;
  _transactioncurrencyid_value?: Maybe<Uuid_Comparison_Exp>;
  cdm_compensationfixedplan?: Maybe<Hr_Cdm_Compensationfixedplans_Bool_Exp>;
  cdm_compensationlevel?: Maybe<Hr_Cdm_Compensationlevels_Bool_Exp>;
  cdm_compensationtype?: Maybe<String_Comparison_Exp>;
  cdm_effectivedate?: Maybe<Timestamptz_Comparison_Exp>;
  cdm_expirationdate?: Maybe<Timestamptz_Comparison_Exp>;
  cdm_jobposition?: Maybe<Hr_Cdm_Jobpositions_Bool_Exp>;
  cdm_linenumber?: Maybe<String_Comparison_Exp>;
  cdm_payrate?: Maybe<String_Comparison_Exp>;
  cdm_payrate_base?: Maybe<Int_Comparison_Exp>;
  cdm_processtype?: Maybe<String_Comparison_Exp>;
  cdm_worker?: Maybe<Hr_Cdm_Workers_Bool_Exp>;
  cdm_workerfixedcompensationid?: Maybe<Uuid_Comparison_Exp>;
  cdm_workerfixedcompensationnumber?: Maybe<String_Comparison_Exp>;
  createdon?: Maybe<Timestamptz_Comparison_Exp>;
  exchangerate?: Maybe<String_Comparison_Exp>;
  importsequencenumber?: Maybe<String_Comparison_Exp>;
  modifiedon?: Maybe<Timestamptz_Comparison_Exp>;
  overriddencreatedon?: Maybe<Timestamptz_Comparison_Exp>;
  statecode?: Maybe<String_Comparison_Exp>;
  statuscode?: Maybe<String_Comparison_Exp>;
  timezoneruleversionnumber?: Maybe<String_Comparison_Exp>;
  utcconversiontimezonecode?: Maybe<String_Comparison_Exp>;
  versionnumber?: Maybe<String_Comparison_Exp>;
};

/** input type for incrementing numeric columns in table "hr.cdm_workerfixedcompensations" */
export type Hr_Cdm_Workerfixedcompensations_Inc_Input = {
  cdm_payrate_base?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "hr.cdm_workerfixedcompensations" */
export type Hr_Cdm_Workerfixedcompensations_Insert_Input = {
  _cdm_companyid_value?: Maybe<Scalars['uuid']>;
  _cdm_compensationlevelid_value?: Maybe<Scalars['uuid']>;
  _cdm_eventid_value?: Maybe<Scalars['uuid']>;
  _cdm_payfrequencyid_value?: Maybe<Scalars['uuid']>;
  _cdm_planid_value?: Maybe<Scalars['uuid']>;
  _cdm_positionid_value?: Maybe<Scalars['uuid']>;
  _cdm_referencepointsetuplineid_value?: Maybe<Scalars['uuid']>;
  _cdm_workerid_value?: Maybe<Scalars['uuid']>;
  _createdby_value?: Maybe<Scalars['uuid']>;
  _createdonbehalfby_value?: Maybe<Scalars['timestamptz']>;
  _modifiedby_value?: Maybe<Scalars['uuid']>;
  _modifiedonbehalfby_value?: Maybe<Scalars['timestamptz']>;
  _ownerid_value?: Maybe<Scalars['uuid']>;
  _owningbusinessunit_value?: Maybe<Scalars['uuid']>;
  _owningteam_value?: Maybe<Scalars['String']>;
  _owninguser_value?: Maybe<Scalars['uuid']>;
  _transactioncurrencyid_value?: Maybe<Scalars['uuid']>;
  cdm_compensationfixedplan?: Maybe<Hr_Cdm_Compensationfixedplans_Obj_Rel_Insert_Input>;
  cdm_compensationlevel?: Maybe<Hr_Cdm_Compensationlevels_Obj_Rel_Insert_Input>;
  cdm_compensationtype?: Maybe<Scalars['String']>;
  cdm_effectivedate?: Maybe<Scalars['timestamptz']>;
  cdm_expirationdate?: Maybe<Scalars['timestamptz']>;
  cdm_jobposition?: Maybe<Hr_Cdm_Jobpositions_Obj_Rel_Insert_Input>;
  cdm_linenumber?: Maybe<Scalars['String']>;
  cdm_payrate?: Maybe<Scalars['String']>;
  cdm_payrate_base?: Maybe<Scalars['Int']>;
  cdm_processtype?: Maybe<Scalars['String']>;
  cdm_worker?: Maybe<Hr_Cdm_Workers_Obj_Rel_Insert_Input>;
  cdm_workerfixedcompensationid?: Maybe<Scalars['uuid']>;
  cdm_workerfixedcompensationnumber?: Maybe<Scalars['String']>;
  createdon?: Maybe<Scalars['timestamptz']>;
  exchangerate?: Maybe<Scalars['String']>;
  importsequencenumber?: Maybe<Scalars['String']>;
  modifiedon?: Maybe<Scalars['timestamptz']>;
  overriddencreatedon?: Maybe<Scalars['timestamptz']>;
  statecode?: Maybe<Scalars['String']>;
  statuscode?: Maybe<Scalars['String']>;
  timezoneruleversionnumber?: Maybe<Scalars['String']>;
  utcconversiontimezonecode?: Maybe<Scalars['String']>;
  versionnumber?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Hr_Cdm_Workerfixedcompensations_Max_Fields = {
  _cdm_companyid_value?: Maybe<Scalars['uuid']>;
  _cdm_compensationlevelid_value?: Maybe<Scalars['uuid']>;
  _cdm_eventid_value?: Maybe<Scalars['uuid']>;
  _cdm_payfrequencyid_value?: Maybe<Scalars['uuid']>;
  _cdm_planid_value?: Maybe<Scalars['uuid']>;
  _cdm_positionid_value?: Maybe<Scalars['uuid']>;
  _cdm_referencepointsetuplineid_value?: Maybe<Scalars['uuid']>;
  _cdm_workerid_value?: Maybe<Scalars['uuid']>;
  _createdby_value?: Maybe<Scalars['uuid']>;
  _createdonbehalfby_value?: Maybe<Scalars['timestamptz']>;
  _modifiedby_value?: Maybe<Scalars['uuid']>;
  _modifiedonbehalfby_value?: Maybe<Scalars['timestamptz']>;
  _ownerid_value?: Maybe<Scalars['uuid']>;
  _owningbusinessunit_value?: Maybe<Scalars['uuid']>;
  _owningteam_value?: Maybe<Scalars['String']>;
  _owninguser_value?: Maybe<Scalars['uuid']>;
  _transactioncurrencyid_value?: Maybe<Scalars['uuid']>;
  cdm_compensationtype?: Maybe<Scalars['String']>;
  cdm_effectivedate?: Maybe<Scalars['timestamptz']>;
  cdm_expirationdate?: Maybe<Scalars['timestamptz']>;
  cdm_linenumber?: Maybe<Scalars['String']>;
  cdm_payrate?: Maybe<Scalars['String']>;
  cdm_payrate_base?: Maybe<Scalars['Int']>;
  cdm_processtype?: Maybe<Scalars['String']>;
  cdm_workerfixedcompensationid?: Maybe<Scalars['uuid']>;
  cdm_workerfixedcompensationnumber?: Maybe<Scalars['String']>;
  createdon?: Maybe<Scalars['timestamptz']>;
  exchangerate?: Maybe<Scalars['String']>;
  importsequencenumber?: Maybe<Scalars['String']>;
  modifiedon?: Maybe<Scalars['timestamptz']>;
  overriddencreatedon?: Maybe<Scalars['timestamptz']>;
  statecode?: Maybe<Scalars['String']>;
  statuscode?: Maybe<Scalars['String']>;
  timezoneruleversionnumber?: Maybe<Scalars['String']>;
  utcconversiontimezonecode?: Maybe<Scalars['String']>;
  versionnumber?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "hr.cdm_workerfixedcompensations" */
export type Hr_Cdm_Workerfixedcompensations_Max_Order_By = {
  _cdm_companyid_value?: Maybe<Order_By>;
  _cdm_compensationlevelid_value?: Maybe<Order_By>;
  _cdm_eventid_value?: Maybe<Order_By>;
  _cdm_payfrequencyid_value?: Maybe<Order_By>;
  _cdm_planid_value?: Maybe<Order_By>;
  _cdm_positionid_value?: Maybe<Order_By>;
  _cdm_referencepointsetuplineid_value?: Maybe<Order_By>;
  _cdm_workerid_value?: Maybe<Order_By>;
  _createdby_value?: Maybe<Order_By>;
  _createdonbehalfby_value?: Maybe<Order_By>;
  _modifiedby_value?: Maybe<Order_By>;
  _modifiedonbehalfby_value?: Maybe<Order_By>;
  _ownerid_value?: Maybe<Order_By>;
  _owningbusinessunit_value?: Maybe<Order_By>;
  _owningteam_value?: Maybe<Order_By>;
  _owninguser_value?: Maybe<Order_By>;
  _transactioncurrencyid_value?: Maybe<Order_By>;
  cdm_compensationtype?: Maybe<Order_By>;
  cdm_effectivedate?: Maybe<Order_By>;
  cdm_expirationdate?: Maybe<Order_By>;
  cdm_linenumber?: Maybe<Order_By>;
  cdm_payrate?: Maybe<Order_By>;
  cdm_payrate_base?: Maybe<Order_By>;
  cdm_processtype?: Maybe<Order_By>;
  cdm_workerfixedcompensationid?: Maybe<Order_By>;
  cdm_workerfixedcompensationnumber?: Maybe<Order_By>;
  createdon?: Maybe<Order_By>;
  exchangerate?: Maybe<Order_By>;
  importsequencenumber?: Maybe<Order_By>;
  modifiedon?: Maybe<Order_By>;
  overriddencreatedon?: Maybe<Order_By>;
  statecode?: Maybe<Order_By>;
  statuscode?: Maybe<Order_By>;
  timezoneruleversionnumber?: Maybe<Order_By>;
  utcconversiontimezonecode?: Maybe<Order_By>;
  versionnumber?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Hr_Cdm_Workerfixedcompensations_Min_Fields = {
  _cdm_companyid_value?: Maybe<Scalars['uuid']>;
  _cdm_compensationlevelid_value?: Maybe<Scalars['uuid']>;
  _cdm_eventid_value?: Maybe<Scalars['uuid']>;
  _cdm_payfrequencyid_value?: Maybe<Scalars['uuid']>;
  _cdm_planid_value?: Maybe<Scalars['uuid']>;
  _cdm_positionid_value?: Maybe<Scalars['uuid']>;
  _cdm_referencepointsetuplineid_value?: Maybe<Scalars['uuid']>;
  _cdm_workerid_value?: Maybe<Scalars['uuid']>;
  _createdby_value?: Maybe<Scalars['uuid']>;
  _createdonbehalfby_value?: Maybe<Scalars['timestamptz']>;
  _modifiedby_value?: Maybe<Scalars['uuid']>;
  _modifiedonbehalfby_value?: Maybe<Scalars['timestamptz']>;
  _ownerid_value?: Maybe<Scalars['uuid']>;
  _owningbusinessunit_value?: Maybe<Scalars['uuid']>;
  _owningteam_value?: Maybe<Scalars['String']>;
  _owninguser_value?: Maybe<Scalars['uuid']>;
  _transactioncurrencyid_value?: Maybe<Scalars['uuid']>;
  cdm_compensationtype?: Maybe<Scalars['String']>;
  cdm_effectivedate?: Maybe<Scalars['timestamptz']>;
  cdm_expirationdate?: Maybe<Scalars['timestamptz']>;
  cdm_linenumber?: Maybe<Scalars['String']>;
  cdm_payrate?: Maybe<Scalars['String']>;
  cdm_payrate_base?: Maybe<Scalars['Int']>;
  cdm_processtype?: Maybe<Scalars['String']>;
  cdm_workerfixedcompensationid?: Maybe<Scalars['uuid']>;
  cdm_workerfixedcompensationnumber?: Maybe<Scalars['String']>;
  createdon?: Maybe<Scalars['timestamptz']>;
  exchangerate?: Maybe<Scalars['String']>;
  importsequencenumber?: Maybe<Scalars['String']>;
  modifiedon?: Maybe<Scalars['timestamptz']>;
  overriddencreatedon?: Maybe<Scalars['timestamptz']>;
  statecode?: Maybe<Scalars['String']>;
  statuscode?: Maybe<Scalars['String']>;
  timezoneruleversionnumber?: Maybe<Scalars['String']>;
  utcconversiontimezonecode?: Maybe<Scalars['String']>;
  versionnumber?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "hr.cdm_workerfixedcompensations" */
export type Hr_Cdm_Workerfixedcompensations_Min_Order_By = {
  _cdm_companyid_value?: Maybe<Order_By>;
  _cdm_compensationlevelid_value?: Maybe<Order_By>;
  _cdm_eventid_value?: Maybe<Order_By>;
  _cdm_payfrequencyid_value?: Maybe<Order_By>;
  _cdm_planid_value?: Maybe<Order_By>;
  _cdm_positionid_value?: Maybe<Order_By>;
  _cdm_referencepointsetuplineid_value?: Maybe<Order_By>;
  _cdm_workerid_value?: Maybe<Order_By>;
  _createdby_value?: Maybe<Order_By>;
  _createdonbehalfby_value?: Maybe<Order_By>;
  _modifiedby_value?: Maybe<Order_By>;
  _modifiedonbehalfby_value?: Maybe<Order_By>;
  _ownerid_value?: Maybe<Order_By>;
  _owningbusinessunit_value?: Maybe<Order_By>;
  _owningteam_value?: Maybe<Order_By>;
  _owninguser_value?: Maybe<Order_By>;
  _transactioncurrencyid_value?: Maybe<Order_By>;
  cdm_compensationtype?: Maybe<Order_By>;
  cdm_effectivedate?: Maybe<Order_By>;
  cdm_expirationdate?: Maybe<Order_By>;
  cdm_linenumber?: Maybe<Order_By>;
  cdm_payrate?: Maybe<Order_By>;
  cdm_payrate_base?: Maybe<Order_By>;
  cdm_processtype?: Maybe<Order_By>;
  cdm_workerfixedcompensationid?: Maybe<Order_By>;
  cdm_workerfixedcompensationnumber?: Maybe<Order_By>;
  createdon?: Maybe<Order_By>;
  exchangerate?: Maybe<Order_By>;
  importsequencenumber?: Maybe<Order_By>;
  modifiedon?: Maybe<Order_By>;
  overriddencreatedon?: Maybe<Order_By>;
  statecode?: Maybe<Order_By>;
  statuscode?: Maybe<Order_By>;
  timezoneruleversionnumber?: Maybe<Order_By>;
  utcconversiontimezonecode?: Maybe<Order_By>;
  versionnumber?: Maybe<Order_By>;
};

/** response of any mutation on the table "hr.cdm_workerfixedcompensations" */
export type Hr_Cdm_Workerfixedcompensations_Mutation_Response = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Hr_Cdm_Workerfixedcompensations>;
};

/** Ordering options when selecting data from "hr.cdm_workerfixedcompensations". */
export type Hr_Cdm_Workerfixedcompensations_Order_By = {
  _cdm_companyid_value?: Maybe<Order_By>;
  _cdm_compensationlevelid_value?: Maybe<Order_By>;
  _cdm_eventid_value?: Maybe<Order_By>;
  _cdm_payfrequencyid_value?: Maybe<Order_By>;
  _cdm_planid_value?: Maybe<Order_By>;
  _cdm_positionid_value?: Maybe<Order_By>;
  _cdm_referencepointsetuplineid_value?: Maybe<Order_By>;
  _cdm_workerid_value?: Maybe<Order_By>;
  _createdby_value?: Maybe<Order_By>;
  _createdonbehalfby_value?: Maybe<Order_By>;
  _modifiedby_value?: Maybe<Order_By>;
  _modifiedonbehalfby_value?: Maybe<Order_By>;
  _ownerid_value?: Maybe<Order_By>;
  _owningbusinessunit_value?: Maybe<Order_By>;
  _owningteam_value?: Maybe<Order_By>;
  _owninguser_value?: Maybe<Order_By>;
  _transactioncurrencyid_value?: Maybe<Order_By>;
  cdm_compensationfixedplan?: Maybe<Hr_Cdm_Compensationfixedplans_Order_By>;
  cdm_compensationlevel?: Maybe<Hr_Cdm_Compensationlevels_Order_By>;
  cdm_compensationtype?: Maybe<Order_By>;
  cdm_effectivedate?: Maybe<Order_By>;
  cdm_expirationdate?: Maybe<Order_By>;
  cdm_jobposition?: Maybe<Hr_Cdm_Jobpositions_Order_By>;
  cdm_linenumber?: Maybe<Order_By>;
  cdm_payrate?: Maybe<Order_By>;
  cdm_payrate_base?: Maybe<Order_By>;
  cdm_processtype?: Maybe<Order_By>;
  cdm_worker?: Maybe<Hr_Cdm_Workers_Order_By>;
  cdm_workerfixedcompensationid?: Maybe<Order_By>;
  cdm_workerfixedcompensationnumber?: Maybe<Order_By>;
  createdon?: Maybe<Order_By>;
  exchangerate?: Maybe<Order_By>;
  importsequencenumber?: Maybe<Order_By>;
  modifiedon?: Maybe<Order_By>;
  overriddencreatedon?: Maybe<Order_By>;
  statecode?: Maybe<Order_By>;
  statuscode?: Maybe<Order_By>;
  timezoneruleversionnumber?: Maybe<Order_By>;
  utcconversiontimezonecode?: Maybe<Order_By>;
  versionnumber?: Maybe<Order_By>;
};

/** select columns of table "hr.cdm_workerfixedcompensations" */
export enum Hr_Cdm_Workerfixedcompensations_Select_Column {
  /** column name */
  CdmCompanyidValue = '_cdm_companyid_value',
  /** column name */
  CdmCompensationlevelidValue = '_cdm_compensationlevelid_value',
  /** column name */
  CdmEventidValue = '_cdm_eventid_value',
  /** column name */
  CdmPayfrequencyidValue = '_cdm_payfrequencyid_value',
  /** column name */
  CdmPlanidValue = '_cdm_planid_value',
  /** column name */
  CdmPositionidValue = '_cdm_positionid_value',
  /** column name */
  CdmReferencepointsetuplineidValue = '_cdm_referencepointsetuplineid_value',
  /** column name */
  CdmWorkeridValue = '_cdm_workerid_value',
  /** column name */
  CreatedbyValue = '_createdby_value',
  /** column name */
  CreatedonbehalfbyValue = '_createdonbehalfby_value',
  /** column name */
  ModifiedbyValue = '_modifiedby_value',
  /** column name */
  ModifiedonbehalfbyValue = '_modifiedonbehalfby_value',
  /** column name */
  OwneridValue = '_ownerid_value',
  /** column name */
  OwningbusinessunitValue = '_owningbusinessunit_value',
  /** column name */
  OwningteamValue = '_owningteam_value',
  /** column name */
  OwninguserValue = '_owninguser_value',
  /** column name */
  TransactioncurrencyidValue = '_transactioncurrencyid_value',
  /** column name */
  CdmCompensationtype = 'cdm_compensationtype',
  /** column name */
  CdmEffectivedate = 'cdm_effectivedate',
  /** column name */
  CdmExpirationdate = 'cdm_expirationdate',
  /** column name */
  CdmLinenumber = 'cdm_linenumber',
  /** column name */
  CdmPayrate = 'cdm_payrate',
  /** column name */
  CdmPayrateBase = 'cdm_payrate_base',
  /** column name */
  CdmProcesstype = 'cdm_processtype',
  /** column name */
  CdmWorkerfixedcompensationid = 'cdm_workerfixedcompensationid',
  /** column name */
  CdmWorkerfixedcompensationnumber = 'cdm_workerfixedcompensationnumber',
  /** column name */
  Createdon = 'createdon',
  /** column name */
  Exchangerate = 'exchangerate',
  /** column name */
  Importsequencenumber = 'importsequencenumber',
  /** column name */
  Modifiedon = 'modifiedon',
  /** column name */
  Overriddencreatedon = 'overriddencreatedon',
  /** column name */
  Statecode = 'statecode',
  /** column name */
  Statuscode = 'statuscode',
  /** column name */
  Timezoneruleversionnumber = 'timezoneruleversionnumber',
  /** column name */
  Utcconversiontimezonecode = 'utcconversiontimezonecode',
  /** column name */
  Versionnumber = 'versionnumber'
}

/** input type for updating data in table "hr.cdm_workerfixedcompensations" */
export type Hr_Cdm_Workerfixedcompensations_Set_Input = {
  _cdm_companyid_value?: Maybe<Scalars['uuid']>;
  _cdm_compensationlevelid_value?: Maybe<Scalars['uuid']>;
  _cdm_eventid_value?: Maybe<Scalars['uuid']>;
  _cdm_payfrequencyid_value?: Maybe<Scalars['uuid']>;
  _cdm_planid_value?: Maybe<Scalars['uuid']>;
  _cdm_positionid_value?: Maybe<Scalars['uuid']>;
  _cdm_referencepointsetuplineid_value?: Maybe<Scalars['uuid']>;
  _cdm_workerid_value?: Maybe<Scalars['uuid']>;
  _createdby_value?: Maybe<Scalars['uuid']>;
  _createdonbehalfby_value?: Maybe<Scalars['timestamptz']>;
  _modifiedby_value?: Maybe<Scalars['uuid']>;
  _modifiedonbehalfby_value?: Maybe<Scalars['timestamptz']>;
  _ownerid_value?: Maybe<Scalars['uuid']>;
  _owningbusinessunit_value?: Maybe<Scalars['uuid']>;
  _owningteam_value?: Maybe<Scalars['String']>;
  _owninguser_value?: Maybe<Scalars['uuid']>;
  _transactioncurrencyid_value?: Maybe<Scalars['uuid']>;
  cdm_compensationtype?: Maybe<Scalars['String']>;
  cdm_effectivedate?: Maybe<Scalars['timestamptz']>;
  cdm_expirationdate?: Maybe<Scalars['timestamptz']>;
  cdm_linenumber?: Maybe<Scalars['String']>;
  cdm_payrate?: Maybe<Scalars['String']>;
  cdm_payrate_base?: Maybe<Scalars['Int']>;
  cdm_processtype?: Maybe<Scalars['String']>;
  cdm_workerfixedcompensationid?: Maybe<Scalars['uuid']>;
  cdm_workerfixedcompensationnumber?: Maybe<Scalars['String']>;
  createdon?: Maybe<Scalars['timestamptz']>;
  exchangerate?: Maybe<Scalars['String']>;
  importsequencenumber?: Maybe<Scalars['String']>;
  modifiedon?: Maybe<Scalars['timestamptz']>;
  overriddencreatedon?: Maybe<Scalars['timestamptz']>;
  statecode?: Maybe<Scalars['String']>;
  statuscode?: Maybe<Scalars['String']>;
  timezoneruleversionnumber?: Maybe<Scalars['String']>;
  utcconversiontimezonecode?: Maybe<Scalars['String']>;
  versionnumber?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Hr_Cdm_Workerfixedcompensations_Stddev_Fields = {
  cdm_payrate_base?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "hr.cdm_workerfixedcompensations" */
export type Hr_Cdm_Workerfixedcompensations_Stddev_Order_By = {
  cdm_payrate_base?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Hr_Cdm_Workerfixedcompensations_Stddev_Pop_Fields = {
  cdm_payrate_base?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "hr.cdm_workerfixedcompensations" */
export type Hr_Cdm_Workerfixedcompensations_Stddev_Pop_Order_By = {
  cdm_payrate_base?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Hr_Cdm_Workerfixedcompensations_Stddev_Samp_Fields = {
  cdm_payrate_base?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "hr.cdm_workerfixedcompensations" */
export type Hr_Cdm_Workerfixedcompensations_Stddev_Samp_Order_By = {
  cdm_payrate_base?: Maybe<Order_By>;
};

/** aggregate sum on columns */
export type Hr_Cdm_Workerfixedcompensations_Sum_Fields = {
  cdm_payrate_base?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "hr.cdm_workerfixedcompensations" */
export type Hr_Cdm_Workerfixedcompensations_Sum_Order_By = {
  cdm_payrate_base?: Maybe<Order_By>;
};

/** aggregate var_pop on columns */
export type Hr_Cdm_Workerfixedcompensations_Var_Pop_Fields = {
  cdm_payrate_base?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "hr.cdm_workerfixedcompensations" */
export type Hr_Cdm_Workerfixedcompensations_Var_Pop_Order_By = {
  cdm_payrate_base?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Hr_Cdm_Workerfixedcompensations_Var_Samp_Fields = {
  cdm_payrate_base?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "hr.cdm_workerfixedcompensations" */
export type Hr_Cdm_Workerfixedcompensations_Var_Samp_Order_By = {
  cdm_payrate_base?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Hr_Cdm_Workerfixedcompensations_Variance_Fields = {
  cdm_payrate_base?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "hr.cdm_workerfixedcompensations" */
export type Hr_Cdm_Workerfixedcompensations_Variance_Order_By = {
  cdm_payrate_base?: Maybe<Order_By>;
};

/** columns and relationships of "hr.cdm_workers" */
export type Hr_Cdm_Workers = {
  _cdm_managerworkerid_value?: Maybe<Scalars['uuid']>;
  _cdm_titleid_value?: Maybe<Scalars['String']>;
  _createdby_value?: Maybe<Scalars['uuid']>;
  _createdonbehalfby_value?: Maybe<Scalars['timestamptz']>;
  _modifiedby_value?: Maybe<Scalars['uuid']>;
  _modifiedonbehalfby_value?: Maybe<Scalars['timestamptz']>;
  _msdyn_userid_value?: Maybe<Scalars['uuid']>;
  _ownerid_value?: Maybe<Scalars['uuid']>;
  _owningbusinessunit_value?: Maybe<Scalars['uuid']>;
  _owningteam_value?: Maybe<Scalars['String']>;
  _owninguser_value?: Maybe<Scalars['uuid']>;
  /** An object relationship */
  account?: Maybe<Auth_Accounts>;
  /** An object relationship */
  accounts?: Maybe<Auth_Accounts>;
  /** An array relationship */
  arm_partnerroleasiignment: Array<Hr_Arm_Partnerroleassignment>;
  /** An aggregate relationship */
  arm_partnerroleasiignment_aggregate: Hr_Arm_Partnerroleassignment_Aggregate;
  /** An array relationship */
  arm_teamleadassignments: Array<Hr_Arm_Teamleadassignment>;
  /** An aggregate relationship */
  arm_teamleadassignments_aggregate: Hr_Arm_Teamleadassignment_Aggregate;
  cdm_anniversarydatetime?: Maybe<Scalars['timestamptz']>;
  cdm_archicad_custom?: Maybe<Scalars['String']>;
  cdm_autocad_custom?: Maybe<Scalars['String']>;
  cdm_birthdate?: Maybe<Scalars['timestamptz']>;
  cdm_description?: Maybe<Scalars['String']>;
  cdm_emailaddress1?: Maybe<Scalars['String']>;
  cdm_emailaddress2?: Maybe<Scalars['String']>;
  /** An array relationship */
  cdm_employments: Array<Hr_Cdm_Employments>;
  /** An aggregate relationship */
  cdm_employments_aggregate: Hr_Cdm_Employments_Aggregate;
  cdm_facebookidentity?: Maybe<Scalars['String']>;
  cdm_firstname?: Maybe<Scalars['String']>;
  cdm_firstnameukr_custom?: Maybe<Scalars['String']>;
  cdm_fullname?: Maybe<Scalars['String']>;
  cdm_gender?: Maybe<Scalars['String']>;
  cdm_generation?: Maybe<Scalars['String']>;
  cdm_isemailcontactallowed?: Maybe<Scalars['String']>;
  cdm_isphonecontactallowed?: Maybe<Scalars['String']>;
  cdm_knownas?: Maybe<Scalars['String']>;
  cdm_languageid?: Maybe<Scalars['String']>;
  cdm_laptop_custom?: Maybe<Scalars['String']>;
  cdm_lastname?: Maybe<Scalars['String']>;
  cdm_lastnameukr_custom?: Maybe<Scalars['String']>;
  /** An array relationship */
  cdm_leaveenrollments: Array<Hr_Cdm_Leaveenrollments>;
  /** An aggregate relationship */
  cdm_leaveenrollments_aggregate: Hr_Cdm_Leaveenrollments_Aggregate;
  cdm_linkedinidentity?: Maybe<Scalars['String']>;
  cdm_maxlumion_custom?: Maybe<Scalars['String']>;
  cdm_middlename?: Maybe<Scalars['String']>;
  cdm_mobilephone?: Maybe<Scalars['String']>;
  cdm_namesequencedisplayas?: Maybe<Scalars['String']>;
  cdm_officegraphidentifier?: Maybe<Scalars['String']>;
  cdm_originalhiredatetime?: Maybe<Scalars['timestamptz']>;
  cdm_patronymicukr_custom?: Maybe<Scalars['String']>;
  cdm_pc_custom?: Maybe<Scalars['String']>;
  /** An array relationship */
  cdm_positionworkerassignmentmaps: Array<Hr_Cdm_Positionworkerassignmentmaps>;
  /** An aggregate relationship */
  cdm_positionworkerassignmentmaps_aggregate: Hr_Cdm_Positionworkerassignmentmaps_Aggregate;
  cdm_primaryemailaddress?: Maybe<Scalars['String']>;
  cdm_primarytelephone?: Maybe<Scalars['String']>;
  cdm_profession?: Maybe<Scalars['String']>;
  cdm_revit_custom?: Maybe<Scalars['String']>;
  cdm_senioritydate?: Maybe<Scalars['timestamptz']>;
  cdm_socialnetwork01?: Maybe<Scalars['String']>;
  cdm_socialnetwork02?: Maybe<Scalars['String']>;
  cdm_socialnetworkidentity01?: Maybe<Scalars['String']>;
  cdm_socialnetworkidentity02?: Maybe<Scalars['String']>;
  cdm_source?: Maybe<Scalars['String']>;
  cdm_status?: Maybe<Scalars['String']>;
  cdm_tablet_custom?: Maybe<Scalars['String']>;
  cdm_telephone1?: Maybe<Scalars['String']>;
  cdm_telephone2?: Maybe<Scalars['String']>;
  cdm_telephone3?: Maybe<Scalars['String']>;
  cdm_twitteridentity?: Maybe<Scalars['String']>;
  cdm_type?: Maybe<Scalars['String']>;
  cdm_websiteurl?: Maybe<Scalars['String']>;
  /** An array relationship */
  cdm_workerfixedcompensations: Array<Hr_Cdm_Workerfixedcompensations>;
  /** An aggregate relationship */
  cdm_workerfixedcompensations_aggregate: Hr_Cdm_Workerfixedcompensations_Aggregate;
  cdm_workerid: Scalars['uuid'];
  cdm_workernumber?: Maybe<Scalars['String']>;
  cdm_worksfromhome?: Maybe<Scalars['String']>;
  cdm_yomifirstname?: Maybe<Scalars['String']>;
  cdm_yomifullname?: Maybe<Scalars['String']>;
  cdm_yomilastname?: Maybe<Scalars['String']>;
  cdm_yomimiddlename?: Maybe<Scalars['String']>;
  createdon?: Maybe<Scalars['timestamptz']>;
  importsequencenumber?: Maybe<Scalars['String']>;
  modifiedon?: Maybe<Scalars['timestamptz']>;
  msdyn_linkedinmemberreference?: Maybe<Scalars['String']>;
  overriddencreatedon?: Maybe<Scalars['timestamptz']>;
  statecode?: Maybe<Scalars['String']>;
  statuscode?: Maybe<Scalars['String']>;
  timezoneruleversionnumber?: Maybe<Scalars['String']>;
  utcconversiontimezonecode?: Maybe<Scalars['String']>;
  versionnumber?: Maybe<Scalars['String']>;
};


/** columns and relationships of "hr.cdm_workers" */
export type Hr_Cdm_WorkersArm_PartnerroleasiignmentArgs = {
  distinct_on?: Maybe<Array<Hr_Arm_Partnerroleassignment_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Hr_Arm_Partnerroleassignment_Order_By>>;
  where?: Maybe<Hr_Arm_Partnerroleassignment_Bool_Exp>;
};


/** columns and relationships of "hr.cdm_workers" */
export type Hr_Cdm_WorkersArm_Partnerroleasiignment_AggregateArgs = {
  distinct_on?: Maybe<Array<Hr_Arm_Partnerroleassignment_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Hr_Arm_Partnerroleassignment_Order_By>>;
  where?: Maybe<Hr_Arm_Partnerroleassignment_Bool_Exp>;
};


/** columns and relationships of "hr.cdm_workers" */
export type Hr_Cdm_WorkersArm_TeamleadassignmentsArgs = {
  distinct_on?: Maybe<Array<Hr_Arm_Teamleadassignment_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Hr_Arm_Teamleadassignment_Order_By>>;
  where?: Maybe<Hr_Arm_Teamleadassignment_Bool_Exp>;
};


/** columns and relationships of "hr.cdm_workers" */
export type Hr_Cdm_WorkersArm_Teamleadassignments_AggregateArgs = {
  distinct_on?: Maybe<Array<Hr_Arm_Teamleadassignment_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Hr_Arm_Teamleadassignment_Order_By>>;
  where?: Maybe<Hr_Arm_Teamleadassignment_Bool_Exp>;
};


/** columns and relationships of "hr.cdm_workers" */
export type Hr_Cdm_WorkersCdm_EmploymentsArgs = {
  distinct_on?: Maybe<Array<Hr_Cdm_Employments_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Hr_Cdm_Employments_Order_By>>;
  where?: Maybe<Hr_Cdm_Employments_Bool_Exp>;
};


/** columns and relationships of "hr.cdm_workers" */
export type Hr_Cdm_WorkersCdm_Employments_AggregateArgs = {
  distinct_on?: Maybe<Array<Hr_Cdm_Employments_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Hr_Cdm_Employments_Order_By>>;
  where?: Maybe<Hr_Cdm_Employments_Bool_Exp>;
};


/** columns and relationships of "hr.cdm_workers" */
export type Hr_Cdm_WorkersCdm_LeaveenrollmentsArgs = {
  distinct_on?: Maybe<Array<Hr_Cdm_Leaveenrollments_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Hr_Cdm_Leaveenrollments_Order_By>>;
  where?: Maybe<Hr_Cdm_Leaveenrollments_Bool_Exp>;
};


/** columns and relationships of "hr.cdm_workers" */
export type Hr_Cdm_WorkersCdm_Leaveenrollments_AggregateArgs = {
  distinct_on?: Maybe<Array<Hr_Cdm_Leaveenrollments_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Hr_Cdm_Leaveenrollments_Order_By>>;
  where?: Maybe<Hr_Cdm_Leaveenrollments_Bool_Exp>;
};


/** columns and relationships of "hr.cdm_workers" */
export type Hr_Cdm_WorkersCdm_PositionworkerassignmentmapsArgs = {
  distinct_on?: Maybe<Array<Hr_Cdm_Positionworkerassignmentmaps_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Hr_Cdm_Positionworkerassignmentmaps_Order_By>>;
  where?: Maybe<Hr_Cdm_Positionworkerassignmentmaps_Bool_Exp>;
};


/** columns and relationships of "hr.cdm_workers" */
export type Hr_Cdm_WorkersCdm_Positionworkerassignmentmaps_AggregateArgs = {
  distinct_on?: Maybe<Array<Hr_Cdm_Positionworkerassignmentmaps_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Hr_Cdm_Positionworkerassignmentmaps_Order_By>>;
  where?: Maybe<Hr_Cdm_Positionworkerassignmentmaps_Bool_Exp>;
};


/** columns and relationships of "hr.cdm_workers" */
export type Hr_Cdm_WorkersCdm_WorkerfixedcompensationsArgs = {
  distinct_on?: Maybe<Array<Hr_Cdm_Workerfixedcompensations_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Hr_Cdm_Workerfixedcompensations_Order_By>>;
  where?: Maybe<Hr_Cdm_Workerfixedcompensations_Bool_Exp>;
};


/** columns and relationships of "hr.cdm_workers" */
export type Hr_Cdm_WorkersCdm_Workerfixedcompensations_AggregateArgs = {
  distinct_on?: Maybe<Array<Hr_Cdm_Workerfixedcompensations_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Hr_Cdm_Workerfixedcompensations_Order_By>>;
  where?: Maybe<Hr_Cdm_Workerfixedcompensations_Bool_Exp>;
};

/** aggregated selection of "hr.cdm_workers" */
export type Hr_Cdm_Workers_Aggregate = {
  aggregate?: Maybe<Hr_Cdm_Workers_Aggregate_Fields>;
  nodes: Array<Hr_Cdm_Workers>;
};

/** aggregate fields of "hr.cdm_workers" */
export type Hr_Cdm_Workers_Aggregate_Fields = {
  count: Scalars['Int'];
  max?: Maybe<Hr_Cdm_Workers_Max_Fields>;
  min?: Maybe<Hr_Cdm_Workers_Min_Fields>;
};


/** aggregate fields of "hr.cdm_workers" */
export type Hr_Cdm_Workers_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Hr_Cdm_Workers_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "hr.cdm_workers". All fields are combined with a logical 'AND'. */
export type Hr_Cdm_Workers_Bool_Exp = {
  _and?: Maybe<Array<Hr_Cdm_Workers_Bool_Exp>>;
  _cdm_managerworkerid_value?: Maybe<Uuid_Comparison_Exp>;
  _cdm_titleid_value?: Maybe<String_Comparison_Exp>;
  _createdby_value?: Maybe<Uuid_Comparison_Exp>;
  _createdonbehalfby_value?: Maybe<Timestamptz_Comparison_Exp>;
  _modifiedby_value?: Maybe<Uuid_Comparison_Exp>;
  _modifiedonbehalfby_value?: Maybe<Timestamptz_Comparison_Exp>;
  _msdyn_userid_value?: Maybe<Uuid_Comparison_Exp>;
  _not?: Maybe<Hr_Cdm_Workers_Bool_Exp>;
  _or?: Maybe<Array<Hr_Cdm_Workers_Bool_Exp>>;
  _ownerid_value?: Maybe<Uuid_Comparison_Exp>;
  _owningbusinessunit_value?: Maybe<Uuid_Comparison_Exp>;
  _owningteam_value?: Maybe<String_Comparison_Exp>;
  _owninguser_value?: Maybe<Uuid_Comparison_Exp>;
  account?: Maybe<Auth_Accounts_Bool_Exp>;
  accounts?: Maybe<Auth_Accounts_Bool_Exp>;
  arm_partnerroleasiignment?: Maybe<Hr_Arm_Partnerroleassignment_Bool_Exp>;
  arm_teamleadassignments?: Maybe<Hr_Arm_Teamleadassignment_Bool_Exp>;
  cdm_anniversarydatetime?: Maybe<Timestamptz_Comparison_Exp>;
  cdm_archicad_custom?: Maybe<String_Comparison_Exp>;
  cdm_autocad_custom?: Maybe<String_Comparison_Exp>;
  cdm_birthdate?: Maybe<Timestamptz_Comparison_Exp>;
  cdm_description?: Maybe<String_Comparison_Exp>;
  cdm_emailaddress1?: Maybe<String_Comparison_Exp>;
  cdm_emailaddress2?: Maybe<String_Comparison_Exp>;
  cdm_employments?: Maybe<Hr_Cdm_Employments_Bool_Exp>;
  cdm_facebookidentity?: Maybe<String_Comparison_Exp>;
  cdm_firstname?: Maybe<String_Comparison_Exp>;
  cdm_firstnameukr_custom?: Maybe<String_Comparison_Exp>;
  cdm_fullname?: Maybe<String_Comparison_Exp>;
  cdm_gender?: Maybe<String_Comparison_Exp>;
  cdm_generation?: Maybe<String_Comparison_Exp>;
  cdm_isemailcontactallowed?: Maybe<String_Comparison_Exp>;
  cdm_isphonecontactallowed?: Maybe<String_Comparison_Exp>;
  cdm_knownas?: Maybe<String_Comparison_Exp>;
  cdm_languageid?: Maybe<String_Comparison_Exp>;
  cdm_laptop_custom?: Maybe<String_Comparison_Exp>;
  cdm_lastname?: Maybe<String_Comparison_Exp>;
  cdm_lastnameukr_custom?: Maybe<String_Comparison_Exp>;
  cdm_leaveenrollments?: Maybe<Hr_Cdm_Leaveenrollments_Bool_Exp>;
  cdm_linkedinidentity?: Maybe<String_Comparison_Exp>;
  cdm_maxlumion_custom?: Maybe<String_Comparison_Exp>;
  cdm_middlename?: Maybe<String_Comparison_Exp>;
  cdm_mobilephone?: Maybe<String_Comparison_Exp>;
  cdm_namesequencedisplayas?: Maybe<String_Comparison_Exp>;
  cdm_officegraphidentifier?: Maybe<String_Comparison_Exp>;
  cdm_originalhiredatetime?: Maybe<Timestamptz_Comparison_Exp>;
  cdm_patronymicukr_custom?: Maybe<String_Comparison_Exp>;
  cdm_pc_custom?: Maybe<String_Comparison_Exp>;
  cdm_positionworkerassignmentmaps?: Maybe<Hr_Cdm_Positionworkerassignmentmaps_Bool_Exp>;
  cdm_primaryemailaddress?: Maybe<String_Comparison_Exp>;
  cdm_primarytelephone?: Maybe<String_Comparison_Exp>;
  cdm_profession?: Maybe<String_Comparison_Exp>;
  cdm_revit_custom?: Maybe<String_Comparison_Exp>;
  cdm_senioritydate?: Maybe<Timestamptz_Comparison_Exp>;
  cdm_socialnetwork01?: Maybe<String_Comparison_Exp>;
  cdm_socialnetwork02?: Maybe<String_Comparison_Exp>;
  cdm_socialnetworkidentity01?: Maybe<String_Comparison_Exp>;
  cdm_socialnetworkidentity02?: Maybe<String_Comparison_Exp>;
  cdm_source?: Maybe<String_Comparison_Exp>;
  cdm_status?: Maybe<String_Comparison_Exp>;
  cdm_tablet_custom?: Maybe<String_Comparison_Exp>;
  cdm_telephone1?: Maybe<String_Comparison_Exp>;
  cdm_telephone2?: Maybe<String_Comparison_Exp>;
  cdm_telephone3?: Maybe<String_Comparison_Exp>;
  cdm_twitteridentity?: Maybe<String_Comparison_Exp>;
  cdm_type?: Maybe<String_Comparison_Exp>;
  cdm_websiteurl?: Maybe<String_Comparison_Exp>;
  cdm_workerfixedcompensations?: Maybe<Hr_Cdm_Workerfixedcompensations_Bool_Exp>;
  cdm_workerid?: Maybe<Uuid_Comparison_Exp>;
  cdm_workernumber?: Maybe<String_Comparison_Exp>;
  cdm_worksfromhome?: Maybe<String_Comparison_Exp>;
  cdm_yomifirstname?: Maybe<String_Comparison_Exp>;
  cdm_yomifullname?: Maybe<String_Comparison_Exp>;
  cdm_yomilastname?: Maybe<String_Comparison_Exp>;
  cdm_yomimiddlename?: Maybe<String_Comparison_Exp>;
  createdon?: Maybe<Timestamptz_Comparison_Exp>;
  importsequencenumber?: Maybe<String_Comparison_Exp>;
  modifiedon?: Maybe<Timestamptz_Comparison_Exp>;
  msdyn_linkedinmemberreference?: Maybe<String_Comparison_Exp>;
  overriddencreatedon?: Maybe<Timestamptz_Comparison_Exp>;
  statecode?: Maybe<String_Comparison_Exp>;
  statuscode?: Maybe<String_Comparison_Exp>;
  timezoneruleversionnumber?: Maybe<String_Comparison_Exp>;
  utcconversiontimezonecode?: Maybe<String_Comparison_Exp>;
  versionnumber?: Maybe<String_Comparison_Exp>;
};

/** input type for inserting data into table "hr.cdm_workers" */
export type Hr_Cdm_Workers_Insert_Input = {
  _cdm_managerworkerid_value?: Maybe<Scalars['uuid']>;
  _cdm_titleid_value?: Maybe<Scalars['String']>;
  _createdby_value?: Maybe<Scalars['uuid']>;
  _createdonbehalfby_value?: Maybe<Scalars['timestamptz']>;
  _modifiedby_value?: Maybe<Scalars['uuid']>;
  _modifiedonbehalfby_value?: Maybe<Scalars['timestamptz']>;
  _msdyn_userid_value?: Maybe<Scalars['uuid']>;
  _ownerid_value?: Maybe<Scalars['uuid']>;
  _owningbusinessunit_value?: Maybe<Scalars['uuid']>;
  _owningteam_value?: Maybe<Scalars['String']>;
  _owninguser_value?: Maybe<Scalars['uuid']>;
  account?: Maybe<Auth_Accounts_Obj_Rel_Insert_Input>;
  accounts?: Maybe<Auth_Accounts_Obj_Rel_Insert_Input>;
  arm_partnerroleasiignment?: Maybe<Hr_Arm_Partnerroleassignment_Arr_Rel_Insert_Input>;
  arm_teamleadassignments?: Maybe<Hr_Arm_Teamleadassignment_Arr_Rel_Insert_Input>;
  cdm_anniversarydatetime?: Maybe<Scalars['timestamptz']>;
  cdm_archicad_custom?: Maybe<Scalars['String']>;
  cdm_autocad_custom?: Maybe<Scalars['String']>;
  cdm_birthdate?: Maybe<Scalars['timestamptz']>;
  cdm_description?: Maybe<Scalars['String']>;
  cdm_emailaddress1?: Maybe<Scalars['String']>;
  cdm_emailaddress2?: Maybe<Scalars['String']>;
  cdm_employments?: Maybe<Hr_Cdm_Employments_Arr_Rel_Insert_Input>;
  cdm_facebookidentity?: Maybe<Scalars['String']>;
  cdm_firstname?: Maybe<Scalars['String']>;
  cdm_firstnameukr_custom?: Maybe<Scalars['String']>;
  cdm_fullname?: Maybe<Scalars['String']>;
  cdm_gender?: Maybe<Scalars['String']>;
  cdm_generation?: Maybe<Scalars['String']>;
  cdm_isemailcontactallowed?: Maybe<Scalars['String']>;
  cdm_isphonecontactallowed?: Maybe<Scalars['String']>;
  cdm_knownas?: Maybe<Scalars['String']>;
  cdm_languageid?: Maybe<Scalars['String']>;
  cdm_laptop_custom?: Maybe<Scalars['String']>;
  cdm_lastname?: Maybe<Scalars['String']>;
  cdm_lastnameukr_custom?: Maybe<Scalars['String']>;
  cdm_leaveenrollments?: Maybe<Hr_Cdm_Leaveenrollments_Arr_Rel_Insert_Input>;
  cdm_linkedinidentity?: Maybe<Scalars['String']>;
  cdm_maxlumion_custom?: Maybe<Scalars['String']>;
  cdm_middlename?: Maybe<Scalars['String']>;
  cdm_mobilephone?: Maybe<Scalars['String']>;
  cdm_namesequencedisplayas?: Maybe<Scalars['String']>;
  cdm_officegraphidentifier?: Maybe<Scalars['String']>;
  cdm_originalhiredatetime?: Maybe<Scalars['timestamptz']>;
  cdm_patronymicukr_custom?: Maybe<Scalars['String']>;
  cdm_pc_custom?: Maybe<Scalars['String']>;
  cdm_positionworkerassignmentmaps?: Maybe<Hr_Cdm_Positionworkerassignmentmaps_Arr_Rel_Insert_Input>;
  cdm_primaryemailaddress?: Maybe<Scalars['String']>;
  cdm_primarytelephone?: Maybe<Scalars['String']>;
  cdm_profession?: Maybe<Scalars['String']>;
  cdm_revit_custom?: Maybe<Scalars['String']>;
  cdm_senioritydate?: Maybe<Scalars['timestamptz']>;
  cdm_socialnetwork01?: Maybe<Scalars['String']>;
  cdm_socialnetwork02?: Maybe<Scalars['String']>;
  cdm_socialnetworkidentity01?: Maybe<Scalars['String']>;
  cdm_socialnetworkidentity02?: Maybe<Scalars['String']>;
  cdm_source?: Maybe<Scalars['String']>;
  cdm_status?: Maybe<Scalars['String']>;
  cdm_tablet_custom?: Maybe<Scalars['String']>;
  cdm_telephone1?: Maybe<Scalars['String']>;
  cdm_telephone2?: Maybe<Scalars['String']>;
  cdm_telephone3?: Maybe<Scalars['String']>;
  cdm_twitteridentity?: Maybe<Scalars['String']>;
  cdm_type?: Maybe<Scalars['String']>;
  cdm_websiteurl?: Maybe<Scalars['String']>;
  cdm_workerfixedcompensations?: Maybe<Hr_Cdm_Workerfixedcompensations_Arr_Rel_Insert_Input>;
  cdm_workerid?: Maybe<Scalars['uuid']>;
  cdm_workernumber?: Maybe<Scalars['String']>;
  cdm_worksfromhome?: Maybe<Scalars['String']>;
  cdm_yomifirstname?: Maybe<Scalars['String']>;
  cdm_yomifullname?: Maybe<Scalars['String']>;
  cdm_yomilastname?: Maybe<Scalars['String']>;
  cdm_yomimiddlename?: Maybe<Scalars['String']>;
  createdon?: Maybe<Scalars['timestamptz']>;
  importsequencenumber?: Maybe<Scalars['String']>;
  modifiedon?: Maybe<Scalars['timestamptz']>;
  msdyn_linkedinmemberreference?: Maybe<Scalars['String']>;
  overriddencreatedon?: Maybe<Scalars['timestamptz']>;
  statecode?: Maybe<Scalars['String']>;
  statuscode?: Maybe<Scalars['String']>;
  timezoneruleversionnumber?: Maybe<Scalars['String']>;
  utcconversiontimezonecode?: Maybe<Scalars['String']>;
  versionnumber?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Hr_Cdm_Workers_Max_Fields = {
  _cdm_managerworkerid_value?: Maybe<Scalars['uuid']>;
  _cdm_titleid_value?: Maybe<Scalars['String']>;
  _createdby_value?: Maybe<Scalars['uuid']>;
  _createdonbehalfby_value?: Maybe<Scalars['timestamptz']>;
  _modifiedby_value?: Maybe<Scalars['uuid']>;
  _modifiedonbehalfby_value?: Maybe<Scalars['timestamptz']>;
  _msdyn_userid_value?: Maybe<Scalars['uuid']>;
  _ownerid_value?: Maybe<Scalars['uuid']>;
  _owningbusinessunit_value?: Maybe<Scalars['uuid']>;
  _owningteam_value?: Maybe<Scalars['String']>;
  _owninguser_value?: Maybe<Scalars['uuid']>;
  cdm_anniversarydatetime?: Maybe<Scalars['timestamptz']>;
  cdm_archicad_custom?: Maybe<Scalars['String']>;
  cdm_autocad_custom?: Maybe<Scalars['String']>;
  cdm_birthdate?: Maybe<Scalars['timestamptz']>;
  cdm_description?: Maybe<Scalars['String']>;
  cdm_emailaddress1?: Maybe<Scalars['String']>;
  cdm_emailaddress2?: Maybe<Scalars['String']>;
  cdm_facebookidentity?: Maybe<Scalars['String']>;
  cdm_firstname?: Maybe<Scalars['String']>;
  cdm_firstnameukr_custom?: Maybe<Scalars['String']>;
  cdm_fullname?: Maybe<Scalars['String']>;
  cdm_gender?: Maybe<Scalars['String']>;
  cdm_generation?: Maybe<Scalars['String']>;
  cdm_isemailcontactallowed?: Maybe<Scalars['String']>;
  cdm_isphonecontactallowed?: Maybe<Scalars['String']>;
  cdm_knownas?: Maybe<Scalars['String']>;
  cdm_languageid?: Maybe<Scalars['String']>;
  cdm_laptop_custom?: Maybe<Scalars['String']>;
  cdm_lastname?: Maybe<Scalars['String']>;
  cdm_lastnameukr_custom?: Maybe<Scalars['String']>;
  cdm_linkedinidentity?: Maybe<Scalars['String']>;
  cdm_maxlumion_custom?: Maybe<Scalars['String']>;
  cdm_middlename?: Maybe<Scalars['String']>;
  cdm_mobilephone?: Maybe<Scalars['String']>;
  cdm_namesequencedisplayas?: Maybe<Scalars['String']>;
  cdm_officegraphidentifier?: Maybe<Scalars['String']>;
  cdm_originalhiredatetime?: Maybe<Scalars['timestamptz']>;
  cdm_patronymicukr_custom?: Maybe<Scalars['String']>;
  cdm_pc_custom?: Maybe<Scalars['String']>;
  cdm_primaryemailaddress?: Maybe<Scalars['String']>;
  cdm_primarytelephone?: Maybe<Scalars['String']>;
  cdm_profession?: Maybe<Scalars['String']>;
  cdm_revit_custom?: Maybe<Scalars['String']>;
  cdm_senioritydate?: Maybe<Scalars['timestamptz']>;
  cdm_socialnetwork01?: Maybe<Scalars['String']>;
  cdm_socialnetwork02?: Maybe<Scalars['String']>;
  cdm_socialnetworkidentity01?: Maybe<Scalars['String']>;
  cdm_socialnetworkidentity02?: Maybe<Scalars['String']>;
  cdm_source?: Maybe<Scalars['String']>;
  cdm_status?: Maybe<Scalars['String']>;
  cdm_tablet_custom?: Maybe<Scalars['String']>;
  cdm_telephone1?: Maybe<Scalars['String']>;
  cdm_telephone2?: Maybe<Scalars['String']>;
  cdm_telephone3?: Maybe<Scalars['String']>;
  cdm_twitteridentity?: Maybe<Scalars['String']>;
  cdm_type?: Maybe<Scalars['String']>;
  cdm_websiteurl?: Maybe<Scalars['String']>;
  cdm_workerid?: Maybe<Scalars['uuid']>;
  cdm_workernumber?: Maybe<Scalars['String']>;
  cdm_worksfromhome?: Maybe<Scalars['String']>;
  cdm_yomifirstname?: Maybe<Scalars['String']>;
  cdm_yomifullname?: Maybe<Scalars['String']>;
  cdm_yomilastname?: Maybe<Scalars['String']>;
  cdm_yomimiddlename?: Maybe<Scalars['String']>;
  createdon?: Maybe<Scalars['timestamptz']>;
  importsequencenumber?: Maybe<Scalars['String']>;
  modifiedon?: Maybe<Scalars['timestamptz']>;
  msdyn_linkedinmemberreference?: Maybe<Scalars['String']>;
  overriddencreatedon?: Maybe<Scalars['timestamptz']>;
  statecode?: Maybe<Scalars['String']>;
  statuscode?: Maybe<Scalars['String']>;
  timezoneruleversionnumber?: Maybe<Scalars['String']>;
  utcconversiontimezonecode?: Maybe<Scalars['String']>;
  versionnumber?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Hr_Cdm_Workers_Min_Fields = {
  _cdm_managerworkerid_value?: Maybe<Scalars['uuid']>;
  _cdm_titleid_value?: Maybe<Scalars['String']>;
  _createdby_value?: Maybe<Scalars['uuid']>;
  _createdonbehalfby_value?: Maybe<Scalars['timestamptz']>;
  _modifiedby_value?: Maybe<Scalars['uuid']>;
  _modifiedonbehalfby_value?: Maybe<Scalars['timestamptz']>;
  _msdyn_userid_value?: Maybe<Scalars['uuid']>;
  _ownerid_value?: Maybe<Scalars['uuid']>;
  _owningbusinessunit_value?: Maybe<Scalars['uuid']>;
  _owningteam_value?: Maybe<Scalars['String']>;
  _owninguser_value?: Maybe<Scalars['uuid']>;
  cdm_anniversarydatetime?: Maybe<Scalars['timestamptz']>;
  cdm_archicad_custom?: Maybe<Scalars['String']>;
  cdm_autocad_custom?: Maybe<Scalars['String']>;
  cdm_birthdate?: Maybe<Scalars['timestamptz']>;
  cdm_description?: Maybe<Scalars['String']>;
  cdm_emailaddress1?: Maybe<Scalars['String']>;
  cdm_emailaddress2?: Maybe<Scalars['String']>;
  cdm_facebookidentity?: Maybe<Scalars['String']>;
  cdm_firstname?: Maybe<Scalars['String']>;
  cdm_firstnameukr_custom?: Maybe<Scalars['String']>;
  cdm_fullname?: Maybe<Scalars['String']>;
  cdm_gender?: Maybe<Scalars['String']>;
  cdm_generation?: Maybe<Scalars['String']>;
  cdm_isemailcontactallowed?: Maybe<Scalars['String']>;
  cdm_isphonecontactallowed?: Maybe<Scalars['String']>;
  cdm_knownas?: Maybe<Scalars['String']>;
  cdm_languageid?: Maybe<Scalars['String']>;
  cdm_laptop_custom?: Maybe<Scalars['String']>;
  cdm_lastname?: Maybe<Scalars['String']>;
  cdm_lastnameukr_custom?: Maybe<Scalars['String']>;
  cdm_linkedinidentity?: Maybe<Scalars['String']>;
  cdm_maxlumion_custom?: Maybe<Scalars['String']>;
  cdm_middlename?: Maybe<Scalars['String']>;
  cdm_mobilephone?: Maybe<Scalars['String']>;
  cdm_namesequencedisplayas?: Maybe<Scalars['String']>;
  cdm_officegraphidentifier?: Maybe<Scalars['String']>;
  cdm_originalhiredatetime?: Maybe<Scalars['timestamptz']>;
  cdm_patronymicukr_custom?: Maybe<Scalars['String']>;
  cdm_pc_custom?: Maybe<Scalars['String']>;
  cdm_primaryemailaddress?: Maybe<Scalars['String']>;
  cdm_primarytelephone?: Maybe<Scalars['String']>;
  cdm_profession?: Maybe<Scalars['String']>;
  cdm_revit_custom?: Maybe<Scalars['String']>;
  cdm_senioritydate?: Maybe<Scalars['timestamptz']>;
  cdm_socialnetwork01?: Maybe<Scalars['String']>;
  cdm_socialnetwork02?: Maybe<Scalars['String']>;
  cdm_socialnetworkidentity01?: Maybe<Scalars['String']>;
  cdm_socialnetworkidentity02?: Maybe<Scalars['String']>;
  cdm_source?: Maybe<Scalars['String']>;
  cdm_status?: Maybe<Scalars['String']>;
  cdm_tablet_custom?: Maybe<Scalars['String']>;
  cdm_telephone1?: Maybe<Scalars['String']>;
  cdm_telephone2?: Maybe<Scalars['String']>;
  cdm_telephone3?: Maybe<Scalars['String']>;
  cdm_twitteridentity?: Maybe<Scalars['String']>;
  cdm_type?: Maybe<Scalars['String']>;
  cdm_websiteurl?: Maybe<Scalars['String']>;
  cdm_workerid?: Maybe<Scalars['uuid']>;
  cdm_workernumber?: Maybe<Scalars['String']>;
  cdm_worksfromhome?: Maybe<Scalars['String']>;
  cdm_yomifirstname?: Maybe<Scalars['String']>;
  cdm_yomifullname?: Maybe<Scalars['String']>;
  cdm_yomilastname?: Maybe<Scalars['String']>;
  cdm_yomimiddlename?: Maybe<Scalars['String']>;
  createdon?: Maybe<Scalars['timestamptz']>;
  importsequencenumber?: Maybe<Scalars['String']>;
  modifiedon?: Maybe<Scalars['timestamptz']>;
  msdyn_linkedinmemberreference?: Maybe<Scalars['String']>;
  overriddencreatedon?: Maybe<Scalars['timestamptz']>;
  statecode?: Maybe<Scalars['String']>;
  statuscode?: Maybe<Scalars['String']>;
  timezoneruleversionnumber?: Maybe<Scalars['String']>;
  utcconversiontimezonecode?: Maybe<Scalars['String']>;
  versionnumber?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "hr.cdm_workers" */
export type Hr_Cdm_Workers_Mutation_Response = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Hr_Cdm_Workers>;
};

/** input type for inserting object relation for remote table "hr.cdm_workers" */
export type Hr_Cdm_Workers_Obj_Rel_Insert_Input = {
  data: Hr_Cdm_Workers_Insert_Input;
};

/** Ordering options when selecting data from "hr.cdm_workers". */
export type Hr_Cdm_Workers_Order_By = {
  _cdm_managerworkerid_value?: Maybe<Order_By>;
  _cdm_titleid_value?: Maybe<Order_By>;
  _createdby_value?: Maybe<Order_By>;
  _createdonbehalfby_value?: Maybe<Order_By>;
  _modifiedby_value?: Maybe<Order_By>;
  _modifiedonbehalfby_value?: Maybe<Order_By>;
  _msdyn_userid_value?: Maybe<Order_By>;
  _ownerid_value?: Maybe<Order_By>;
  _owningbusinessunit_value?: Maybe<Order_By>;
  _owningteam_value?: Maybe<Order_By>;
  _owninguser_value?: Maybe<Order_By>;
  account?: Maybe<Auth_Accounts_Order_By>;
  accounts?: Maybe<Auth_Accounts_Order_By>;
  arm_partnerroleasiignment_aggregate?: Maybe<Hr_Arm_Partnerroleassignment_Aggregate_Order_By>;
  arm_teamleadassignments_aggregate?: Maybe<Hr_Arm_Teamleadassignment_Aggregate_Order_By>;
  cdm_anniversarydatetime?: Maybe<Order_By>;
  cdm_archicad_custom?: Maybe<Order_By>;
  cdm_autocad_custom?: Maybe<Order_By>;
  cdm_birthdate?: Maybe<Order_By>;
  cdm_description?: Maybe<Order_By>;
  cdm_emailaddress1?: Maybe<Order_By>;
  cdm_emailaddress2?: Maybe<Order_By>;
  cdm_employments_aggregate?: Maybe<Hr_Cdm_Employments_Aggregate_Order_By>;
  cdm_facebookidentity?: Maybe<Order_By>;
  cdm_firstname?: Maybe<Order_By>;
  cdm_firstnameukr_custom?: Maybe<Order_By>;
  cdm_fullname?: Maybe<Order_By>;
  cdm_gender?: Maybe<Order_By>;
  cdm_generation?: Maybe<Order_By>;
  cdm_isemailcontactallowed?: Maybe<Order_By>;
  cdm_isphonecontactallowed?: Maybe<Order_By>;
  cdm_knownas?: Maybe<Order_By>;
  cdm_languageid?: Maybe<Order_By>;
  cdm_laptop_custom?: Maybe<Order_By>;
  cdm_lastname?: Maybe<Order_By>;
  cdm_lastnameukr_custom?: Maybe<Order_By>;
  cdm_leaveenrollments_aggregate?: Maybe<Hr_Cdm_Leaveenrollments_Aggregate_Order_By>;
  cdm_linkedinidentity?: Maybe<Order_By>;
  cdm_maxlumion_custom?: Maybe<Order_By>;
  cdm_middlename?: Maybe<Order_By>;
  cdm_mobilephone?: Maybe<Order_By>;
  cdm_namesequencedisplayas?: Maybe<Order_By>;
  cdm_officegraphidentifier?: Maybe<Order_By>;
  cdm_originalhiredatetime?: Maybe<Order_By>;
  cdm_patronymicukr_custom?: Maybe<Order_By>;
  cdm_pc_custom?: Maybe<Order_By>;
  cdm_positionworkerassignmentmaps_aggregate?: Maybe<Hr_Cdm_Positionworkerassignmentmaps_Aggregate_Order_By>;
  cdm_primaryemailaddress?: Maybe<Order_By>;
  cdm_primarytelephone?: Maybe<Order_By>;
  cdm_profession?: Maybe<Order_By>;
  cdm_revit_custom?: Maybe<Order_By>;
  cdm_senioritydate?: Maybe<Order_By>;
  cdm_socialnetwork01?: Maybe<Order_By>;
  cdm_socialnetwork02?: Maybe<Order_By>;
  cdm_socialnetworkidentity01?: Maybe<Order_By>;
  cdm_socialnetworkidentity02?: Maybe<Order_By>;
  cdm_source?: Maybe<Order_By>;
  cdm_status?: Maybe<Order_By>;
  cdm_tablet_custom?: Maybe<Order_By>;
  cdm_telephone1?: Maybe<Order_By>;
  cdm_telephone2?: Maybe<Order_By>;
  cdm_telephone3?: Maybe<Order_By>;
  cdm_twitteridentity?: Maybe<Order_By>;
  cdm_type?: Maybe<Order_By>;
  cdm_websiteurl?: Maybe<Order_By>;
  cdm_workerfixedcompensations_aggregate?: Maybe<Hr_Cdm_Workerfixedcompensations_Aggregate_Order_By>;
  cdm_workerid?: Maybe<Order_By>;
  cdm_workernumber?: Maybe<Order_By>;
  cdm_worksfromhome?: Maybe<Order_By>;
  cdm_yomifirstname?: Maybe<Order_By>;
  cdm_yomifullname?: Maybe<Order_By>;
  cdm_yomilastname?: Maybe<Order_By>;
  cdm_yomimiddlename?: Maybe<Order_By>;
  createdon?: Maybe<Order_By>;
  importsequencenumber?: Maybe<Order_By>;
  modifiedon?: Maybe<Order_By>;
  msdyn_linkedinmemberreference?: Maybe<Order_By>;
  overriddencreatedon?: Maybe<Order_By>;
  statecode?: Maybe<Order_By>;
  statuscode?: Maybe<Order_By>;
  timezoneruleversionnumber?: Maybe<Order_By>;
  utcconversiontimezonecode?: Maybe<Order_By>;
  versionnumber?: Maybe<Order_By>;
};

/** select columns of table "hr.cdm_workers" */
export enum Hr_Cdm_Workers_Select_Column {
  /** column name */
  CdmManagerworkeridValue = '_cdm_managerworkerid_value',
  /** column name */
  CdmTitleidValue = '_cdm_titleid_value',
  /** column name */
  CreatedbyValue = '_createdby_value',
  /** column name */
  CreatedonbehalfbyValue = '_createdonbehalfby_value',
  /** column name */
  ModifiedbyValue = '_modifiedby_value',
  /** column name */
  ModifiedonbehalfbyValue = '_modifiedonbehalfby_value',
  /** column name */
  MsdynUseridValue = '_msdyn_userid_value',
  /** column name */
  OwneridValue = '_ownerid_value',
  /** column name */
  OwningbusinessunitValue = '_owningbusinessunit_value',
  /** column name */
  OwningteamValue = '_owningteam_value',
  /** column name */
  OwninguserValue = '_owninguser_value',
  /** column name */
  CdmAnniversarydatetime = 'cdm_anniversarydatetime',
  /** column name */
  CdmArchicadCustom = 'cdm_archicad_custom',
  /** column name */
  CdmAutocadCustom = 'cdm_autocad_custom',
  /** column name */
  CdmBirthdate = 'cdm_birthdate',
  /** column name */
  CdmDescription = 'cdm_description',
  /** column name */
  CdmEmailaddress1 = 'cdm_emailaddress1',
  /** column name */
  CdmEmailaddress2 = 'cdm_emailaddress2',
  /** column name */
  CdmFacebookidentity = 'cdm_facebookidentity',
  /** column name */
  CdmFirstname = 'cdm_firstname',
  /** column name */
  CdmFirstnameukrCustom = 'cdm_firstnameukr_custom',
  /** column name */
  CdmFullname = 'cdm_fullname',
  /** column name */
  CdmGender = 'cdm_gender',
  /** column name */
  CdmGeneration = 'cdm_generation',
  /** column name */
  CdmIsemailcontactallowed = 'cdm_isemailcontactallowed',
  /** column name */
  CdmIsphonecontactallowed = 'cdm_isphonecontactallowed',
  /** column name */
  CdmKnownas = 'cdm_knownas',
  /** column name */
  CdmLanguageid = 'cdm_languageid',
  /** column name */
  CdmLaptopCustom = 'cdm_laptop_custom',
  /** column name */
  CdmLastname = 'cdm_lastname',
  /** column name */
  CdmLastnameukrCustom = 'cdm_lastnameukr_custom',
  /** column name */
  CdmLinkedinidentity = 'cdm_linkedinidentity',
  /** column name */
  CdmMaxlumionCustom = 'cdm_maxlumion_custom',
  /** column name */
  CdmMiddlename = 'cdm_middlename',
  /** column name */
  CdmMobilephone = 'cdm_mobilephone',
  /** column name */
  CdmNamesequencedisplayas = 'cdm_namesequencedisplayas',
  /** column name */
  CdmOfficegraphidentifier = 'cdm_officegraphidentifier',
  /** column name */
  CdmOriginalhiredatetime = 'cdm_originalhiredatetime',
  /** column name */
  CdmPatronymicukrCustom = 'cdm_patronymicukr_custom',
  /** column name */
  CdmPcCustom = 'cdm_pc_custom',
  /** column name */
  CdmPrimaryemailaddress = 'cdm_primaryemailaddress',
  /** column name */
  CdmPrimarytelephone = 'cdm_primarytelephone',
  /** column name */
  CdmProfession = 'cdm_profession',
  /** column name */
  CdmRevitCustom = 'cdm_revit_custom',
  /** column name */
  CdmSenioritydate = 'cdm_senioritydate',
  /** column name */
  CdmSocialnetwork01 = 'cdm_socialnetwork01',
  /** column name */
  CdmSocialnetwork02 = 'cdm_socialnetwork02',
  /** column name */
  CdmSocialnetworkidentity01 = 'cdm_socialnetworkidentity01',
  /** column name */
  CdmSocialnetworkidentity02 = 'cdm_socialnetworkidentity02',
  /** column name */
  CdmSource = 'cdm_source',
  /** column name */
  CdmStatus = 'cdm_status',
  /** column name */
  CdmTabletCustom = 'cdm_tablet_custom',
  /** column name */
  CdmTelephone1 = 'cdm_telephone1',
  /** column name */
  CdmTelephone2 = 'cdm_telephone2',
  /** column name */
  CdmTelephone3 = 'cdm_telephone3',
  /** column name */
  CdmTwitteridentity = 'cdm_twitteridentity',
  /** column name */
  CdmType = 'cdm_type',
  /** column name */
  CdmWebsiteurl = 'cdm_websiteurl',
  /** column name */
  CdmWorkerid = 'cdm_workerid',
  /** column name */
  CdmWorkernumber = 'cdm_workernumber',
  /** column name */
  CdmWorksfromhome = 'cdm_worksfromhome',
  /** column name */
  CdmYomifirstname = 'cdm_yomifirstname',
  /** column name */
  CdmYomifullname = 'cdm_yomifullname',
  /** column name */
  CdmYomilastname = 'cdm_yomilastname',
  /** column name */
  CdmYomimiddlename = 'cdm_yomimiddlename',
  /** column name */
  Createdon = 'createdon',
  /** column name */
  Importsequencenumber = 'importsequencenumber',
  /** column name */
  Modifiedon = 'modifiedon',
  /** column name */
  MsdynLinkedinmemberreference = 'msdyn_linkedinmemberreference',
  /** column name */
  Overriddencreatedon = 'overriddencreatedon',
  /** column name */
  Statecode = 'statecode',
  /** column name */
  Statuscode = 'statuscode',
  /** column name */
  Timezoneruleversionnumber = 'timezoneruleversionnumber',
  /** column name */
  Utcconversiontimezonecode = 'utcconversiontimezonecode',
  /** column name */
  Versionnumber = 'versionnumber'
}

/** input type for updating data in table "hr.cdm_workers" */
export type Hr_Cdm_Workers_Set_Input = {
  _cdm_managerworkerid_value?: Maybe<Scalars['uuid']>;
  _cdm_titleid_value?: Maybe<Scalars['String']>;
  _createdby_value?: Maybe<Scalars['uuid']>;
  _createdonbehalfby_value?: Maybe<Scalars['timestamptz']>;
  _modifiedby_value?: Maybe<Scalars['uuid']>;
  _modifiedonbehalfby_value?: Maybe<Scalars['timestamptz']>;
  _msdyn_userid_value?: Maybe<Scalars['uuid']>;
  _ownerid_value?: Maybe<Scalars['uuid']>;
  _owningbusinessunit_value?: Maybe<Scalars['uuid']>;
  _owningteam_value?: Maybe<Scalars['String']>;
  _owninguser_value?: Maybe<Scalars['uuid']>;
  cdm_anniversarydatetime?: Maybe<Scalars['timestamptz']>;
  cdm_archicad_custom?: Maybe<Scalars['String']>;
  cdm_autocad_custom?: Maybe<Scalars['String']>;
  cdm_birthdate?: Maybe<Scalars['timestamptz']>;
  cdm_description?: Maybe<Scalars['String']>;
  cdm_emailaddress1?: Maybe<Scalars['String']>;
  cdm_emailaddress2?: Maybe<Scalars['String']>;
  cdm_facebookidentity?: Maybe<Scalars['String']>;
  cdm_firstname?: Maybe<Scalars['String']>;
  cdm_firstnameukr_custom?: Maybe<Scalars['String']>;
  cdm_fullname?: Maybe<Scalars['String']>;
  cdm_gender?: Maybe<Scalars['String']>;
  cdm_generation?: Maybe<Scalars['String']>;
  cdm_isemailcontactallowed?: Maybe<Scalars['String']>;
  cdm_isphonecontactallowed?: Maybe<Scalars['String']>;
  cdm_knownas?: Maybe<Scalars['String']>;
  cdm_languageid?: Maybe<Scalars['String']>;
  cdm_laptop_custom?: Maybe<Scalars['String']>;
  cdm_lastname?: Maybe<Scalars['String']>;
  cdm_lastnameukr_custom?: Maybe<Scalars['String']>;
  cdm_linkedinidentity?: Maybe<Scalars['String']>;
  cdm_maxlumion_custom?: Maybe<Scalars['String']>;
  cdm_middlename?: Maybe<Scalars['String']>;
  cdm_mobilephone?: Maybe<Scalars['String']>;
  cdm_namesequencedisplayas?: Maybe<Scalars['String']>;
  cdm_officegraphidentifier?: Maybe<Scalars['String']>;
  cdm_originalhiredatetime?: Maybe<Scalars['timestamptz']>;
  cdm_patronymicukr_custom?: Maybe<Scalars['String']>;
  cdm_pc_custom?: Maybe<Scalars['String']>;
  cdm_primaryemailaddress?: Maybe<Scalars['String']>;
  cdm_primarytelephone?: Maybe<Scalars['String']>;
  cdm_profession?: Maybe<Scalars['String']>;
  cdm_revit_custom?: Maybe<Scalars['String']>;
  cdm_senioritydate?: Maybe<Scalars['timestamptz']>;
  cdm_socialnetwork01?: Maybe<Scalars['String']>;
  cdm_socialnetwork02?: Maybe<Scalars['String']>;
  cdm_socialnetworkidentity01?: Maybe<Scalars['String']>;
  cdm_socialnetworkidentity02?: Maybe<Scalars['String']>;
  cdm_source?: Maybe<Scalars['String']>;
  cdm_status?: Maybe<Scalars['String']>;
  cdm_tablet_custom?: Maybe<Scalars['String']>;
  cdm_telephone1?: Maybe<Scalars['String']>;
  cdm_telephone2?: Maybe<Scalars['String']>;
  cdm_telephone3?: Maybe<Scalars['String']>;
  cdm_twitteridentity?: Maybe<Scalars['String']>;
  cdm_type?: Maybe<Scalars['String']>;
  cdm_websiteurl?: Maybe<Scalars['String']>;
  cdm_workerid?: Maybe<Scalars['uuid']>;
  cdm_workernumber?: Maybe<Scalars['String']>;
  cdm_worksfromhome?: Maybe<Scalars['String']>;
  cdm_yomifirstname?: Maybe<Scalars['String']>;
  cdm_yomifullname?: Maybe<Scalars['String']>;
  cdm_yomilastname?: Maybe<Scalars['String']>;
  cdm_yomimiddlename?: Maybe<Scalars['String']>;
  createdon?: Maybe<Scalars['timestamptz']>;
  importsequencenumber?: Maybe<Scalars['String']>;
  modifiedon?: Maybe<Scalars['timestamptz']>;
  msdyn_linkedinmemberreference?: Maybe<Scalars['String']>;
  overriddencreatedon?: Maybe<Scalars['timestamptz']>;
  statecode?: Maybe<Scalars['String']>;
  statuscode?: Maybe<Scalars['String']>;
  timezoneruleversionnumber?: Maybe<Scalars['String']>;
  utcconversiontimezonecode?: Maybe<Scalars['String']>;
  versionnumber?: Maybe<Scalars['String']>;
};


/** Boolean expression to compare columns of type "jsonb". All fields are combined with logical 'AND'. */
export type Jsonb_Comparison_Exp = {
  /** is the column contained in the given json value */
  _contained_in?: Maybe<Scalars['jsonb']>;
  /** does the column contain the given json value at the top level */
  _contains?: Maybe<Scalars['jsonb']>;
  _eq?: Maybe<Scalars['jsonb']>;
  _gt?: Maybe<Scalars['jsonb']>;
  _gte?: Maybe<Scalars['jsonb']>;
  /** does the string exist as a top-level key in the column */
  _has_key?: Maybe<Scalars['String']>;
  /** do all of these strings exist as top-level keys in the column */
  _has_keys_all?: Maybe<Array<Scalars['String']>>;
  /** do any of these strings exist as top-level keys in the column */
  _has_keys_any?: Maybe<Array<Scalars['String']>>;
  _in?: Maybe<Array<Scalars['jsonb']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['jsonb']>;
  _lte?: Maybe<Scalars['jsonb']>;
  _neq?: Maybe<Scalars['jsonb']>;
  _nin?: Maybe<Array<Scalars['jsonb']>>;
};

/** mutation root */
export type Mutation_Root = {
  /** delete data from the table: "auth.account_providers" */
  delete_auth_account_providers?: Maybe<Auth_Account_Providers_Mutation_Response>;
  /** delete single row from the table: "auth.account_providers" */
  delete_auth_account_providers_by_pk?: Maybe<Auth_Account_Providers>;
  /** delete data from the table: "auth.account_roles" */
  delete_auth_account_roles?: Maybe<Auth_Account_Roles_Mutation_Response>;
  /** delete single row from the table: "auth.account_roles" */
  delete_auth_account_roles_by_pk?: Maybe<Auth_Account_Roles>;
  /** delete data from the table: "auth.accounts" */
  delete_auth_accounts?: Maybe<Auth_Accounts_Mutation_Response>;
  /** delete single row from the table: "auth.accounts" */
  delete_auth_accounts_by_pk?: Maybe<Auth_Accounts>;
  /** delete data from the table: "auth.migrations" */
  delete_auth_migrations?: Maybe<Auth_Migrations_Mutation_Response>;
  /** delete single row from the table: "auth.migrations" */
  delete_auth_migrations_by_pk?: Maybe<Auth_Migrations>;
  /** delete data from the table: "auth.providers" */
  delete_auth_providers?: Maybe<Auth_Providers_Mutation_Response>;
  /** delete single row from the table: "auth.providers" */
  delete_auth_providers_by_pk?: Maybe<Auth_Providers>;
  /** delete data from the table: "auth.refresh_tokens" */
  delete_auth_refresh_tokens?: Maybe<Auth_Refresh_Tokens_Mutation_Response>;
  /** delete single row from the table: "auth.refresh_tokens" */
  delete_auth_refresh_tokens_by_pk?: Maybe<Auth_Refresh_Tokens>;
  /** delete data from the table: "auth.roles" */
  delete_auth_roles?: Maybe<Auth_Roles_Mutation_Response>;
  /** delete single row from the table: "auth.roles" */
  delete_auth_roles_by_pk?: Maybe<Auth_Roles>;
  /** delete data from the table: "budg_data" */
  delete_budg_data?: Maybe<Budg_Data_Mutation_Response>;
  /** delete single row from the table: "budg_data" */
  delete_budg_data_by_pk?: Maybe<Budg_Data>;
  /** delete data from the table: "budget.metadata" */
  delete_budget_metadata?: Maybe<Budget_Metadata_Mutation_Response>;
  /** delete single row from the table: "budget.metadata" */
  delete_budget_metadata_by_pk?: Maybe<Budget_Metadata>;
  /** delete data from the table: "budget.parameters" */
  delete_budget_parameters?: Maybe<Budget_Parameters_Mutation_Response>;
  /** delete data from the table: "budget.parameters_data" */
  delete_budget_parameters_data?: Maybe<Budget_Parameters_Data_Mutation_Response>;
  /** delete data from the table: "dividends" */
  delete_dividends?: Maybe<Dividends_Mutation_Response>;
  /** delete data from the table: "hr.arm_partnerroleassignment" */
  delete_hr_arm_partnerroleassignment?: Maybe<Hr_Arm_Partnerroleassignment_Mutation_Response>;
  /** delete data from the table: "hr.arm_partners" */
  delete_hr_arm_partners?: Maybe<Hr_Arm_Partners_Mutation_Response>;
  /** delete data from the table: "hr.arm_teamleadassignment" */
  delete_hr_arm_teamleadassignment?: Maybe<Hr_Arm_Teamleadassignment_Mutation_Response>;
  /** delete data from the table: "hr.cdm_compensationfixedplans" */
  delete_hr_cdm_compensationfixedplans?: Maybe<Hr_Cdm_Compensationfixedplans_Mutation_Response>;
  /** delete data from the table: "hr.cdm_compensationlevels" */
  delete_hr_cdm_compensationlevels?: Maybe<Hr_Cdm_Compensationlevels_Mutation_Response>;
  /** delete data from the table: "hr.cdm_departments" */
  delete_hr_cdm_departments?: Maybe<Hr_Cdm_Departments_Mutation_Response>;
  /** delete data from the table: "hr.cdm_employments" */
  delete_hr_cdm_employments?: Maybe<Hr_Cdm_Employments_Mutation_Response>;
  /** delete data from the table: "hr.cdm_jobpositions" */
  delete_hr_cdm_jobpositions?: Maybe<Hr_Cdm_Jobpositions_Mutation_Response>;
  /** delete data from the table: "hr.cdm_jobs" */
  delete_hr_cdm_jobs?: Maybe<Hr_Cdm_Jobs_Mutation_Response>;
  /** delete data from the table: "hr.cdm_jobtypes" */
  delete_hr_cdm_jobtypes?: Maybe<Hr_Cdm_Jobtypes_Mutation_Response>;
  /** delete data from the table: "hr.cdm_leaveenrollments" */
  delete_hr_cdm_leaveenrollments?: Maybe<Hr_Cdm_Leaveenrollments_Mutation_Response>;
  /** delete data from the table: "hr.cdm_leaveplans" */
  delete_hr_cdm_leaveplans?: Maybe<Hr_Cdm_Leaveplans_Mutation_Response>;
  /** delete data from the table: "hr.cdm_positionworkerassignmentmaps" */
  delete_hr_cdm_positionworkerassignmentmaps?: Maybe<Hr_Cdm_Positionworkerassignmentmaps_Mutation_Response>;
  /** delete data from the table: "hr.cdm_workerfixedcompensations" */
  delete_hr_cdm_workerfixedcompensations?: Maybe<Hr_Cdm_Workerfixedcompensations_Mutation_Response>;
  /** delete data from the table: "hr.cdm_workers" */
  delete_hr_cdm_workers?: Maybe<Hr_Cdm_Workers_Mutation_Response>;
  /** delete data from the table: "pwa.projects" */
  delete_pwa_projects?: Maybe<Pwa_Projects_Mutation_Response>;
  /** delete data from the table: "users" */
  delete_users?: Maybe<Users_Mutation_Response>;
  /** delete single row from the table: "users" */
  delete_users_by_pk?: Maybe<Users>;
  /** insert data into the table: "auth.account_providers" */
  insert_auth_account_providers?: Maybe<Auth_Account_Providers_Mutation_Response>;
  /** insert a single row into the table: "auth.account_providers" */
  insert_auth_account_providers_one?: Maybe<Auth_Account_Providers>;
  /** insert data into the table: "auth.account_roles" */
  insert_auth_account_roles?: Maybe<Auth_Account_Roles_Mutation_Response>;
  /** insert a single row into the table: "auth.account_roles" */
  insert_auth_account_roles_one?: Maybe<Auth_Account_Roles>;
  /** insert data into the table: "auth.accounts" */
  insert_auth_accounts?: Maybe<Auth_Accounts_Mutation_Response>;
  /** insert a single row into the table: "auth.accounts" */
  insert_auth_accounts_one?: Maybe<Auth_Accounts>;
  /** insert data into the table: "auth.migrations" */
  insert_auth_migrations?: Maybe<Auth_Migrations_Mutation_Response>;
  /** insert a single row into the table: "auth.migrations" */
  insert_auth_migrations_one?: Maybe<Auth_Migrations>;
  /** insert data into the table: "auth.providers" */
  insert_auth_providers?: Maybe<Auth_Providers_Mutation_Response>;
  /** insert a single row into the table: "auth.providers" */
  insert_auth_providers_one?: Maybe<Auth_Providers>;
  /** insert data into the table: "auth.refresh_tokens" */
  insert_auth_refresh_tokens?: Maybe<Auth_Refresh_Tokens_Mutation_Response>;
  /** insert a single row into the table: "auth.refresh_tokens" */
  insert_auth_refresh_tokens_one?: Maybe<Auth_Refresh_Tokens>;
  /** insert data into the table: "auth.roles" */
  insert_auth_roles?: Maybe<Auth_Roles_Mutation_Response>;
  /** insert a single row into the table: "auth.roles" */
  insert_auth_roles_one?: Maybe<Auth_Roles>;
  /** insert data into the table: "budg_data" */
  insert_budg_data?: Maybe<Budg_Data_Mutation_Response>;
  /** insert a single row into the table: "budg_data" */
  insert_budg_data_one?: Maybe<Budg_Data>;
  /** insert data into the table: "budget.metadata" */
  insert_budget_metadata?: Maybe<Budget_Metadata_Mutation_Response>;
  /** insert a single row into the table: "budget.metadata" */
  insert_budget_metadata_one?: Maybe<Budget_Metadata>;
  /** insert data into the table: "budget.parameters" */
  insert_budget_parameters?: Maybe<Budget_Parameters_Mutation_Response>;
  /** insert data into the table: "budget.parameters_data" */
  insert_budget_parameters_data?: Maybe<Budget_Parameters_Data_Mutation_Response>;
  /** insert a single row into the table: "budget.parameters_data" */
  insert_budget_parameters_data_one?: Maybe<Budget_Parameters_Data>;
  /** insert a single row into the table: "budget.parameters" */
  insert_budget_parameters_one?: Maybe<Budget_Parameters>;
  /** insert data into the table: "dividends" */
  insert_dividends?: Maybe<Dividends_Mutation_Response>;
  /** insert a single row into the table: "dividends" */
  insert_dividends_one?: Maybe<Dividends>;
  /** insert data into the table: "hr.arm_partnerroleassignment" */
  insert_hr_arm_partnerroleassignment?: Maybe<Hr_Arm_Partnerroleassignment_Mutation_Response>;
  /** insert a single row into the table: "hr.arm_partnerroleassignment" */
  insert_hr_arm_partnerroleassignment_one?: Maybe<Hr_Arm_Partnerroleassignment>;
  /** insert data into the table: "hr.arm_partners" */
  insert_hr_arm_partners?: Maybe<Hr_Arm_Partners_Mutation_Response>;
  /** insert a single row into the table: "hr.arm_partners" */
  insert_hr_arm_partners_one?: Maybe<Hr_Arm_Partners>;
  /** insert data into the table: "hr.arm_teamleadassignment" */
  insert_hr_arm_teamleadassignment?: Maybe<Hr_Arm_Teamleadassignment_Mutation_Response>;
  /** insert a single row into the table: "hr.arm_teamleadassignment" */
  insert_hr_arm_teamleadassignment_one?: Maybe<Hr_Arm_Teamleadassignment>;
  /** insert data into the table: "hr.cdm_compensationfixedplans" */
  insert_hr_cdm_compensationfixedplans?: Maybe<Hr_Cdm_Compensationfixedplans_Mutation_Response>;
  /** insert a single row into the table: "hr.cdm_compensationfixedplans" */
  insert_hr_cdm_compensationfixedplans_one?: Maybe<Hr_Cdm_Compensationfixedplans>;
  /** insert data into the table: "hr.cdm_compensationlevels" */
  insert_hr_cdm_compensationlevels?: Maybe<Hr_Cdm_Compensationlevels_Mutation_Response>;
  /** insert a single row into the table: "hr.cdm_compensationlevels" */
  insert_hr_cdm_compensationlevels_one?: Maybe<Hr_Cdm_Compensationlevels>;
  /** insert data into the table: "hr.cdm_departments" */
  insert_hr_cdm_departments?: Maybe<Hr_Cdm_Departments_Mutation_Response>;
  /** insert a single row into the table: "hr.cdm_departments" */
  insert_hr_cdm_departments_one?: Maybe<Hr_Cdm_Departments>;
  /** insert data into the table: "hr.cdm_employments" */
  insert_hr_cdm_employments?: Maybe<Hr_Cdm_Employments_Mutation_Response>;
  /** insert a single row into the table: "hr.cdm_employments" */
  insert_hr_cdm_employments_one?: Maybe<Hr_Cdm_Employments>;
  /** insert data into the table: "hr.cdm_jobpositions" */
  insert_hr_cdm_jobpositions?: Maybe<Hr_Cdm_Jobpositions_Mutation_Response>;
  /** insert a single row into the table: "hr.cdm_jobpositions" */
  insert_hr_cdm_jobpositions_one?: Maybe<Hr_Cdm_Jobpositions>;
  /** insert data into the table: "hr.cdm_jobs" */
  insert_hr_cdm_jobs?: Maybe<Hr_Cdm_Jobs_Mutation_Response>;
  /** insert a single row into the table: "hr.cdm_jobs" */
  insert_hr_cdm_jobs_one?: Maybe<Hr_Cdm_Jobs>;
  /** insert data into the table: "hr.cdm_jobtypes" */
  insert_hr_cdm_jobtypes?: Maybe<Hr_Cdm_Jobtypes_Mutation_Response>;
  /** insert a single row into the table: "hr.cdm_jobtypes" */
  insert_hr_cdm_jobtypes_one?: Maybe<Hr_Cdm_Jobtypes>;
  /** insert data into the table: "hr.cdm_leaveenrollments" */
  insert_hr_cdm_leaveenrollments?: Maybe<Hr_Cdm_Leaveenrollments_Mutation_Response>;
  /** insert a single row into the table: "hr.cdm_leaveenrollments" */
  insert_hr_cdm_leaveenrollments_one?: Maybe<Hr_Cdm_Leaveenrollments>;
  /** insert data into the table: "hr.cdm_leaveplans" */
  insert_hr_cdm_leaveplans?: Maybe<Hr_Cdm_Leaveplans_Mutation_Response>;
  /** insert a single row into the table: "hr.cdm_leaveplans" */
  insert_hr_cdm_leaveplans_one?: Maybe<Hr_Cdm_Leaveplans>;
  /** insert data into the table: "hr.cdm_positionworkerassignmentmaps" */
  insert_hr_cdm_positionworkerassignmentmaps?: Maybe<Hr_Cdm_Positionworkerassignmentmaps_Mutation_Response>;
  /** insert a single row into the table: "hr.cdm_positionworkerassignmentmaps" */
  insert_hr_cdm_positionworkerassignmentmaps_one?: Maybe<Hr_Cdm_Positionworkerassignmentmaps>;
  /** insert data into the table: "hr.cdm_workerfixedcompensations" */
  insert_hr_cdm_workerfixedcompensations?: Maybe<Hr_Cdm_Workerfixedcompensations_Mutation_Response>;
  /** insert a single row into the table: "hr.cdm_workerfixedcompensations" */
  insert_hr_cdm_workerfixedcompensations_one?: Maybe<Hr_Cdm_Workerfixedcompensations>;
  /** insert data into the table: "hr.cdm_workers" */
  insert_hr_cdm_workers?: Maybe<Hr_Cdm_Workers_Mutation_Response>;
  /** insert a single row into the table: "hr.cdm_workers" */
  insert_hr_cdm_workers_one?: Maybe<Hr_Cdm_Workers>;
  /** insert data into the table: "pwa.projects" */
  insert_pwa_projects?: Maybe<Pwa_Projects_Mutation_Response>;
  /** insert a single row into the table: "pwa.projects" */
  insert_pwa_projects_one?: Maybe<Pwa_Projects>;
  /** insert data into the table: "users" */
  insert_users?: Maybe<Users_Mutation_Response>;
  /** insert a single row into the table: "users" */
  insert_users_one?: Maybe<Users>;
  /** update data of the table: "auth.account_providers" */
  update_auth_account_providers?: Maybe<Auth_Account_Providers_Mutation_Response>;
  /** update single row of the table: "auth.account_providers" */
  update_auth_account_providers_by_pk?: Maybe<Auth_Account_Providers>;
  /** update data of the table: "auth.account_roles" */
  update_auth_account_roles?: Maybe<Auth_Account_Roles_Mutation_Response>;
  /** update single row of the table: "auth.account_roles" */
  update_auth_account_roles_by_pk?: Maybe<Auth_Account_Roles>;
  /** update data of the table: "auth.accounts" */
  update_auth_accounts?: Maybe<Auth_Accounts_Mutation_Response>;
  /** update single row of the table: "auth.accounts" */
  update_auth_accounts_by_pk?: Maybe<Auth_Accounts>;
  /** update data of the table: "auth.migrations" */
  update_auth_migrations?: Maybe<Auth_Migrations_Mutation_Response>;
  /** update single row of the table: "auth.migrations" */
  update_auth_migrations_by_pk?: Maybe<Auth_Migrations>;
  /** update data of the table: "auth.providers" */
  update_auth_providers?: Maybe<Auth_Providers_Mutation_Response>;
  /** update single row of the table: "auth.providers" */
  update_auth_providers_by_pk?: Maybe<Auth_Providers>;
  /** update data of the table: "auth.refresh_tokens" */
  update_auth_refresh_tokens?: Maybe<Auth_Refresh_Tokens_Mutation_Response>;
  /** update single row of the table: "auth.refresh_tokens" */
  update_auth_refresh_tokens_by_pk?: Maybe<Auth_Refresh_Tokens>;
  /** update data of the table: "auth.roles" */
  update_auth_roles?: Maybe<Auth_Roles_Mutation_Response>;
  /** update single row of the table: "auth.roles" */
  update_auth_roles_by_pk?: Maybe<Auth_Roles>;
  /** update data of the table: "budg_data" */
  update_budg_data?: Maybe<Budg_Data_Mutation_Response>;
  /** update single row of the table: "budg_data" */
  update_budg_data_by_pk?: Maybe<Budg_Data>;
  /** update data of the table: "budget.metadata" */
  update_budget_metadata?: Maybe<Budget_Metadata_Mutation_Response>;
  /** update single row of the table: "budget.metadata" */
  update_budget_metadata_by_pk?: Maybe<Budget_Metadata>;
  /** update data of the table: "budget.parameters" */
  update_budget_parameters?: Maybe<Budget_Parameters_Mutation_Response>;
  /** update data of the table: "budget.parameters_data" */
  update_budget_parameters_data?: Maybe<Budget_Parameters_Data_Mutation_Response>;
  /** update data of the table: "dividends" */
  update_dividends?: Maybe<Dividends_Mutation_Response>;
  /** update data of the table: "hr.arm_partnerroleassignment" */
  update_hr_arm_partnerroleassignment?: Maybe<Hr_Arm_Partnerroleassignment_Mutation_Response>;
  /** update data of the table: "hr.arm_partners" */
  update_hr_arm_partners?: Maybe<Hr_Arm_Partners_Mutation_Response>;
  /** update data of the table: "hr.arm_teamleadassignment" */
  update_hr_arm_teamleadassignment?: Maybe<Hr_Arm_Teamleadassignment_Mutation_Response>;
  /** update data of the table: "hr.cdm_compensationfixedplans" */
  update_hr_cdm_compensationfixedplans?: Maybe<Hr_Cdm_Compensationfixedplans_Mutation_Response>;
  /** update data of the table: "hr.cdm_compensationlevels" */
  update_hr_cdm_compensationlevels?: Maybe<Hr_Cdm_Compensationlevels_Mutation_Response>;
  /** update data of the table: "hr.cdm_departments" */
  update_hr_cdm_departments?: Maybe<Hr_Cdm_Departments_Mutation_Response>;
  /** update data of the table: "hr.cdm_employments" */
  update_hr_cdm_employments?: Maybe<Hr_Cdm_Employments_Mutation_Response>;
  /** update data of the table: "hr.cdm_jobpositions" */
  update_hr_cdm_jobpositions?: Maybe<Hr_Cdm_Jobpositions_Mutation_Response>;
  /** update data of the table: "hr.cdm_jobs" */
  update_hr_cdm_jobs?: Maybe<Hr_Cdm_Jobs_Mutation_Response>;
  /** update data of the table: "hr.cdm_jobtypes" */
  update_hr_cdm_jobtypes?: Maybe<Hr_Cdm_Jobtypes_Mutation_Response>;
  /** update data of the table: "hr.cdm_leaveenrollments" */
  update_hr_cdm_leaveenrollments?: Maybe<Hr_Cdm_Leaveenrollments_Mutation_Response>;
  /** update data of the table: "hr.cdm_leaveplans" */
  update_hr_cdm_leaveplans?: Maybe<Hr_Cdm_Leaveplans_Mutation_Response>;
  /** update data of the table: "hr.cdm_positionworkerassignmentmaps" */
  update_hr_cdm_positionworkerassignmentmaps?: Maybe<Hr_Cdm_Positionworkerassignmentmaps_Mutation_Response>;
  /** update data of the table: "hr.cdm_workerfixedcompensations" */
  update_hr_cdm_workerfixedcompensations?: Maybe<Hr_Cdm_Workerfixedcompensations_Mutation_Response>;
  /** update data of the table: "hr.cdm_workers" */
  update_hr_cdm_workers?: Maybe<Hr_Cdm_Workers_Mutation_Response>;
  /** update data of the table: "pwa.projects" */
  update_pwa_projects?: Maybe<Pwa_Projects_Mutation_Response>;
  /** update data of the table: "users" */
  update_users?: Maybe<Users_Mutation_Response>;
  /** update single row of the table: "users" */
  update_users_by_pk?: Maybe<Users>;
};


/** mutation root */
export type Mutation_RootDelete_Auth_Account_ProvidersArgs = {
  where: Auth_Account_Providers_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Auth_Account_Providers_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Auth_Account_RolesArgs = {
  where: Auth_Account_Roles_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Auth_Account_Roles_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Auth_AccountsArgs = {
  where: Auth_Accounts_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Auth_Accounts_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Auth_MigrationsArgs = {
  where: Auth_Migrations_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Auth_Migrations_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Auth_ProvidersArgs = {
  where: Auth_Providers_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Auth_Providers_By_PkArgs = {
  provider: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Auth_Refresh_TokensArgs = {
  where: Auth_Refresh_Tokens_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Auth_Refresh_Tokens_By_PkArgs = {
  refresh_token: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Auth_RolesArgs = {
  where: Auth_Roles_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Auth_Roles_By_PkArgs = {
  role: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_Budg_DataArgs = {
  where: Budg_Data_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Budg_Data_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Budget_MetadataArgs = {
  where: Budget_Metadata_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Budget_Metadata_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootDelete_Budget_ParametersArgs = {
  where: Budget_Parameters_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Budget_Parameters_DataArgs = {
  where: Budget_Parameters_Data_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_DividendsArgs = {
  where: Dividends_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Hr_Arm_PartnerroleassignmentArgs = {
  where: Hr_Arm_Partnerroleassignment_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Hr_Arm_PartnersArgs = {
  where: Hr_Arm_Partners_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Hr_Arm_TeamleadassignmentArgs = {
  where: Hr_Arm_Teamleadassignment_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Hr_Cdm_CompensationfixedplansArgs = {
  where: Hr_Cdm_Compensationfixedplans_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Hr_Cdm_CompensationlevelsArgs = {
  where: Hr_Cdm_Compensationlevels_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Hr_Cdm_DepartmentsArgs = {
  where: Hr_Cdm_Departments_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Hr_Cdm_EmploymentsArgs = {
  where: Hr_Cdm_Employments_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Hr_Cdm_JobpositionsArgs = {
  where: Hr_Cdm_Jobpositions_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Hr_Cdm_JobsArgs = {
  where: Hr_Cdm_Jobs_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Hr_Cdm_JobtypesArgs = {
  where: Hr_Cdm_Jobtypes_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Hr_Cdm_LeaveenrollmentsArgs = {
  where: Hr_Cdm_Leaveenrollments_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Hr_Cdm_LeaveplansArgs = {
  where: Hr_Cdm_Leaveplans_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Hr_Cdm_PositionworkerassignmentmapsArgs = {
  where: Hr_Cdm_Positionworkerassignmentmaps_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Hr_Cdm_WorkerfixedcompensationsArgs = {
  where: Hr_Cdm_Workerfixedcompensations_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Hr_Cdm_WorkersArgs = {
  where: Hr_Cdm_Workers_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Pwa_ProjectsArgs = {
  where: Pwa_Projects_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_UsersArgs = {
  where: Users_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Users_By_PkArgs = {
  id: Scalars['uuid'];
};


/** mutation root */
export type Mutation_RootInsert_Auth_Account_ProvidersArgs = {
  objects: Array<Auth_Account_Providers_Insert_Input>;
  on_conflict?: Maybe<Auth_Account_Providers_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Auth_Account_Providers_OneArgs = {
  object: Auth_Account_Providers_Insert_Input;
  on_conflict?: Maybe<Auth_Account_Providers_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Auth_Account_RolesArgs = {
  objects: Array<Auth_Account_Roles_Insert_Input>;
  on_conflict?: Maybe<Auth_Account_Roles_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Auth_Account_Roles_OneArgs = {
  object: Auth_Account_Roles_Insert_Input;
  on_conflict?: Maybe<Auth_Account_Roles_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Auth_AccountsArgs = {
  objects: Array<Auth_Accounts_Insert_Input>;
  on_conflict?: Maybe<Auth_Accounts_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Auth_Accounts_OneArgs = {
  object: Auth_Accounts_Insert_Input;
  on_conflict?: Maybe<Auth_Accounts_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Auth_MigrationsArgs = {
  objects: Array<Auth_Migrations_Insert_Input>;
  on_conflict?: Maybe<Auth_Migrations_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Auth_Migrations_OneArgs = {
  object: Auth_Migrations_Insert_Input;
  on_conflict?: Maybe<Auth_Migrations_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Auth_ProvidersArgs = {
  objects: Array<Auth_Providers_Insert_Input>;
  on_conflict?: Maybe<Auth_Providers_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Auth_Providers_OneArgs = {
  object: Auth_Providers_Insert_Input;
  on_conflict?: Maybe<Auth_Providers_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Auth_Refresh_TokensArgs = {
  objects: Array<Auth_Refresh_Tokens_Insert_Input>;
  on_conflict?: Maybe<Auth_Refresh_Tokens_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Auth_Refresh_Tokens_OneArgs = {
  object: Auth_Refresh_Tokens_Insert_Input;
  on_conflict?: Maybe<Auth_Refresh_Tokens_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Auth_RolesArgs = {
  objects: Array<Auth_Roles_Insert_Input>;
  on_conflict?: Maybe<Auth_Roles_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Auth_Roles_OneArgs = {
  object: Auth_Roles_Insert_Input;
  on_conflict?: Maybe<Auth_Roles_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Budg_DataArgs = {
  objects: Array<Budg_Data_Insert_Input>;
  on_conflict?: Maybe<Budg_Data_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Budg_Data_OneArgs = {
  object: Budg_Data_Insert_Input;
  on_conflict?: Maybe<Budg_Data_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Budget_MetadataArgs = {
  objects: Array<Budget_Metadata_Insert_Input>;
  on_conflict?: Maybe<Budget_Metadata_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Budget_Metadata_OneArgs = {
  object: Budget_Metadata_Insert_Input;
  on_conflict?: Maybe<Budget_Metadata_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Budget_ParametersArgs = {
  objects: Array<Budget_Parameters_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_Budget_Parameters_DataArgs = {
  objects: Array<Budget_Parameters_Data_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_Budget_Parameters_Data_OneArgs = {
  object: Budget_Parameters_Data_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_Budget_Parameters_OneArgs = {
  object: Budget_Parameters_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_DividendsArgs = {
  objects: Array<Dividends_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_Dividends_OneArgs = {
  object: Dividends_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_Hr_Arm_PartnerroleassignmentArgs = {
  objects: Array<Hr_Arm_Partnerroleassignment_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_Hr_Arm_Partnerroleassignment_OneArgs = {
  object: Hr_Arm_Partnerroleassignment_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_Hr_Arm_PartnersArgs = {
  objects: Array<Hr_Arm_Partners_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_Hr_Arm_Partners_OneArgs = {
  object: Hr_Arm_Partners_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_Hr_Arm_TeamleadassignmentArgs = {
  objects: Array<Hr_Arm_Teamleadassignment_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_Hr_Arm_Teamleadassignment_OneArgs = {
  object: Hr_Arm_Teamleadassignment_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_Hr_Cdm_CompensationfixedplansArgs = {
  objects: Array<Hr_Cdm_Compensationfixedplans_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_Hr_Cdm_Compensationfixedplans_OneArgs = {
  object: Hr_Cdm_Compensationfixedplans_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_Hr_Cdm_CompensationlevelsArgs = {
  objects: Array<Hr_Cdm_Compensationlevels_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_Hr_Cdm_Compensationlevels_OneArgs = {
  object: Hr_Cdm_Compensationlevels_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_Hr_Cdm_DepartmentsArgs = {
  objects: Array<Hr_Cdm_Departments_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_Hr_Cdm_Departments_OneArgs = {
  object: Hr_Cdm_Departments_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_Hr_Cdm_EmploymentsArgs = {
  objects: Array<Hr_Cdm_Employments_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_Hr_Cdm_Employments_OneArgs = {
  object: Hr_Cdm_Employments_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_Hr_Cdm_JobpositionsArgs = {
  objects: Array<Hr_Cdm_Jobpositions_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_Hr_Cdm_Jobpositions_OneArgs = {
  object: Hr_Cdm_Jobpositions_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_Hr_Cdm_JobsArgs = {
  objects: Array<Hr_Cdm_Jobs_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_Hr_Cdm_Jobs_OneArgs = {
  object: Hr_Cdm_Jobs_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_Hr_Cdm_JobtypesArgs = {
  objects: Array<Hr_Cdm_Jobtypes_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_Hr_Cdm_Jobtypes_OneArgs = {
  object: Hr_Cdm_Jobtypes_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_Hr_Cdm_LeaveenrollmentsArgs = {
  objects: Array<Hr_Cdm_Leaveenrollments_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_Hr_Cdm_Leaveenrollments_OneArgs = {
  object: Hr_Cdm_Leaveenrollments_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_Hr_Cdm_LeaveplansArgs = {
  objects: Array<Hr_Cdm_Leaveplans_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_Hr_Cdm_Leaveplans_OneArgs = {
  object: Hr_Cdm_Leaveplans_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_Hr_Cdm_PositionworkerassignmentmapsArgs = {
  objects: Array<Hr_Cdm_Positionworkerassignmentmaps_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_Hr_Cdm_Positionworkerassignmentmaps_OneArgs = {
  object: Hr_Cdm_Positionworkerassignmentmaps_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_Hr_Cdm_WorkerfixedcompensationsArgs = {
  objects: Array<Hr_Cdm_Workerfixedcompensations_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_Hr_Cdm_Workerfixedcompensations_OneArgs = {
  object: Hr_Cdm_Workerfixedcompensations_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_Hr_Cdm_WorkersArgs = {
  objects: Array<Hr_Cdm_Workers_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_Hr_Cdm_Workers_OneArgs = {
  object: Hr_Cdm_Workers_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_Pwa_ProjectsArgs = {
  objects: Array<Pwa_Projects_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_Pwa_Projects_OneArgs = {
  object: Pwa_Projects_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_UsersArgs = {
  objects: Array<Users_Insert_Input>;
  on_conflict?: Maybe<Users_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Users_OneArgs = {
  object: Users_Insert_Input;
  on_conflict?: Maybe<Users_On_Conflict>;
};


/** mutation root */
export type Mutation_RootUpdate_Auth_Account_ProvidersArgs = {
  _set?: Maybe<Auth_Account_Providers_Set_Input>;
  where: Auth_Account_Providers_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Auth_Account_Providers_By_PkArgs = {
  _set?: Maybe<Auth_Account_Providers_Set_Input>;
  pk_columns: Auth_Account_Providers_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Auth_Account_RolesArgs = {
  _set?: Maybe<Auth_Account_Roles_Set_Input>;
  where: Auth_Account_Roles_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Auth_Account_Roles_By_PkArgs = {
  _set?: Maybe<Auth_Account_Roles_Set_Input>;
  pk_columns: Auth_Account_Roles_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Auth_AccountsArgs = {
  _append?: Maybe<Auth_Accounts_Append_Input>;
  _delete_at_path?: Maybe<Auth_Accounts_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Auth_Accounts_Delete_Elem_Input>;
  _delete_key?: Maybe<Auth_Accounts_Delete_Key_Input>;
  _prepend?: Maybe<Auth_Accounts_Prepend_Input>;
  _set?: Maybe<Auth_Accounts_Set_Input>;
  where: Auth_Accounts_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Auth_Accounts_By_PkArgs = {
  _append?: Maybe<Auth_Accounts_Append_Input>;
  _delete_at_path?: Maybe<Auth_Accounts_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Auth_Accounts_Delete_Elem_Input>;
  _delete_key?: Maybe<Auth_Accounts_Delete_Key_Input>;
  _prepend?: Maybe<Auth_Accounts_Prepend_Input>;
  _set?: Maybe<Auth_Accounts_Set_Input>;
  pk_columns: Auth_Accounts_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Auth_MigrationsArgs = {
  _inc?: Maybe<Auth_Migrations_Inc_Input>;
  _set?: Maybe<Auth_Migrations_Set_Input>;
  where: Auth_Migrations_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Auth_Migrations_By_PkArgs = {
  _inc?: Maybe<Auth_Migrations_Inc_Input>;
  _set?: Maybe<Auth_Migrations_Set_Input>;
  pk_columns: Auth_Migrations_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Auth_ProvidersArgs = {
  _set?: Maybe<Auth_Providers_Set_Input>;
  where: Auth_Providers_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Auth_Providers_By_PkArgs = {
  _set?: Maybe<Auth_Providers_Set_Input>;
  pk_columns: Auth_Providers_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Auth_Refresh_TokensArgs = {
  _set?: Maybe<Auth_Refresh_Tokens_Set_Input>;
  where: Auth_Refresh_Tokens_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Auth_Refresh_Tokens_By_PkArgs = {
  _set?: Maybe<Auth_Refresh_Tokens_Set_Input>;
  pk_columns: Auth_Refresh_Tokens_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Auth_RolesArgs = {
  _set?: Maybe<Auth_Roles_Set_Input>;
  where: Auth_Roles_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Auth_Roles_By_PkArgs = {
  _set?: Maybe<Auth_Roles_Set_Input>;
  pk_columns: Auth_Roles_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Budg_DataArgs = {
  _inc?: Maybe<Budg_Data_Inc_Input>;
  _set?: Maybe<Budg_Data_Set_Input>;
  where: Budg_Data_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Budg_Data_By_PkArgs = {
  _inc?: Maybe<Budg_Data_Inc_Input>;
  _set?: Maybe<Budg_Data_Set_Input>;
  pk_columns: Budg_Data_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Budget_MetadataArgs = {
  _inc?: Maybe<Budget_Metadata_Inc_Input>;
  _set?: Maybe<Budget_Metadata_Set_Input>;
  where: Budget_Metadata_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Budget_Metadata_By_PkArgs = {
  _inc?: Maybe<Budget_Metadata_Inc_Input>;
  _set?: Maybe<Budget_Metadata_Set_Input>;
  pk_columns: Budget_Metadata_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Budget_ParametersArgs = {
  _set?: Maybe<Budget_Parameters_Set_Input>;
  where: Budget_Parameters_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Budget_Parameters_DataArgs = {
  _inc?: Maybe<Budget_Parameters_Data_Inc_Input>;
  _set?: Maybe<Budget_Parameters_Data_Set_Input>;
  where: Budget_Parameters_Data_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_DividendsArgs = {
  _set?: Maybe<Dividends_Set_Input>;
  where: Dividends_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Hr_Arm_PartnerroleassignmentArgs = {
  _set?: Maybe<Hr_Arm_Partnerroleassignment_Set_Input>;
  where: Hr_Arm_Partnerroleassignment_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Hr_Arm_PartnersArgs = {
  _set?: Maybe<Hr_Arm_Partners_Set_Input>;
  where: Hr_Arm_Partners_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Hr_Arm_TeamleadassignmentArgs = {
  _set?: Maybe<Hr_Arm_Teamleadassignment_Set_Input>;
  where: Hr_Arm_Teamleadassignment_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Hr_Cdm_CompensationfixedplansArgs = {
  _set?: Maybe<Hr_Cdm_Compensationfixedplans_Set_Input>;
  where: Hr_Cdm_Compensationfixedplans_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Hr_Cdm_CompensationlevelsArgs = {
  _set?: Maybe<Hr_Cdm_Compensationlevels_Set_Input>;
  where: Hr_Cdm_Compensationlevels_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Hr_Cdm_DepartmentsArgs = {
  _set?: Maybe<Hr_Cdm_Departments_Set_Input>;
  where: Hr_Cdm_Departments_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Hr_Cdm_EmploymentsArgs = {
  _set?: Maybe<Hr_Cdm_Employments_Set_Input>;
  where: Hr_Cdm_Employments_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Hr_Cdm_JobpositionsArgs = {
  _set?: Maybe<Hr_Cdm_Jobpositions_Set_Input>;
  where: Hr_Cdm_Jobpositions_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Hr_Cdm_JobsArgs = {
  _set?: Maybe<Hr_Cdm_Jobs_Set_Input>;
  where: Hr_Cdm_Jobs_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Hr_Cdm_JobtypesArgs = {
  _set?: Maybe<Hr_Cdm_Jobtypes_Set_Input>;
  where: Hr_Cdm_Jobtypes_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Hr_Cdm_LeaveenrollmentsArgs = {
  _set?: Maybe<Hr_Cdm_Leaveenrollments_Set_Input>;
  where: Hr_Cdm_Leaveenrollments_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Hr_Cdm_LeaveplansArgs = {
  _set?: Maybe<Hr_Cdm_Leaveplans_Set_Input>;
  where: Hr_Cdm_Leaveplans_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Hr_Cdm_PositionworkerassignmentmapsArgs = {
  _set?: Maybe<Hr_Cdm_Positionworkerassignmentmaps_Set_Input>;
  where: Hr_Cdm_Positionworkerassignmentmaps_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Hr_Cdm_WorkerfixedcompensationsArgs = {
  _inc?: Maybe<Hr_Cdm_Workerfixedcompensations_Inc_Input>;
  _set?: Maybe<Hr_Cdm_Workerfixedcompensations_Set_Input>;
  where: Hr_Cdm_Workerfixedcompensations_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Hr_Cdm_WorkersArgs = {
  _set?: Maybe<Hr_Cdm_Workers_Set_Input>;
  where: Hr_Cdm_Workers_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Pwa_ProjectsArgs = {
  _inc?: Maybe<Pwa_Projects_Inc_Input>;
  _set?: Maybe<Pwa_Projects_Set_Input>;
  where: Pwa_Projects_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_UsersArgs = {
  _set?: Maybe<Users_Set_Input>;
  where: Users_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Users_By_PkArgs = {
  _set?: Maybe<Users_Set_Input>;
  pk_columns: Users_Pk_Columns_Input;
};

/** column ordering options */
export enum Order_By {
  /** in ascending order, nulls last */
  Asc = 'asc',
  /** in ascending order, nulls first */
  AscNullsFirst = 'asc_nulls_first',
  /** in ascending order, nulls last */
  AscNullsLast = 'asc_nulls_last',
  /** in descending order, nulls first */
  Desc = 'desc',
  /** in descending order, nulls first */
  DescNullsFirst = 'desc_nulls_first',
  /** in descending order, nulls last */
  DescNullsLast = 'desc_nulls_last'
}

/** columns and relationships of "pwa.projects" */
export type Pwa_Projects = {
  actual_cost?: Maybe<Scalars['Float']>;
  actual_duration?: Maybe<Scalars['Float']>;
  actual_overtime_cost?: Maybe<Scalars['Float']>;
  actual_overtime_work?: Maybe<Scalars['Float']>;
  actual_regular_cost?: Maybe<Scalars['Float']>;
  actual_regular_work?: Maybe<Scalars['Float']>;
  actual_start_date?: Maybe<Scalars['timestamp']>;
  actual_work?: Maybe<Scalars['Float']>;
  admin_type?: Maybe<Scalars['bigint']>;
  budget_cost?: Maybe<Scalars['Float']>;
  city?: Maybe<Scalars['String']>;
  client?: Maybe<Scalars['String']>;
  contagent?: Maybe<Scalars['String']>;
  contract_number?: Maybe<Scalars['String']>;
  cost?: Maybe<Scalars['Float']>;
  country?: Maybe<Scalars['String']>;
  created_date?: Maybe<Scalars['timestamp']>;
  deadline?: Maybe<Scalars['timestamp']>;
  description?: Maybe<Scalars['String']>;
  director?: Maybe<Scalars['String']>;
  duration?: Maybe<Scalars['Float']>;
  finish_date?: Maybe<Scalars['timestamp']>;
  full_name?: Maybe<Scalars['String']>;
  id: Scalars['uuid'];
  last_published_date?: Maybe<Scalars['timestamp']>;
  level?: Maybe<Scalars['String']>;
  macro_project?: Maybe<Scalars['String']>;
  manager_name?: Maybe<Scalars['String']>;
  masterplan_number?: Maybe<Scalars['String']>;
  modified_date?: Maybe<Scalars['timestamp']>;
  name?: Maybe<Scalars['String']>;
  overtime_cost?: Maybe<Scalars['Float']>;
  overtime_work?: Maybe<Scalars['Float']>;
  owner_id?: Maybe<Scalars['uuid']>;
  owner_name?: Maybe<Scalars['String']>;
  parent_id?: Maybe<Scalars['uuid']>;
  percent_completed?: Maybe<Scalars['Float']>;
  percentage_completness?: Maybe<Scalars['Float']>;
  percentage_documentation?: Maybe<Scalars['Float']>;
  process_comment?: Maybe<Scalars['String']>;
  project_departments?: Maybe<Scalars['String']>;
  projectassignment?: Maybe<Scalars['String']>;
  projectstatus?: Maybe<Scalars['String']>;
  regular_cost?: Maybe<Scalars['Float']>;
  regular_work?: Maybe<Scalars['Float']>;
  remaining_cost?: Maybe<Scalars['Float']>;
  remaining_duration?: Maybe<Scalars['Float']>;
  remaining_overtime_cost?: Maybe<Scalars['Float']>;
  remaining_overtime_work?: Maybe<Scalars['Float']>;
  remaining_regular_cost?: Maybe<Scalars['Float']>;
  remaining_regular_work?: Maybe<Scalars['Float']>;
  remaining_work?: Maybe<Scalars['Float']>;
  stage?: Maybe<Scalars['String']>;
  start_date?: Maybe<Scalars['timestamp']>;
  status?: Maybe<Scalars['String']>;
  timeline?: Maybe<Scalars['Float']>;
  total_area?: Maybe<Scalars['String']>;
  version?: Maybe<Scalars['smallint']>;
  wbs?: Maybe<Scalars['String']>;
  work?: Maybe<Scalars['Float']>;
};

/** aggregated selection of "pwa.projects" */
export type Pwa_Projects_Aggregate = {
  aggregate?: Maybe<Pwa_Projects_Aggregate_Fields>;
  nodes: Array<Pwa_Projects>;
};

/** aggregate fields of "pwa.projects" */
export type Pwa_Projects_Aggregate_Fields = {
  avg?: Maybe<Pwa_Projects_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Pwa_Projects_Max_Fields>;
  min?: Maybe<Pwa_Projects_Min_Fields>;
  stddev?: Maybe<Pwa_Projects_Stddev_Fields>;
  stddev_pop?: Maybe<Pwa_Projects_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Pwa_Projects_Stddev_Samp_Fields>;
  sum?: Maybe<Pwa_Projects_Sum_Fields>;
  var_pop?: Maybe<Pwa_Projects_Var_Pop_Fields>;
  var_samp?: Maybe<Pwa_Projects_Var_Samp_Fields>;
  variance?: Maybe<Pwa_Projects_Variance_Fields>;
};


/** aggregate fields of "pwa.projects" */
export type Pwa_Projects_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Pwa_Projects_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Pwa_Projects_Avg_Fields = {
  actual_cost?: Maybe<Scalars['Float']>;
  actual_duration?: Maybe<Scalars['Float']>;
  actual_overtime_cost?: Maybe<Scalars['Float']>;
  actual_overtime_work?: Maybe<Scalars['Float']>;
  actual_regular_cost?: Maybe<Scalars['Float']>;
  actual_regular_work?: Maybe<Scalars['Float']>;
  actual_work?: Maybe<Scalars['Float']>;
  admin_type?: Maybe<Scalars['Float']>;
  budget_cost?: Maybe<Scalars['Float']>;
  cost?: Maybe<Scalars['Float']>;
  duration?: Maybe<Scalars['Float']>;
  overtime_cost?: Maybe<Scalars['Float']>;
  overtime_work?: Maybe<Scalars['Float']>;
  percent_completed?: Maybe<Scalars['Float']>;
  percentage_completness?: Maybe<Scalars['Float']>;
  percentage_documentation?: Maybe<Scalars['Float']>;
  regular_cost?: Maybe<Scalars['Float']>;
  regular_work?: Maybe<Scalars['Float']>;
  remaining_cost?: Maybe<Scalars['Float']>;
  remaining_duration?: Maybe<Scalars['Float']>;
  remaining_overtime_cost?: Maybe<Scalars['Float']>;
  remaining_overtime_work?: Maybe<Scalars['Float']>;
  remaining_regular_cost?: Maybe<Scalars['Float']>;
  remaining_regular_work?: Maybe<Scalars['Float']>;
  remaining_work?: Maybe<Scalars['Float']>;
  timeline?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
  work?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "pwa.projects". All fields are combined with a logical 'AND'. */
export type Pwa_Projects_Bool_Exp = {
  _and?: Maybe<Array<Pwa_Projects_Bool_Exp>>;
  _not?: Maybe<Pwa_Projects_Bool_Exp>;
  _or?: Maybe<Array<Pwa_Projects_Bool_Exp>>;
  actual_cost?: Maybe<Float_Comparison_Exp>;
  actual_duration?: Maybe<Float_Comparison_Exp>;
  actual_overtime_cost?: Maybe<Float_Comparison_Exp>;
  actual_overtime_work?: Maybe<Float_Comparison_Exp>;
  actual_regular_cost?: Maybe<Float_Comparison_Exp>;
  actual_regular_work?: Maybe<Float_Comparison_Exp>;
  actual_start_date?: Maybe<Timestamp_Comparison_Exp>;
  actual_work?: Maybe<Float_Comparison_Exp>;
  admin_type?: Maybe<Bigint_Comparison_Exp>;
  budget_cost?: Maybe<Float_Comparison_Exp>;
  city?: Maybe<String_Comparison_Exp>;
  client?: Maybe<String_Comparison_Exp>;
  contagent?: Maybe<String_Comparison_Exp>;
  contract_number?: Maybe<String_Comparison_Exp>;
  cost?: Maybe<Float_Comparison_Exp>;
  country?: Maybe<String_Comparison_Exp>;
  created_date?: Maybe<Timestamp_Comparison_Exp>;
  deadline?: Maybe<Timestamp_Comparison_Exp>;
  description?: Maybe<String_Comparison_Exp>;
  director?: Maybe<String_Comparison_Exp>;
  duration?: Maybe<Float_Comparison_Exp>;
  finish_date?: Maybe<Timestamp_Comparison_Exp>;
  full_name?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  last_published_date?: Maybe<Timestamp_Comparison_Exp>;
  level?: Maybe<String_Comparison_Exp>;
  macro_project?: Maybe<String_Comparison_Exp>;
  manager_name?: Maybe<String_Comparison_Exp>;
  masterplan_number?: Maybe<String_Comparison_Exp>;
  modified_date?: Maybe<Timestamp_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  overtime_cost?: Maybe<Float_Comparison_Exp>;
  overtime_work?: Maybe<Float_Comparison_Exp>;
  owner_id?: Maybe<Uuid_Comparison_Exp>;
  owner_name?: Maybe<String_Comparison_Exp>;
  parent_id?: Maybe<Uuid_Comparison_Exp>;
  percent_completed?: Maybe<Float_Comparison_Exp>;
  percentage_completness?: Maybe<Float_Comparison_Exp>;
  percentage_documentation?: Maybe<Float_Comparison_Exp>;
  process_comment?: Maybe<String_Comparison_Exp>;
  project_departments?: Maybe<String_Comparison_Exp>;
  projectassignment?: Maybe<String_Comparison_Exp>;
  projectstatus?: Maybe<String_Comparison_Exp>;
  regular_cost?: Maybe<Float_Comparison_Exp>;
  regular_work?: Maybe<Float_Comparison_Exp>;
  remaining_cost?: Maybe<Float_Comparison_Exp>;
  remaining_duration?: Maybe<Float_Comparison_Exp>;
  remaining_overtime_cost?: Maybe<Float_Comparison_Exp>;
  remaining_overtime_work?: Maybe<Float_Comparison_Exp>;
  remaining_regular_cost?: Maybe<Float_Comparison_Exp>;
  remaining_regular_work?: Maybe<Float_Comparison_Exp>;
  remaining_work?: Maybe<Float_Comparison_Exp>;
  stage?: Maybe<String_Comparison_Exp>;
  start_date?: Maybe<Timestamp_Comparison_Exp>;
  status?: Maybe<String_Comparison_Exp>;
  timeline?: Maybe<Float_Comparison_Exp>;
  total_area?: Maybe<String_Comparison_Exp>;
  version?: Maybe<Smallint_Comparison_Exp>;
  wbs?: Maybe<String_Comparison_Exp>;
  work?: Maybe<Float_Comparison_Exp>;
};

/** input type for incrementing numeric columns in table "pwa.projects" */
export type Pwa_Projects_Inc_Input = {
  actual_cost?: Maybe<Scalars['Float']>;
  actual_duration?: Maybe<Scalars['Float']>;
  actual_overtime_cost?: Maybe<Scalars['Float']>;
  actual_overtime_work?: Maybe<Scalars['Float']>;
  actual_regular_cost?: Maybe<Scalars['Float']>;
  actual_regular_work?: Maybe<Scalars['Float']>;
  actual_work?: Maybe<Scalars['Float']>;
  admin_type?: Maybe<Scalars['bigint']>;
  budget_cost?: Maybe<Scalars['Float']>;
  cost?: Maybe<Scalars['Float']>;
  duration?: Maybe<Scalars['Float']>;
  overtime_cost?: Maybe<Scalars['Float']>;
  overtime_work?: Maybe<Scalars['Float']>;
  percent_completed?: Maybe<Scalars['Float']>;
  percentage_completness?: Maybe<Scalars['Float']>;
  percentage_documentation?: Maybe<Scalars['Float']>;
  regular_cost?: Maybe<Scalars['Float']>;
  regular_work?: Maybe<Scalars['Float']>;
  remaining_cost?: Maybe<Scalars['Float']>;
  remaining_duration?: Maybe<Scalars['Float']>;
  remaining_overtime_cost?: Maybe<Scalars['Float']>;
  remaining_overtime_work?: Maybe<Scalars['Float']>;
  remaining_regular_cost?: Maybe<Scalars['Float']>;
  remaining_regular_work?: Maybe<Scalars['Float']>;
  remaining_work?: Maybe<Scalars['Float']>;
  timeline?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['smallint']>;
  work?: Maybe<Scalars['Float']>;
};

/** input type for inserting data into table "pwa.projects" */
export type Pwa_Projects_Insert_Input = {
  actual_cost?: Maybe<Scalars['Float']>;
  actual_duration?: Maybe<Scalars['Float']>;
  actual_overtime_cost?: Maybe<Scalars['Float']>;
  actual_overtime_work?: Maybe<Scalars['Float']>;
  actual_regular_cost?: Maybe<Scalars['Float']>;
  actual_regular_work?: Maybe<Scalars['Float']>;
  actual_start_date?: Maybe<Scalars['timestamp']>;
  actual_work?: Maybe<Scalars['Float']>;
  admin_type?: Maybe<Scalars['bigint']>;
  budget_cost?: Maybe<Scalars['Float']>;
  city?: Maybe<Scalars['String']>;
  client?: Maybe<Scalars['String']>;
  contagent?: Maybe<Scalars['String']>;
  contract_number?: Maybe<Scalars['String']>;
  cost?: Maybe<Scalars['Float']>;
  country?: Maybe<Scalars['String']>;
  created_date?: Maybe<Scalars['timestamp']>;
  deadline?: Maybe<Scalars['timestamp']>;
  description?: Maybe<Scalars['String']>;
  director?: Maybe<Scalars['String']>;
  duration?: Maybe<Scalars['Float']>;
  finish_date?: Maybe<Scalars['timestamp']>;
  full_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  last_published_date?: Maybe<Scalars['timestamp']>;
  level?: Maybe<Scalars['String']>;
  macro_project?: Maybe<Scalars['String']>;
  manager_name?: Maybe<Scalars['String']>;
  masterplan_number?: Maybe<Scalars['String']>;
  modified_date?: Maybe<Scalars['timestamp']>;
  name?: Maybe<Scalars['String']>;
  overtime_cost?: Maybe<Scalars['Float']>;
  overtime_work?: Maybe<Scalars['Float']>;
  owner_id?: Maybe<Scalars['uuid']>;
  owner_name?: Maybe<Scalars['String']>;
  parent_id?: Maybe<Scalars['uuid']>;
  percent_completed?: Maybe<Scalars['Float']>;
  percentage_completness?: Maybe<Scalars['Float']>;
  percentage_documentation?: Maybe<Scalars['Float']>;
  process_comment?: Maybe<Scalars['String']>;
  project_departments?: Maybe<Scalars['String']>;
  projectassignment?: Maybe<Scalars['String']>;
  projectstatus?: Maybe<Scalars['String']>;
  regular_cost?: Maybe<Scalars['Float']>;
  regular_work?: Maybe<Scalars['Float']>;
  remaining_cost?: Maybe<Scalars['Float']>;
  remaining_duration?: Maybe<Scalars['Float']>;
  remaining_overtime_cost?: Maybe<Scalars['Float']>;
  remaining_overtime_work?: Maybe<Scalars['Float']>;
  remaining_regular_cost?: Maybe<Scalars['Float']>;
  remaining_regular_work?: Maybe<Scalars['Float']>;
  remaining_work?: Maybe<Scalars['Float']>;
  stage?: Maybe<Scalars['String']>;
  start_date?: Maybe<Scalars['timestamp']>;
  status?: Maybe<Scalars['String']>;
  timeline?: Maybe<Scalars['Float']>;
  total_area?: Maybe<Scalars['String']>;
  version?: Maybe<Scalars['smallint']>;
  wbs?: Maybe<Scalars['String']>;
  work?: Maybe<Scalars['Float']>;
};

/** aggregate max on columns */
export type Pwa_Projects_Max_Fields = {
  actual_cost?: Maybe<Scalars['Float']>;
  actual_duration?: Maybe<Scalars['Float']>;
  actual_overtime_cost?: Maybe<Scalars['Float']>;
  actual_overtime_work?: Maybe<Scalars['Float']>;
  actual_regular_cost?: Maybe<Scalars['Float']>;
  actual_regular_work?: Maybe<Scalars['Float']>;
  actual_start_date?: Maybe<Scalars['timestamp']>;
  actual_work?: Maybe<Scalars['Float']>;
  admin_type?: Maybe<Scalars['bigint']>;
  budget_cost?: Maybe<Scalars['Float']>;
  city?: Maybe<Scalars['String']>;
  client?: Maybe<Scalars['String']>;
  contagent?: Maybe<Scalars['String']>;
  contract_number?: Maybe<Scalars['String']>;
  cost?: Maybe<Scalars['Float']>;
  country?: Maybe<Scalars['String']>;
  created_date?: Maybe<Scalars['timestamp']>;
  deadline?: Maybe<Scalars['timestamp']>;
  description?: Maybe<Scalars['String']>;
  director?: Maybe<Scalars['String']>;
  duration?: Maybe<Scalars['Float']>;
  finish_date?: Maybe<Scalars['timestamp']>;
  full_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  last_published_date?: Maybe<Scalars['timestamp']>;
  level?: Maybe<Scalars['String']>;
  macro_project?: Maybe<Scalars['String']>;
  manager_name?: Maybe<Scalars['String']>;
  masterplan_number?: Maybe<Scalars['String']>;
  modified_date?: Maybe<Scalars['timestamp']>;
  name?: Maybe<Scalars['String']>;
  overtime_cost?: Maybe<Scalars['Float']>;
  overtime_work?: Maybe<Scalars['Float']>;
  owner_id?: Maybe<Scalars['uuid']>;
  owner_name?: Maybe<Scalars['String']>;
  parent_id?: Maybe<Scalars['uuid']>;
  percent_completed?: Maybe<Scalars['Float']>;
  percentage_completness?: Maybe<Scalars['Float']>;
  percentage_documentation?: Maybe<Scalars['Float']>;
  process_comment?: Maybe<Scalars['String']>;
  project_departments?: Maybe<Scalars['String']>;
  projectassignment?: Maybe<Scalars['String']>;
  projectstatus?: Maybe<Scalars['String']>;
  regular_cost?: Maybe<Scalars['Float']>;
  regular_work?: Maybe<Scalars['Float']>;
  remaining_cost?: Maybe<Scalars['Float']>;
  remaining_duration?: Maybe<Scalars['Float']>;
  remaining_overtime_cost?: Maybe<Scalars['Float']>;
  remaining_overtime_work?: Maybe<Scalars['Float']>;
  remaining_regular_cost?: Maybe<Scalars['Float']>;
  remaining_regular_work?: Maybe<Scalars['Float']>;
  remaining_work?: Maybe<Scalars['Float']>;
  stage?: Maybe<Scalars['String']>;
  start_date?: Maybe<Scalars['timestamp']>;
  status?: Maybe<Scalars['String']>;
  timeline?: Maybe<Scalars['Float']>;
  total_area?: Maybe<Scalars['String']>;
  version?: Maybe<Scalars['smallint']>;
  wbs?: Maybe<Scalars['String']>;
  work?: Maybe<Scalars['Float']>;
};

/** aggregate min on columns */
export type Pwa_Projects_Min_Fields = {
  actual_cost?: Maybe<Scalars['Float']>;
  actual_duration?: Maybe<Scalars['Float']>;
  actual_overtime_cost?: Maybe<Scalars['Float']>;
  actual_overtime_work?: Maybe<Scalars['Float']>;
  actual_regular_cost?: Maybe<Scalars['Float']>;
  actual_regular_work?: Maybe<Scalars['Float']>;
  actual_start_date?: Maybe<Scalars['timestamp']>;
  actual_work?: Maybe<Scalars['Float']>;
  admin_type?: Maybe<Scalars['bigint']>;
  budget_cost?: Maybe<Scalars['Float']>;
  city?: Maybe<Scalars['String']>;
  client?: Maybe<Scalars['String']>;
  contagent?: Maybe<Scalars['String']>;
  contract_number?: Maybe<Scalars['String']>;
  cost?: Maybe<Scalars['Float']>;
  country?: Maybe<Scalars['String']>;
  created_date?: Maybe<Scalars['timestamp']>;
  deadline?: Maybe<Scalars['timestamp']>;
  description?: Maybe<Scalars['String']>;
  director?: Maybe<Scalars['String']>;
  duration?: Maybe<Scalars['Float']>;
  finish_date?: Maybe<Scalars['timestamp']>;
  full_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  last_published_date?: Maybe<Scalars['timestamp']>;
  level?: Maybe<Scalars['String']>;
  macro_project?: Maybe<Scalars['String']>;
  manager_name?: Maybe<Scalars['String']>;
  masterplan_number?: Maybe<Scalars['String']>;
  modified_date?: Maybe<Scalars['timestamp']>;
  name?: Maybe<Scalars['String']>;
  overtime_cost?: Maybe<Scalars['Float']>;
  overtime_work?: Maybe<Scalars['Float']>;
  owner_id?: Maybe<Scalars['uuid']>;
  owner_name?: Maybe<Scalars['String']>;
  parent_id?: Maybe<Scalars['uuid']>;
  percent_completed?: Maybe<Scalars['Float']>;
  percentage_completness?: Maybe<Scalars['Float']>;
  percentage_documentation?: Maybe<Scalars['Float']>;
  process_comment?: Maybe<Scalars['String']>;
  project_departments?: Maybe<Scalars['String']>;
  projectassignment?: Maybe<Scalars['String']>;
  projectstatus?: Maybe<Scalars['String']>;
  regular_cost?: Maybe<Scalars['Float']>;
  regular_work?: Maybe<Scalars['Float']>;
  remaining_cost?: Maybe<Scalars['Float']>;
  remaining_duration?: Maybe<Scalars['Float']>;
  remaining_overtime_cost?: Maybe<Scalars['Float']>;
  remaining_overtime_work?: Maybe<Scalars['Float']>;
  remaining_regular_cost?: Maybe<Scalars['Float']>;
  remaining_regular_work?: Maybe<Scalars['Float']>;
  remaining_work?: Maybe<Scalars['Float']>;
  stage?: Maybe<Scalars['String']>;
  start_date?: Maybe<Scalars['timestamp']>;
  status?: Maybe<Scalars['String']>;
  timeline?: Maybe<Scalars['Float']>;
  total_area?: Maybe<Scalars['String']>;
  version?: Maybe<Scalars['smallint']>;
  wbs?: Maybe<Scalars['String']>;
  work?: Maybe<Scalars['Float']>;
};

/** response of any mutation on the table "pwa.projects" */
export type Pwa_Projects_Mutation_Response = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Pwa_Projects>;
};

/** Ordering options when selecting data from "pwa.projects". */
export type Pwa_Projects_Order_By = {
  actual_cost?: Maybe<Order_By>;
  actual_duration?: Maybe<Order_By>;
  actual_overtime_cost?: Maybe<Order_By>;
  actual_overtime_work?: Maybe<Order_By>;
  actual_regular_cost?: Maybe<Order_By>;
  actual_regular_work?: Maybe<Order_By>;
  actual_start_date?: Maybe<Order_By>;
  actual_work?: Maybe<Order_By>;
  admin_type?: Maybe<Order_By>;
  budget_cost?: Maybe<Order_By>;
  city?: Maybe<Order_By>;
  client?: Maybe<Order_By>;
  contagent?: Maybe<Order_By>;
  contract_number?: Maybe<Order_By>;
  cost?: Maybe<Order_By>;
  country?: Maybe<Order_By>;
  created_date?: Maybe<Order_By>;
  deadline?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  director?: Maybe<Order_By>;
  duration?: Maybe<Order_By>;
  finish_date?: Maybe<Order_By>;
  full_name?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_published_date?: Maybe<Order_By>;
  level?: Maybe<Order_By>;
  macro_project?: Maybe<Order_By>;
  manager_name?: Maybe<Order_By>;
  masterplan_number?: Maybe<Order_By>;
  modified_date?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  overtime_cost?: Maybe<Order_By>;
  overtime_work?: Maybe<Order_By>;
  owner_id?: Maybe<Order_By>;
  owner_name?: Maybe<Order_By>;
  parent_id?: Maybe<Order_By>;
  percent_completed?: Maybe<Order_By>;
  percentage_completness?: Maybe<Order_By>;
  percentage_documentation?: Maybe<Order_By>;
  process_comment?: Maybe<Order_By>;
  project_departments?: Maybe<Order_By>;
  projectassignment?: Maybe<Order_By>;
  projectstatus?: Maybe<Order_By>;
  regular_cost?: Maybe<Order_By>;
  regular_work?: Maybe<Order_By>;
  remaining_cost?: Maybe<Order_By>;
  remaining_duration?: Maybe<Order_By>;
  remaining_overtime_cost?: Maybe<Order_By>;
  remaining_overtime_work?: Maybe<Order_By>;
  remaining_regular_cost?: Maybe<Order_By>;
  remaining_regular_work?: Maybe<Order_By>;
  remaining_work?: Maybe<Order_By>;
  stage?: Maybe<Order_By>;
  start_date?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
  timeline?: Maybe<Order_By>;
  total_area?: Maybe<Order_By>;
  version?: Maybe<Order_By>;
  wbs?: Maybe<Order_By>;
  work?: Maybe<Order_By>;
};

/** select columns of table "pwa.projects" */
export enum Pwa_Projects_Select_Column {
  /** column name */
  ActualCost = 'actual_cost',
  /** column name */
  ActualDuration = 'actual_duration',
  /** column name */
  ActualOvertimeCost = 'actual_overtime_cost',
  /** column name */
  ActualOvertimeWork = 'actual_overtime_work',
  /** column name */
  ActualRegularCost = 'actual_regular_cost',
  /** column name */
  ActualRegularWork = 'actual_regular_work',
  /** column name */
  ActualStartDate = 'actual_start_date',
  /** column name */
  ActualWork = 'actual_work',
  /** column name */
  AdminType = 'admin_type',
  /** column name */
  BudgetCost = 'budget_cost',
  /** column name */
  City = 'city',
  /** column name */
  Client = 'client',
  /** column name */
  Contagent = 'contagent',
  /** column name */
  ContractNumber = 'contract_number',
  /** column name */
  Cost = 'cost',
  /** column name */
  Country = 'country',
  /** column name */
  CreatedDate = 'created_date',
  /** column name */
  Deadline = 'deadline',
  /** column name */
  Description = 'description',
  /** column name */
  Director = 'director',
  /** column name */
  Duration = 'duration',
  /** column name */
  FinishDate = 'finish_date',
  /** column name */
  FullName = 'full_name',
  /** column name */
  Id = 'id',
  /** column name */
  LastPublishedDate = 'last_published_date',
  /** column name */
  Level = 'level',
  /** column name */
  MacroProject = 'macro_project',
  /** column name */
  ManagerName = 'manager_name',
  /** column name */
  MasterplanNumber = 'masterplan_number',
  /** column name */
  ModifiedDate = 'modified_date',
  /** column name */
  Name = 'name',
  /** column name */
  OvertimeCost = 'overtime_cost',
  /** column name */
  OvertimeWork = 'overtime_work',
  /** column name */
  OwnerId = 'owner_id',
  /** column name */
  OwnerName = 'owner_name',
  /** column name */
  ParentId = 'parent_id',
  /** column name */
  PercentCompleted = 'percent_completed',
  /** column name */
  PercentageCompletness = 'percentage_completness',
  /** column name */
  PercentageDocumentation = 'percentage_documentation',
  /** column name */
  ProcessComment = 'process_comment',
  /** column name */
  ProjectDepartments = 'project_departments',
  /** column name */
  Projectassignment = 'projectassignment',
  /** column name */
  Projectstatus = 'projectstatus',
  /** column name */
  RegularCost = 'regular_cost',
  /** column name */
  RegularWork = 'regular_work',
  /** column name */
  RemainingCost = 'remaining_cost',
  /** column name */
  RemainingDuration = 'remaining_duration',
  /** column name */
  RemainingOvertimeCost = 'remaining_overtime_cost',
  /** column name */
  RemainingOvertimeWork = 'remaining_overtime_work',
  /** column name */
  RemainingRegularCost = 'remaining_regular_cost',
  /** column name */
  RemainingRegularWork = 'remaining_regular_work',
  /** column name */
  RemainingWork = 'remaining_work',
  /** column name */
  Stage = 'stage',
  /** column name */
  StartDate = 'start_date',
  /** column name */
  Status = 'status',
  /** column name */
  Timeline = 'timeline',
  /** column name */
  TotalArea = 'total_area',
  /** column name */
  Version = 'version',
  /** column name */
  Wbs = 'wbs',
  /** column name */
  Work = 'work'
}

/** input type for updating data in table "pwa.projects" */
export type Pwa_Projects_Set_Input = {
  actual_cost?: Maybe<Scalars['Float']>;
  actual_duration?: Maybe<Scalars['Float']>;
  actual_overtime_cost?: Maybe<Scalars['Float']>;
  actual_overtime_work?: Maybe<Scalars['Float']>;
  actual_regular_cost?: Maybe<Scalars['Float']>;
  actual_regular_work?: Maybe<Scalars['Float']>;
  actual_start_date?: Maybe<Scalars['timestamp']>;
  actual_work?: Maybe<Scalars['Float']>;
  admin_type?: Maybe<Scalars['bigint']>;
  budget_cost?: Maybe<Scalars['Float']>;
  city?: Maybe<Scalars['String']>;
  client?: Maybe<Scalars['String']>;
  contagent?: Maybe<Scalars['String']>;
  contract_number?: Maybe<Scalars['String']>;
  cost?: Maybe<Scalars['Float']>;
  country?: Maybe<Scalars['String']>;
  created_date?: Maybe<Scalars['timestamp']>;
  deadline?: Maybe<Scalars['timestamp']>;
  description?: Maybe<Scalars['String']>;
  director?: Maybe<Scalars['String']>;
  duration?: Maybe<Scalars['Float']>;
  finish_date?: Maybe<Scalars['timestamp']>;
  full_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  last_published_date?: Maybe<Scalars['timestamp']>;
  level?: Maybe<Scalars['String']>;
  macro_project?: Maybe<Scalars['String']>;
  manager_name?: Maybe<Scalars['String']>;
  masterplan_number?: Maybe<Scalars['String']>;
  modified_date?: Maybe<Scalars['timestamp']>;
  name?: Maybe<Scalars['String']>;
  overtime_cost?: Maybe<Scalars['Float']>;
  overtime_work?: Maybe<Scalars['Float']>;
  owner_id?: Maybe<Scalars['uuid']>;
  owner_name?: Maybe<Scalars['String']>;
  parent_id?: Maybe<Scalars['uuid']>;
  percent_completed?: Maybe<Scalars['Float']>;
  percentage_completness?: Maybe<Scalars['Float']>;
  percentage_documentation?: Maybe<Scalars['Float']>;
  process_comment?: Maybe<Scalars['String']>;
  project_departments?: Maybe<Scalars['String']>;
  projectassignment?: Maybe<Scalars['String']>;
  projectstatus?: Maybe<Scalars['String']>;
  regular_cost?: Maybe<Scalars['Float']>;
  regular_work?: Maybe<Scalars['Float']>;
  remaining_cost?: Maybe<Scalars['Float']>;
  remaining_duration?: Maybe<Scalars['Float']>;
  remaining_overtime_cost?: Maybe<Scalars['Float']>;
  remaining_overtime_work?: Maybe<Scalars['Float']>;
  remaining_regular_cost?: Maybe<Scalars['Float']>;
  remaining_regular_work?: Maybe<Scalars['Float']>;
  remaining_work?: Maybe<Scalars['Float']>;
  stage?: Maybe<Scalars['String']>;
  start_date?: Maybe<Scalars['timestamp']>;
  status?: Maybe<Scalars['String']>;
  timeline?: Maybe<Scalars['Float']>;
  total_area?: Maybe<Scalars['String']>;
  version?: Maybe<Scalars['smallint']>;
  wbs?: Maybe<Scalars['String']>;
  work?: Maybe<Scalars['Float']>;
};

/** aggregate stddev on columns */
export type Pwa_Projects_Stddev_Fields = {
  actual_cost?: Maybe<Scalars['Float']>;
  actual_duration?: Maybe<Scalars['Float']>;
  actual_overtime_cost?: Maybe<Scalars['Float']>;
  actual_overtime_work?: Maybe<Scalars['Float']>;
  actual_regular_cost?: Maybe<Scalars['Float']>;
  actual_regular_work?: Maybe<Scalars['Float']>;
  actual_work?: Maybe<Scalars['Float']>;
  admin_type?: Maybe<Scalars['Float']>;
  budget_cost?: Maybe<Scalars['Float']>;
  cost?: Maybe<Scalars['Float']>;
  duration?: Maybe<Scalars['Float']>;
  overtime_cost?: Maybe<Scalars['Float']>;
  overtime_work?: Maybe<Scalars['Float']>;
  percent_completed?: Maybe<Scalars['Float']>;
  percentage_completness?: Maybe<Scalars['Float']>;
  percentage_documentation?: Maybe<Scalars['Float']>;
  regular_cost?: Maybe<Scalars['Float']>;
  regular_work?: Maybe<Scalars['Float']>;
  remaining_cost?: Maybe<Scalars['Float']>;
  remaining_duration?: Maybe<Scalars['Float']>;
  remaining_overtime_cost?: Maybe<Scalars['Float']>;
  remaining_overtime_work?: Maybe<Scalars['Float']>;
  remaining_regular_cost?: Maybe<Scalars['Float']>;
  remaining_regular_work?: Maybe<Scalars['Float']>;
  remaining_work?: Maybe<Scalars['Float']>;
  timeline?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
  work?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Pwa_Projects_Stddev_Pop_Fields = {
  actual_cost?: Maybe<Scalars['Float']>;
  actual_duration?: Maybe<Scalars['Float']>;
  actual_overtime_cost?: Maybe<Scalars['Float']>;
  actual_overtime_work?: Maybe<Scalars['Float']>;
  actual_regular_cost?: Maybe<Scalars['Float']>;
  actual_regular_work?: Maybe<Scalars['Float']>;
  actual_work?: Maybe<Scalars['Float']>;
  admin_type?: Maybe<Scalars['Float']>;
  budget_cost?: Maybe<Scalars['Float']>;
  cost?: Maybe<Scalars['Float']>;
  duration?: Maybe<Scalars['Float']>;
  overtime_cost?: Maybe<Scalars['Float']>;
  overtime_work?: Maybe<Scalars['Float']>;
  percent_completed?: Maybe<Scalars['Float']>;
  percentage_completness?: Maybe<Scalars['Float']>;
  percentage_documentation?: Maybe<Scalars['Float']>;
  regular_cost?: Maybe<Scalars['Float']>;
  regular_work?: Maybe<Scalars['Float']>;
  remaining_cost?: Maybe<Scalars['Float']>;
  remaining_duration?: Maybe<Scalars['Float']>;
  remaining_overtime_cost?: Maybe<Scalars['Float']>;
  remaining_overtime_work?: Maybe<Scalars['Float']>;
  remaining_regular_cost?: Maybe<Scalars['Float']>;
  remaining_regular_work?: Maybe<Scalars['Float']>;
  remaining_work?: Maybe<Scalars['Float']>;
  timeline?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
  work?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Pwa_Projects_Stddev_Samp_Fields = {
  actual_cost?: Maybe<Scalars['Float']>;
  actual_duration?: Maybe<Scalars['Float']>;
  actual_overtime_cost?: Maybe<Scalars['Float']>;
  actual_overtime_work?: Maybe<Scalars['Float']>;
  actual_regular_cost?: Maybe<Scalars['Float']>;
  actual_regular_work?: Maybe<Scalars['Float']>;
  actual_work?: Maybe<Scalars['Float']>;
  admin_type?: Maybe<Scalars['Float']>;
  budget_cost?: Maybe<Scalars['Float']>;
  cost?: Maybe<Scalars['Float']>;
  duration?: Maybe<Scalars['Float']>;
  overtime_cost?: Maybe<Scalars['Float']>;
  overtime_work?: Maybe<Scalars['Float']>;
  percent_completed?: Maybe<Scalars['Float']>;
  percentage_completness?: Maybe<Scalars['Float']>;
  percentage_documentation?: Maybe<Scalars['Float']>;
  regular_cost?: Maybe<Scalars['Float']>;
  regular_work?: Maybe<Scalars['Float']>;
  remaining_cost?: Maybe<Scalars['Float']>;
  remaining_duration?: Maybe<Scalars['Float']>;
  remaining_overtime_cost?: Maybe<Scalars['Float']>;
  remaining_overtime_work?: Maybe<Scalars['Float']>;
  remaining_regular_cost?: Maybe<Scalars['Float']>;
  remaining_regular_work?: Maybe<Scalars['Float']>;
  remaining_work?: Maybe<Scalars['Float']>;
  timeline?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
  work?: Maybe<Scalars['Float']>;
};

/** aggregate sum on columns */
export type Pwa_Projects_Sum_Fields = {
  actual_cost?: Maybe<Scalars['Float']>;
  actual_duration?: Maybe<Scalars['Float']>;
  actual_overtime_cost?: Maybe<Scalars['Float']>;
  actual_overtime_work?: Maybe<Scalars['Float']>;
  actual_regular_cost?: Maybe<Scalars['Float']>;
  actual_regular_work?: Maybe<Scalars['Float']>;
  actual_work?: Maybe<Scalars['Float']>;
  admin_type?: Maybe<Scalars['bigint']>;
  budget_cost?: Maybe<Scalars['Float']>;
  cost?: Maybe<Scalars['Float']>;
  duration?: Maybe<Scalars['Float']>;
  overtime_cost?: Maybe<Scalars['Float']>;
  overtime_work?: Maybe<Scalars['Float']>;
  percent_completed?: Maybe<Scalars['Float']>;
  percentage_completness?: Maybe<Scalars['Float']>;
  percentage_documentation?: Maybe<Scalars['Float']>;
  regular_cost?: Maybe<Scalars['Float']>;
  regular_work?: Maybe<Scalars['Float']>;
  remaining_cost?: Maybe<Scalars['Float']>;
  remaining_duration?: Maybe<Scalars['Float']>;
  remaining_overtime_cost?: Maybe<Scalars['Float']>;
  remaining_overtime_work?: Maybe<Scalars['Float']>;
  remaining_regular_cost?: Maybe<Scalars['Float']>;
  remaining_regular_work?: Maybe<Scalars['Float']>;
  remaining_work?: Maybe<Scalars['Float']>;
  timeline?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['smallint']>;
  work?: Maybe<Scalars['Float']>;
};

/** aggregate var_pop on columns */
export type Pwa_Projects_Var_Pop_Fields = {
  actual_cost?: Maybe<Scalars['Float']>;
  actual_duration?: Maybe<Scalars['Float']>;
  actual_overtime_cost?: Maybe<Scalars['Float']>;
  actual_overtime_work?: Maybe<Scalars['Float']>;
  actual_regular_cost?: Maybe<Scalars['Float']>;
  actual_regular_work?: Maybe<Scalars['Float']>;
  actual_work?: Maybe<Scalars['Float']>;
  admin_type?: Maybe<Scalars['Float']>;
  budget_cost?: Maybe<Scalars['Float']>;
  cost?: Maybe<Scalars['Float']>;
  duration?: Maybe<Scalars['Float']>;
  overtime_cost?: Maybe<Scalars['Float']>;
  overtime_work?: Maybe<Scalars['Float']>;
  percent_completed?: Maybe<Scalars['Float']>;
  percentage_completness?: Maybe<Scalars['Float']>;
  percentage_documentation?: Maybe<Scalars['Float']>;
  regular_cost?: Maybe<Scalars['Float']>;
  regular_work?: Maybe<Scalars['Float']>;
  remaining_cost?: Maybe<Scalars['Float']>;
  remaining_duration?: Maybe<Scalars['Float']>;
  remaining_overtime_cost?: Maybe<Scalars['Float']>;
  remaining_overtime_work?: Maybe<Scalars['Float']>;
  remaining_regular_cost?: Maybe<Scalars['Float']>;
  remaining_regular_work?: Maybe<Scalars['Float']>;
  remaining_work?: Maybe<Scalars['Float']>;
  timeline?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
  work?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Pwa_Projects_Var_Samp_Fields = {
  actual_cost?: Maybe<Scalars['Float']>;
  actual_duration?: Maybe<Scalars['Float']>;
  actual_overtime_cost?: Maybe<Scalars['Float']>;
  actual_overtime_work?: Maybe<Scalars['Float']>;
  actual_regular_cost?: Maybe<Scalars['Float']>;
  actual_regular_work?: Maybe<Scalars['Float']>;
  actual_work?: Maybe<Scalars['Float']>;
  admin_type?: Maybe<Scalars['Float']>;
  budget_cost?: Maybe<Scalars['Float']>;
  cost?: Maybe<Scalars['Float']>;
  duration?: Maybe<Scalars['Float']>;
  overtime_cost?: Maybe<Scalars['Float']>;
  overtime_work?: Maybe<Scalars['Float']>;
  percent_completed?: Maybe<Scalars['Float']>;
  percentage_completness?: Maybe<Scalars['Float']>;
  percentage_documentation?: Maybe<Scalars['Float']>;
  regular_cost?: Maybe<Scalars['Float']>;
  regular_work?: Maybe<Scalars['Float']>;
  remaining_cost?: Maybe<Scalars['Float']>;
  remaining_duration?: Maybe<Scalars['Float']>;
  remaining_overtime_cost?: Maybe<Scalars['Float']>;
  remaining_overtime_work?: Maybe<Scalars['Float']>;
  remaining_regular_cost?: Maybe<Scalars['Float']>;
  remaining_regular_work?: Maybe<Scalars['Float']>;
  remaining_work?: Maybe<Scalars['Float']>;
  timeline?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
  work?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Pwa_Projects_Variance_Fields = {
  actual_cost?: Maybe<Scalars['Float']>;
  actual_duration?: Maybe<Scalars['Float']>;
  actual_overtime_cost?: Maybe<Scalars['Float']>;
  actual_overtime_work?: Maybe<Scalars['Float']>;
  actual_regular_cost?: Maybe<Scalars['Float']>;
  actual_regular_work?: Maybe<Scalars['Float']>;
  actual_work?: Maybe<Scalars['Float']>;
  admin_type?: Maybe<Scalars['Float']>;
  budget_cost?: Maybe<Scalars['Float']>;
  cost?: Maybe<Scalars['Float']>;
  duration?: Maybe<Scalars['Float']>;
  overtime_cost?: Maybe<Scalars['Float']>;
  overtime_work?: Maybe<Scalars['Float']>;
  percent_completed?: Maybe<Scalars['Float']>;
  percentage_completness?: Maybe<Scalars['Float']>;
  percentage_documentation?: Maybe<Scalars['Float']>;
  regular_cost?: Maybe<Scalars['Float']>;
  regular_work?: Maybe<Scalars['Float']>;
  remaining_cost?: Maybe<Scalars['Float']>;
  remaining_duration?: Maybe<Scalars['Float']>;
  remaining_overtime_cost?: Maybe<Scalars['Float']>;
  remaining_overtime_work?: Maybe<Scalars['Float']>;
  remaining_regular_cost?: Maybe<Scalars['Float']>;
  remaining_regular_work?: Maybe<Scalars['Float']>;
  remaining_work?: Maybe<Scalars['Float']>;
  timeline?: Maybe<Scalars['Float']>;
  version?: Maybe<Scalars['Float']>;
  work?: Maybe<Scalars['Float']>;
};

export type Query_Root = {
  /** fetch data from the table: "auth.account_providers" */
  auth_account_providers: Array<Auth_Account_Providers>;
  /** fetch aggregated fields from the table: "auth.account_providers" */
  auth_account_providers_aggregate: Auth_Account_Providers_Aggregate;
  /** fetch data from the table: "auth.account_providers" using primary key columns */
  auth_account_providers_by_pk?: Maybe<Auth_Account_Providers>;
  /** fetch data from the table: "auth.account_roles" */
  auth_account_roles: Array<Auth_Account_Roles>;
  /** fetch aggregated fields from the table: "auth.account_roles" */
  auth_account_roles_aggregate: Auth_Account_Roles_Aggregate;
  /** fetch data from the table: "auth.account_roles" using primary key columns */
  auth_account_roles_by_pk?: Maybe<Auth_Account_Roles>;
  /** fetch data from the table: "auth.accounts" */
  auth_accounts: Array<Auth_Accounts>;
  /** fetch aggregated fields from the table: "auth.accounts" */
  auth_accounts_aggregate: Auth_Accounts_Aggregate;
  /** fetch data from the table: "auth.accounts" using primary key columns */
  auth_accounts_by_pk?: Maybe<Auth_Accounts>;
  /** fetch data from the table: "auth.migrations" */
  auth_migrations: Array<Auth_Migrations>;
  /** fetch aggregated fields from the table: "auth.migrations" */
  auth_migrations_aggregate: Auth_Migrations_Aggregate;
  /** fetch data from the table: "auth.migrations" using primary key columns */
  auth_migrations_by_pk?: Maybe<Auth_Migrations>;
  /** fetch data from the table: "auth.providers" */
  auth_providers: Array<Auth_Providers>;
  /** fetch aggregated fields from the table: "auth.providers" */
  auth_providers_aggregate: Auth_Providers_Aggregate;
  /** fetch data from the table: "auth.providers" using primary key columns */
  auth_providers_by_pk?: Maybe<Auth_Providers>;
  /** fetch data from the table: "auth.refresh_tokens" */
  auth_refresh_tokens: Array<Auth_Refresh_Tokens>;
  /** fetch aggregated fields from the table: "auth.refresh_tokens" */
  auth_refresh_tokens_aggregate: Auth_Refresh_Tokens_Aggregate;
  /** fetch data from the table: "auth.refresh_tokens" using primary key columns */
  auth_refresh_tokens_by_pk?: Maybe<Auth_Refresh_Tokens>;
  /** fetch data from the table: "auth.roles" */
  auth_roles: Array<Auth_Roles>;
  /** fetch aggregated fields from the table: "auth.roles" */
  auth_roles_aggregate: Auth_Roles_Aggregate;
  /** fetch data from the table: "auth.roles" using primary key columns */
  auth_roles_by_pk?: Maybe<Auth_Roles>;
  /** fetch data from the table: "budg_data" */
  budg_data: Array<Budg_Data>;
  /** fetch aggregated fields from the table: "budg_data" */
  budg_data_aggregate: Budg_Data_Aggregate;
  /** fetch data from the table: "budg_data" using primary key columns */
  budg_data_by_pk?: Maybe<Budg_Data>;
  /** fetch data from the table: "budget.metadata" */
  budget_metadata: Array<Budget_Metadata>;
  /** fetch aggregated fields from the table: "budget.metadata" */
  budget_metadata_aggregate: Budget_Metadata_Aggregate;
  /** fetch data from the table: "budget.metadata" using primary key columns */
  budget_metadata_by_pk?: Maybe<Budget_Metadata>;
  /** fetch data from the table: "budget.parameters" */
  budget_parameters: Array<Budget_Parameters>;
  /** fetch aggregated fields from the table: "budget.parameters" */
  budget_parameters_aggregate: Budget_Parameters_Aggregate;
  /** fetch data from the table: "budget.parameters_data" */
  budget_parameters_data: Array<Budget_Parameters_Data>;
  /** fetch aggregated fields from the table: "budget.parameters_data" */
  budget_parameters_data_aggregate: Budget_Parameters_Data_Aggregate;
  /** fetch data from the table: "dividends" */
  dividends: Array<Dividends>;
  /** fetch aggregated fields from the table: "dividends" */
  dividends_aggregate: Dividends_Aggregate;
  getDividends?: Maybe<Array<Maybe<Div>>>;
  /** fetch data from the table: "hr.arm_partnerroleassignment" */
  hr_arm_partnerroleassignment: Array<Hr_Arm_Partnerroleassignment>;
  /** fetch aggregated fields from the table: "hr.arm_partnerroleassignment" */
  hr_arm_partnerroleassignment_aggregate: Hr_Arm_Partnerroleassignment_Aggregate;
  /** fetch data from the table: "hr.arm_partners" */
  hr_arm_partners: Array<Hr_Arm_Partners>;
  /** fetch aggregated fields from the table: "hr.arm_partners" */
  hr_arm_partners_aggregate: Hr_Arm_Partners_Aggregate;
  /** fetch data from the table: "hr.arm_teamleadassignment" */
  hr_arm_teamleadassignment: Array<Hr_Arm_Teamleadassignment>;
  /** fetch aggregated fields from the table: "hr.arm_teamleadassignment" */
  hr_arm_teamleadassignment_aggregate: Hr_Arm_Teamleadassignment_Aggregate;
  /** fetch data from the table: "hr.cdm_compensationfixedplans" */
  hr_cdm_compensationfixedplans: Array<Hr_Cdm_Compensationfixedplans>;
  /** fetch aggregated fields from the table: "hr.cdm_compensationfixedplans" */
  hr_cdm_compensationfixedplans_aggregate: Hr_Cdm_Compensationfixedplans_Aggregate;
  /** fetch data from the table: "hr.cdm_compensationlevels" */
  hr_cdm_compensationlevels: Array<Hr_Cdm_Compensationlevels>;
  /** fetch aggregated fields from the table: "hr.cdm_compensationlevels" */
  hr_cdm_compensationlevels_aggregate: Hr_Cdm_Compensationlevels_Aggregate;
  /** fetch data from the table: "hr.cdm_departments" */
  hr_cdm_departments: Array<Hr_Cdm_Departments>;
  /** fetch aggregated fields from the table: "hr.cdm_departments" */
  hr_cdm_departments_aggregate: Hr_Cdm_Departments_Aggregate;
  /** fetch data from the table: "hr.cdm_employments" */
  hr_cdm_employments: Array<Hr_Cdm_Employments>;
  /** fetch aggregated fields from the table: "hr.cdm_employments" */
  hr_cdm_employments_aggregate: Hr_Cdm_Employments_Aggregate;
  /** fetch data from the table: "hr.cdm_jobpositions" */
  hr_cdm_jobpositions: Array<Hr_Cdm_Jobpositions>;
  /** fetch aggregated fields from the table: "hr.cdm_jobpositions" */
  hr_cdm_jobpositions_aggregate: Hr_Cdm_Jobpositions_Aggregate;
  /** fetch data from the table: "hr.cdm_jobs" */
  hr_cdm_jobs: Array<Hr_Cdm_Jobs>;
  /** fetch aggregated fields from the table: "hr.cdm_jobs" */
  hr_cdm_jobs_aggregate: Hr_Cdm_Jobs_Aggregate;
  /** fetch data from the table: "hr.cdm_jobtypes" */
  hr_cdm_jobtypes: Array<Hr_Cdm_Jobtypes>;
  /** fetch aggregated fields from the table: "hr.cdm_jobtypes" */
  hr_cdm_jobtypes_aggregate: Hr_Cdm_Jobtypes_Aggregate;
  /** fetch data from the table: "hr.cdm_leaveenrollments" */
  hr_cdm_leaveenrollments: Array<Hr_Cdm_Leaveenrollments>;
  /** fetch aggregated fields from the table: "hr.cdm_leaveenrollments" */
  hr_cdm_leaveenrollments_aggregate: Hr_Cdm_Leaveenrollments_Aggregate;
  /** fetch data from the table: "hr.cdm_leaveplans" */
  hr_cdm_leaveplans: Array<Hr_Cdm_Leaveplans>;
  /** fetch aggregated fields from the table: "hr.cdm_leaveplans" */
  hr_cdm_leaveplans_aggregate: Hr_Cdm_Leaveplans_Aggregate;
  /** fetch data from the table: "hr.cdm_positionworkerassignmentmaps" */
  hr_cdm_positionworkerassignmentmaps: Array<Hr_Cdm_Positionworkerassignmentmaps>;
  /** fetch aggregated fields from the table: "hr.cdm_positionworkerassignmentmaps" */
  hr_cdm_positionworkerassignmentmaps_aggregate: Hr_Cdm_Positionworkerassignmentmaps_Aggregate;
  /** fetch data from the table: "hr.cdm_workerfixedcompensations" */
  hr_cdm_workerfixedcompensations: Array<Hr_Cdm_Workerfixedcompensations>;
  /** fetch aggregated fields from the table: "hr.cdm_workerfixedcompensations" */
  hr_cdm_workerfixedcompensations_aggregate: Hr_Cdm_Workerfixedcompensations_Aggregate;
  /** fetch data from the table: "hr.cdm_workers" */
  hr_cdm_workers: Array<Hr_Cdm_Workers>;
  /** fetch aggregated fields from the table: "hr.cdm_workers" */
  hr_cdm_workers_aggregate: Hr_Cdm_Workers_Aggregate;
  /** fetch data from the table: "pwa.projects" */
  pwa_projects: Array<Pwa_Projects>;
  /** fetch aggregated fields from the table: "pwa.projects" */
  pwa_projects_aggregate: Pwa_Projects_Aggregate;
  /** fetch data from the table: "users" */
  users: Array<Users>;
  /** fetch aggregated fields from the table: "users" */
  users_aggregate: Users_Aggregate;
  /** fetch data from the table: "users" using primary key columns */
  users_by_pk?: Maybe<Users>;
};


export type Query_RootAuth_Account_ProvidersArgs = {
  distinct_on?: Maybe<Array<Auth_Account_Providers_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Auth_Account_Providers_Order_By>>;
  where?: Maybe<Auth_Account_Providers_Bool_Exp>;
};


export type Query_RootAuth_Account_Providers_AggregateArgs = {
  distinct_on?: Maybe<Array<Auth_Account_Providers_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Auth_Account_Providers_Order_By>>;
  where?: Maybe<Auth_Account_Providers_Bool_Exp>;
};


export type Query_RootAuth_Account_Providers_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootAuth_Account_RolesArgs = {
  distinct_on?: Maybe<Array<Auth_Account_Roles_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Auth_Account_Roles_Order_By>>;
  where?: Maybe<Auth_Account_Roles_Bool_Exp>;
};


export type Query_RootAuth_Account_Roles_AggregateArgs = {
  distinct_on?: Maybe<Array<Auth_Account_Roles_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Auth_Account_Roles_Order_By>>;
  where?: Maybe<Auth_Account_Roles_Bool_Exp>;
};


export type Query_RootAuth_Account_Roles_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootAuth_AccountsArgs = {
  distinct_on?: Maybe<Array<Auth_Accounts_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Auth_Accounts_Order_By>>;
  where?: Maybe<Auth_Accounts_Bool_Exp>;
};


export type Query_RootAuth_Accounts_AggregateArgs = {
  distinct_on?: Maybe<Array<Auth_Accounts_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Auth_Accounts_Order_By>>;
  where?: Maybe<Auth_Accounts_Bool_Exp>;
};


export type Query_RootAuth_Accounts_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootAuth_MigrationsArgs = {
  distinct_on?: Maybe<Array<Auth_Migrations_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Auth_Migrations_Order_By>>;
  where?: Maybe<Auth_Migrations_Bool_Exp>;
};


export type Query_RootAuth_Migrations_AggregateArgs = {
  distinct_on?: Maybe<Array<Auth_Migrations_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Auth_Migrations_Order_By>>;
  where?: Maybe<Auth_Migrations_Bool_Exp>;
};


export type Query_RootAuth_Migrations_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootAuth_ProvidersArgs = {
  distinct_on?: Maybe<Array<Auth_Providers_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Auth_Providers_Order_By>>;
  where?: Maybe<Auth_Providers_Bool_Exp>;
};


export type Query_RootAuth_Providers_AggregateArgs = {
  distinct_on?: Maybe<Array<Auth_Providers_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Auth_Providers_Order_By>>;
  where?: Maybe<Auth_Providers_Bool_Exp>;
};


export type Query_RootAuth_Providers_By_PkArgs = {
  provider: Scalars['String'];
};


export type Query_RootAuth_Refresh_TokensArgs = {
  distinct_on?: Maybe<Array<Auth_Refresh_Tokens_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Auth_Refresh_Tokens_Order_By>>;
  where?: Maybe<Auth_Refresh_Tokens_Bool_Exp>;
};


export type Query_RootAuth_Refresh_Tokens_AggregateArgs = {
  distinct_on?: Maybe<Array<Auth_Refresh_Tokens_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Auth_Refresh_Tokens_Order_By>>;
  where?: Maybe<Auth_Refresh_Tokens_Bool_Exp>;
};


export type Query_RootAuth_Refresh_Tokens_By_PkArgs = {
  refresh_token: Scalars['uuid'];
};


export type Query_RootAuth_RolesArgs = {
  distinct_on?: Maybe<Array<Auth_Roles_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Auth_Roles_Order_By>>;
  where?: Maybe<Auth_Roles_Bool_Exp>;
};


export type Query_RootAuth_Roles_AggregateArgs = {
  distinct_on?: Maybe<Array<Auth_Roles_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Auth_Roles_Order_By>>;
  where?: Maybe<Auth_Roles_Bool_Exp>;
};


export type Query_RootAuth_Roles_By_PkArgs = {
  role: Scalars['String'];
};


export type Query_RootBudg_DataArgs = {
  distinct_on?: Maybe<Array<Budg_Data_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Budg_Data_Order_By>>;
  where?: Maybe<Budg_Data_Bool_Exp>;
};


export type Query_RootBudg_Data_AggregateArgs = {
  distinct_on?: Maybe<Array<Budg_Data_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Budg_Data_Order_By>>;
  where?: Maybe<Budg_Data_Bool_Exp>;
};


export type Query_RootBudg_Data_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootBudget_MetadataArgs = {
  distinct_on?: Maybe<Array<Budget_Metadata_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Budget_Metadata_Order_By>>;
  where?: Maybe<Budget_Metadata_Bool_Exp>;
};


export type Query_RootBudget_Metadata_AggregateArgs = {
  distinct_on?: Maybe<Array<Budget_Metadata_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Budget_Metadata_Order_By>>;
  where?: Maybe<Budget_Metadata_Bool_Exp>;
};


export type Query_RootBudget_Metadata_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Query_RootBudget_ParametersArgs = {
  distinct_on?: Maybe<Array<Budget_Parameters_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Budget_Parameters_Order_By>>;
  where?: Maybe<Budget_Parameters_Bool_Exp>;
};


export type Query_RootBudget_Parameters_AggregateArgs = {
  distinct_on?: Maybe<Array<Budget_Parameters_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Budget_Parameters_Order_By>>;
  where?: Maybe<Budget_Parameters_Bool_Exp>;
};


export type Query_RootBudget_Parameters_DataArgs = {
  distinct_on?: Maybe<Array<Budget_Parameters_Data_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Budget_Parameters_Data_Order_By>>;
  where?: Maybe<Budget_Parameters_Data_Bool_Exp>;
};


export type Query_RootBudget_Parameters_Data_AggregateArgs = {
  distinct_on?: Maybe<Array<Budget_Parameters_Data_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Budget_Parameters_Data_Order_By>>;
  where?: Maybe<Budget_Parameters_Data_Bool_Exp>;
};


export type Query_RootDividendsArgs = {
  distinct_on?: Maybe<Array<Dividends_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dividends_Order_By>>;
  where?: Maybe<Dividends_Bool_Exp>;
};


export type Query_RootDividends_AggregateArgs = {
  distinct_on?: Maybe<Array<Dividends_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dividends_Order_By>>;
  where?: Maybe<Dividends_Bool_Exp>;
};


export type Query_RootHr_Arm_PartnerroleassignmentArgs = {
  distinct_on?: Maybe<Array<Hr_Arm_Partnerroleassignment_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Hr_Arm_Partnerroleassignment_Order_By>>;
  where?: Maybe<Hr_Arm_Partnerroleassignment_Bool_Exp>;
};


export type Query_RootHr_Arm_Partnerroleassignment_AggregateArgs = {
  distinct_on?: Maybe<Array<Hr_Arm_Partnerroleassignment_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Hr_Arm_Partnerroleassignment_Order_By>>;
  where?: Maybe<Hr_Arm_Partnerroleassignment_Bool_Exp>;
};


export type Query_RootHr_Arm_PartnersArgs = {
  distinct_on?: Maybe<Array<Hr_Arm_Partners_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Hr_Arm_Partners_Order_By>>;
  where?: Maybe<Hr_Arm_Partners_Bool_Exp>;
};


export type Query_RootHr_Arm_Partners_AggregateArgs = {
  distinct_on?: Maybe<Array<Hr_Arm_Partners_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Hr_Arm_Partners_Order_By>>;
  where?: Maybe<Hr_Arm_Partners_Bool_Exp>;
};


export type Query_RootHr_Arm_TeamleadassignmentArgs = {
  distinct_on?: Maybe<Array<Hr_Arm_Teamleadassignment_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Hr_Arm_Teamleadassignment_Order_By>>;
  where?: Maybe<Hr_Arm_Teamleadassignment_Bool_Exp>;
};


export type Query_RootHr_Arm_Teamleadassignment_AggregateArgs = {
  distinct_on?: Maybe<Array<Hr_Arm_Teamleadassignment_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Hr_Arm_Teamleadassignment_Order_By>>;
  where?: Maybe<Hr_Arm_Teamleadassignment_Bool_Exp>;
};


export type Query_RootHr_Cdm_CompensationfixedplansArgs = {
  distinct_on?: Maybe<Array<Hr_Cdm_Compensationfixedplans_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Hr_Cdm_Compensationfixedplans_Order_By>>;
  where?: Maybe<Hr_Cdm_Compensationfixedplans_Bool_Exp>;
};


export type Query_RootHr_Cdm_Compensationfixedplans_AggregateArgs = {
  distinct_on?: Maybe<Array<Hr_Cdm_Compensationfixedplans_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Hr_Cdm_Compensationfixedplans_Order_By>>;
  where?: Maybe<Hr_Cdm_Compensationfixedplans_Bool_Exp>;
};


export type Query_RootHr_Cdm_CompensationlevelsArgs = {
  distinct_on?: Maybe<Array<Hr_Cdm_Compensationlevels_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Hr_Cdm_Compensationlevels_Order_By>>;
  where?: Maybe<Hr_Cdm_Compensationlevels_Bool_Exp>;
};


export type Query_RootHr_Cdm_Compensationlevels_AggregateArgs = {
  distinct_on?: Maybe<Array<Hr_Cdm_Compensationlevels_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Hr_Cdm_Compensationlevels_Order_By>>;
  where?: Maybe<Hr_Cdm_Compensationlevels_Bool_Exp>;
};


export type Query_RootHr_Cdm_DepartmentsArgs = {
  distinct_on?: Maybe<Array<Hr_Cdm_Departments_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Hr_Cdm_Departments_Order_By>>;
  where?: Maybe<Hr_Cdm_Departments_Bool_Exp>;
};


export type Query_RootHr_Cdm_Departments_AggregateArgs = {
  distinct_on?: Maybe<Array<Hr_Cdm_Departments_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Hr_Cdm_Departments_Order_By>>;
  where?: Maybe<Hr_Cdm_Departments_Bool_Exp>;
};


export type Query_RootHr_Cdm_EmploymentsArgs = {
  distinct_on?: Maybe<Array<Hr_Cdm_Employments_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Hr_Cdm_Employments_Order_By>>;
  where?: Maybe<Hr_Cdm_Employments_Bool_Exp>;
};


export type Query_RootHr_Cdm_Employments_AggregateArgs = {
  distinct_on?: Maybe<Array<Hr_Cdm_Employments_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Hr_Cdm_Employments_Order_By>>;
  where?: Maybe<Hr_Cdm_Employments_Bool_Exp>;
};


export type Query_RootHr_Cdm_JobpositionsArgs = {
  distinct_on?: Maybe<Array<Hr_Cdm_Jobpositions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Hr_Cdm_Jobpositions_Order_By>>;
  where?: Maybe<Hr_Cdm_Jobpositions_Bool_Exp>;
};


export type Query_RootHr_Cdm_Jobpositions_AggregateArgs = {
  distinct_on?: Maybe<Array<Hr_Cdm_Jobpositions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Hr_Cdm_Jobpositions_Order_By>>;
  where?: Maybe<Hr_Cdm_Jobpositions_Bool_Exp>;
};


export type Query_RootHr_Cdm_JobsArgs = {
  distinct_on?: Maybe<Array<Hr_Cdm_Jobs_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Hr_Cdm_Jobs_Order_By>>;
  where?: Maybe<Hr_Cdm_Jobs_Bool_Exp>;
};


export type Query_RootHr_Cdm_Jobs_AggregateArgs = {
  distinct_on?: Maybe<Array<Hr_Cdm_Jobs_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Hr_Cdm_Jobs_Order_By>>;
  where?: Maybe<Hr_Cdm_Jobs_Bool_Exp>;
};


export type Query_RootHr_Cdm_JobtypesArgs = {
  distinct_on?: Maybe<Array<Hr_Cdm_Jobtypes_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Hr_Cdm_Jobtypes_Order_By>>;
  where?: Maybe<Hr_Cdm_Jobtypes_Bool_Exp>;
};


export type Query_RootHr_Cdm_Jobtypes_AggregateArgs = {
  distinct_on?: Maybe<Array<Hr_Cdm_Jobtypes_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Hr_Cdm_Jobtypes_Order_By>>;
  where?: Maybe<Hr_Cdm_Jobtypes_Bool_Exp>;
};


export type Query_RootHr_Cdm_LeaveenrollmentsArgs = {
  distinct_on?: Maybe<Array<Hr_Cdm_Leaveenrollments_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Hr_Cdm_Leaveenrollments_Order_By>>;
  where?: Maybe<Hr_Cdm_Leaveenrollments_Bool_Exp>;
};


export type Query_RootHr_Cdm_Leaveenrollments_AggregateArgs = {
  distinct_on?: Maybe<Array<Hr_Cdm_Leaveenrollments_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Hr_Cdm_Leaveenrollments_Order_By>>;
  where?: Maybe<Hr_Cdm_Leaveenrollments_Bool_Exp>;
};


export type Query_RootHr_Cdm_LeaveplansArgs = {
  distinct_on?: Maybe<Array<Hr_Cdm_Leaveplans_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Hr_Cdm_Leaveplans_Order_By>>;
  where?: Maybe<Hr_Cdm_Leaveplans_Bool_Exp>;
};


export type Query_RootHr_Cdm_Leaveplans_AggregateArgs = {
  distinct_on?: Maybe<Array<Hr_Cdm_Leaveplans_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Hr_Cdm_Leaveplans_Order_By>>;
  where?: Maybe<Hr_Cdm_Leaveplans_Bool_Exp>;
};


export type Query_RootHr_Cdm_PositionworkerassignmentmapsArgs = {
  distinct_on?: Maybe<Array<Hr_Cdm_Positionworkerassignmentmaps_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Hr_Cdm_Positionworkerassignmentmaps_Order_By>>;
  where?: Maybe<Hr_Cdm_Positionworkerassignmentmaps_Bool_Exp>;
};


export type Query_RootHr_Cdm_Positionworkerassignmentmaps_AggregateArgs = {
  distinct_on?: Maybe<Array<Hr_Cdm_Positionworkerassignmentmaps_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Hr_Cdm_Positionworkerassignmentmaps_Order_By>>;
  where?: Maybe<Hr_Cdm_Positionworkerassignmentmaps_Bool_Exp>;
};


export type Query_RootHr_Cdm_WorkerfixedcompensationsArgs = {
  distinct_on?: Maybe<Array<Hr_Cdm_Workerfixedcompensations_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Hr_Cdm_Workerfixedcompensations_Order_By>>;
  where?: Maybe<Hr_Cdm_Workerfixedcompensations_Bool_Exp>;
};


export type Query_RootHr_Cdm_Workerfixedcompensations_AggregateArgs = {
  distinct_on?: Maybe<Array<Hr_Cdm_Workerfixedcompensations_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Hr_Cdm_Workerfixedcompensations_Order_By>>;
  where?: Maybe<Hr_Cdm_Workerfixedcompensations_Bool_Exp>;
};


export type Query_RootHr_Cdm_WorkersArgs = {
  distinct_on?: Maybe<Array<Hr_Cdm_Workers_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Hr_Cdm_Workers_Order_By>>;
  where?: Maybe<Hr_Cdm_Workers_Bool_Exp>;
};


export type Query_RootHr_Cdm_Workers_AggregateArgs = {
  distinct_on?: Maybe<Array<Hr_Cdm_Workers_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Hr_Cdm_Workers_Order_By>>;
  where?: Maybe<Hr_Cdm_Workers_Bool_Exp>;
};


export type Query_RootPwa_ProjectsArgs = {
  distinct_on?: Maybe<Array<Pwa_Projects_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Pwa_Projects_Order_By>>;
  where?: Maybe<Pwa_Projects_Bool_Exp>;
};


export type Query_RootPwa_Projects_AggregateArgs = {
  distinct_on?: Maybe<Array<Pwa_Projects_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Pwa_Projects_Order_By>>;
  where?: Maybe<Pwa_Projects_Bool_Exp>;
};


export type Query_RootUsersArgs = {
  distinct_on?: Maybe<Array<Users_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Users_Order_By>>;
  where?: Maybe<Users_Bool_Exp>;
};


export type Query_RootUsers_AggregateArgs = {
  distinct_on?: Maybe<Array<Users_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Users_Order_By>>;
  where?: Maybe<Users_Bool_Exp>;
};


export type Query_RootUsers_By_PkArgs = {
  id: Scalars['uuid'];
};


/** Boolean expression to compare columns of type "smallint". All fields are combined with logical 'AND'. */
export type Smallint_Comparison_Exp = {
  _eq?: Maybe<Scalars['smallint']>;
  _gt?: Maybe<Scalars['smallint']>;
  _gte?: Maybe<Scalars['smallint']>;
  _in?: Maybe<Array<Scalars['smallint']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['smallint']>;
  _lte?: Maybe<Scalars['smallint']>;
  _neq?: Maybe<Scalars['smallint']>;
  _nin?: Maybe<Array<Scalars['smallint']>>;
};

export type Subscription_Root = {
  /** fetch data from the table: "auth.account_providers" */
  auth_account_providers: Array<Auth_Account_Providers>;
  /** fetch aggregated fields from the table: "auth.account_providers" */
  auth_account_providers_aggregate: Auth_Account_Providers_Aggregate;
  /** fetch data from the table: "auth.account_providers" using primary key columns */
  auth_account_providers_by_pk?: Maybe<Auth_Account_Providers>;
  /** fetch data from the table: "auth.account_roles" */
  auth_account_roles: Array<Auth_Account_Roles>;
  /** fetch aggregated fields from the table: "auth.account_roles" */
  auth_account_roles_aggregate: Auth_Account_Roles_Aggregate;
  /** fetch data from the table: "auth.account_roles" using primary key columns */
  auth_account_roles_by_pk?: Maybe<Auth_Account_Roles>;
  /** fetch data from the table: "auth.accounts" */
  auth_accounts: Array<Auth_Accounts>;
  /** fetch aggregated fields from the table: "auth.accounts" */
  auth_accounts_aggregate: Auth_Accounts_Aggregate;
  /** fetch data from the table: "auth.accounts" using primary key columns */
  auth_accounts_by_pk?: Maybe<Auth_Accounts>;
  /** fetch data from the table: "auth.migrations" */
  auth_migrations: Array<Auth_Migrations>;
  /** fetch aggregated fields from the table: "auth.migrations" */
  auth_migrations_aggregate: Auth_Migrations_Aggregate;
  /** fetch data from the table: "auth.migrations" using primary key columns */
  auth_migrations_by_pk?: Maybe<Auth_Migrations>;
  /** fetch data from the table: "auth.providers" */
  auth_providers: Array<Auth_Providers>;
  /** fetch aggregated fields from the table: "auth.providers" */
  auth_providers_aggregate: Auth_Providers_Aggregate;
  /** fetch data from the table: "auth.providers" using primary key columns */
  auth_providers_by_pk?: Maybe<Auth_Providers>;
  /** fetch data from the table: "auth.refresh_tokens" */
  auth_refresh_tokens: Array<Auth_Refresh_Tokens>;
  /** fetch aggregated fields from the table: "auth.refresh_tokens" */
  auth_refresh_tokens_aggregate: Auth_Refresh_Tokens_Aggregate;
  /** fetch data from the table: "auth.refresh_tokens" using primary key columns */
  auth_refresh_tokens_by_pk?: Maybe<Auth_Refresh_Tokens>;
  /** fetch data from the table: "auth.roles" */
  auth_roles: Array<Auth_Roles>;
  /** fetch aggregated fields from the table: "auth.roles" */
  auth_roles_aggregate: Auth_Roles_Aggregate;
  /** fetch data from the table: "auth.roles" using primary key columns */
  auth_roles_by_pk?: Maybe<Auth_Roles>;
  /** fetch data from the table: "budg_data" */
  budg_data: Array<Budg_Data>;
  /** fetch aggregated fields from the table: "budg_data" */
  budg_data_aggregate: Budg_Data_Aggregate;
  /** fetch data from the table: "budg_data" using primary key columns */
  budg_data_by_pk?: Maybe<Budg_Data>;
  /** fetch data from the table: "budget.metadata" */
  budget_metadata: Array<Budget_Metadata>;
  /** fetch aggregated fields from the table: "budget.metadata" */
  budget_metadata_aggregate: Budget_Metadata_Aggregate;
  /** fetch data from the table: "budget.metadata" using primary key columns */
  budget_metadata_by_pk?: Maybe<Budget_Metadata>;
  /** fetch data from the table: "budget.parameters" */
  budget_parameters: Array<Budget_Parameters>;
  /** fetch aggregated fields from the table: "budget.parameters" */
  budget_parameters_aggregate: Budget_Parameters_Aggregate;
  /** fetch data from the table: "budget.parameters_data" */
  budget_parameters_data: Array<Budget_Parameters_Data>;
  /** fetch aggregated fields from the table: "budget.parameters_data" */
  budget_parameters_data_aggregate: Budget_Parameters_Data_Aggregate;
  /** fetch data from the table: "dividends" */
  dividends: Array<Dividends>;
  /** fetch aggregated fields from the table: "dividends" */
  dividends_aggregate: Dividends_Aggregate;
  /** fetch data from the table: "hr.arm_partnerroleassignment" */
  hr_arm_partnerroleassignment: Array<Hr_Arm_Partnerroleassignment>;
  /** fetch aggregated fields from the table: "hr.arm_partnerroleassignment" */
  hr_arm_partnerroleassignment_aggregate: Hr_Arm_Partnerroleassignment_Aggregate;
  /** fetch data from the table: "hr.arm_partners" */
  hr_arm_partners: Array<Hr_Arm_Partners>;
  /** fetch aggregated fields from the table: "hr.arm_partners" */
  hr_arm_partners_aggregate: Hr_Arm_Partners_Aggregate;
  /** fetch data from the table: "hr.arm_teamleadassignment" */
  hr_arm_teamleadassignment: Array<Hr_Arm_Teamleadassignment>;
  /** fetch aggregated fields from the table: "hr.arm_teamleadassignment" */
  hr_arm_teamleadassignment_aggregate: Hr_Arm_Teamleadassignment_Aggregate;
  /** fetch data from the table: "hr.cdm_compensationfixedplans" */
  hr_cdm_compensationfixedplans: Array<Hr_Cdm_Compensationfixedplans>;
  /** fetch aggregated fields from the table: "hr.cdm_compensationfixedplans" */
  hr_cdm_compensationfixedplans_aggregate: Hr_Cdm_Compensationfixedplans_Aggregate;
  /** fetch data from the table: "hr.cdm_compensationlevels" */
  hr_cdm_compensationlevels: Array<Hr_Cdm_Compensationlevels>;
  /** fetch aggregated fields from the table: "hr.cdm_compensationlevels" */
  hr_cdm_compensationlevels_aggregate: Hr_Cdm_Compensationlevels_Aggregate;
  /** fetch data from the table: "hr.cdm_departments" */
  hr_cdm_departments: Array<Hr_Cdm_Departments>;
  /** fetch aggregated fields from the table: "hr.cdm_departments" */
  hr_cdm_departments_aggregate: Hr_Cdm_Departments_Aggregate;
  /** fetch data from the table: "hr.cdm_employments" */
  hr_cdm_employments: Array<Hr_Cdm_Employments>;
  /** fetch aggregated fields from the table: "hr.cdm_employments" */
  hr_cdm_employments_aggregate: Hr_Cdm_Employments_Aggregate;
  /** fetch data from the table: "hr.cdm_jobpositions" */
  hr_cdm_jobpositions: Array<Hr_Cdm_Jobpositions>;
  /** fetch aggregated fields from the table: "hr.cdm_jobpositions" */
  hr_cdm_jobpositions_aggregate: Hr_Cdm_Jobpositions_Aggregate;
  /** fetch data from the table: "hr.cdm_jobs" */
  hr_cdm_jobs: Array<Hr_Cdm_Jobs>;
  /** fetch aggregated fields from the table: "hr.cdm_jobs" */
  hr_cdm_jobs_aggregate: Hr_Cdm_Jobs_Aggregate;
  /** fetch data from the table: "hr.cdm_jobtypes" */
  hr_cdm_jobtypes: Array<Hr_Cdm_Jobtypes>;
  /** fetch aggregated fields from the table: "hr.cdm_jobtypes" */
  hr_cdm_jobtypes_aggregate: Hr_Cdm_Jobtypes_Aggregate;
  /** fetch data from the table: "hr.cdm_leaveenrollments" */
  hr_cdm_leaveenrollments: Array<Hr_Cdm_Leaveenrollments>;
  /** fetch aggregated fields from the table: "hr.cdm_leaveenrollments" */
  hr_cdm_leaveenrollments_aggregate: Hr_Cdm_Leaveenrollments_Aggregate;
  /** fetch data from the table: "hr.cdm_leaveplans" */
  hr_cdm_leaveplans: Array<Hr_Cdm_Leaveplans>;
  /** fetch aggregated fields from the table: "hr.cdm_leaveplans" */
  hr_cdm_leaveplans_aggregate: Hr_Cdm_Leaveplans_Aggregate;
  /** fetch data from the table: "hr.cdm_positionworkerassignmentmaps" */
  hr_cdm_positionworkerassignmentmaps: Array<Hr_Cdm_Positionworkerassignmentmaps>;
  /** fetch aggregated fields from the table: "hr.cdm_positionworkerassignmentmaps" */
  hr_cdm_positionworkerassignmentmaps_aggregate: Hr_Cdm_Positionworkerassignmentmaps_Aggregate;
  /** fetch data from the table: "hr.cdm_workerfixedcompensations" */
  hr_cdm_workerfixedcompensations: Array<Hr_Cdm_Workerfixedcompensations>;
  /** fetch aggregated fields from the table: "hr.cdm_workerfixedcompensations" */
  hr_cdm_workerfixedcompensations_aggregate: Hr_Cdm_Workerfixedcompensations_Aggregate;
  /** fetch data from the table: "hr.cdm_workers" */
  hr_cdm_workers: Array<Hr_Cdm_Workers>;
  /** fetch aggregated fields from the table: "hr.cdm_workers" */
  hr_cdm_workers_aggregate: Hr_Cdm_Workers_Aggregate;
  /** fetch data from the table: "pwa.projects" */
  pwa_projects: Array<Pwa_Projects>;
  /** fetch aggregated fields from the table: "pwa.projects" */
  pwa_projects_aggregate: Pwa_Projects_Aggregate;
  /** fetch data from the table: "users" */
  users: Array<Users>;
  /** fetch aggregated fields from the table: "users" */
  users_aggregate: Users_Aggregate;
  /** fetch data from the table: "users" using primary key columns */
  users_by_pk?: Maybe<Users>;
};


export type Subscription_RootAuth_Account_ProvidersArgs = {
  distinct_on?: Maybe<Array<Auth_Account_Providers_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Auth_Account_Providers_Order_By>>;
  where?: Maybe<Auth_Account_Providers_Bool_Exp>;
};


export type Subscription_RootAuth_Account_Providers_AggregateArgs = {
  distinct_on?: Maybe<Array<Auth_Account_Providers_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Auth_Account_Providers_Order_By>>;
  where?: Maybe<Auth_Account_Providers_Bool_Exp>;
};


export type Subscription_RootAuth_Account_Providers_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootAuth_Account_RolesArgs = {
  distinct_on?: Maybe<Array<Auth_Account_Roles_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Auth_Account_Roles_Order_By>>;
  where?: Maybe<Auth_Account_Roles_Bool_Exp>;
};


export type Subscription_RootAuth_Account_Roles_AggregateArgs = {
  distinct_on?: Maybe<Array<Auth_Account_Roles_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Auth_Account_Roles_Order_By>>;
  where?: Maybe<Auth_Account_Roles_Bool_Exp>;
};


export type Subscription_RootAuth_Account_Roles_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootAuth_AccountsArgs = {
  distinct_on?: Maybe<Array<Auth_Accounts_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Auth_Accounts_Order_By>>;
  where?: Maybe<Auth_Accounts_Bool_Exp>;
};


export type Subscription_RootAuth_Accounts_AggregateArgs = {
  distinct_on?: Maybe<Array<Auth_Accounts_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Auth_Accounts_Order_By>>;
  where?: Maybe<Auth_Accounts_Bool_Exp>;
};


export type Subscription_RootAuth_Accounts_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootAuth_MigrationsArgs = {
  distinct_on?: Maybe<Array<Auth_Migrations_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Auth_Migrations_Order_By>>;
  where?: Maybe<Auth_Migrations_Bool_Exp>;
};


export type Subscription_RootAuth_Migrations_AggregateArgs = {
  distinct_on?: Maybe<Array<Auth_Migrations_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Auth_Migrations_Order_By>>;
  where?: Maybe<Auth_Migrations_Bool_Exp>;
};


export type Subscription_RootAuth_Migrations_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootAuth_ProvidersArgs = {
  distinct_on?: Maybe<Array<Auth_Providers_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Auth_Providers_Order_By>>;
  where?: Maybe<Auth_Providers_Bool_Exp>;
};


export type Subscription_RootAuth_Providers_AggregateArgs = {
  distinct_on?: Maybe<Array<Auth_Providers_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Auth_Providers_Order_By>>;
  where?: Maybe<Auth_Providers_Bool_Exp>;
};


export type Subscription_RootAuth_Providers_By_PkArgs = {
  provider: Scalars['String'];
};


export type Subscription_RootAuth_Refresh_TokensArgs = {
  distinct_on?: Maybe<Array<Auth_Refresh_Tokens_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Auth_Refresh_Tokens_Order_By>>;
  where?: Maybe<Auth_Refresh_Tokens_Bool_Exp>;
};


export type Subscription_RootAuth_Refresh_Tokens_AggregateArgs = {
  distinct_on?: Maybe<Array<Auth_Refresh_Tokens_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Auth_Refresh_Tokens_Order_By>>;
  where?: Maybe<Auth_Refresh_Tokens_Bool_Exp>;
};


export type Subscription_RootAuth_Refresh_Tokens_By_PkArgs = {
  refresh_token: Scalars['uuid'];
};


export type Subscription_RootAuth_RolesArgs = {
  distinct_on?: Maybe<Array<Auth_Roles_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Auth_Roles_Order_By>>;
  where?: Maybe<Auth_Roles_Bool_Exp>;
};


export type Subscription_RootAuth_Roles_AggregateArgs = {
  distinct_on?: Maybe<Array<Auth_Roles_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Auth_Roles_Order_By>>;
  where?: Maybe<Auth_Roles_Bool_Exp>;
};


export type Subscription_RootAuth_Roles_By_PkArgs = {
  role: Scalars['String'];
};


export type Subscription_RootBudg_DataArgs = {
  distinct_on?: Maybe<Array<Budg_Data_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Budg_Data_Order_By>>;
  where?: Maybe<Budg_Data_Bool_Exp>;
};


export type Subscription_RootBudg_Data_AggregateArgs = {
  distinct_on?: Maybe<Array<Budg_Data_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Budg_Data_Order_By>>;
  where?: Maybe<Budg_Data_Bool_Exp>;
};


export type Subscription_RootBudg_Data_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootBudget_MetadataArgs = {
  distinct_on?: Maybe<Array<Budget_Metadata_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Budget_Metadata_Order_By>>;
  where?: Maybe<Budget_Metadata_Bool_Exp>;
};


export type Subscription_RootBudget_Metadata_AggregateArgs = {
  distinct_on?: Maybe<Array<Budget_Metadata_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Budget_Metadata_Order_By>>;
  where?: Maybe<Budget_Metadata_Bool_Exp>;
};


export type Subscription_RootBudget_Metadata_By_PkArgs = {
  id: Scalars['uuid'];
};


export type Subscription_RootBudget_ParametersArgs = {
  distinct_on?: Maybe<Array<Budget_Parameters_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Budget_Parameters_Order_By>>;
  where?: Maybe<Budget_Parameters_Bool_Exp>;
};


export type Subscription_RootBudget_Parameters_AggregateArgs = {
  distinct_on?: Maybe<Array<Budget_Parameters_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Budget_Parameters_Order_By>>;
  where?: Maybe<Budget_Parameters_Bool_Exp>;
};


export type Subscription_RootBudget_Parameters_DataArgs = {
  distinct_on?: Maybe<Array<Budget_Parameters_Data_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Budget_Parameters_Data_Order_By>>;
  where?: Maybe<Budget_Parameters_Data_Bool_Exp>;
};


export type Subscription_RootBudget_Parameters_Data_AggregateArgs = {
  distinct_on?: Maybe<Array<Budget_Parameters_Data_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Budget_Parameters_Data_Order_By>>;
  where?: Maybe<Budget_Parameters_Data_Bool_Exp>;
};


export type Subscription_RootDividendsArgs = {
  distinct_on?: Maybe<Array<Dividends_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dividends_Order_By>>;
  where?: Maybe<Dividends_Bool_Exp>;
};


export type Subscription_RootDividends_AggregateArgs = {
  distinct_on?: Maybe<Array<Dividends_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Dividends_Order_By>>;
  where?: Maybe<Dividends_Bool_Exp>;
};


export type Subscription_RootHr_Arm_PartnerroleassignmentArgs = {
  distinct_on?: Maybe<Array<Hr_Arm_Partnerroleassignment_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Hr_Arm_Partnerroleassignment_Order_By>>;
  where?: Maybe<Hr_Arm_Partnerroleassignment_Bool_Exp>;
};


export type Subscription_RootHr_Arm_Partnerroleassignment_AggregateArgs = {
  distinct_on?: Maybe<Array<Hr_Arm_Partnerroleassignment_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Hr_Arm_Partnerroleassignment_Order_By>>;
  where?: Maybe<Hr_Arm_Partnerroleassignment_Bool_Exp>;
};


export type Subscription_RootHr_Arm_PartnersArgs = {
  distinct_on?: Maybe<Array<Hr_Arm_Partners_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Hr_Arm_Partners_Order_By>>;
  where?: Maybe<Hr_Arm_Partners_Bool_Exp>;
};


export type Subscription_RootHr_Arm_Partners_AggregateArgs = {
  distinct_on?: Maybe<Array<Hr_Arm_Partners_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Hr_Arm_Partners_Order_By>>;
  where?: Maybe<Hr_Arm_Partners_Bool_Exp>;
};


export type Subscription_RootHr_Arm_TeamleadassignmentArgs = {
  distinct_on?: Maybe<Array<Hr_Arm_Teamleadassignment_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Hr_Arm_Teamleadassignment_Order_By>>;
  where?: Maybe<Hr_Arm_Teamleadassignment_Bool_Exp>;
};


export type Subscription_RootHr_Arm_Teamleadassignment_AggregateArgs = {
  distinct_on?: Maybe<Array<Hr_Arm_Teamleadassignment_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Hr_Arm_Teamleadassignment_Order_By>>;
  where?: Maybe<Hr_Arm_Teamleadassignment_Bool_Exp>;
};


export type Subscription_RootHr_Cdm_CompensationfixedplansArgs = {
  distinct_on?: Maybe<Array<Hr_Cdm_Compensationfixedplans_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Hr_Cdm_Compensationfixedplans_Order_By>>;
  where?: Maybe<Hr_Cdm_Compensationfixedplans_Bool_Exp>;
};


export type Subscription_RootHr_Cdm_Compensationfixedplans_AggregateArgs = {
  distinct_on?: Maybe<Array<Hr_Cdm_Compensationfixedplans_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Hr_Cdm_Compensationfixedplans_Order_By>>;
  where?: Maybe<Hr_Cdm_Compensationfixedplans_Bool_Exp>;
};


export type Subscription_RootHr_Cdm_CompensationlevelsArgs = {
  distinct_on?: Maybe<Array<Hr_Cdm_Compensationlevels_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Hr_Cdm_Compensationlevels_Order_By>>;
  where?: Maybe<Hr_Cdm_Compensationlevels_Bool_Exp>;
};


export type Subscription_RootHr_Cdm_Compensationlevels_AggregateArgs = {
  distinct_on?: Maybe<Array<Hr_Cdm_Compensationlevels_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Hr_Cdm_Compensationlevels_Order_By>>;
  where?: Maybe<Hr_Cdm_Compensationlevels_Bool_Exp>;
};


export type Subscription_RootHr_Cdm_DepartmentsArgs = {
  distinct_on?: Maybe<Array<Hr_Cdm_Departments_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Hr_Cdm_Departments_Order_By>>;
  where?: Maybe<Hr_Cdm_Departments_Bool_Exp>;
};


export type Subscription_RootHr_Cdm_Departments_AggregateArgs = {
  distinct_on?: Maybe<Array<Hr_Cdm_Departments_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Hr_Cdm_Departments_Order_By>>;
  where?: Maybe<Hr_Cdm_Departments_Bool_Exp>;
};


export type Subscription_RootHr_Cdm_EmploymentsArgs = {
  distinct_on?: Maybe<Array<Hr_Cdm_Employments_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Hr_Cdm_Employments_Order_By>>;
  where?: Maybe<Hr_Cdm_Employments_Bool_Exp>;
};


export type Subscription_RootHr_Cdm_Employments_AggregateArgs = {
  distinct_on?: Maybe<Array<Hr_Cdm_Employments_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Hr_Cdm_Employments_Order_By>>;
  where?: Maybe<Hr_Cdm_Employments_Bool_Exp>;
};


export type Subscription_RootHr_Cdm_JobpositionsArgs = {
  distinct_on?: Maybe<Array<Hr_Cdm_Jobpositions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Hr_Cdm_Jobpositions_Order_By>>;
  where?: Maybe<Hr_Cdm_Jobpositions_Bool_Exp>;
};


export type Subscription_RootHr_Cdm_Jobpositions_AggregateArgs = {
  distinct_on?: Maybe<Array<Hr_Cdm_Jobpositions_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Hr_Cdm_Jobpositions_Order_By>>;
  where?: Maybe<Hr_Cdm_Jobpositions_Bool_Exp>;
};


export type Subscription_RootHr_Cdm_JobsArgs = {
  distinct_on?: Maybe<Array<Hr_Cdm_Jobs_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Hr_Cdm_Jobs_Order_By>>;
  where?: Maybe<Hr_Cdm_Jobs_Bool_Exp>;
};


export type Subscription_RootHr_Cdm_Jobs_AggregateArgs = {
  distinct_on?: Maybe<Array<Hr_Cdm_Jobs_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Hr_Cdm_Jobs_Order_By>>;
  where?: Maybe<Hr_Cdm_Jobs_Bool_Exp>;
};


export type Subscription_RootHr_Cdm_JobtypesArgs = {
  distinct_on?: Maybe<Array<Hr_Cdm_Jobtypes_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Hr_Cdm_Jobtypes_Order_By>>;
  where?: Maybe<Hr_Cdm_Jobtypes_Bool_Exp>;
};


export type Subscription_RootHr_Cdm_Jobtypes_AggregateArgs = {
  distinct_on?: Maybe<Array<Hr_Cdm_Jobtypes_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Hr_Cdm_Jobtypes_Order_By>>;
  where?: Maybe<Hr_Cdm_Jobtypes_Bool_Exp>;
};


export type Subscription_RootHr_Cdm_LeaveenrollmentsArgs = {
  distinct_on?: Maybe<Array<Hr_Cdm_Leaveenrollments_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Hr_Cdm_Leaveenrollments_Order_By>>;
  where?: Maybe<Hr_Cdm_Leaveenrollments_Bool_Exp>;
};


export type Subscription_RootHr_Cdm_Leaveenrollments_AggregateArgs = {
  distinct_on?: Maybe<Array<Hr_Cdm_Leaveenrollments_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Hr_Cdm_Leaveenrollments_Order_By>>;
  where?: Maybe<Hr_Cdm_Leaveenrollments_Bool_Exp>;
};


export type Subscription_RootHr_Cdm_LeaveplansArgs = {
  distinct_on?: Maybe<Array<Hr_Cdm_Leaveplans_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Hr_Cdm_Leaveplans_Order_By>>;
  where?: Maybe<Hr_Cdm_Leaveplans_Bool_Exp>;
};


export type Subscription_RootHr_Cdm_Leaveplans_AggregateArgs = {
  distinct_on?: Maybe<Array<Hr_Cdm_Leaveplans_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Hr_Cdm_Leaveplans_Order_By>>;
  where?: Maybe<Hr_Cdm_Leaveplans_Bool_Exp>;
};


export type Subscription_RootHr_Cdm_PositionworkerassignmentmapsArgs = {
  distinct_on?: Maybe<Array<Hr_Cdm_Positionworkerassignmentmaps_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Hr_Cdm_Positionworkerassignmentmaps_Order_By>>;
  where?: Maybe<Hr_Cdm_Positionworkerassignmentmaps_Bool_Exp>;
};


export type Subscription_RootHr_Cdm_Positionworkerassignmentmaps_AggregateArgs = {
  distinct_on?: Maybe<Array<Hr_Cdm_Positionworkerassignmentmaps_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Hr_Cdm_Positionworkerassignmentmaps_Order_By>>;
  where?: Maybe<Hr_Cdm_Positionworkerassignmentmaps_Bool_Exp>;
};


export type Subscription_RootHr_Cdm_WorkerfixedcompensationsArgs = {
  distinct_on?: Maybe<Array<Hr_Cdm_Workerfixedcompensations_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Hr_Cdm_Workerfixedcompensations_Order_By>>;
  where?: Maybe<Hr_Cdm_Workerfixedcompensations_Bool_Exp>;
};


export type Subscription_RootHr_Cdm_Workerfixedcompensations_AggregateArgs = {
  distinct_on?: Maybe<Array<Hr_Cdm_Workerfixedcompensations_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Hr_Cdm_Workerfixedcompensations_Order_By>>;
  where?: Maybe<Hr_Cdm_Workerfixedcompensations_Bool_Exp>;
};


export type Subscription_RootHr_Cdm_WorkersArgs = {
  distinct_on?: Maybe<Array<Hr_Cdm_Workers_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Hr_Cdm_Workers_Order_By>>;
  where?: Maybe<Hr_Cdm_Workers_Bool_Exp>;
};


export type Subscription_RootHr_Cdm_Workers_AggregateArgs = {
  distinct_on?: Maybe<Array<Hr_Cdm_Workers_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Hr_Cdm_Workers_Order_By>>;
  where?: Maybe<Hr_Cdm_Workers_Bool_Exp>;
};


export type Subscription_RootPwa_ProjectsArgs = {
  distinct_on?: Maybe<Array<Pwa_Projects_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Pwa_Projects_Order_By>>;
  where?: Maybe<Pwa_Projects_Bool_Exp>;
};


export type Subscription_RootPwa_Projects_AggregateArgs = {
  distinct_on?: Maybe<Array<Pwa_Projects_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Pwa_Projects_Order_By>>;
  where?: Maybe<Pwa_Projects_Bool_Exp>;
};


export type Subscription_RootUsersArgs = {
  distinct_on?: Maybe<Array<Users_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Users_Order_By>>;
  where?: Maybe<Users_Bool_Exp>;
};


export type Subscription_RootUsers_AggregateArgs = {
  distinct_on?: Maybe<Array<Users_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Users_Order_By>>;
  where?: Maybe<Users_Bool_Exp>;
};


export type Subscription_RootUsers_By_PkArgs = {
  id: Scalars['uuid'];
};


/** Boolean expression to compare columns of type "timestamp". All fields are combined with logical 'AND'. */
export type Timestamp_Comparison_Exp = {
  _eq?: Maybe<Scalars['timestamp']>;
  _gt?: Maybe<Scalars['timestamp']>;
  _gte?: Maybe<Scalars['timestamp']>;
  _in?: Maybe<Array<Scalars['timestamp']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['timestamp']>;
  _lte?: Maybe<Scalars['timestamp']>;
  _neq?: Maybe<Scalars['timestamp']>;
  _nin?: Maybe<Array<Scalars['timestamp']>>;
};


/** Boolean expression to compare columns of type "timestamptz". All fields are combined with logical 'AND'. */
export type Timestamptz_Comparison_Exp = {
  _eq?: Maybe<Scalars['timestamptz']>;
  _gt?: Maybe<Scalars['timestamptz']>;
  _gte?: Maybe<Scalars['timestamptz']>;
  _in?: Maybe<Array<Scalars['timestamptz']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['timestamptz']>;
  _lte?: Maybe<Scalars['timestamptz']>;
  _neq?: Maybe<Scalars['timestamptz']>;
  _nin?: Maybe<Array<Scalars['timestamptz']>>;
};

/** columns and relationships of "users" */
export type Users = {
  /** An object relationship */
  account?: Maybe<Auth_Accounts>;
  avatar_url?: Maybe<Scalars['String']>;
  created_at: Scalars['timestamptz'];
  display_name?: Maybe<Scalars['String']>;
  id: Scalars['uuid'];
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "users" */
export type Users_Aggregate = {
  aggregate?: Maybe<Users_Aggregate_Fields>;
  nodes: Array<Users>;
};

/** aggregate fields of "users" */
export type Users_Aggregate_Fields = {
  count: Scalars['Int'];
  max?: Maybe<Users_Max_Fields>;
  min?: Maybe<Users_Min_Fields>;
};


/** aggregate fields of "users" */
export type Users_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Users_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "users". All fields are combined with a logical 'AND'. */
export type Users_Bool_Exp = {
  _and?: Maybe<Array<Users_Bool_Exp>>;
  _not?: Maybe<Users_Bool_Exp>;
  _or?: Maybe<Array<Users_Bool_Exp>>;
  account?: Maybe<Auth_Accounts_Bool_Exp>;
  avatar_url?: Maybe<String_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  display_name?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "users" */
export enum Users_Constraint {
  /** unique or primary key constraint */
  UsersPkey = 'users_pkey'
}

/** input type for inserting data into table "users" */
export type Users_Insert_Input = {
  account?: Maybe<Auth_Accounts_Obj_Rel_Insert_Input>;
  avatar_url?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  display_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Users_Max_Fields = {
  avatar_url?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  display_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Users_Min_Fields = {
  avatar_url?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  display_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "users" */
export type Users_Mutation_Response = {
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Users>;
};

/** input type for inserting object relation for remote table "users" */
export type Users_Obj_Rel_Insert_Input = {
  data: Users_Insert_Input;
  /** on conflict condition */
  on_conflict?: Maybe<Users_On_Conflict>;
};

/** on conflict condition type for table "users" */
export type Users_On_Conflict = {
  constraint: Users_Constraint;
  update_columns?: Array<Users_Update_Column>;
  where?: Maybe<Users_Bool_Exp>;
};

/** Ordering options when selecting data from "users". */
export type Users_Order_By = {
  account?: Maybe<Auth_Accounts_Order_By>;
  avatar_url?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  display_name?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: users */
export type Users_Pk_Columns_Input = {
  id: Scalars['uuid'];
};

/** select columns of table "users" */
export enum Users_Select_Column {
  /** column name */
  AvatarUrl = 'avatar_url',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DisplayName = 'display_name',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "users" */
export type Users_Set_Input = {
  avatar_url?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  display_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['uuid']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** update columns of table "users" */
export enum Users_Update_Column {
  /** column name */
  AvatarUrl = 'avatar_url',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DisplayName = 'display_name',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at'
}


/** Boolean expression to compare columns of type "uuid". All fields are combined with logical 'AND'. */
export type Uuid_Comparison_Exp = {
  _eq?: Maybe<Scalars['uuid']>;
  _gt?: Maybe<Scalars['uuid']>;
  _gte?: Maybe<Scalars['uuid']>;
  _in?: Maybe<Array<Scalars['uuid']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['uuid']>;
  _lte?: Maybe<Scalars['uuid']>;
  _neq?: Maybe<Scalars['uuid']>;
  _nin?: Maybe<Array<Scalars['uuid']>>;
};

export type SelectPhaseProjectsWithBudgetsQueryVariables = Exact<{ [key: string]: never; }>;


export type SelectPhaseProjectsWithBudgetsQuery = { pwa_projects: Array<Pick<Pwa_Projects, 'name' | 'macro_project' | 'owner_name'>> };

export type SelectPhaseProjectByNameQueryVariables = Exact<{
  name?: Maybe<Scalars['String']>;
}>;


export type SelectPhaseProjectByNameQuery = { pwa_projects: Array<Pick<Pwa_Projects, 'name' | 'macro_project' | 'owner_name'>> };

export type SelectPhaseProjecDividendstByNameQueryVariables = Exact<{
  name?: Maybe<Scalars['String']>;
  _eq?: Maybe<Scalars['smallint']>;
}>;


export type SelectPhaseProjecDividendstByNameQuery = { pwa_projects: Array<Pick<Pwa_Projects, 'name' | 'owner_name'>> };

export type WorkersInfoQueryVariables = Exact<{ [key: string]: never; }>;


export type WorkersInfoQuery = { hr_cdm_workers: Array<(
    Pick<Hr_Cdm_Workers, 'cdm_primaryemailaddress' | 'cdm_primarytelephone' | 'cdm_patronymicukr_custom' | 'cdm_lastnameukr_custom' | 'cdm_firstnameukr_custom' | 'cdm_fullname' | 'cdm_birthdate' | 'cdm_workerid' | 'cdm_type'>
    & { cdm_positionworkerassignmentmaps: Array<{ cdm_jobposition?: Maybe<(
        Pick<Hr_Cdm_Jobpositions, 'cdm_description'>
        & { cdm_department?: Maybe<Pick<Hr_Cdm_Departments, 'cdm_name'>>, cdm_job?: Maybe<{ cdm_jobtype?: Maybe<Pick<Hr_Cdm_Jobtypes, 'cdm_name'>> }> }
      )> }>, arm_teamleadassignments: Array<Pick<Hr_Arm_Teamleadassignment, 'arm_teamleadassignmentid'>> }
  )> };

export type WorkerDetailInfoBasicQueryVariables = Exact<{
  id?: Maybe<Scalars['uuid']>;
}>;


export type WorkerDetailInfoBasicQuery = { hr_cdm_workers: Array<(
    Pick<Hr_Cdm_Workers, 'cdm_fullname' | 'cdm_firstnameukr_custom' | 'cdm_lastnameukr_custom' | 'cdm_primaryemailaddress' | 'cdm_patronymicukr_custom' | 'cdm_primarytelephone' | 'cdm_workerid' | 'cdm_birthdate' | 'cdm_type'>
    & { cdm_positionworkerassignmentmaps: Array<{ cdm_jobposition?: Maybe<Pick<Hr_Cdm_Jobpositions, 'cdm_description' | 'cdm_validfrom'>> }> }
  )> };

export type PartnerInfoQueryVariables = Exact<{ [key: string]: never; }>;


export type PartnerInfoQuery = { hr_cdm_workers: Array<(
    Pick<Hr_Cdm_Workers, 'cdm_primaryemailaddress' | 'cdm_primarytelephone' | 'cdm_patronymicukr_custom' | 'cdm_lastnameukr_custom' | 'cdm_firstnameukr_custom' | 'cdm_fullname' | 'cdm_birthdate' | 'cdm_workerid' | 'cdm_type'>
    & { cdm_positionworkerassignmentmaps: Array<{ cdm_jobposition?: Maybe<(
        Pick<Hr_Cdm_Jobpositions, 'cdm_description'>
        & { cdm_department?: Maybe<Pick<Hr_Cdm_Departments, 'cdm_name'>>, cdm_job?: Maybe<{ cdm_jobtype?: Maybe<Pick<Hr_Cdm_Jobtypes, 'cdm_name'>> }> }
      )> }>, arm_partnerroleasiignment: Array<(
      Pick<Hr_Arm_Partnerroleassignment, 'start_date' | 'end_date'>
      & { role?: Maybe<Pick<Hr_Arm_Partners, 'id' | 'name' | 'name_ru' | 'name_en'>> }
    )> }
  )> };

export type WorkerDetailInfoPersonalQueryVariables = Exact<{
  id?: Maybe<Scalars['uuid']>;
}>;


export type WorkerDetailInfoPersonalQuery = { hr_cdm_workers: Array<(
    Pick<Hr_Cdm_Workers, 'cdm_type' | 'cdm_workerid'>
    & { cdm_workerfixedcompensations: Array<(
      Pick<Hr_Cdm_Workerfixedcompensations, 'cdm_effectivedate' | 'cdm_expirationdate' | 'cdm_payrate'>
      & { cdm_compensationfixedplan?: Maybe<Pick<Hr_Cdm_Compensationfixedplans, 'cdm_name' | 'cdm_description'>>, cdm_compensationlevel?: Maybe<Pick<Hr_Cdm_Compensationlevels, 'cdm_name'>> }
    )>, cdm_leaveenrollments: Array<(
      Pick<Hr_Cdm_Leaveenrollments, 'cdm_startdate' | 'cdm_enddate'>
      & { cdm_leaveplan?: Maybe<Pick<Hr_Cdm_Leaveplans, 'cdm_name'>> }
    )> }
  )> };

export type WorkerPositionsQueryVariables = Exact<{
  id?: Maybe<Scalars['uuid']>;
}>;


export type WorkerPositionsQuery = { hr_cdm_workers: Array<{ cdm_positionworkerassignmentmaps: Array<(
      Pick<Hr_Cdm_Positionworkerassignmentmaps, 'cdm_validfrom' | 'cdm_validto'>
      & { cdm_jobposition?: Maybe<(
        Pick<Hr_Cdm_Jobpositions, 'cdm_description'>
        & { cdm_department?: Maybe<Pick<Hr_Cdm_Departments, 'cdm_name'>> }
      )> }
    )>, cdm_employments: Array<Pick<Hr_Cdm_Employments, 'cdm_employmentenddate' | 'cdm_employmentstartdate'>> }> };

export type WorkerPayHistoryQueryVariables = Exact<{
  id?: Maybe<Scalars['uuid']>;
}>;


export type WorkerPayHistoryQuery = { hr_cdm_workers: Array<(
    Pick<Hr_Cdm_Workers, 'cdm_workerid'>
    & { cdm_workerfixedcompensations: Array<(
      Pick<Hr_Cdm_Workerfixedcompensations, 'cdm_effectivedate' | 'cdm_expirationdate' | 'cdm_payrate' | 'cdm_workerfixedcompensationid'>
      & { cdm_compensationfixedplan?: Maybe<Pick<Hr_Cdm_Compensationfixedplans, 'cdm_name' | 'cdm_description'>>, cdm_compensationlevel?: Maybe<Pick<Hr_Cdm_Compensationlevels, 'cdm_name'>> }
    )> }
  )> };

export type GetJobTypeQueryVariables = Exact<{ [key: string]: never; }>;


export type GetJobTypeQuery = { hr_cdm_jobtypes: Array<Pick<Hr_Cdm_Jobtypes, 'cdm_name'>> };

export type GetTeamleadQueryVariables = Exact<{ [key: string]: never; }>;


export type GetTeamleadQuery = { hr_arm_teamleadassignment: Array<Pick<Hr_Arm_Teamleadassignment, '_cdm_wokerid'>> };

export type Test3QueryVariables = Exact<{ [key: string]: never; }>;


export type Test3Query = { users: Array<Pick<Users, 'display_name' | 'avatar_url' | 'id'>> };

export type TestssQueryVariables = Exact<{ [key: string]: never; }>;


export type TestssQuery = { auth_account_roles: Array<Pick<Auth_Account_Roles, 'id' | 'role'>> };

export type DepartmentsQueryVariables = Exact<{ [key: string]: never; }>;


export type DepartmentsQuery = { hr_cdm_departments: Array<Pick<Hr_Cdm_Departments, 'cdm_name' | 'cdm_description'>> };

export type MyTestQueryVariables = Exact<{
  id?: Maybe<Scalars['uuid']>;
}>;


export type MyTestQuery = { users: Array<Pick<Users, 'id' | 'avatar_url'>> };

export type UserQueryVariables = Exact<{
  id: Scalars['uuid'];
}>;


export type UserQuery = { users_by_pk?: Maybe<(
    Pick<Users, 'display_name' | 'id' | 'avatar_url' | 'created_at'>
    & { account?: Maybe<{ cdm_workers?: Maybe<Pick<Hr_Cdm_Workers, 'cdm_workerid'>> }> }
  )> };

export type PartnerRolesQueryVariables = Exact<{ [key: string]: never; }>;


export type PartnerRolesQuery = { hr_arm_partners: Array<Pick<Hr_Arm_Partners, 'name'>> };

export type DividendsQueryVariables = Exact<{ [key: string]: never; }>;


export type DividendsQuery = { dividends: Array<Pick<Dividends, 'dividends_worker_name' | 'macroproject_name' | 'dividend_proportion' | 'project_name' | 'project_lead_name' | 'project_manager_name' | 'budget_revision' | 'payment_accrued_percent' | 'dividends_planned_cash' | 'dividends_accrued_cash' | 'dividends_paid_cash' | 'leftover_paid_cash' | 'leftover_by_closed_projects_cash'>> };


export const SelectPhaseProjectsWithBudgetsDocument = gql`
    query SelectPhaseProjectsWithBudgets {
  pwa_projects {
    name
    macro_project
    owner_name
  }
}
    `;

/**
 * __useSelectPhaseProjectsWithBudgetsQuery__
 *
 * To run a query within a React component, call `useSelectPhaseProjectsWithBudgetsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSelectPhaseProjectsWithBudgetsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSelectPhaseProjectsWithBudgetsQuery({
 *   variables: {
 *   },
 * });
 */
export function useSelectPhaseProjectsWithBudgetsQuery(baseOptions?: Apollo.QueryHookOptions<SelectPhaseProjectsWithBudgetsQuery, SelectPhaseProjectsWithBudgetsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SelectPhaseProjectsWithBudgetsQuery, SelectPhaseProjectsWithBudgetsQueryVariables>(SelectPhaseProjectsWithBudgetsDocument, options);
      }
export function useSelectPhaseProjectsWithBudgetsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SelectPhaseProjectsWithBudgetsQuery, SelectPhaseProjectsWithBudgetsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SelectPhaseProjectsWithBudgetsQuery, SelectPhaseProjectsWithBudgetsQueryVariables>(SelectPhaseProjectsWithBudgetsDocument, options);
        }
export type SelectPhaseProjectsWithBudgetsQueryHookResult = ReturnType<typeof useSelectPhaseProjectsWithBudgetsQuery>;
export type SelectPhaseProjectsWithBudgetsLazyQueryHookResult = ReturnType<typeof useSelectPhaseProjectsWithBudgetsLazyQuery>;
export type SelectPhaseProjectsWithBudgetsQueryResult = Apollo.QueryResult<SelectPhaseProjectsWithBudgetsQuery, SelectPhaseProjectsWithBudgetsQueryVariables>;
export const SelectPhaseProjectByNameDocument = gql`
    query SelectPhaseProjectByName($name: String) {
  pwa_projects {
    name
    macro_project
    owner_name
  }
}
    `;

/**
 * __useSelectPhaseProjectByNameQuery__
 *
 * To run a query within a React component, call `useSelectPhaseProjectByNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useSelectPhaseProjectByNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSelectPhaseProjectByNameQuery({
 *   variables: {
 *      name: // value for 'name'
 *   },
 * });
 */
export function useSelectPhaseProjectByNameQuery(baseOptions?: Apollo.QueryHookOptions<SelectPhaseProjectByNameQuery, SelectPhaseProjectByNameQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SelectPhaseProjectByNameQuery, SelectPhaseProjectByNameQueryVariables>(SelectPhaseProjectByNameDocument, options);
      }
export function useSelectPhaseProjectByNameLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SelectPhaseProjectByNameQuery, SelectPhaseProjectByNameQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SelectPhaseProjectByNameQuery, SelectPhaseProjectByNameQueryVariables>(SelectPhaseProjectByNameDocument, options);
        }
export type SelectPhaseProjectByNameQueryHookResult = ReturnType<typeof useSelectPhaseProjectByNameQuery>;
export type SelectPhaseProjectByNameLazyQueryHookResult = ReturnType<typeof useSelectPhaseProjectByNameLazyQuery>;
export type SelectPhaseProjectByNameQueryResult = Apollo.QueryResult<SelectPhaseProjectByNameQuery, SelectPhaseProjectByNameQueryVariables>;
export const SelectPhaseProjecDividendstByNameDocument = gql`
    query SelectPhaseProjecDividendstByName($name: String, $_eq: smallint = true) {
  pwa_projects {
    name
    owner_name
  }
}
    `;

/**
 * __useSelectPhaseProjecDividendstByNameQuery__
 *
 * To run a query within a React component, call `useSelectPhaseProjecDividendstByNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useSelectPhaseProjecDividendstByNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSelectPhaseProjecDividendstByNameQuery({
 *   variables: {
 *      name: // value for 'name'
 *      _eq: // value for '_eq'
 *   },
 * });
 */
export function useSelectPhaseProjecDividendstByNameQuery(baseOptions?: Apollo.QueryHookOptions<SelectPhaseProjecDividendstByNameQuery, SelectPhaseProjecDividendstByNameQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SelectPhaseProjecDividendstByNameQuery, SelectPhaseProjecDividendstByNameQueryVariables>(SelectPhaseProjecDividendstByNameDocument, options);
      }
export function useSelectPhaseProjecDividendstByNameLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SelectPhaseProjecDividendstByNameQuery, SelectPhaseProjecDividendstByNameQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SelectPhaseProjecDividendstByNameQuery, SelectPhaseProjecDividendstByNameQueryVariables>(SelectPhaseProjecDividendstByNameDocument, options);
        }
export type SelectPhaseProjecDividendstByNameQueryHookResult = ReturnType<typeof useSelectPhaseProjecDividendstByNameQuery>;
export type SelectPhaseProjecDividendstByNameLazyQueryHookResult = ReturnType<typeof useSelectPhaseProjecDividendstByNameLazyQuery>;
export type SelectPhaseProjecDividendstByNameQueryResult = Apollo.QueryResult<SelectPhaseProjecDividendstByNameQuery, SelectPhaseProjecDividendstByNameQueryVariables>;
export const WorkersInfoDocument = gql`
    query WorkersInfo {
  hr_cdm_workers(
    where: {cdm_positionworkerassignmentmaps: {cdm_validto: {_gt: "now()"}}}
    order_by: {cdm_fullname: asc}
  ) {
    cdm_positionworkerassignmentmaps(where: {cdm_validto: {_gt: "now()"}}) {
      cdm_jobposition {
        cdm_department {
          cdm_name
        }
        cdm_description
        cdm_job {
          cdm_jobtype {
            cdm_name
          }
        }
      }
    }
    cdm_primaryemailaddress
    cdm_primarytelephone
    cdm_patronymicukr_custom
    cdm_lastnameukr_custom
    cdm_firstnameukr_custom
    cdm_fullname
    cdm_birthdate
    cdm_workerid
    cdm_type
    arm_teamleadassignments(where: {valid_to: {_gt: "now()"}}) {
      arm_teamleadassignmentid
    }
  }
}
    `;

/**
 * __useWorkersInfoQuery__
 *
 * To run a query within a React component, call `useWorkersInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useWorkersInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWorkersInfoQuery({
 *   variables: {
 *   },
 * });
 */
export function useWorkersInfoQuery(baseOptions?: Apollo.QueryHookOptions<WorkersInfoQuery, WorkersInfoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<WorkersInfoQuery, WorkersInfoQueryVariables>(WorkersInfoDocument, options);
      }
export function useWorkersInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<WorkersInfoQuery, WorkersInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<WorkersInfoQuery, WorkersInfoQueryVariables>(WorkersInfoDocument, options);
        }
export type WorkersInfoQueryHookResult = ReturnType<typeof useWorkersInfoQuery>;
export type WorkersInfoLazyQueryHookResult = ReturnType<typeof useWorkersInfoLazyQuery>;
export type WorkersInfoQueryResult = Apollo.QueryResult<WorkersInfoQuery, WorkersInfoQueryVariables>;
export const WorkerDetailInfoBasicDocument = gql`
    query WorkerDetailInfoBasic($id: uuid) {
  hr_cdm_workers(where: {cdm_workerid: {_eq: $id}}) {
    cdm_fullname
    cdm_firstnameukr_custom
    cdm_lastnameukr_custom
    cdm_primaryemailaddress
    cdm_patronymicukr_custom
    cdm_primarytelephone
    cdm_workerid
    cdm_birthdate
    cdm_positionworkerassignmentmaps(where: {cdm_validto: {_gt: "now()"}}) {
      cdm_jobposition {
        cdm_description
        cdm_validfrom
      }
    }
    cdm_type
  }
}
    `;

/**
 * __useWorkerDetailInfoBasicQuery__
 *
 * To run a query within a React component, call `useWorkerDetailInfoBasicQuery` and pass it any options that fit your needs.
 * When your component renders, `useWorkerDetailInfoBasicQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWorkerDetailInfoBasicQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useWorkerDetailInfoBasicQuery(baseOptions?: Apollo.QueryHookOptions<WorkerDetailInfoBasicQuery, WorkerDetailInfoBasicQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<WorkerDetailInfoBasicQuery, WorkerDetailInfoBasicQueryVariables>(WorkerDetailInfoBasicDocument, options);
      }
export function useWorkerDetailInfoBasicLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<WorkerDetailInfoBasicQuery, WorkerDetailInfoBasicQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<WorkerDetailInfoBasicQuery, WorkerDetailInfoBasicQueryVariables>(WorkerDetailInfoBasicDocument, options);
        }
export type WorkerDetailInfoBasicQueryHookResult = ReturnType<typeof useWorkerDetailInfoBasicQuery>;
export type WorkerDetailInfoBasicLazyQueryHookResult = ReturnType<typeof useWorkerDetailInfoBasicLazyQuery>;
export type WorkerDetailInfoBasicQueryResult = Apollo.QueryResult<WorkerDetailInfoBasicQuery, WorkerDetailInfoBasicQueryVariables>;
export const PartnerInfoDocument = gql`
    query PartnerInfo {
  hr_cdm_workers(
    where: {arm_partnerroleasiignment: {id: {_is_null: false}}}
    order_by: {cdm_fullname: asc}
  ) {
    cdm_positionworkerassignmentmaps(where: {cdm_validto: {_gt: "now()"}}) {
      cdm_jobposition {
        cdm_department {
          cdm_name
        }
        cdm_description
        cdm_job {
          cdm_jobtype {
            cdm_name
          }
        }
      }
    }
    cdm_primaryemailaddress
    cdm_primarytelephone
    cdm_patronymicukr_custom
    cdm_lastnameukr_custom
    cdm_firstnameukr_custom
    cdm_fullname
    cdm_birthdate
    cdm_workerid
    cdm_type
    arm_partnerroleasiignment {
      start_date
      end_date
      role {
        id
        name
        name_ru
        name_en
      }
    }
  }
}
    `;

/**
 * __usePartnerInfoQuery__
 *
 * To run a query within a React component, call `usePartnerInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `usePartnerInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePartnerInfoQuery({
 *   variables: {
 *   },
 * });
 */
export function usePartnerInfoQuery(baseOptions?: Apollo.QueryHookOptions<PartnerInfoQuery, PartnerInfoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PartnerInfoQuery, PartnerInfoQueryVariables>(PartnerInfoDocument, options);
      }
export function usePartnerInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PartnerInfoQuery, PartnerInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PartnerInfoQuery, PartnerInfoQueryVariables>(PartnerInfoDocument, options);
        }
export type PartnerInfoQueryHookResult = ReturnType<typeof usePartnerInfoQuery>;
export type PartnerInfoLazyQueryHookResult = ReturnType<typeof usePartnerInfoLazyQuery>;
export type PartnerInfoQueryResult = Apollo.QueryResult<PartnerInfoQuery, PartnerInfoQueryVariables>;
export const WorkerDetailInfoPersonalDocument = gql`
    query WorkerDetailInfoPersonal($id: uuid) {
  hr_cdm_workers(where: {cdm_workerid: {_eq: $id}}) {
    cdm_workerfixedcompensations(where: {cdm_expirationdate: {_gt: "now()"}}) {
      cdm_effectivedate
      cdm_expirationdate
      cdm_payrate
      cdm_compensationfixedplan {
        cdm_name
        cdm_description
      }
      cdm_compensationlevel {
        cdm_name
      }
    }
    cdm_leaveenrollments(where: {cdm_enddate: {_gt: "now()"}}) {
      cdm_startdate
      cdm_enddate
      cdm_leaveplan {
        cdm_name
      }
    }
    cdm_type
    cdm_workerid
  }
}
    `;

/**
 * __useWorkerDetailInfoPersonalQuery__
 *
 * To run a query within a React component, call `useWorkerDetailInfoPersonalQuery` and pass it any options that fit your needs.
 * When your component renders, `useWorkerDetailInfoPersonalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWorkerDetailInfoPersonalQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useWorkerDetailInfoPersonalQuery(baseOptions?: Apollo.QueryHookOptions<WorkerDetailInfoPersonalQuery, WorkerDetailInfoPersonalQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<WorkerDetailInfoPersonalQuery, WorkerDetailInfoPersonalQueryVariables>(WorkerDetailInfoPersonalDocument, options);
      }
export function useWorkerDetailInfoPersonalLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<WorkerDetailInfoPersonalQuery, WorkerDetailInfoPersonalQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<WorkerDetailInfoPersonalQuery, WorkerDetailInfoPersonalQueryVariables>(WorkerDetailInfoPersonalDocument, options);
        }
export type WorkerDetailInfoPersonalQueryHookResult = ReturnType<typeof useWorkerDetailInfoPersonalQuery>;
export type WorkerDetailInfoPersonalLazyQueryHookResult = ReturnType<typeof useWorkerDetailInfoPersonalLazyQuery>;
export type WorkerDetailInfoPersonalQueryResult = Apollo.QueryResult<WorkerDetailInfoPersonalQuery, WorkerDetailInfoPersonalQueryVariables>;
export const WorkerPositionsDocument = gql`
    query WorkerPositions($id: uuid) {
  hr_cdm_workers(where: {cdm_workerid: {_eq: $id}}) {
    cdm_positionworkerassignmentmaps(order_by: {cdm_validfrom: desc}) {
      cdm_validfrom
      cdm_validto
      cdm_jobposition {
        cdm_department {
          cdm_name
        }
        cdm_description
      }
    }
    cdm_employments(where: {cdm_employmentenddate: {_gt: "now()"}}) {
      cdm_employmentenddate
      cdm_employmentstartdate
    }
  }
}
    `;

/**
 * __useWorkerPositionsQuery__
 *
 * To run a query within a React component, call `useWorkerPositionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useWorkerPositionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWorkerPositionsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useWorkerPositionsQuery(baseOptions?: Apollo.QueryHookOptions<WorkerPositionsQuery, WorkerPositionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<WorkerPositionsQuery, WorkerPositionsQueryVariables>(WorkerPositionsDocument, options);
      }
export function useWorkerPositionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<WorkerPositionsQuery, WorkerPositionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<WorkerPositionsQuery, WorkerPositionsQueryVariables>(WorkerPositionsDocument, options);
        }
export type WorkerPositionsQueryHookResult = ReturnType<typeof useWorkerPositionsQuery>;
export type WorkerPositionsLazyQueryHookResult = ReturnType<typeof useWorkerPositionsLazyQuery>;
export type WorkerPositionsQueryResult = Apollo.QueryResult<WorkerPositionsQuery, WorkerPositionsQueryVariables>;
export const WorkerPayHistoryDocument = gql`
    query WorkerPayHistory($id: uuid) {
  hr_cdm_workers(where: {cdm_workerid: {_eq: $id}}) {
    cdm_workerid
    cdm_workerfixedcompensations(order_by: {cdm_effectivedate: asc}) {
      cdm_effectivedate
      cdm_expirationdate
      cdm_payrate
      cdm_compensationfixedplan {
        cdm_name
        cdm_description
      }
      cdm_compensationlevel {
        cdm_name
      }
      cdm_workerfixedcompensationid
    }
  }
}
    `;

/**
 * __useWorkerPayHistoryQuery__
 *
 * To run a query within a React component, call `useWorkerPayHistoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useWorkerPayHistoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWorkerPayHistoryQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useWorkerPayHistoryQuery(baseOptions?: Apollo.QueryHookOptions<WorkerPayHistoryQuery, WorkerPayHistoryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<WorkerPayHistoryQuery, WorkerPayHistoryQueryVariables>(WorkerPayHistoryDocument, options);
      }
export function useWorkerPayHistoryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<WorkerPayHistoryQuery, WorkerPayHistoryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<WorkerPayHistoryQuery, WorkerPayHistoryQueryVariables>(WorkerPayHistoryDocument, options);
        }
export type WorkerPayHistoryQueryHookResult = ReturnType<typeof useWorkerPayHistoryQuery>;
export type WorkerPayHistoryLazyQueryHookResult = ReturnType<typeof useWorkerPayHistoryLazyQuery>;
export type WorkerPayHistoryQueryResult = Apollo.QueryResult<WorkerPayHistoryQuery, WorkerPayHistoryQueryVariables>;
export const GetJobTypeDocument = gql`
    query getJobType {
  hr_cdm_jobtypes(
    where: {cdm_name: {_neq: "Clerical"}, _or: {cdm_name: {_neq: "Directors"}, _or: {cdm_name: {_neq: "Executives"}, _or: {cdm_name: {_neq: "Hourly"}, _or: {cdm_name: {_neq: "Managers"}, _or: {cdm_name: {_neq: "Salary"}}}}}}}
  ) {
    cdm_name
  }
}
    `;

/**
 * __useGetJobTypeQuery__
 *
 * To run a query within a React component, call `useGetJobTypeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetJobTypeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetJobTypeQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetJobTypeQuery(baseOptions?: Apollo.QueryHookOptions<GetJobTypeQuery, GetJobTypeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetJobTypeQuery, GetJobTypeQueryVariables>(GetJobTypeDocument, options);
      }
export function useGetJobTypeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetJobTypeQuery, GetJobTypeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetJobTypeQuery, GetJobTypeQueryVariables>(GetJobTypeDocument, options);
        }
export type GetJobTypeQueryHookResult = ReturnType<typeof useGetJobTypeQuery>;
export type GetJobTypeLazyQueryHookResult = ReturnType<typeof useGetJobTypeLazyQuery>;
export type GetJobTypeQueryResult = Apollo.QueryResult<GetJobTypeQuery, GetJobTypeQueryVariables>;
export const GetTeamleadDocument = gql`
    query getTeamlead {
  hr_arm_teamleadassignment(where: {valid_to: {_gt: "now()"}}) {
    _cdm_wokerid
  }
}
    `;

/**
 * __useGetTeamleadQuery__
 *
 * To run a query within a React component, call `useGetTeamleadQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTeamleadQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTeamleadQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetTeamleadQuery(baseOptions?: Apollo.QueryHookOptions<GetTeamleadQuery, GetTeamleadQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTeamleadQuery, GetTeamleadQueryVariables>(GetTeamleadDocument, options);
      }
export function useGetTeamleadLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTeamleadQuery, GetTeamleadQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTeamleadQuery, GetTeamleadQueryVariables>(GetTeamleadDocument, options);
        }
export type GetTeamleadQueryHookResult = ReturnType<typeof useGetTeamleadQuery>;
export type GetTeamleadLazyQueryHookResult = ReturnType<typeof useGetTeamleadLazyQuery>;
export type GetTeamleadQueryResult = Apollo.QueryResult<GetTeamleadQuery, GetTeamleadQueryVariables>;
export const Test3Document = gql`
    query test3 {
  users {
    display_name
    avatar_url
    id
  }
}
    `;

/**
 * __useTest3Query__
 *
 * To run a query within a React component, call `useTest3Query` and pass it any options that fit your needs.
 * When your component renders, `useTest3Query` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTest3Query({
 *   variables: {
 *   },
 * });
 */
export function useTest3Query(baseOptions?: Apollo.QueryHookOptions<Test3Query, Test3QueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Test3Query, Test3QueryVariables>(Test3Document, options);
      }
export function useTest3LazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Test3Query, Test3QueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Test3Query, Test3QueryVariables>(Test3Document, options);
        }
export type Test3QueryHookResult = ReturnType<typeof useTest3Query>;
export type Test3LazyQueryHookResult = ReturnType<typeof useTest3LazyQuery>;
export type Test3QueryResult = Apollo.QueryResult<Test3Query, Test3QueryVariables>;
export const TestssDocument = gql`
    query testss {
  auth_account_roles(limit: 10) {
    id
    role
  }
}
    `;

/**
 * __useTestssQuery__
 *
 * To run a query within a React component, call `useTestssQuery` and pass it any options that fit your needs.
 * When your component renders, `useTestssQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTestssQuery({
 *   variables: {
 *   },
 * });
 */
export function useTestssQuery(baseOptions?: Apollo.QueryHookOptions<TestssQuery, TestssQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TestssQuery, TestssQueryVariables>(TestssDocument, options);
      }
export function useTestssLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TestssQuery, TestssQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TestssQuery, TestssQueryVariables>(TestssDocument, options);
        }
export type TestssQueryHookResult = ReturnType<typeof useTestssQuery>;
export type TestssLazyQueryHookResult = ReturnType<typeof useTestssLazyQuery>;
export type TestssQueryResult = Apollo.QueryResult<TestssQuery, TestssQueryVariables>;
export const DepartmentsDocument = gql`
    query Departments {
  hr_cdm_departments(
    order_by: {cdm_name: asc}
    where: {cdm_jobpositions: {cdm_positionworkerassignmentmaps: {_and: {cdm_validto: {_gt: "now()"}}, cdm_positionworkerassignmentmapid: {_is_null: false}}}}
  ) {
    cdm_name
    cdm_description
  }
}
    `;

/**
 * __useDepartmentsQuery__
 *
 * To run a query within a React component, call `useDepartmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDepartmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDepartmentsQuery({
 *   variables: {
 *   },
 * });
 */
export function useDepartmentsQuery(baseOptions?: Apollo.QueryHookOptions<DepartmentsQuery, DepartmentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DepartmentsQuery, DepartmentsQueryVariables>(DepartmentsDocument, options);
      }
export function useDepartmentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DepartmentsQuery, DepartmentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DepartmentsQuery, DepartmentsQueryVariables>(DepartmentsDocument, options);
        }
export type DepartmentsQueryHookResult = ReturnType<typeof useDepartmentsQuery>;
export type DepartmentsLazyQueryHookResult = ReturnType<typeof useDepartmentsLazyQuery>;
export type DepartmentsQueryResult = Apollo.QueryResult<DepartmentsQuery, DepartmentsQueryVariables>;
export const MyTestDocument = gql`
    query MyTest($id: uuid) {
  users(where: {account: {cdm_workers: {cdm_workerid: {_eq: $id}}}}) {
    id
    avatar_url
  }
}
    `;

/**
 * __useMyTestQuery__
 *
 * To run a query within a React component, call `useMyTestQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyTestQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyTestQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useMyTestQuery(baseOptions?: Apollo.QueryHookOptions<MyTestQuery, MyTestQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MyTestQuery, MyTestQueryVariables>(MyTestDocument, options);
      }
export function useMyTestLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MyTestQuery, MyTestQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MyTestQuery, MyTestQueryVariables>(MyTestDocument, options);
        }
export type MyTestQueryHookResult = ReturnType<typeof useMyTestQuery>;
export type MyTestLazyQueryHookResult = ReturnType<typeof useMyTestLazyQuery>;
export type MyTestQueryResult = Apollo.QueryResult<MyTestQuery, MyTestQueryVariables>;
export const UserDocument = gql`
    query User($id: uuid!) {
  users_by_pk(id: $id) {
    display_name
    id
    avatar_url
    created_at
    account {
      cdm_workers {
        cdm_workerid
      }
    }
  }
}
    `;

/**
 * __useUserQuery__
 *
 * To run a query within a React component, call `useUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUserQuery(baseOptions: Apollo.QueryHookOptions<UserQuery, UserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserQuery, UserQueryVariables>(UserDocument, options);
      }
export function useUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserQuery, UserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserQuery, UserQueryVariables>(UserDocument, options);
        }
export type UserQueryHookResult = ReturnType<typeof useUserQuery>;
export type UserLazyQueryHookResult = ReturnType<typeof useUserLazyQuery>;
export type UserQueryResult = Apollo.QueryResult<UserQuery, UserQueryVariables>;
export const PartnerRolesDocument = gql`
    query PartnerRoles {
  hr_arm_partners(where: {partners: {worker_id: {_is_null: false}}}) {
    name
  }
}
    `;

/**
 * __usePartnerRolesQuery__
 *
 * To run a query within a React component, call `usePartnerRolesQuery` and pass it any options that fit your needs.
 * When your component renders, `usePartnerRolesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePartnerRolesQuery({
 *   variables: {
 *   },
 * });
 */
export function usePartnerRolesQuery(baseOptions?: Apollo.QueryHookOptions<PartnerRolesQuery, PartnerRolesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PartnerRolesQuery, PartnerRolesQueryVariables>(PartnerRolesDocument, options);
      }
export function usePartnerRolesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PartnerRolesQuery, PartnerRolesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PartnerRolesQuery, PartnerRolesQueryVariables>(PartnerRolesDocument, options);
        }
export type PartnerRolesQueryHookResult = ReturnType<typeof usePartnerRolesQuery>;
export type PartnerRolesLazyQueryHookResult = ReturnType<typeof usePartnerRolesLazyQuery>;
export type PartnerRolesQueryResult = Apollo.QueryResult<PartnerRolesQuery, PartnerRolesQueryVariables>;
export const DividendsDocument = gql`
    query Dividends {
  dividends(
    order_by: {dividends_worker_name: asc, macroproject_name: asc, project_name: asc, project_lead_name: asc, project_manager_name: asc}
  ) {
    dividends_worker_name
    macroproject_name
    dividend_proportion
    project_name
    project_lead_name
    project_manager_name
    budget_revision
    payment_accrued_percent
    dividends_planned_cash
    dividends_accrued_cash
    dividends_paid_cash
    leftover_paid_cash
    leftover_by_closed_projects_cash
  }
}
    `;

/**
 * __useDividendsQuery__
 *
 * To run a query within a React component, call `useDividendsQuery` and pass it any options that fit your needs.
 * When your component renders, `useDividendsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDividendsQuery({
 *   variables: {
 *   },
 * });
 */
export function useDividendsQuery(baseOptions?: Apollo.QueryHookOptions<DividendsQuery, DividendsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DividendsQuery, DividendsQueryVariables>(DividendsDocument, options);
      }
export function useDividendsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DividendsQuery, DividendsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DividendsQuery, DividendsQueryVariables>(DividendsDocument, options);
        }
export type DividendsQueryHookResult = ReturnType<typeof useDividendsQuery>;
export type DividendsLazyQueryHookResult = ReturnType<typeof useDividendsLazyQuery>;
export type DividendsQueryResult = Apollo.QueryResult<DividendsQuery, DividendsQueryVariables>;