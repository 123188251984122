  import { useHistory, useParams } from "react-router-dom";
  

  
  const OfficeMap = (props:any) => {
    const { path, block, part } = useParams<{
      path?: string;
      block?: string;
      part?: string;
    }>();

    return (
      <div>
        <iframe src="https://dev.archimatika.com/embed?stream=a3a36829cc&commit=bbb44487ae" width="600" height="400"></iframe>
 <iframe width="1140" height="541.25" src="https://app.powerbi.com/reportEmbed?reportId=7e974162-e30c-40be-9ad8-0f89adaadffc&autoAuth=true&ctid=f5c9a7ab-a2f6-4390-9843-a399f97eb894&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly93YWJpLXdlc3QtZXVyb3BlLXJlZGlyZWN0LmFuYWx5c2lzLndpbmRvd3MubmV0LyJ9" frameBorder="0" ></iframe>
      </div>
    );
  };
  
  export default OfficeMap;
  