import {
  useGetJobTypeQuery,
  useGetTeamleadQuery,
  usePartnerInfoQuery,
  PartnerInfoQuery,
  usePartnerRolesQuery,
} from "../../../generated/graphql";
import { useEffect, useState } from "react";
import styled from "styled-components";
import { useHistory, useParams } from "react-router-dom";
import React from "react";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { StyledToggleButton } from "components/styled";
import PartnersModal from "./Partners_modal";
import RTableDiv from "./ReactTable/ReactTable_Partners";

const ResetButton = styled.button`
  border: 0px solid;
  background-color: #c0c0c0;
  border-color: #d3d3d3;
  margin: 5px 10px 5px 0px;
  padding: 3px 7px;
  border-radius: 3px;
  font-size: small;
  font-weight: normal;
  line-height: normal;
  font-family: Inter;
  color: #404040;
  border-radius: 3px;
  &: hover {
    background-color: #fb888a;
  }
  cursor: pointer;
`;

const Search = styled.input`
  margin: 5px 10px 5px 0px;
  padding: 1px 7px;
  border: 1px solid #d3d3d3;
  border-radius: 3px;
`;

type EmplType = {
  typename?: string;
  workers?: PartnerInfoQuery["hr_cdm_workers"];
};

type Role = {
  name?: string;
  type?: EmplType[];
};

const Partners = (props: any) => {
  const { path, block, part } = useParams<{
    path?: string;
    block?: string;
    part?: string;
  }>();

  const history = useHistory();

  const { loading, data: dataWorkes } = usePartnerInfoQuery();
  const { data: datajobtype } = useGetJobTypeQuery();
  const { data: datalead } = useGetTeamleadQuery();
  const { data: dataRole } = usePartnerRolesQuery();

  const wn = dataWorkes?.hr_cdm_workers as PartnerInfoQuery["hr_cdm_workers"];

  var JobTypes: Array<string> = [];
  datajobtype?.hr_cdm_jobtypes.forEach((type) => JobTypes.push(type.cdm_name!));

  var Teamleads: Array<string> = [];
  datalead?.hr_arm_teamleadassignment.forEach((lead) =>
    Teamleads.push(lead._cdm_wokerid.toLowerCase())
  );
  const [search, setSearch] = useState("");
  const [searchdep, setSearchDep] = useState("");
  const [searchtype, setSearchType] = React.useState<Array<string>>([]);

  const [tl, setTL] = useState(false);
  const [empl, setEmpl] = useState(false);
  const [contr, setContr] = useState(false);
  const [notempl, setNotEmpl] = useState(false);
  const [roles, setRoles] = useState<Role[] | undefined>([]);
  const [filteredRoles, setFilteredRoles] = useState<Role[] | undefined>([]);
  const [isTable, setIstable] = React.useState(true);

  const handleFormat = (
    event: React.MouseEvent<HTMLElement>,
    newType: string[]
  ) => {
    setSearchType(newType);
  };

  useEffect(() => {
    let role: Role[] = [];
    let etype: EmplType[] = [];

    if (dataRole) {
      for (let i = 0; i < dataRole.hr_arm_partners.length; i++) {
        const r = { ...dataRole.hr_arm_partners[i] };

        const wa = dataWorkes?.hr_cdm_workers.filter(
          (t) => t.arm_partnerroleasiignment[0].role?.name === r.name
        );
        let waa: any;
        let wbb: any;
        let wcc: any;

        if (wa) {
          waa = wa.filter((t) => t.cdm_type === "754400001");
          wbb = wa.filter(
            (t) =>
              t.cdm_type === "754400000" &&
              t.cdm_positionworkerassignmentmaps[0]?.cdm_jobposition
                ?.cdm_description
          );
          wcc = wa.filter(
            (t) =>
              t.cdm_positionworkerassignmentmaps[0]?.cdm_jobposition
                ?.cdm_description === undefined
          );
        }

        // console.log(waa, i ,"waa")
        // console.log(wbb, i ,"wbb")
        // console.log(wcc, i ,"wcc")

        if (wbb?.length > 0) {
          etype.push({ workers: wbb, typename: "Employee" });
        }

        if (waa?.length > 0) {
          etype.push({ workers: waa, typename: "Contractor" });
        }

        if (wcc?.length > 0) {
          etype.push({ workers: wcc, typename: "Not emplyed" });
        }

        //console.log(i, etype, 'etype')

        if (etype.length > 0) {
          role.push({
            name: r.name?.toString(),
            type: etype,
          });
        } else {
          role = [];
        }
        etype = [];
      }
      setRoles(role);
    }
  }, [dataRole, dataWorkes]);

  useEffect(() => {
    if (roles) {
      const rol: any = roles?.map((t) => {
        const typ: any = t?.type?.map((d) => {
          const workers = d.workers?.filter(
            (worker) =>
              (worker?.cdm_fullname
                ?.toLowerCase()
                .includes(search.toLowerCase()) ||
                worker?.cdm_lastnameukr_custom
                  ?.toLowerCase()
                  .concat(" ", worker?.cdm_firstnameukr_custom!.toLowerCase())
                  .includes(search.toLowerCase())) &&
              (worker?.cdm_positionworkerassignmentmaps[0]?.cdm_jobposition?.cdm_department?.cdm_name
                ?.toLocaleLowerCase()
                .includes(searchdep.toLocaleLowerCase()) ||
                worker?.cdm_positionworkerassignmentmaps[0]?.cdm_jobposition
                  ?.cdm_department?.cdm_name === undefined) &&
              (searchtype.length !== 0
                ? searchtype.includes(
                    worker?.cdm_positionworkerassignmentmaps[0]?.cdm_jobposition
                      ?.cdm_job?.cdm_jobtype?.cdm_name!
                  ) ||
                  worker?.cdm_positionworkerassignmentmaps[0]?.cdm_jobposition
                    ?.cdm_job?.cdm_jobtype?.cdm_name === undefined
                : JobTypes.includes(
                    worker?.cdm_positionworkerassignmentmaps[0]?.cdm_jobposition
                      ?.cdm_job?.cdm_jobtype?.cdm_name!
                  ) ||
                  worker?.cdm_positionworkerassignmentmaps[0]?.cdm_jobposition
                    ?.cdm_job?.cdm_jobtype?.cdm_name === undefined) &&
              (tl === true ? Teamleads.includes(worker.cdm_workerid) : {}) &&
              (empl === true
                ? worker.cdm_type?.toString().includes("754400000") &&
                  worker?.cdm_positionworkerassignmentmaps[0]?.cdm_jobposition
                    ?.cdm_job?.cdm_jobtype?.cdm_name
                : {}) &&
              (contr === true
                ? worker.cdm_type?.toString().includes("754400001")
                : {}) &&
              (notempl === true
                ? worker?.cdm_positionworkerassignmentmaps[0]?.cdm_jobposition
                    ?.cdm_job?.cdm_jobtype?.cdm_name === undefined
                : {})
          );

          return (
            workers && workers?.length > 0 && { typename: d.typename, workers }
          );
        });
        return { name: t.name, type: typ };
      });
      setFilteredRoles(rol);
    }
  }, [search, searchdep, searchtype, tl, wn, empl, contr, roles, notempl]);

  let changesArr:any =[]
  const [arr, setArr] = useState([{}])

  const setReset = () => {
    setSearch("");
    setSearchDep("");
    setTL(false);
    setSearchType([]);
    setEmpl(false);
    setContr(false);
  };

  if (loading) return <></>;
  return (
    <div>
      <Search
        type="text"
        placeholder="Filter by Name"
        value={search}
        onChange={(e) => setSearch(e.target.value)}
      />

      <Search
        type="text"
        placeholder="Filter by Dep"
        value={searchdep}
        onChange={(e) => setSearchDep(e.target.value)}
      />
      <br />

      {JobTypes.map((type) => (
        <ToggleButtonGroup value={searchtype} onChange={handleFormat}>
          <StyledToggleButton id="JC" value={type}>
            {type}
          </StyledToggleButton>
        </ToggleButtonGroup>
      ))}
      <br />

      <StyledToggleButton id="TL" onChange={() => setTL(!tl)} selected={tl}>
        Teamlead
      </StyledToggleButton>
      <br />

      <StyledToggleButton
        id="EM"
        onChange={() => setEmpl(!empl)}
        selected={empl}
      >
        Employee
      </StyledToggleButton>
      <StyledToggleButton
        id="CT"
        onChange={() => setContr(!contr)}
        selected={contr}
      >
        Contractor
      </StyledToggleButton>
      <StyledToggleButton
        id="Nn"
        onChange={() => setNotEmpl(!notempl)}
        selected={notempl}
      >
        Not employed
      </StyledToggleButton>
      <br />

      <ResetButton onClick={setReset}>Reset filters</ResetButton>
      <PartnersModal arr = {arr}/>

      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div>
          {filteredRoles?.map((rol) => (
            <div>
              <p>
                {rol?.type &&
                rol?.type?.length === 1 &&
                rol?.type[0] === false ? (
                  <></>
                ) : (
                  <h2>{rol?.name + "s"} </h2>
                )}
              </p>
              <p>
                {rol?.type?.map((ty) => (
                  <>
                    {ty?.workers && ty?.workers?.length > 0 && (
                      <>
                        {rol?.type && rol.type.length > 1 && (
                          <h3> {ty?.typename} </h3>
                        )}
                      </>
                    )}
                    {ty.workers && (
                      <RTableDiv worker={ty.workers} Teamleads={Teamleads} setArr={setArr} changesArr={changesArr} />
                    )}
                  </>
                ))}
              </p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Partners;
