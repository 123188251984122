import { useWorkerPayHistoryQuery } from "../../generated/graphql";
import { useParams } from "react-router-dom";
import { H4, Paper, Text } from "components/styled";
import { ResponsiveLine, Serie } from "@nivo/line";

const WorkerPayHistory = () => {
  const { block } = useParams<{
    path?: string;
    block?: string;
    part?: string;
  }>();

  const { loading, data } = useWorkerPayHistoryQuery({
    variables: { id: block },
    context: {
      headers: {
        "x-hasura-role": "me",
      },
    },
  });

  const options: any = { dateStyle: "short" };

  const comp = data?.hr_cdm_workers[0].cdm_workerfixedcompensations;

  var arr: Array<object> = [];
  comp?.forEach((t) =>
    arr.push({
      x: new Date(t.cdm_effectivedate).toLocaleString("ru", options),
      y: t.cdm_payrate,
    })
  );

  const newarr: Serie[] = [{ id: "pay rate", data: arr }];
  console.log(newarr);

  if (loading) return <></>;
  return (
    <div style={{ paddingBottom: "20px" }}>
      <Paper style={{ padding: 20 }}>
        <div style={{ position: "relative", height: "80px" }}>
          <div
            style={{
              position: "absolute",
              right: 0,
              width: "300px",
              textAlign: "right",
            }}
          >
            <H4></H4>
          </div>
          <div
            style={{
              position: "absolute",
              left: 0,
              width: "300px",
              textAlign: "left",
            }}
          >
            <H4>Pay rate history</H4>
          </div>
        </div>
        <>
          <div style={{ height: "400px" }}>
            <ResponsiveLine
              data={newarr}
              margin={{
                top: 30,
                right: 30,
                bottom: 50,
                left: 60,
              }}
              colors={{ scheme: "accent" }}
              axisLeft={{
                tickValues: arr.length,
                tickSize: 0,
                tickPadding: 30,
              }}
              lineWidth={3}
              pointSize={9}
              enablePointLabel={true}
              enableGridX={false}
              xScale={{
                type: "point",
              }}
              yScale={{
                type: "linear",
                stacked: true,
                min: 0,
                max: "auto",
              }}
              curve="linear"
              animate={true}
              enableSlices="x"
            />
          </div>
        </>
      </Paper>
    </div>
  );
};

export default WorkerPayHistory;
