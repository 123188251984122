import { auth } from "utils";
import { Button, Menu, MenuItem, IconButton, Icon } from "@mui/material";
import { useEffect, useState, useRef } from "react";
import { useUserQuery } from "generated";
import styled from "styled-components";
import { useHistory, useParams } from "react-router-dom";
import { FiLogOut, FiUser } from "react-icons/fi";
import LoginPage from "./LoginPage";

export default function LogIn() {
  const AvatarNew = styled.img`
    height: 40px;
    width: 40px;
    border-radius: 50%;
  `;

  const { path, block, part } = useParams<{
    path?: string;
    block?: string;
    part?: string;
  }>();

  const history = useHistory();

  const [error, setError] = useState<any>();

  const { data } = useUserQuery({
    variables: { id: auth.user()?.id },
    context: {
      headers: {
        "x-hasura-role": "me",
      },
    },
  });

  useEffect(() => {
    error && console.log(error);
  }, [error]);

  const divRef: any = useRef();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(divRef.current);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      {auth.isAuthenticated() === true ? (
        <>
          <IconButton onClick={handleClick} ref={divRef} size="large">
            <AvatarNew src={data?.users_by_pk?.avatar_url!} />
          </IconButton>

          <Menu
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            onClick={handleClose}
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            transformOrigin={{ vertical: "top", horizontal: "center" }}
          >
            <MenuItem
              onClick={() =>
                history.push(
                  `/team/${data?.users_by_pk?.account?.cdm_workers?.cdm_workerid}/info`
                )
              }
            >
              <FiUser style={{ marginRight: "20px" }} />
              My account
            </MenuItem>

            <MenuItem onClick={() => auth.logout()}>
              <FiLogOut style={{ marginRight: "20px" }} />
              Log out
            </MenuItem>
          </Menu>
        </>
      ) : (
        <></>
      )}
    </>
  );
}
