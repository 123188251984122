import React from "react";
import {
  Dialog,
  Button,
  Snackbar,
  IconButton,
  Divider,
} from "@mui/material";
import MuiAlert from '@mui/material/Alert';
import CloseIcon from "@mui/icons-material/Close";
import { useState } from "react";
import { H1 } from "components/styled";
import styled from "styled-components";

const StyledPage = styled.div`
  font-family: Inter;
  font-size: 14px;
  padding: 20px;
`;

const Grid = styled.div`
  display: grid;
  grid-gap: 3vh;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 10px;
`;


export default function NewDialog(props: any) {
  const [openDialog, setOpenDialog] = useState(false);
  const [openSnack, setOpenSnack] = useState(false);
  const [negativeSnack, setNegativeSnack] = useState(false);

  const submitButton = () => {
    setOpenDialog(true);
    setOpenSnack(false);
  };

  const handleCloseSnack = (event: any, reason: any) => {
    if (reason === "clickaway") {
      return setOpenSnack(false);
    }
    setOpenSnack(false);
  };

  const closeDialog = () => {
    setOpenDialog(false);
    setNegativeSnack(true);
    setOpenSnack(true);
  };
  const buttonAgree = () => {
    setOpenDialog(false);
    setNegativeSnack(false);
    setOpenSnack(true);
    props.valCh(false);
    props.valTxt();
    props.valDate(Date.now());
  };

  let varia: any;
  let name = "";

  if (negativeSnack === false) {
    varia = "success";
    name = "All done!";
  } else if (negativeSnack === true) {
    varia = "error";
    name = "Form is not submitted";
  }

  return (
    <div
      style={{
        margin: "10px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Button variant="outlined" onClick={() => submitButton()}>
        Submit
      </Button>

      <Dialog open={openDialog}  >
        <StyledPage>
          <div style={{ display: "flex", flexDirection: "row", alignItems:'center' }}>
            <H1>Submition</H1>
            <IconButton onClick={() => closeDialog()} style={{marginLeft:'auto'}} size="large">
              <CloseIcon  />
            </IconButton>
          </div>
          <Divider />
          <Grid>
            <text> Please, check entered data</text>
            <text> {props.data}</text>
            <div style={{ display: "flex", flexDirection: "row", gridGap:'2vw', marginLeft:'auto'}}>
              <Button
                variant="outlined"
                onClick={() => closeDialog()}
              >
                Disagree
              </Button>
              <Button
                type="submit"
                variant="outlined"
                onClick={() => buttonAgree()}
              >
                Agree
              </Button>
            </div>
          </Grid>
        </StyledPage>
      </Dialog>

      <Snackbar
        open={openSnack}
        autoHideDuration={2000}
        onClose={handleCloseSnack}
      >
        <MuiAlert severity={varia}>{name}</MuiAlert>
      </Snackbar>
    </div>
  );
}
