import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import {
  ThemeProvider as StyledThemeProvider,
  BaseStyles,
} from "@primer/components";
import { ApolloClient, InMemoryCache, ApolloProvider } from "@apollo/client";
import StylesProvider from "@mui/styles/StylesProvider";
import {
  unstable_createMuiStrictModeTheme as createMuiTheme,
  ThemeProvider,
  responsiveFontSizes,
} from "@mui/material";
import { NhostAuthProvider } from "@nhost/react-auth";
import { auth } from "./utils/nhost";
import { NhostApolloProvider } from "@nhost/react-apollo";
import { RecoilRoot } from "recoil";

const theme = responsiveFontSizes(
  createMuiTheme({
    components: {
      MuiTextField: {
        defaultProps: {
          variant: "outlined",
          size: "small",
        },
      },
      MuiButton: {
        defaultProps: {
          variant: "outlined",
          size: "small",
        },
      },
      MuiToggleButton:{
        styleOverrides: {
          root: {
            margin: '5px 10px 5px 0px',
            padding: '3px 7px',
            textTransform: 'capitalize',
            fontsize: 'small',
            fontWeight: 'normal',
            lineHeight: 'normal',
            fontFamily: 'Inter'
          },
        },
        defaultProps: {
          size: "small",
        },
      }
    },
    palette: {
      primary: {
        main: "rgba(0, 122, 255, 0.5)",
      },
      secondary: {
        main: "rgba(0, 155, 255, 0.25)",
      },
    },
    typography: {
      fontFamily: "Inter",
    },
  })
);

const client = new ApolloClient({
  uri: "https://dev.archimatika.com/hasura/v1/graphql",
  cache: new InMemoryCache({}),
});

ReactDOM.render(
  <React.StrictMode>
    <RecoilRoot>
      <NhostAuthProvider auth={auth}>
        {/* <StyledThemeProvider> */}
          <ThemeProvider theme={theme}>
            <BaseStyles>
              <StylesProvider injectFirst>
                {/* <ApolloProvider client={client}> */}
                <NhostApolloProvider
                  auth={auth}
                  gqlEndpoint={`https://dev.archimatika.com/hasura/v1/graphql`}
                  cache={
                    new InMemoryCache({
                      addTypename: false,
                      typePolicies: {
                        UnconventionalRootQuery: {
                          // The RootQueryFragment can only match if the cache knows the __typename
                          // of the root query object.
                          queryType: true,
                        },
                      },
                    })
                  }
                >
                  <App />
                </NhostApolloProvider>
                {/* </ApolloProvider> */}
              </StylesProvider>
            </BaseStyles>
          </ThemeProvider>
        {/* </StyledThemeProvider> */}
      </NhostAuthProvider>
    </RecoilRoot>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
