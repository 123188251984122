import styled from "styled-components";

const Style = styled.div`
  border-spacing: 0;
width: fill-available;
  /* height: 600px; */
  height: 100%;
  margin: 20px;
  // overflow: auto;
  border-radius: 5px;
  box-shadow: rgb(15 15 15 / 10%) 0px 0px 0px 1px,
    rgb(15 15 15 / 10%) 0px 2px 4px;

  th {
    padding: 5px 5px;
    color: #555555;
    border-right: 1px solid rgb(237, 237, 236);
    border-top: 1px solid rgb(237, 237, 236);
    position: sticky;
    top: 0;
    background: white;
  }
  tr {
    &:hover {
      border-bottom: 2px #ebebea solid;
    }
  }
  td {
    padding: 0.1rem 1rem;
    border-top: 1px solid rgb(237, 237, 236);
    border-bottom: 1px solid rgb(237, 237, 236);
    border-right: 1px solid rgb(237, 237, 236);
  }
  table {
    width: fill-available;
  }
`;

export const StyleTable = (props: any) => {
  return <Style {...props} />;
};
