import {
  useSelectPhaseProjectsWithBudgetsQuery,
  SelectPhaseProjectsWithBudgetsQuery,
} from "../../generated/graphql";
import { useEffect, useState } from "react";
import styled from "styled-components";
import { useHistory, useParams } from "react-router-dom";
import { useTable } from "react-table";
import { info } from "console";
import Table, { Td } from "../styled/Table";

const Search = styled.input``;

const Projects = (props: any) => {
  const { path, block, part } = useParams<{
    path?: string;
    block?: string;
    part?: string;
  }>();

  const history = useHistory();
  const { loading, data } = useSelectPhaseProjectsWithBudgetsQuery();
  const [search, setSearch] = useState("");
  const [searchManager, setSearchManager] = useState("");

  const [filteredProjects, setFilteredProjects] = useState<
    SelectPhaseProjectsWithBudgetsQuery["pwa_projects"]
  >();
  console.log(search);
  console.log(searchManager);

  const pp = data?.pwa_projects as SelectPhaseProjectsWithBudgetsQuery["pwa_projects"];

  useEffect(() => {
    if (pp) {
      const projects = pp?.filter(
        (project) =>
          project?.name?.toLowerCase().includes(search.toLowerCase()) &&
          project?.owner_name?.toLowerCase().includes(searchManager.toLowerCase())
      );
      console.log(search);
      console.log(searchManager);
      setFilteredProjects(projects);
    }
  }, [search,searchManager, pp]);

  if (loading) return <></>;
  return (
    <div>
      <Search
        type="text"
        placeholder="Filter by Project"
        onChange={(e) => setSearch(e.target.value)}
      />
      <Search
        type="text"
        placeholder="Filter by Manager"
        onChange={(e) => setSearchManager(e.target.value)}
      />
      <Table>
        <thead></thead>
        <tbody>
          {filteredProjects?.map((project) => (
            <tr>
              <td>{project.macro_project}</td>
              {/* <Td onClick={() => history.push(`/${path}/${project.name}`)}>
                {project.name}
              </Td> */}
              <Td >
                {project.name}
              </Td>
              <Td>{project.owner_name}</Td>
              {/* <td>{project.revisions.aggregate?.count}</td>
              <td>{project.budget[0].revision_number}</td> */}
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
};

export default Projects;
