import { Paper, H1, H4 } from "../../../styled";
import { Divider } from "@mui/material";


const HireForm = () => {
  return (
    <div
      style={{
        justifyContent: "center",
        alignItems: "center",
        display: "flex",
        margin: "30px",
      }}
    >
      <Paper>
        <div style={{ padding: "20px" }}>
          <H4> Hire worker </H4>
          <Divider />
          <H4>Hire worker</H4>
        </div>
      </Paper>
    </div>
  );
};

export default HireForm;
