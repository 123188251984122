import { useParams, useHistory } from "react-router";
import { useState } from "react";
import TerminateForm from "./Forms/F_Terminate";
import { Autocomplete } from '@mui/material';
import { TextField } from "@mui/material";
import ChangePayrateForm from "./Forms/F_ChangePayrate";
import ChangePositionForm from "./Forms/F_ChangePosition";
import HireForm from "./Forms/F_HireWorker";
import NewPositionForm from "./Forms/F_NewPosition";

const SelectForm = (props: any) => {
  const { path, block, part } = useParams<{
    path?: string;
    block?: string;
    part?: string;
  }>();
  const [vval] = useState();
  const [val, setVal] = useState("");
  console.log(val);

  const options: any[] = [
      "Create new position",
      "Hire new worker",
      "Change worker position",
      "Change worker pay rate",
      "Terminate worker", 
    ];
  

  const history = useHistory();
  return (
    <div style={{ marginTop: "20px", marginBottom: "20px" }}>
      <Autocomplete
        value={vval}
        inputValue={val}
        onInputChange={(event, value) => setVal(value)}
        options={options}
        renderInput={(params) => <TextField {...params} label="Select form" />}
      />
      
        {val === "Terminate worker" && <TerminateForm />}
        {val === "Hire new worker" && <HireForm/>}
        {val === "Change worker position" && <ChangePositionForm/>}
        {val === "Change worker pay rate" && <ChangePayrateForm/>}
        {val === "Create new position" && <NewPositionForm/>}
      
     
    </div>
  );
};

export default SelectForm;
