import { Button, Avatar } from "@mui/material";
import { Paper, H1, H3, Text } from "components/styled";
import styled from "styled-components";
import { auth } from "utils";

const StyledPage = styled.div`
  font-family: Inter;
  font-size: 14px;
  padding: 20px;
`;

const StyledImage = styled.img`
  height: 50px;
  width: 50px;
`;

const Grid = styled.div`
  display: grid;
  grid-gap: 3vh;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`;

function LoginPage(props: any) {
  return (
    <div style={{ top: "30%", position: "fixed", left: "40%" }}>
      <StyledPage>
        <Paper style={{ padding: "40px" }}>
          <Grid>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <StyledImage src={"logo.png"} />
            </div>

            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <div style={{ textAlign: "center" }}>
                {/* <h1>Please login</h1> */}

                {/* <Text>Пожалуйста, войдите в свой аккаунт</Text> */}
              </div>
            </div>

            <Button variant="contained" onClick={() => auth.login({ provider: "google" })} fullWidth>
              Login with Google
            </Button>
          </Grid>
        </Paper>
      </StyledPage>
    </div>
  );
}

export default LoginPage;
