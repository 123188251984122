import { useWorkerDetailInfoPersonalQuery } from "../../generated/graphql";
import styled from "styled-components";
import { useParams } from "react-router-dom";
import { H4, Paper } from "components/styled";

const Grid = styled.div`
  display: grid;
  grid-template-columns: 150px 250px;
`;

const WorkerLeave = () => {
  const { block } = useParams<{
    path?: string;
    block?: string;
    part?: string;
  }>();

  const { loading, data } = useWorkerDetailInfoPersonalQuery({
    variables: { id: block },
    context: {
      headers: {
        "x-hasura-role": "me",
      },
    },
  });

  const options: any = { dateStyle: "short" };

  const leave = data?.hr_cdm_workers[0]?.cdm_leaveenrollments[0];
  const leavestart = new Date(leave?.cdm_startdate).toLocaleString(
    "ru",
    options
  );
  const start = new Date(leave?.cdm_startdate);
  let leaveend;
  if (new Date(leave?.cdm_enddate).getFullYear() === 2154) {
    leaveend = "∞";
  } else {
    leaveend = new Date(leave?.cdm_enddate).toLocaleString("ru", options);
  }
  const leaveplan = leave?.cdm_leaveplan?.cdm_name;
  const leavedays = Math.ceil(
    Math.abs(Date.now().valueOf() - start.valueOf()) / (1000 * 3600 * 24)
  );

  if (loading) return <></>;
  return (
    <div>
      <Paper style={{ padding: 20 }}>
        <div style={{ position: "relative", height: "80px" }}>
          <div
            style={{
              position: "absolute",
              right: 0,
              width: "300px",
              textAlign: "right",
            }}
          >
            <H4> active leave plan</H4>
          </div>
          <div
            style={{
              position: "absolute",
              left: 0,
              width: "300px",
              textAlign: "left",
            }}
          >
            {leaveplan ? <H4> {leaveplan}</H4> : <H4> No Plan</H4>}
          </div>
        </div>
        {leaveplan ? (
          <>
            <Grid>
              <p> Dates:</p>
              <p>
                {leavestart} - {leaveend}
              </p>
              <p> Leave period:</p>
              <p>{leavedays} days </p>
            </Grid>
          </>
        ) : (
          <p> Empty 😢 </p>
        )}
      </Paper>
    </div>
  );
};

export default WorkerLeave;
