import React from "react";
import styled from "styled-components";

const StyledH1 = styled.h1`
  text-transform: capitalize;
  font-weight: 700;
`;

export const H1 = (props: any) => {
  return <StyledH1 {...props} />;
};

