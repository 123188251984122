import { createClient } from "nhost-js-sdk";

const origin = window.location.origin;
const BACKEND_ENDPOINT =
  origin === "http://localhost:3000"
    ? "https://auth.archimatika.com/dev"
    : "https://auth.archimatika.com";

const config = {
  baseURL: BACKEND_ENDPOINT,
};

const { auth, storage } = createClient(config);

export { auth, storage };
