import { useHistory, useParams } from "react-router-dom";
import { Text, Link, Tag } from "../styled";
import { Td } from "../styled/Table";

const WorkerTable = (props: any) => {
  const { path, block, part } = useParams<{
    path?: string;
    block?: string;
    part?: string;
  }>();
  const history = useHistory();

  return (
    <tbody>
      <tr>
        <td>
          {
            props.worker.cdm_positionworkerassignmentmaps[0]?.cdm_jobposition
              ?.cdm_department?.cdm_name
          }
        </td>
        <Td
          onClick={() =>
            history.push(`/${path}/${props.worker.cdm_workerid}/info`)
          }
        >
          {props.worker.cdm_fullname}
        </Td>
        <td>
          <p style={{ margin: 3 }}>
            {" "}
            {
              props.worker.cdm_positionworkerassignmentmaps[0]?.cdm_jobposition
                ?.cdm_description
            }{" "}
          </p>
        </td>
        <td>
          <p style={{ marginTop: 5, marginBottom: 3 }}>
            {props.worker.cdm_positionworkerassignmentmaps[0]?.cdm_jobposition
              ?.cdm_job?.cdm_jobtype?.cdm_name ? (
              <Tag id="JC">
                {
                  props.worker.cdm_positionworkerassignmentmaps[0]
                    ?.cdm_jobposition?.cdm_job?.cdm_jobtype?.cdm_name
                }
              </Tag>
            ) : (
              <></>
            )}

            {props.worker.cdm_positionworkerassignmentmaps[0]?.cdm_jobposition
              ?.cdm_description === undefined ? (
              <Tag id="Nn">{"Not employed"}</Tag>
            ) : props.worker.cdm_type!.toString() === "754400000" ? (
              <Tag id="EM">{"Employee"}</Tag>
            ) : (
              <Tag id="CT">{"Contractor"}</Tag>
            )}
          </p>

          <p style={{ marginTop: 8, marginBottom: 3 }}>
            {props.Teamleads.includes(props.worker.cdm_workerid) ? (
              <Tag id="TL">Teamlead</Tag>
            ) : (
              <></>
            )}
          </p>
        </td>
        {props.type === "partners" && (
          <td>{props?.worker?.arm_partnerroleasiignment[0]?.start_date}</td>
        )}
       
        {/* <td>
          {props.worker.cdm_firstnameukr_custom}{" "}
          {props.worker.cdm_lastnameukr_custom}
        </td> */}
        <td>
          {" "}
          <Text>
            <Link href={"tel:" + props.worker.cdm_primarytelephone}>
              {props?.worker?.cdm_primarytelephone?.split(" ").join("")}
            </Link>
          </Text>
        </td>
        <td>
          <Text>
            <Link href={"mailto:" + props.worker.cdm_primaryemailaddress}>
              {props.worker.cdm_primaryemailaddress}
            </Link>
          </Text>
        </td>
      </tr>
    </tbody>
  );
};

export default WorkerTable;
