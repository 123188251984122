import React from "react";
import styled from "styled-components";

const StyledTable = styled.table`
  border-spacing: 0;
  border-bottom: 1px solid rgb(237, 237, 236);

  th {
    color: #555555;
    &:hover {
      background-color: none;
      cursor: auto;
    }
  }
  tr {
    &:hover {
      background-color: #f7f6f3;
      cursor: pointer;
    }
    :last-child {
      border-bottom: 2px solid rgb(237, 237, 236);
    }
  }

  td {
    padding: 0.1rem 1rem;
    border-top: 1px solid rgb(237, 237, 236);
    border-bottom: 1px solid rgb(237, 237, 236);
    border-right: 1px solid rgb(237, 237, 236);

    :last-child {
      border-right: 0;
    }
  }
`;

export const Td = styled.td`
  &:hover {
    background-color: #e8e7e4;
  }
`;

export const TrH1 = styled.tr`
  td {
    padding-top: 20px;
    border: none;
    color: #4b4b4b;
  }
  
  border: none;
  margin-top: 12px;
  text-transform: capitalize;
  font-weight: 700;
`;

const Table = (props: any) => {
  return <StyledTable {...props} />;
};

export default Table;
