import { Navbar } from "./utils/types";

export const nav: Navbar = {
  name: "archimatika",
  blocks: [
    {
      name: "hr",
      content: [
        {
          name: "team",
          path: "/team",
          emoji: "🏃‍♂️",
          description:
            "List of active workers",
        },
        {
          name: "partners",
          path: "/partners",
          emoji: "🤝",
          description:
            "List of active company partmenrs",
        },
        // {
        //   name: "payrates",
        //   path: "/payrates",
        //   emoji: "🪙",
        //   description:
        //     "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
        // },
        // {
        //   name: "office map",
        //   path: "/office_map",
        //   emoji: "🗺️",
        //   description:
        //     "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
        // },
        // {
        //   name: "manage",
        //   path: "/manage",
        //   emoji: "📝",
        //   description:
        //     "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
        // },
      ],
    },
    {
      name: "ppm",
      content: [
        {
          name: "projects",
          path: "/projects",
          emoji: "💼",
          description:
            "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
        },
        // {
        //   name: "funds",
        //   path: "/funds",
        //   emoji: "📈",
        //   description:
        //     "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.",
        // },
        {
          name: "dividends",
          path: "/dividends",
          emoji: "💸",
          description:
            "Dividends balance by project",
        },
      ],
    },
  ],
};
