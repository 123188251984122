import React, { useState } from "react";
import Nav from "./Nav";
import { nav } from "../config";
import { Route, Switch, useParams } from "react-router-dom";
import styled from "@emotion/styled";
import PageContent from "./PageContent";
import Login from "./Login";

const StyledPage = styled.div`
  font-family: Inter;
  font-size: 14px;
  display: flex;
  flex-direction: row;
  height: 100vh;
`;

const Page = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { path } = useParams<{ path?: string }>();
  console.log(path);
  return (
    <StyledPage>
      <Nav isOpen={isOpen} blocks={nav.blocks} />
      <PageContent />
    </StyledPage>
  );
};

export default Page;
