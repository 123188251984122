import { useTable, useGroupBy, useExpanded, useSortBy } from "react-table";
import { FiArrowDown, FiArrowUp } from "react-icons/fi";


function MainRTableDiv({ columns, data, updateMyData, skipPageReset}: any) {

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state: { groupBy, expanded },
  } = useTable(
    {
      columns,
      data: data,
      autoResetPage: !skipPageReset,
      updateMyData,
    },
    useGroupBy,
    useSortBy,
    useExpanded
  );

  // Render the UI for your table
  return (
    <table {...getTableProps()}>
      <thead>
        {headerGroups.map((headerGroup) => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              <th {...column.getHeaderProps()}>
                <span {...column.getSortByToggleProps()}>
                  {column.render("Header")}{" "}
                  {/* Add a sort direction indicator */}
                  {column.isSorted ? (
                    column.isSortedDesc ? (
                      <FiArrowDown size={17} />
                    ) : (
                      <FiArrowUp size={17} />
                    )
                  ) : (
                    ""
                  )}
                </span>
                {column.canGroupBy ? (
                  // If the column can be grouped, let's add a toggle
                  <span {...column.getGroupByToggleProps()}>
                    {column.isGrouped ? " 🛑 " : " 👊 "}
                  </span>
                ) : null}
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row, i) => {
          prepareRow(row);
          return (
            <tr {...row.getRowProps()}>
              {row.cells.map((cell) => {
                return (
                  <td
                    {...cell.getCellProps()}
                    style={{ padding: "5px 5px" }}
                  >
                    {cell.isGrouped ? (
                      <div
                        style={{ display: "inline-flex", whiteSpace: "nowrap" }}
                      >
                        <span {...row.getToggleRowExpandedProps()}>
                          {row.isExpanded ? "▾" : "▸"}
                        </span>{" "}
                        {cell.render("Cell")} ({row.subRows.length})
                      </div>
                    ) : cell.isPlaceholder ? null : (
                      <div>
                        {cell.render("Cell")}
                      </div>
                    )}
                  </td>
                );
              })}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}

export default MainRTableDiv;
