import React from "react";
import styled from "styled-components";

const StyledH3 = styled.h2`
  text-transform: capitalize;
  font-weight: 700;
`;

export const H3 = (props: any) => {
  return <StyledH3 {...props} />;
};

