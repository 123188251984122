function DefaultColumnFilter({
    //@ts-ignore
    column: { filterValue, preFilteredRows, setFilter },
  }) {
    const count = preFilteredRows.length
  
    return (
      <input
      style={{width:'65px', marginTop: "5px"}}
        value={filterValue || ''}
        onChange={e => {
          setFilter(e.target.value || undefined) // Set undefined to remove the filter entirely
        }}
        placeholder={`Search`}
      />
    )
  }
  export default DefaultColumnFilter;