import styled from "styled-components";


const NewLink = styled.a`
  text-decoration: none;
  color: inherit;
  background-image: linear-gradient(
    to right,
    rgba(55, 53, 47, 0.16) 0%,
    rgba(55, 53, 47, 0.16) 100%
  );
  background-repeat: repeat-x;
  background-position: 0px 100%;
  background-size: 100% 1px;

  &:hover {
    cursor: pointer;
  }
`;


export const Link = (props: any) => {
  return <NewLink {...props} />;
};

