import styled from "styled-components";
import ToggleButton from "@mui/material/ToggleButton";

const StyledButton = styled(ToggleButton)`
  text-transform: capitalize;
  margin: 5px 10px 5px 0px;
  padding: 3px 7px;
  border-radius: 3px;
  font-size: small;
  font-weight: normal;
  line-height: normal;
  font-family: Inter;
  &: hover {
    background-color: ${(props) =>
      props.id === "EM"
        ? "#9FC680"
        : (props) =>
            props.id === "TL"
              ? "#99AFC2"
              : (props) =>
                  props.id === "CT"
                    ? "#F9C74F"
                    : (props) => (props.id === "JC" ? "#BDE0EF" : "#a8a8a8")};
  }
  &.MuiToggleButton-root.Mui-selected {
    border: 0px;
    color: black;
    background-color: ${(props) =>
      props.id === "EM"
        ? "#9FC680"
        : (props) =>
            props.id === "TL"
              ? "#99AFC2"
              : (props) =>
                  props.id === "CT"
                    ? "#F9C74F"
                    : (props) => (props.id === "JC" ? "#BDE0EF" : "#a8a8a8")};
  }
`;
export const StyledToggleButton = (props: any) => {
  return <StyledButton {...props} />;
};
