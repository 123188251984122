import styled from "styled-components";

const StyledTag = styled.label`
  margin: 5px 10px 5px 0px;
  padding: 3px 7px;
  border-radius: 3px;
  font-size: small;
  font-weight: normal;
  line-height: normal;
  font-family: Inter;
  white-space: nowrap;
  color: black;
  background-color: ${(props) =>
    props.id === "EM"
      ? "#9FC680"
      : (props) =>
          props.id === "TL"
            ? "#99AFC2"
            : (props) => (props.id === "CT" ? "#F9C74F" : (props) =>
            props.id === "JC"
              ? "#BDE0EF" : "#a8a8a8")};
`;
export const Tag = (props: any) => {
  return <StyledTag {...props} />;
};

