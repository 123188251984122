import styled from "styled-components";
import {Paper} from "./Paper"

const StyledPaper = styled(Paper)`
&:hover {
  background-color: rgba(55, 53, 47, 0.03);
  /* box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2); */
  cursor: pointer;
}
}
`;

export const HoveredPaper = (props: any) => {
  return <StyledPaper {...props} />;
};
