import { useWorkerDetailInfoPersonalQuery } from "../../generated/graphql";
import { useParams, useHistory } from "react-router-dom";
import { H4, HoveredPaper } from "components/styled";
import styled from "styled-components";
import { Tooltip } from "@mui/material";

const Grid = styled.div`
  display: grid;
  grid-template-columns: 150px 250px;
`;

const WorkerCompensation = () => {
  const { path, part, block } = useParams<{
    path?: string;
    block?: string;
    part?: string;
  }>();

  const { loading, data } = useWorkerDetailInfoPersonalQuery({
    variables: { id: block },
    context: {
      headers: {
        "x-hasura-role": ["me"],
      },
    },
  });
  const history = useHistory();

  const options: any = { dateStyle: "short" };

  const comp = data?.hr_cdm_workers[0]?.cdm_workerfixedcompensations[0];
  const compstart = new Date(comp?.cdm_effectivedate).toLocaleString(
    "ru",
    options
  );
  let compend;
  if (new Date(comp?.cdm_expirationdate).getFullYear() === 2154) {
    compend = "∞";
  } else {
    compend = new Date(comp?.cdm_expirationdate).toLocaleString("ru", options);
  }
  const compplan = comp?.cdm_compensationfixedplan?.cdm_description;
  const complvl = comp?.cdm_compensationlevel?.cdm_name;
  const comppay = comp?.cdm_payrate;

  if (loading) return <></>;
  return (
    <Tooltip title="Click to see more details" >
      <div>
        <HoveredPaper
          onClick={() => history.push(`/${path}/${block}/payhistory`)}
        >
          <div style={{ padding: "20px" }}>
            <div style={{ position: "relative", height: "80px" }}>
              <div
                style={{
                  position: "absolute",
                  right: 0,
                  width: "300px",
                  textAlign: "right",
                }}
              >
                <H4> active compensation plan</H4>
              </div>
              <div
                style={{
                  position: "absolute",
                  left: 0,
                  width: "300px",
                  textAlign: "left",
                }}
              >
                {comp ? <H4> {compplan}</H4> : <H4> No plan</H4>}
              </div>
            </div>

            {comp ? (
              <>
                <Grid>
                  <>
                    <p> Dates:</p>
                    <p>
                      {compstart} - {compend}
                    </p>
                    <p> Grade: </p>
                    <p> {complvl} </p>
                    <p> Pay rate: </p>
                    <p> {comppay} </p>
                  </>
                </Grid>
              </>
            ) : (
              <p> Empty 😢 </p>
            )}
          </div>
        </HoveredPaper>
      </div>
    </Tooltip>
  );
};

export default WorkerCompensation;
