import * as React from "react";
import MainRTableDiv from "./MainReactTable";
import { Tag } from "../../styled";
import { StyleTable } from "../../styled/TableStyle";
import { valueFromASTUntyped } from "graphql";
import _ from "lodash";
import DefaultColumnFilter from "./DefaultFilter";


function RTableDiv(props: any) {
  const columns = React.useMemo(
    () => [
      {
        Header: "Партнер",
        accessor: (dataDivid: any) => dataDivid.dividends_worker_name,
        //@ts-ignore
        Cell: ({ cell: { value } }) => (
          <div style={{ whiteSpace: "nowrap" }}>{value}</div>
        ),
        disableFilters: false
      },
      {
        Header: "Макро",
        accessor: (dataDivid: any) => dataDivid.macroproject_name,
      },
      {
        Header: "Проект",
        accessor: (dataDivid: any) => dataDivid.project_name,
        //@ts-ignore
        Cell: ({ cell: { value } }) => (
          <div style={{ whiteSpace: "nowrap" }}>{value}</div>
        ),
      },
      {
        Header: "Руководитель проекта",
        accessor: (dataDivid: any) => dataDivid.project_lead_name,
        //@ts-ignore
        Cell: ({ cell: { value } }) => (
          <div style={{ whiteSpace: "nowrap" }}>{value}</div>
        ),
        disableFilters: true
      },
      {
        Header: "Менеджер проекта",
        accessor: (dataDivid: any) => dataDivid.project_manager_name,
        //@ts-ignore
        Cell: ({ cell: { value } }) => (
          <div style={{ whiteSpace: "nowrap" }}>{value}</div>
        ),
        disableFilters: true
      },
      {
        Header: "№ ревизии бюджета",
        accessor: (dataDivid: any) =>
          dataDivid.budget_revision == "null"
            ? "00"
            : dataDivid.budget_revision.length < 2
            ? "0" + dataDivid.budget_revision
            : dataDivid.budget_revision,
      },
      {
        Header: "% оплаты",
        accessor: (dataDivid: any) => Math.round(dataDivid.payment_accrued_percent*100*10)/10+ "%",
        canGroupBy: false,
        disableFilters: true
      },
      {
        Header: "Долевое участие",
        accessor: (dataDivid: any) => Math.round(dataDivid.dividend_proportion*100*10)/10+ "%",
        canGroupBy: false,
        disableFilters: true
      },
      {
        Header: "План бюджет, нал",
        accessor: (dataDivid: any) =>
          isNaN(dataDivid.dividends_planned_cash)
            ? "0"
            : new Intl.NumberFormat("en").format(
                Math.round(dataDivid.dividends_planned_cash)
              ),
        aggregate: (vals: any) =>
          _.sum(
            vals.map((v: string) => parseInt(v.replace(",", "")))
          ).toLocaleString("en"),
        Aggregated: ({ value }: any) =>  <div style={{ textAlign: "right" }}>{value}</div>,
        //@ts-ignore
        Cell: ({ cell: { value } }) => (
          <div style={{ textAlign: "right" }}>{value}</div>
        ),
        canGroupBy: false,
        disableFilters: true
      },
      {
        Header: "Начислено, нал",
        accessor: (dataDivid: any) =>
          isNaN(dataDivid.dividends_accrued_cash)
            ? "0"
            : new Intl.NumberFormat("en").format(
               Math.round(dataDivid.dividends_accrued_cash)
              ),
        aggregate: (vals: any) =>
          _.sum(
            vals.map(
              (v: string) => parseInt(v.split(",").join("")),
              // console.log(vals)
            )
          ).toLocaleString("en"),
        Aggregated: ({ value }: any) =>  <div style={{ textAlign: "right" }}>{value}</div>,
        //@ts-ignore
        Cell: ({ cell: { value } }) => (
          <div style={{ textAlign: "right" }}>{value}</div>
        ),
        canGroupBy: false,
        disableFilters: true
      },
      {
        Header: "Выплачено, нал",
        accessor: (dataDivid: any) =>
          isNaN(dataDivid.dividends_paid_cash)
            ? "0"
            : new Intl.NumberFormat("en").format(
                Math.round(dataDivid.dividends_paid_cash)
              ),
        aggregate: (vals: any) =>
          _.sum(
            vals.map(
              (v: string) => parseInt(v.split(",").join("")),
              console.log(vals)
            )
          ).toLocaleString("en"),
        Aggregated: ({ value }: any) =>  <div style={{ textAlign: "right" }}>{value}</div>,
        //@ts-ignore
        Cell: ({ cell: { value } }) => (
          <div style={{ textAlign: "right" }}>{value}</div>
        ),
        canGroupBy: false,
        disableFilters: true
      },
      {
        Header: "Остаток выплаты, нал",
        accessor: (dataDivid: any) =>
          isNaN(dataDivid.leftover_paid_cash)
            ? "0"
            : new Intl.NumberFormat("en").format(
                Math.round(dataDivid.leftover_paid_cash)
              ),
        aggregate: (vals: any) =>
          _.sum(
            vals.map(
              (v: string) => parseInt(v.split(",").join("")),
              console.log(vals)
            )
          ).toLocaleString("en"),
        Aggregated: ({ value }: any) =>  <div style={{ textAlign: "right" }}>{value}</div>,
        //@ts-ignore
        Cell: ({ cell: { value } }) => (
          <div style={{ textAlign: "right" }}>{value}</div>
        ),
        canGroupBy: false,
        disableFilters: true
      },
      {
        Header: "Остаток выплаты по закрытым проектам, нал",
        accessor: (dataDivid: any) =>
          isNaN(dataDivid.leftover_by_closed_projects_cash)
            ? "0"
            : Math.round(
                dataDivid.leftover_by_closed_projects_cash 
              ).toLocaleString("en"),
        aggregate: (vals: any) =>
          _.sum(
            vals.map(
              (v: string) => parseInt(v.split(",").join("")),
              console.log(vals)
            )
          ).toLocaleString("en"),
        Aggregated: ({ value }: any) =>  <div style={{ textAlign: "right" }}>{value}</div>,
        //@ts-ignore
        Cell: ({ cell: { value } }) => (
          <div style={{ textAlign: "right" }}>{value}</div>
        ),
        canGroupBy: false,
        disableFilters: true
      },
    ],
    []
  );

  return (
    <StyleTable>
      <MainRTableDiv
        columns={columns}
        data={props?.dataDivid ? props?.dataDivid : []}
      />
    </StyleTable>
  );
}

export default RTableDiv;
