import React from "react";
import styled from "styled-components";
import { Navbar } from "../utils/types";
import { Link, useHistory } from "react-router-dom";

const StyledNav = styled.div<Navbar>`
  font-family: Inter;
  display: flex;
  flex-direction: column;
  background-color: #f7f6f3;
  min-width: 288px;
  height: 100vh;
  font-size: 14px;
`;

const Logo = styled.img`
  width: 164px;
  mix-blend-mode: darken;
  opacity: 0.9;
  cursor: pointer;
`;

const Header = styled.div`
  display: flex;
  color: gray;
  width: 100%;
  height: 72px;
  border-bottom: solid 1px #efefef;
  padding-left: 32px;
  align-items: center;
`;
const Block = styled.div`
  padding-bottom: 24px;
`;
const BlockHeader = styled.div`
  margin-left: 24px;
  text-transform: uppercase;
  color: rgba(55, 53, 47, 0.6);
  cursor: default;
  *
`;

const Links = styled.div`
  display: flex;
  flex-direction: row;
  cursor: pointer;
  &:hover {
    background-color: #e8e7e4;
  }
`;

const StyledLink = styled.div`
  text-decoration: none;
  font-weight: 500;
  margin-left: 32px;
  color: #666666;
  cursor: default;
  text-transform: capitalize;
  line-height: 24px;
  cursor: pointer;
`;

const Nav: React.FC<Navbar> = (props) => {
  const history = useHistory();
  function goHome() {
    history.push("/home");
  }
  return (
    <StyledNav isOpen={props.isOpen}>
      <Header>
        <Logo src={"/logo.jpg"} onClick={goHome} />
      </Header>
      {props?.blocks?.map((block) => (
        <Block key={block.name}>
          <BlockHeader> {block.name}</BlockHeader>
          {block?.content?.map((link) => (
            <Links onClick={() => history.push(`${link!.path!}`)}>
              <StyledLink>
                {link.emoji} {link.name}
              </StyledLink>
            </Links>
          ))}
        </Block>
      ))}
    </StyledNav>
  );
};

export default Nav;
