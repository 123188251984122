import { Paper, H4, H3, Text } from "../../../styled";
import { Divider, TextField, Checkbox } from "@mui/material";
import { useWorkersInfoQuery } from "generated";
import { Autocomplete } from "@mui/material";
import { useState } from "react";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import DatePicker from "@mui/lab/DatePicker";
import styled from "styled-components";
import NewDialog from "../Components/Dialog";

const Grid = styled.div`
  display: grid;
  grid-gap: 1.5vh;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`;

const TerminateForm = () => {
  const { data: dataWorkes } = useWorkersInfoQuery();

  let workers: any[] = [];
  //@ts-ignore
  for (let i = 0; i < dataWorkes?.hr_cdm_workers!.length; i++) {
    workers.push(dataWorkes?.hr_cdm_workers[i].cdm_fullname);
  }
  const [vwname] = useState();
  const [wname, setWName] = useState("");

  const [selectedDate, setSelectedDate] = useState(Date.now());

  const [checked, setChecked] = useState(false);

  const enteredData = JSON.stringify({ wname, selectedDate, checked }, null, 2);

  return (
    <form
      style={{
        justifyContent: "center",
        alignItems: "center",
        margin: "30px",
      }}
    >
      <Paper>
        <div style={{ padding: "20px" }}>
          <H3> Terminate </H3>
          <Divider />
          <H4>Terminate worker</H4>
          <Grid>
            <div>
              <Text>Select worker to terminate</Text>
              <Autocomplete
                value={vwname}
                inputValue={wname}
                onInputChange={(event, value) => setWName(value)}
                options={workers}
                renderInput={(params) => <TextField {...params} />}
              />
            </div>
            <div>
              <Text>Last working day</Text>

              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  value={selectedDate}
                  onChange={(newValue:any) => {
                    setSelectedDate(newValue);
                  }}
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>
            </div>
            <div>
              <Checkbox
                checked={checked}
                onChange={(e) => setChecked(e.target.checked)}
              />
              <text> Retire position? </text>
            </div>
          </Grid>

          <NewDialog
            data={enteredData}
            valCh={setChecked}
            valTxt={setWName}
            valDate={setSelectedDate}
          />
        </div>
      </Paper>
    </form>
  );
};

export default TerminateForm;
