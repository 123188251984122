import {
  useSelectPhaseProjectsWithBudgetsQuery,
  SelectPhaseProjectsWithBudgetsQuery,
  
  useDividendsQuery,
} from "../../generated/graphql";
import { useEffect, useState } from "react";
import styled from "styled-components";
import { useHistory, useParams } from "react-router-dom";
import { useTable } from "react-table";
import { info } from "console";
import Table, { Td } from "../styled/Table";
import RTableDiv from "./ReactTable_dividends/WorkerReactTable_dividends";
import { auth } from "utils";
import jwt_decode from "jwt-decode";

const Search = styled.input``;

const STable = styled.table`
  table,
  th,
  td {
    border: 1px solid black;
  }
`;

const Dividends = (props: any) => {
  const { path, block, part } = useParams<{
    path?: string;
    block?: string;
    part?: string;
  }>();

  const decode: any = jwt_decode(auth.getJWTToken() as string);
  const roles: string[] =
    decode["https://hasura.io/jwt/claims"]["x-hasura-allowed-roles"];

  // console.log(role);
  const history = useHistory();
  const { loading, data } = useSelectPhaseProjectsWithBudgetsQuery();
  // const { data: datadiv } = useSelectDividendsByRecieverQuery();
  const { data: dataDivid } = useDividendsQuery({
    context: {
      headers: {
        "x-hasura-role": roles.includes("manager") ? "manager" : "user",
      },
    },
  });

  const [search, setSearch] = useState("");
  const [searchManager, setSearchManager] = useState("");

  const [filteredProjects, setFilteredProjects] = useState<
    SelectPhaseProjectsWithBudgetsQuery["pwa_projects"]
  >();
  // console.log(datadiv)

  // const pp = data?.pwa_projects as SelectPhaseProjectsWithBudgetsQuery["pwa_projects"];

  // useEffect(() => {
  //   if (pp) {
  //     const projects = pp?.filter(
  //       (project) =>
  //         project?.name?.toLowerCase().includes(search.toLowerCase()) &&
  //         project?.owner_name?.toLowerCase().includes(searchManager.toLowerCase())
  //     );
  //     console.log(search);
  //     console.log(searchManager);
  //     setFilteredProjects(projects);
  //   }
  // }, [search,searchManager, pp]);

  if (loading) return <div>...loading</div>;
  return (
    <div>
      {/* <Search
        type="text"
        placeholder="Filter by Project"
        onChange={(e) => setSearch(e.target.value)}
      />
      <Search
        type="text"
        placeholder="Filter by Manager"
        onChange={(e) => setSearchManager(e.target.value)}
      /> */}
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <div>
        <RTableDiv dataDivid={dataDivid?.dividends} />
        </div>
        {/* <Table>
          <thead>
            <tr>
              <th>Партнер</th>
              <th>Макропроект</th>
              <th>Проект</th>
              <th>Руководитель проекта</th>
              <th>Менеджер проекта</th>
              <th>№ ревизии буджета</th>
              <th>% оплаты</th>
              <th>План бюджет, нал</th>
              <th>Начислено, нал</th>
              <th>Выплачено, нал</th>
              <th>Остаток выплаты, нал</th>
              <th>Остаток выплаты по закрытым проектам, нал</th>
             
            </tr>
          </thead>
          <tbody>
            {dataDivid?.dividends.map((div) => (
              <tr>
                <td>{div.ResourceName}</td>
                <td>{div.Macroproject}</td>
                <td>{div.project_name}</td>
                <td>{div.ProjectLead}</td>
                <td>{div.ProjectManagerName}</td>
                <td>{div.budget_revision}</td>
                <td>{div.ProjectsState_Payment_accrued_percent}</td>
                <td>{div.dividends_planned_cash}</td>
                <td>{div.dividends_accrued_cash}</td>
                <td>{div.dividends_paid_cash}</td>
                <td>{div.leftover_paid_cash}</td>
                <td>{div.leftover_by_closed_projects_cash}</td>
              </tr>
            ))}
          </tbody>
        </Table> */}
      </div>
    </div>
  );
};

export default Dividends;
